import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useSelector, useDispatch, connect } from "react-redux";

import { callApi } from "../../Api";
import {
  changeLanguage,
  changeTheme,
  changeResize,
  sidebaropen,
} from "../../Store/Actions";
import { logout } from "../../Store/Actions/User";
import BreadCrum from "../BreadCrumb/BreadCrumb";

/******************* 
@Purpose : Used for header view
@Parameter : props
@Author : INIC
******************/
function Header(props) {
  const { userProfile } = useSelector((data) => data.user);
  const [openSideBar, setOpenSideBar] = useState(
    props.sidebar && props.sidebar.data
  );
  const [showEditProfile, setShowEditProfile] = useState(false);
  const url = props.match.path;
  const dispatch = useDispatch();

  let [photo] = useState(userProfile.photo);

  /******************* 
  @Purpose : Used for set url
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    if (url === "/profile" || userProfile.isPlanExpired) {
      setShowEditProfile(false);
    } else {
      setShowEditProfile(true);
    }
  }, []);

  /******************* 
  @Purpose : Used for sidebar open
  @Parameter : {}
  @Author : INIC
  ******************/
  const SidebarOpen = () => {
    document.body.classList.add("sidebar-open");
    setOpenSideBar(!openSideBar);
    props.sidebaropen(!openSideBar);
  };

  /******************* 
  @Purpose : Used for user logout
  @Parameter : {}
  @Author : INIC
  ******************/
  const onLogout = () => {
    dispatch(logout());
    props.history.push("/");
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <div id="container1" className="App">
      <div className="main-content-area">
        <header>
          <div className="navigation-bar">
            <nav className="navbar d-flex navbar-expand bd-navbar fixed-top justify-content-between">
              <div className="left-nav mr-auto d-flex align-items-center">
                <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                  <ul className="nav navbar-nav">
                    <li className="nav-item mobile-menu ps-0">
                      <span
                        onClick={() => {
                          SidebarOpen();
                        }}
                        className="nav-link nav-menu-main menu-toggle hidden-xs"
                      >
                        <i className="bx bx-menu"></i>
                      </span>
                    </li>
                  </ul>
                  <ul className="horizontal-brand nav navbar-nav">
                    <li>
                      <Link to="/">
                        <img
                          className="img-fluid"
                          src="/assets/images/brand-logo.svg"
                          alt="branding logo"
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
                <BreadCrum
                  breadcrumb={props.breadcrumb}
                  breadcrumbTitle={props.breadcrumbTitle}
                />
              </div>

              <ul className="navbar-nav flex-row ml-md-auto d-md-flex">
                <li className="nav-item user-dropdown dropdown">
                  <Dropdown className="custome-dropdown">
                    <Dropdown.Toggle
                      id="dropdown-basic1"
                      className="custome-btn"
                    >
                      <Link
                        className="nav-link dropdown-toggle dropdown-user-link"
                        to="#"
                        id="userDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-expanded="true"
                      >
                        <div className="user-nav d-sm-flex d-none">
                          <span className="user-name text-capitalize flex-shrink-0 fw-semibold">
                            {userProfile?.organization
                              ? userProfile?.organization
                              : ""}
                          </span>
                        </div>
                        {/* profile image */}
                        <span className="flex-shrink-0">
                          {photo ? (
                            <img
                              className="round"
                              src={userProfile.photo}
                              alt="avatar2"
                              height="28"
                              width="28"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/assets/images/user.png";
                              }}
                            />
                          ) : (
                            <img
                              className="round"
                              src="/assets/images/user.png"
                              alt="avatar"
                              height="28"
                              width="28"
                            />
                          )}
                        </span>
                      </Link>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {showEditProfile && (
                        <Dropdown.Item
                          onClick={() => props.history.push("/profile")}
                        >
                          <i className="icon-user me-2"></i>Edit Profile
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item onClick={() => onLogout()}>
                        <i className="icon-logout me-2"></i>Log out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </nav>
          </div>
          <div></div>
        </header>
      </div>
    </div>
  );
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  language: state.admin.language,
  theme: state.admin.theme,
  resize: state.admin.resize,
  sidebar: state.admin.sidebar,
  admindata: state.admin.adminData,
  editadminprofile: state.admin.editAdminProfileData,
});
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, {
  changeTheme,
  changeLanguage,
  changeResize,
  callApi,
  sidebaropen,
})(withRouter(Header));
