import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import { useHistory } from "react-router";

import { callApi } from "../../Api";
import {
  LIST_OF_VIDEO_AD,
  route,
  selectDurationPurchaseVideoAdSchema,
  showMessageNotification,
  VIDEO_AD_LIST,
} from "../../Utils";
import Layout from "../../Components/Layout/Layout";
import { uploadAdVideos } from "../../Store/Actions/BrandPromotedVideoAd";
import { Footer } from "../../Components/Layout";
import { fetchMiscInfo } from "../../Store/Actions/MiscInfo";

/******************* 
@Purpose : used for select Duration
@Parameter : {}
@Author : INIC
******************/
const SelectDuration = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [promotionAmountKey, setPromotionAmountKey] = useState("");
  const { purchasevideoDetail } = useSelector((data) => data?.brandvideos);
  const { miscinfo } = useSelector((data) => data?.admin);
  const [amountData, setAmountData] = useState("");
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    if (miscinfo) {
      setAmountData({
        videoAd_1_dayDuration: miscinfo?.[0]?.videoAd_1_dayPrice,
        videoAd_7_dayDuration: miscinfo?.[0]?.videoAd_7_dayPrice,
        videoAd_15_dayDuration: miscinfo?.[0]?.videoAd_15_dayPrice,
      });
    }
  }, [miscinfo]);

  useEffect(() => {
    dispatch(fetchMiscInfo());
  }, []);

  /******************* 
@Purpose : Used for Bread crumb
@Parameter : {}
@Author : INIC
******************/
  const breadcrumb = [
    {
      title: VIDEO_AD_LIST,
      url: route.PURCHASE_VIDEO_ADD,
    },
    {
      title: LIST_OF_VIDEO_AD,
      url: route.PURCHASE_VIDEO_ADD,
    },
  ];

  /******************* 
   @purpose : set formik validation schema
   @Parameter : {}
   @Author :INIC
   ******************/
  const formik = useFormik({
    initialValues: {
      ...purchasevideoDetail,
      PromotionDurationinDays: "",
      promotionStartDate: "",
    },
    enableReinitialize: true,
    validationSchema: selectDurationPurchaseVideoAdSchema(),
    onSubmit: (values) => {
      values.promotionAmountKey = promotionAmountKey;
      dispatch(uploadAdVideos(values)).then((res) => {
        if (res.status) {
          showMessageNotification(res?.message?.[0]?.msg, "success");
          history.push("/ManageVideoAd");
        } else {
          showMessageNotification(res?.message?.[0]?.msg, "error");
          history.push("/selectduration");
        }
      });
    },
  });

  const handleCancel = () => {
    history.push("/ManageVideoAd");
  };
  // disable past date

  function disablePastDatesAndCurrentTime() {
    const inputElement = document.querySelector(
      'input[name="promotionStartDate"]'
    );
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const currentDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;

    inputElement.min = currentDateTime;

    const currentDateTimeWithoutSeconds = `${year}-${month}-${day}T${hours}:${minutes}`;
    const currentDateTimeWithoutSecondsPlusOneMinute = `${year}-${month}-${day}T${hours}:${String(
      parseInt(minutes) + 1
    ).padStart(2, "0")}`;

    inputElement.addEventListener("input", function () {
      const selectedDateTime = this.value;

      if (
        selectedDateTime < currentDateTimeWithoutSeconds ||
        selectedDateTime === currentDateTimeWithoutSeconds ||
        selectedDateTime === currentDateTimeWithoutSecondsPlusOneMinute
      ) {
        this.value = currentDateTimeWithoutSecondsPlusOneMinute;
      }
    });
  }
  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <>
      <Layout breadcrumb={breadcrumb} breadcrumbTitle="Manage Video AD">
        <div className="dashboard-container">
          <div className="main-content-area">
            <div className="overlay" />
            <div className="main-content-block overflow-auto">
              <div className="content-area position-relative">
                <div className="grid">
                  <div className="grid-content">
                    {/* <!-- filterbar buttons --> */}

                    <div className="overlay" />
                    <form className="mb-7">
                      <div className="card profile-card mb-5 p-0">
                        <div className="card-header bg-white p-3">
                          <nav aria-label="breadcrumb">
                            <ol className="breadcrumb p-0">
                              <li
                                className="breadcrumb-item active"
                                aria-current="page"
                              >
                                Upload Video Ad
                              </li>
                              <li
                                className="breadcrumb-item "
                                aria-current="page"
                              >
                                <React.Fragment>Select Duration</React.Fragment>
                              </li>
                              <li className="breadcrumb-item active">
                                <React.Fragment>
                                  Select Payment Method
                                </React.Fragment>
                              </li>
                            </ol>
                          </nav>
                        </div>
                        <div className="card-body p-3">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group  mb-md-5 mb-3 ">
                                <label className="mb-2 xl-label" htmlFor="Name">
                                  Display Days
                                  <sup className="text-danger">*</sup>
                                </label>
                                <div
                                  className="d-flex mx-auto"
                                  value={
                                    formik?.values?.PromotionDurationinDays
                                  }
                                  onChange={(e) => {
                                    if (e.target.id === "1") {
                                      setAmount(
                                        amountData?.["videoAd_1_dayDuration"]
                                      );
                                      setPromotionAmountKey(
                                        "videoAd_1_dayPrice"
                                      );
                                    } else if (e.target.id === "7") {
                                      setAmount(
                                        amountData?.["videoAd_7_dayDuration"]
                                      );
                                      setPromotionAmountKey(
                                        "videoAd_7_dayPrice"
                                      );
                                    } else if (e.target.id === "15") {
                                      setAmount(
                                        amountData?.["videoAd_15_dayDuration"]
                                      );
                                      setPromotionAmountKey(
                                        "videoAd_15_dayPrice"
                                      );
                                    }

                                    formik.setFieldValue(
                                      "PromotionDurationinDays",
                                      e.target.id
                                    );
                                  }}
                                >
                                  <input
                                    id="1"
                                    type="radio"
                                    value={miscinfo?.[0]?.videoAd_1_dayDuration}
                                    name="PromotionDurationinDays"
                                    formik={formik}
                                  />
                                  <label>1 day</label>
                                  <input
                                    id="7"
                                    type="radio"
                                    name="PromotionDurationinDays"
                                    value={miscinfo?.[0]?.videoAd_7_dayDuration}
                                    className="mx-3"
                                  />
                                  <label>7 days</label>
                                  <input
                                    id="15"
                                    type="radio"
                                    name="PromotionDurationinDays"
                                    value={
                                      miscinfo?.[0]?.videoAd_15_dayDuration
                                    }
                                    className="mx-3"
                                  />
                                  <label>15 days</label>
                                </div>
                                {formik?.touched?.PromotionDurationinDays &&
                                  formik?.errors?.PromotionDurationinDays ? (
                                  <span className="text-danger">
                                    {formik?.errors?.PromotionDurationinDays}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="notification-form col-md-12 mx-5 px-5">
                                <div className="form-group mx-5 position-absolute px-5">
                                  <label className="mb-2" htmlFor="CountryName">
                                    Start Date
                                    <sup className="text-danger">*</sup>
                                  </label>

                                  <input
                                    type="datetime-local"
                                    className="d-flex"
                                    name="promotionStartDate"
                                    formik={formik}
                                    {...formik.getFieldProps(
                                      "promotionStartDate"
                                    )}
                                    onFocus={disablePastDatesAndCurrentTime}
                                  />

                                  {formik.touched.promotionStartDate &&
                                    formik.errors.promotionStartDate ? (
                                    <span className="text-danger">
                                      {formik.errors.promotionStartDate}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* style={{ marginTop: "-210px" }} */}
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Amount
                                  <sup className="text-danger">*</sup>
                                </label>
                                <input
                                  type="text"
                                  className="form-control back-color form-control"
                                  name="promotionAmountKey"
                                  value={amount}
                                  disabled
                                  placeholder="Enter promotionAmountKey"
                                />
                              </div>
                            </div>
                          </div>

                          {/* style={{ marginTop: "56px" }} */}
                          <div className="row " style={{ marginTop: "56px" }}>
                            <div className="col-md-12">
                              <div className="pt-3 d-flex justify-content-end align-item-center flex-wrap g-3">
                                <Button
                                  variant="light"
                                  className="mx-2"
                                  onClick={() => handleCancel()}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  variant="primary"
                                  onClick={() => formik.handleSubmit()}
                                >
                                  Pay
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </Layout>
    </>
  );
};

/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  UserAccess:
    state.admin.adminData &&
    state.admin.adminData.staticRolePermission &&
    state.admin.adminData.staticRolePermission.userAccess,
});
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, { callApi })(SelectDuration);
