//internal
import { callApi } from "../../Api";
import Routes from "../../Api/Routes"; // API endpoint routes
import { removeItem, showMessageNotification } from "../../Utils/Functions";
import { setTabsValue } from "./Tabs";

const {
  SET_PASSWORD,
  SAVE_BRAND_PROFILE,
  BRAND_IMAGE,
  SELECT_PLAN,
  LOGIN,
  LOGOUT,
  CHANGE_PASSWORD,
  PAYMENT,
  EDIT_BRAND_PROFILE,
  FORGOT,
  CMS,
} = Routes;

/*******************
@purpose : used for Reset Password
@Parameter : {data}
@Author : INIC
******************/
export const setPassword = (data) => async (dispatch) => {
  const res = await callApi(`${SET_PASSWORD}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      localStorage.setItem("id", res.data._id);
      localStorage.setItem("accessToken", res.access_token);
      dispatch(setUserProfile(res.data));
      showMessageNotification(res.message, "success");
    } else showMessageNotification(res.message, "error");
    return Promise.resolve(res);
  }
  return Promise.reject(res);
};

/*******************
@purpose :  set user profile
@Parameter : {data}
@Author : INIC
******************/
export const setUserProfile = (data) => ({
  type: "SET_USER_PROFILE",
  payload: data,
});

/*******************
@purpose :  save brand profile
@Parameter : {data}
@Author : INIC
******************/
export const saveBrandProfile = (data) => async (dispatch) => {
  const res = await callApi(`${SAVE_BRAND_PROFILE}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setUserProfile(res.data));
      showMessageNotification(res.message, "success");
    } else showMessageNotification(res.message, "error");
    return Promise.resolve(res);
  }
  return Promise.reject(res);
};

/*******************
@purpose :  used for upload profile picture
@Parameter : {formData2}
@Author : INIC
******************/
export const Uploadimages = (formData2) => async (dispatch) => {
  const res = await callApi(
    `${BRAND_IMAGE}`,
    formData2,
    "post",
    "IMAGES",
    null,
    true
  );
  if (res) {
    if (res.status) {
      dispatch(setUserProfile(res.data));
    }
    return Promise.resolve(res);
  }
  return Promise.reject(res);
};

/*******************
@purpose : used fo select plan
@Parameter : {}
@Author : INIC
******************/
export const getPlan = (body) => async (dispatch) => {
  const res = await callApi(`${SELECT_PLAN}`, body, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setPlanData(res));
    }
    return Promise.resolve(res);
  }
  return Promise.reject(res);
};

/*******************
@purpose :  set user plan data
@Parameter : {data}
@Author : INIC
******************/
export const setPlanData = (data) => ({
  type: "SET_PLAN_DATA",
  payload: data,
});

/*******************
@purpose :  used fo user login
@Parameter : {data}
@Author : INIC
******************/
export const login = (data) => async (dispatch) => {
  const res = await callApi(LOGIN, data, "post", "BRAND_LOGIN");
  if (res) {
    if (res?.status) {
      dispatch(setLoginData(res.data));
      showMessageNotification(res.message, "success");
    }
    return Promise.resolve(res);
  }
  return Promise.reject(res);
};

/*******************
@purpose :  set user profile
@Parameter : {data}
@Author : INIC
******************/
export const setLoginData = (data) => ({
  type: "SET_USER_PROFILE",
  payload: data,
});

/*******************
@purpose :  used for user logout
@Parameter : {}
@Author : INIC
******************/
export const logout = () => async (dispatch) => {
  removeItem("accessToken");
  removeItem("emailid");
  localStorage.clear();
  dispatch(removeLoginData());
  dispatch(setTabsValue());
  callApi(LOGOUT, "", "", "LOGOUT", true);
  showMessageNotification("Logged Out Successfully", "success");
};

/*******************
@purpose :  set user profile
@Parameter : {data}
@Author : INIC
******************/
export const removeLoginData = () => ({
  type: "SET_USER_PROFILE",
});

/*******************
@purpose :  used for payment
@Parameter : {data}
@Author : INIC
******************/
export const paymentData = (data) => async (dispatch) => {
  const res = await callApi(PAYMENT, data, "post", "", true);
  if (res) {
    if (res.status) {
      showMessageNotification(res.message, "success");
      dispatch(setpaymentData(res.data));
    } else showMessageNotification(res.message, "error");
    return Promise.resolve(res);
  }
  return Promise.reject(res);
};
/*******************
@purpose :  set user profile
@Parameter : {}
@Author : INIC
******************/

export const setpaymentData = (data) => ({
  type: "SET_USER_PROFILE",
  payload: data,
});

/*******************
@purpose :  used for set plan
@Parameter : {data}
@Author : INIC
******************/
export const setPlan = (data) => async (dispatch) => {
  dispatch(setPlanid(data));
};

/*******************
@purpose :  set user profile
@Parameter : {data}
@Author : INIC
******************/
export const setPlanid = (data) => ({
  type: "SET_PLAN_ID",
  payload: data,
});

/*******************
@purpose :  used for change password
@Parameter : {data}
@Author : INIC
******************/
export const changePassword = (data) => async () => {
  const res = await callApi(CHANGE_PASSWORD, data, "post", "", true);
  if (res) {
    if (res.status) {
      showMessageNotification(res.message, "success");
    } else showMessageNotification(res.message, "error");
    return Promise.resolve(res);
  }
  return Promise.reject(res);
};

/*******************
@purpose :  edit brand profile
@Parameter : {data}
@Author : INIC
******************/
export const editBrandProfile = (data) => async (dispatch) => {
  const res = await callApi(`${EDIT_BRAND_PROFILE}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setUserProfile(res?.data));
      showMessageNotification(res?.message, "success");
    } else {
      showMessageNotification(res?.message[0]?.msg, "error");
    }
    return Promise.resolve(res);
  }
  return Promise.reject(res);
};

/*******************
@purpose :  forgot password
@Parameter : {data}
@Author : INIC
******************/
export const forgotPasswordAction = (data) => async () => {
  const res = await callApi(`${FORGOT}`, data, "post", "ADMIN_FORGOT");
  if (res) {
    if (res.status) {
      showMessageNotification(res.message, "success");
    } else {
      showMessageNotification(res.message, "error");
    }
    return Promise.resolve(res);
  }
  return Promise.reject(res);
};

/*******************
@purpose :  cms data
@Parameter : {data}
@Author : INIC
******************/
export const cmsDetail = (data) => async (dispatch) => {
  const res = await callApi(`${CMS}`, data, "get", true);
  if (res) {
    if (res.status) {
      dispatch(setCmsData(res?.data?.[2]));
    } else {
      showMessageNotification(res.message, "error");
    }
    return Promise.resolve(res);
  }
  return Promise.reject(res);
};

export const setCmsData = (data) => ({
  type: "SET_CMS_DATA",
  payload: data,
});
