import React, { useState, useRef, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";
import * as _ from "lodash";
import { Link, withRouter } from "react-router-dom";

import { callApi } from "../../Api";
import { sidebaropen } from "../../Store/Actions";
import { setTabsValue } from "../../Store/Actions/Tabs";

/******************* 
@Purpose : Used for sidebar view
@Parameter : props
@Author : INIC
******************/
function Sidebar(props) {
  const dispatch = useDispatch();
  const [, setUserName] = useState("");
  const [, setPhoto] = useState("");
  const [, setAdminUserAccess] = useState({});
  const [, setCmsPagesAccess] = useState({});
  const [, setEmailSettingsAccess] = useState({});
  const [, setEmailTemplateAccess] = useState({});
  const [, setRolesAccess] = useState({});
  const [, setUserAccess] = useState({});
  const [lang] = useTranslation("language");
  const [usermenu, openUsermenu] = useState(false);
  const [settingsmenu, openSettingsmenu] = useState(false);
  const [contentmenu, openContentmenu] = useState(false);
  const [blogsmenu, openBlogsmenu] = useState(false);

  const path = props?.location?.pathname;
  const innerRef = useOuterClick();
  const { userProfile } = useSelector((data) => data.user);
  useEffect(() => {
    if (props.rolePermissions && !_.isEmpty(props.rolePermissions)) {
      let {
        adminUserAccess,
        cmsPagesAccess,
        emailSettingsAccess,
        emailTemplateAccess,
        rolesAccess,
        userAccess,
      } = props.rolePermissions;

      setAdminUserAccess(adminUserAccess);
      setCmsPagesAccess(cmsPagesAccess);
      setEmailSettingsAccess(emailSettingsAccess);
      setEmailTemplateAccess(emailTemplateAccess);
      setRolesAccess(rolesAccess);
      setUserAccess(userAccess);
    }
    if (props.admindata && !_.isEmpty(props.admindata)) {
      let { firstname, lastname, photo } = props.admindata;
      setUserName(firstname + " " + lastname);
      setPhoto(photo);
    }
  }, [props]);

  function useOuterClick(callback) {
    const outerRef = useRef();
    const callbackRef = useRef();

    useEffect(() => {
      callbackRef.current = callback;
    }, []);

    useEffect(() => {
      document.addEventListener("click", handleClick);
      return () => document.removeEventListener("click", handleClick);
      function handleClick(e) {
        if (
          outerRef.current &&
          callbackRef.current &&
          !outerRef.current.contains(e.target)
        ) {
          callbackRef.current(e);
        }
      }
    }, []);
    return outerRef;
  }

  /******************* 
  @Purpose : Used for sidebar open
  @Parameter : {}
  @Author : INIC
  ******************/
  const SidebarOpen = () => {
    document.body.classList.remove("sidebar-open");
  };
  /******************* 
  @Purpose : Used for change routes
  @Parameter : e, page
  @Author : INIC
  ******************/
  const changeRoute = (e, page) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    if (
      userProfile.isAccountActivated &&
      (page === "/dashboard" ||
        page === "/profile" ||
        page === "/reportedvideos" ||
        page === "/ManageVideoAd" ||
        page === "/BrandVideos" ||
        page === "/videoapprovalqueue" ||
        page === "/celebrityList" ||
        page === "/purchasevideo" ||
        page === "/publishvideodetail")
    ) {
      openUsermenu(false);
      openContentmenu(false);
      openSettingsmenu(false);
      dispatch(setTabsValue());
    }

    if (page === "/faq" && userProfile.isAccountActivated) {
      openBlogsmenu(false);
    }
    document.body.classList.add("disabled");
    return userProfile.isAccountActivated ? props.history.push(page) : 0;
  };

  /******************* 
  @Purpose : Used for settings handle
  @Parameter : {}
  @Author : INIC
  ******************/
  const getActiveLinkClass = (activePath, type, subpath = []) => {
    let pathSplit = path.split("/");
    let isActive = false;

    switch (type) {
      case "base-path":
        isActive = path === activePath || subpath.includes(`/${pathSplit[1]}`);
        break;
      case "user-parent":
      case "user-child":
        isActive = activePath === "showUserMenu" && usermenu;
        break;
      case "content-parent":
      case "content-child":
        isActive = activePath === "contentmenu" && contentmenu;
        break;
      case "blogs-parent":
        isActive = activePath === "blogsmenu" && blogsmenu;
        break;
      case "settings-parent":
        isActive = activePath === "settingsmenu" && settingsmenu;
        break;
      default:
        break;
    }

    const getClass = (prefix, active) => {
      return `${prefix} ${active ? "submenu-open" : ""}`;
    };

    switch (type) {
      case "base-path":
        return `menu-link ${isActive ? "active-link" : ""}`;
      case "user-parent":
        return getClass("menu-list-item has-child-submenu level1", isActive);
      case "user-child":
        return getClass("sidebar-menu-list sub-menu-list", isActive);
      case "content-parent":
        return getClass("menu-list-item has-child-submenu level1", isActive);
      case "content-child":
        return getClass("sidebar-menu-list sub-menu-list", isActive);
      case "blogs-parent":
        return getClass("menu-list-item has-child-submenu level1", isActive);
      case "settings-parent":
        return getClass("menu-list-item has-child-submenu level1", isActive);
      default:
        return "";
    }
  };

  let isLogined = localStorage.getItem("accessToken");

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <div>
      {isLogined && (
        <div ref={innerRef} id="container" className="App">
          <header className="App-header"></header>
          <aside className="sidebar">
            <div className="sidebar-inner-wrapper">
              <div className="sidebar-heading d-flex align-items-end justify-content-between">
                <Link className="navbar-brand" to="/dashboard">
                  <div className="brand-logo">
                    <img
                      className="img-fluid"
                      src="/assets/images/logo.svg"
                      alt="branding logo"
                      width="64px"
                      height="64px"
                    />
                  </div>
                </Link>
                <span onClick={SidebarOpen} className="sidebar-close">
                  <em className="icon-close"></em>
                </span>
              </div>
              <PerfectScrollbar>
                <div className="sidebar-menu">
                  <ul className="sidebar-menu-list">
                    {/* dashboard */}
                    <li className="menu-list-item">
                      <a
                        className={getActiveLinkClass(
                          "/dashboard",
                          "base-path"
                        )}
                        onClick={(e) => {
                          changeRoute(e, "/dashboard");
                        }}
                      >
                        <i className="bx bx-home-alt" />
                        <span className="menu-title">{lang("Dashboard")}</span>
                      </a>
                    </li>
                    {/* my celibrity */}
                    <li className="menu-list-item">
                      <a
                        onClick={(evt) => {
                          changeRoute(evt, "/celebrityList");
                        }}
                        className={getActiveLinkClass(
                          "/celebrityList",
                          "base-path",
                          ["/purchaseHistory", "/purchasevideo"]
                        )}
                      >
                        <i className="bx bx-user" />
                        <span className="menu-title">
                          {lang(" My Celebrity")}
                        </span>
                      </a>
                    </li>

                    <li className="menu-list-item">
                      <a
                        onClick={(evt) => {
                          changeRoute(evt, "/videoapprovalqueue");
                        }}
                        className={getActiveLinkClass(
                          "/videoapprovalqueue",
                          "base-path",
                          ["/videodetails"]
                        )}
                      >
                        <i className="bx bx-video" />
                        <span className="menu-title">
                          {lang("Video Approval Queue")}
                        </span>
                      </a>
                    </li>

                    {/* Brand videos */}
                    <li className="menu-list-item">
                      <a
                        onClick={(evt) => {
                          changeRoute(evt, "/BrandVideos");
                        }}
                        className={getActiveLinkClass(
                          "/BrandVideos",
                          "base-path",
                          ["/VideoInfo"]
                        )}
                      >
                        <i className="bx bx-video-recording" />
                        <span className="menu-title">
                          {lang("Tagged Videos")}
                        </span>
                      </a>
                    </li>

                    {/* Manage video ad */}
                    <li className="menu-list-item">
                      <a
                        className={getActiveLinkClass(
                          "/ManageVideoAd",
                          "base-path",
                          [
                            "/managevideodetails",
                            "/PurchaseVideoAd",
                            "/selectduration",
                            "/ChangePurchasevideo",
                          ]
                        )}
                        onClick={(e) => {
                          changeRoute(e, "/ManageVideoAd");
                        }}
                      >
                        <i className="bx bx-video-plus" />
                        <span className="menu-title">
                          {lang("Manage Video Ad")}
                        </span>
                      </a>
                    </li>
                    {/* post video */}
                    <li className="menu-list-item">
                      <a
                        onClick={(evt) => {
                          changeRoute(evt, "/postvideo");
                        }}
                        className={getActiveLinkClass(
                          "/postvideo",
                          "base-path",
                          [
                            "/publishvideo",
                            "/publishvideodetail",
                            "/editpostedvideo",
                          ]
                        )}
                      >
                        <i className="bx bxs-user" />
                        <span className="menu-title">Post A Video</span>
                      </a>
                    </li>

                    {/* my profile */}
                    <li className="menu-list-item">
                      <a
                        onClick={(evt) => {
                          changeRoute(evt, "/profile");
                        }}
                        className={getActiveLinkClass("/profile", "base-path", [
                          "/changeplan",
                        ])}
                      >
                        <i className="bx bxs-user" />
                        <span className="menu-title">My Profile</span>
                      </a>
                    </li>
                    {/* report */}
                    <li className="menu-list-item">
                      <a
                        onClick={(evt) => {
                          changeRoute(evt, "/report");
                        }}
                        className={getActiveLinkClass("/report", "base-path")}
                      >
                        <i className="bx bxs-report" />
                        <span className="menu-title">{lang("Report")}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </PerfectScrollbar>
            </div>
          </aside>
        </div>
      )}
    </div>
  );
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  language: state.admin.language,
  admindata: state.admin.adminData,
  sidebar: state.admin.sidebar,
  editadminprofile: state.admin.editAdminProfileData,
  rolePermissions: state.admin.adminData.staticRolePermission,
});
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, { callApi, sidebaropen })(
  withRouter(Sidebar)
);
