/******************* 
@Purpose :  We can use following for BreadCrumb
@Author : INIC
******************/
export const MANAGE_PROFILE = "Manage Profile Data";
export const MANAGE_PASSWORD = "Manage Password";
export const MANAGE_PLAN = "Manage Plan";
export const MANAGE_SERVICE_CATEGORY = "Manage Service Category";
export const LINK_SELLER_ACCOUNT = "Link Seller account";
export const MANAGE_CARD = "Manage Card";
export const DASHBOARD = "Dashboard";
export const VIDEO_LIST = "Tagged Video List";
export const VIDEO_DETAILS = "Video Detail";
export const PROFILE_DETAILS = "Profile Detail";
export const SELECT_PLAN = "SelectPlan";
export const SUBSCRIPTION_FEES = "Subscription Fees";
export const MANAGE_CELEBRITY = "Manage Celebrity";
export const LIST_OF_VIDEO_AD = "List of video AD";
export const LIST_OF_PUBLISHED_VIDEO = "List of Publish Video";
export const PUR_VIDEO_AD = "Brand purchasing Video from Celebrity";
export const PUR_OF_VIDEO_AD = "Purchase Video Ad";
export const CHANGE_OF_VIDEO_AD = "change Video Ad";
export const PUBLISH_VIDEO = "Publish Video";
export const VIDEO_AD_LIST = "video Ad List";
export const REPORTED_VIDEOS = "Reported Videos";
export const LIST_OF_REPORTS = "List Of Reports";
export const PUR_HISTORY = "Purchase History";
