import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";

import { getItem } from "./Utils/Functions";
import { route } from "./Utils";
import { store, persistor } from "./Store";
import VideoInfo from "./Components/VideoInfo/VideoInfo";
import Sidebar from "./Components/Layout/SideBar";
import ManagePassword from "./Components/ManagePassword/ManagePassword";
import Report from "./Pages/Reports/Reports";
import BrandAccount from "./Pages/BrandAccount/BrandAccount";
import BrandPayout from "./Pages/BrandPayout/BrandPayout";
import BrandVideos from "./Pages/BrandVideos/BrandVideos";
import CelebrityList from "./Pages/Celebrity/CelebrityList";
import ManageVideoAd from "./Pages/ManageVideoAd/ManageVideoAd";
import PurchaseVideoAd from "./Pages/PurchaseVideoAd/PurchaseVideoAd";
import SelectDuration from "./Pages/SelectDuration/SelectDuration";
import SelectPlan from "./Pages/SelectPlan/SelectPlan";
import EditBrand from "./Pages/EditBrand/EditBrand";
import BrandLogin from "./Pages/Authentication/BrandLogin";
import PasswordPage from "./Pages/Authentication/PasswordPage";
import setPassword from "./Pages/Authentication/ResetPassword";
import ForgotPassword from "./Pages/Authentication/ForgotPassword";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Profile from "./Pages/Profile/Profile";
import FourZeroFour from "./Pages/FourZeroFour/FourZeroFour";
import SignUp from "./Pages/Authentication/SignUp";
import VideoApprovalQueue from "./Pages/VideoApprovalQueue/VideoApprovalQueue";
import VideoDetail from "./Pages/VideoApprovalQueue/VideoDetail";
import PurchaseHistory from "./Pages/Celebrity/PurchaseHistory";
import PurchasevideoAdDetail from "./Pages/PurchaseVideoAd/PurchaseVideoAdDetail";

import "./Scss/styles.scss";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import PurchaseCelebVideos from "./Components/PurchaseCelebVideos/PurchaseCelebVideos";
import ChangePurchasevideo from "./Pages/PurchaseVideoAd/ChangePurchasevideo";
import PostVideo from "./Pages/postvideo/PostVideo";
import PublishVideo from "./Pages/publishvideo/PublishVideo";
import PublishVideoDetail from "./Pages/publishvideo/PublishVideoDetail";
import EditPostedVideo from "./Pages/publishvideo/EditPostedVideo";
import ChangePlan from "./Pages/ChangePlan/ChangePlan";

/******************* 
@Purpose : This page is default page for our project
@Parameter : {}
@Author : INIC
******************/
function App() {
  /******************* 
  @Purpose : Used for token authorization
  @Parameter : {}
  @Author : INIC
  ******************/
  const Authorization = () => {
    return getItem("accessToken") ? true : false;
  };

  /******************* 
  @Purpose : Used for private routing
  @Parameter : {Component1, ...rest}
  @Author : INIC
  ******************/
  const PrivateRoute = ({ component: Component1, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        Authorization() ? <Component1 {...props} /> : <Redirect to="/" />
      }
    />
  );

  /******************* 
  @Purpose : Used for check user is already login or not
  @Parameter : {Component2, ...rest }
  @Author : INIC
  ******************/
  const LoginCheckRoute = ({ component: Component2, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        Authorization() ? (
          <Redirect to={route.DASHBOARD} />
        ) : (
          <Component2 {...props} />
        )
      }
    />
  );

  /******************* 
  @Purpose : Used for check reset password
  @Parameter : {Component3, ...rest}
  @Author : INIC
  ******************/
  const ResetPasswordCheckRoute = ({ component: Component3, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        Authorization() ? (
          <Redirect to={route.DASHBOARD} />
        ) : (
          <Component3 {...props} />
        )
      }
    />
  );

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Suspense fallback="loading">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Sidebar />
            <div style={{ textAlign: "center" }}>
              <ToastContainer />
            </div>
            <Switch>
              <LoginCheckRoute exact path="/" component={BrandLogin} />
              <PrivateRoute exact path="/password" component={PasswordPage} />
              <ResetPasswordCheckRoute
                exact
                path={route.SET_PASSWORD}
                component={setPassword}
              />

              <Route
                exact
                path={route.FORGOT_PASSWORD}
                component={ForgotPassword}
              />
              <Route exact path={route.SIGNUP} component={SignUp} />
              <PrivateRoute
                exact
                path={route.DASHBOARD}
                component={Dashboard}
              />
              <PrivateRoute
                exact
                path={route.BRAND_ACCOUNT}
                component={BrandAccount}
              />
              <PrivateRoute
                exact
                path={route.CELIBRITY_LIST}
                component={CelebrityList}
              />
              <PrivateRoute
                exact
                path={route.EDIT_BRAND}
                component={EditBrand}
              />
              <PrivateRoute exact path={route.PROFILE} component={Profile} />
              <PrivateRoute
                exact
                path={route.BRAND_PAYOUT}
                component={BrandPayout}
              />
              <PrivateRoute
                exact
                path={route.BRAND_VIDEOS}
                component={BrandVideos}
              />
              <PrivateRoute
                exact
                path={route.SELECT_PLAN}
                component={SelectPlan}
              />
              <PrivateRoute
                exact
                path={route.MANAGE_VIDEO_ADD}
                component={ManageVideoAd}
              />
              <PrivateRoute
                exact
                path={route.PURCHASE_VIDEO_ADD}
                component={PurchaseVideoAd}
              />
              <PrivateRoute
                exact
                path="/videoapprovalqueue"
                component={VideoApprovalQueue}
              />
              <PrivateRoute
                exact
                path={route.SELECT_DURATION}
                component={SelectDuration}
              />
              <PrivateRoute
                exact
                path={route.BRAND_PAYOUT}
                component={BrandPayout}
              />

              <PrivateRoute exact path="/postvideo" component={PostVideo} />
              <PrivateRoute
                exact
                path="/publishvideo"
                component={PublishVideo}
              />
              <PrivateRoute
                exact
                path="/publishvideodetail/:slug"
                component={PublishVideoDetail}
              />
              <PrivateRoute
                exact
                path={route.BRAND_VIDEOS}
                component={BrandVideos}
              />
              <PrivateRoute
                exact
                path={`${route.VIDEO_INFO}/:slug`}
                component={VideoInfo}
              />
              <PrivateRoute
                exact
                path={`${route.PURCHASE_HISTORY}/:slug`}
                component={PurchaseHistory}
              />

              <PrivateRoute
                exact
                path="/videodetails/:slug"
                component={VideoDetail}
              />
              <PrivateRoute
                exact
                path="/managevideodetails/:slug"
                component={PurchasevideoAdDetail}
              />
              <PrivateRoute
                exact
                path="/purchasevideo/:slug/:slug1"
                component={PurchaseCelebVideos}
              />
              <PrivateRoute
                exact
                path="/ChangePurchasevideo/:slug"
                component={ChangePurchasevideo}
              />
              <PrivateRoute
                exact
                path="/EditPostedVideo/:slug"
                component={EditPostedVideo}
              />
              <PrivateRoute exact path="/changeplan" component={ChangePlan} />

              <PrivateRoute exact path={route.REPORT} component={Report} />
              <PrivateRoute path="/*" component={FourZeroFour} />
              <PrivateRoute path={route.CELEBRITY} component={CelebrityList} />
              <PrivateRoute
                path="/videoapprovalqueue"
                component={VideoApprovalQueue}
              />

              <PrivateRoute
                path={route.MANAGE_PASSWORD}
                component={ManagePassword}
              />
            </Switch>
          </Router>
        </PersistGate>
      </Provider>
    </Suspense>
  );
}

export default App;
