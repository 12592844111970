import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { showModalNotification } from "../../Utils/Functions";
import { getDashboardsTrendData } from "../../Store/Actions/BrandDashboard";
import { useDispatch, useSelector } from "react-redux";

const Trending = (props) => {
  const dispatch = useDispatch();
  const { subCatTrendDate } = props;

  const [trendData, setTrendData] = useState([]);

  useEffect(() => {
    try {
      dispatch(
        getDashboardsTrendData({ year: subCatTrendDate.getFullYear() })
      ).then((response) => {
        if (response.status === 1) {
          setTrendData(response?.monthWiseTopFiveSubCtegoriesData);
        } else if (response?.message === 0) {
          showModalNotification(response?.message, "error");
          setTrendData([]);
        }
      });
    } catch (error) {
      console.error();
    }
  }, [subCatTrendDate]);

  const getMonthName = (monthNumber) => {
    const date = new Date(subCatTrendDate.getFullYear(), monthNumber - 1);
    return date.toLocaleString("default", { month: "short" });
  };
  // graphicl data
  const generateGraphData = () => {
    const datasets = [];
    const labels = [];

    trendData.forEach((data) => {
      const monthName = getMonthName(data.month);

      data.topSubcategories.forEach((subcategory) => {
        const { subcategoryTitle, viewCount } = subcategory;
        const existingDataset = datasets.find(
          (ds) => ds.label === subcategoryTitle
        );

        if (existingDataset) {
          const existingData = existingDataset.data.find(
            (d) => d.label === monthName
          );
          if (existingData) {
            existingData.value += viewCount;
          } else {
            existingDataset.data.push({ label: monthName, value: viewCount });
          }
        } else {
          const newDataset = {
            label: subcategoryTitle,
            data: [{ label: monthName, value: viewCount }],
            backgroundColor: `rgba(${Math.floor(
              Math.random() * 256
            )}, ${Math.floor(Math.random() * 256)}, ${Math.floor(
              Math.random() * 256
            )}, 0.7)`,
            borderRadius: 10,
            barThickness: 20,
          };
          datasets.push(newDataset);
        }

        if (!labels.includes(monthName)) {
          labels.push(monthName);
        }
      });
    });

    const formattedDatasets = datasets.map((dataset) => {
      const dataValues = labels.map((label) => {
        const data = dataset.data.find((d) => d.label === label);
        return data ? data.value : 0;
      });

      return {
        ...dataset,
        data: dataValues,
      };
    });

    return {
      labels,
      datasets: formattedDatasets,
    };
  };

  const graphData = generateGraphData();

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Month",
        },
      },
      y: {
        title: {
          display: true,
          text: "Product Clicks Count",
        },
      },
    },
  };

  return (
    <div>
      <h6>Product Clicks Chart</h6>
      <Bar data={graphData} options={options} />
    </div>
  );
};

export default Trending;
