import * as yup from "yup";

const URL =
  /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
// /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
// !/^(https?:\/\/)([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]+)*\/?$/i;
const alphabatic = /^[a-zA-Z!@#\$%\^\&*\)\(+=._-\s]+$/g;
const GST = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{2}[0-9]{2}$/;
export const addBrand = yup.object().shape({
  organization_name: yup
    .string()
    .max(50, "The organization name may not be greater than 50 characters")
    .matches(alphabatic, "Enter only Alphabet and special character")
    .required("Enter the organization name"),
  first_name: yup
    .string()
    .max(50, "The contact first name may not be greater than 50 characters")
    .required("Enter the contact name"),
  last_name: yup
    .string()
    .max(50, "The contact last name may not be greater than 50 characters")
    .required("Enter the contact last name"),
  mobile_number: yup
    .string()
    .max(15, "Invalid Number")
    .required("Enter the contact number"),
  address: yup
    .string()
    .max(100, "The address may not be greater than 100 characters")
    .required("Enter address"),
  email: yup
    .string()
    .email("Enter a valid email address")
    .required("Enter a valid email address"),
  country: yup.string().required(" select the country"),
  state: yup.string().required(" select the State"),
  city: yup.string().required("Enter the city"),
  zipcode: yup
    .string()
    .max(8, "The zip code may not be greater than 8 characters")
    .required("Enter the zip/postal code"),
  photo: yup.string().nullable().notRequired(),
});

/******************* 
@purpose : Used for formic login page schema
@Parameter : {lang}
@Author : INIC
******************/
export const EditBrandDetails = () =>
  yup.object().shape({
    organization: yup
      .string()
      .max(50, "The organization name may not be greater than 50 characters")
      .matches(alphabatic, "Enter only Alphabet and special character")
      .required("Enter the organization name"),
    mobileNumber: yup
      .string()
      .min(12, "Invalid Number")
      .max(12, "Invalid Number")
      .required("Enter the contact number"),
    firstName: yup
      .string()
      .max(50, "The contact first name may not be greater than 50 characters")
      .matches(alphabatic, "Enter only Alphabet")
      .required("Enter the contact name"),
    address: yup
      .string()
      .max(100, "The address may not be greater than 100 characters")
      .required("Enter the address"),
    state: yup.mixed().required("Select the state"),
    city: yup.mixed().required("Select the city"),
    bio: yup
      .string()
      .max(500, "The address may not be greater than 500 characters"),
    youTubeId: yup.string().matches(URL, "Enter correct youtube link!"),
    twitterId: yup.string().matches(URL, "Enter correct twitter link!"),
    linkdnId: yup.string().matches(URL, "Enter correct linkdIn link!"),
    instagramId: yup.string().matches(URL, "Enter correct instagram link!"),
    fbId: yup.string().matches(URL, "Enter correct facebook link!"),
    pin: yup

      .string()
      .min(6, "The zip code may not be less than 6 characters")
      .max(6, "The zip code may not be greater than 6 characters")
      .required("Enter the zip/postal code"),
    websiteUrl: yup.string().when("isBusinessOnline", {
      is: "true",
      then: yup
        .string()
        .required("Please Enter valid url ")
        .matches(URL, "Enter valid url !"),
    }),
    whatsAppBusinessNumber: yup.string().when("isBusinessOnline", {
      is: "false",
      then: yup
        .string()
        .min(12, "Enter whatsapp business number")
        .max(12, "Enter maximum number")
        .required("Enter the whatsapp business number"),
    }),
    isBusinessOnline: yup.mixed().required("Select business type"),
    gstNumber: yup
      .string()
      .length(15)
      .matches(/^[a-zA-Z0-9]{15}$/, "Enter the valid gst number"),
  });

/******************* 
@purpose : Used for formic login page schema
@Parameter : {lang}
@Author : INIC
******************/

export const FilterBrandVideos = yup.object().shape({
  friends: yup
    .array()
    .of(
      yup.object().shape({
        country: yup.string().required(" select Filter Field"),
        name: yup
          .string()
          .max(50, "Name or email are not greater then 50 character")
          .required("Enter the name or email"),
      })
    )
    .required("Must have friends")
    .min(3, "Minimum of 3 friends"),
});

/******************* 
@purpose : Used for formic login page schema
@Parameter : {lang}
@Author : INIC
******************/
export const SignUpBrandDetails = () =>
  yup.object().shape({
    organization: yup
      .string()
      .max(50, "The organization name may not be greater than 50 characters")
      .matches(alphabatic, "Enter only Alphabet and special character")
      .required("Enter the organization name"),
    firstName: yup
      .string()
      .max(50, "The first name may not be greater than 50 characters")
      .matches(alphabatic, "Enter only Alphabet")
      .required("Enter the first name"),
    lastName: yup
      .string()
      .max(50, "The contact last name may not be greater than 50 characters")
      .required("Enter the last name"),
    mobileNumber: yup.string().min(12, "Invalid Number")?.optional(),
    address: yup
      .string()
      .max(100, "The address may not be greater than 100 characters")
      .required("Enter the address"),
    emailId: yup
      .string()
      .email("Enter a valid email address")
      .required("Enter the email address"),
    state: yup.object().required("Select the state"),
    city: yup.object().required("Select the city"),
    pin: yup
      .string()
      .min(6, "The zip code may not be less than 6 characters")
      .max(6, "The zip code may not be greater than 6 characters")
      .required("Enter the zip/postal code"),
    websiteUrl: yup.string().when("isBusinessOnline", {
      is: "true",
      then: yup.string().required("Please enter url"),
    }),
    whatsAppBusinessNumber: yup.string().when("isBusinessOnline", {
      is: "false",
      then: yup.string().required("Please Enter Whatsapp Business Number"),
    }),
    isBusinessOnline: yup.mixed().required("Select business type"),
    gstNumber: yup
      .string()
      .length(15, "GST Number length can be 15 characters")
      .matches(/^[a-zA-Z0-9]{15}$/, "Enter the valid GST Number")
      .optional(),
  });

/******************* 
@purpose : Used for purchase video ad schema
@Parameter : {lang}
@Author : INIC
******************/
export const PurchaseVideoAdSchema = () =>
  yup.object().shape({
    title: yup
      .string()
      .min(3, "Title may not be less than 3 characters")
      .max(50, "Title may not be greater than 50 characters")
      .matches(
        /^(?=.*[a-zA-Z ])[a-zA-Z\d ]+$/,
        "Title should contain both characters and numerics."
      )
      .required("Enter the title"),
    category: yup.object().required("Select the category"),
    subCategory: yup.mixed().required("Select the sub-category").nullable(),
    videoData: yup.mixed().required("Upload a video"),
  });
export const changeVideoAdSchema = () =>
  yup.object().shape({
    videoData: yup.mixed().required("Upload a video"),
  });

/******************* 
@purpose : Used for purchase video ad schema
@Parameter : {lang}
@Author : INIC
******************/
export const selectDurationPurchaseVideoAdSchema = () =>
  yup.object().shape({
    PromotionDurationinDays: yup.mixed().required("Select display days"),
    promotionStartDate: yup.string().required("Select start date").nullable(),
  });
/******************* 
@purpose : Used for purchase video ad schema
@Parameter : {lang}
@Author : INIC
******************/

export const purchaseCelebVideoSchema = (lang) => {
  return yup.object().shape({
    purchase_celeb_details: yup.array().of(
      yup.object().shape({
        category: yup.object().required("Select the category"),
        subCategory: yup.mixed().required("Select the sub-category").nullable(),
        videoTitle: yup.string().required("Video title is required"),
        caption: yup.string().optional(),
        productSku: yup.string().optional(),
        affilietedProductUrl: yup
          .boolean()
          .required("Please enter the Affiliated link or Whatsapp number"),
        affiliatedLink: yup
          .string()
          .when("affilietedProductUrl", {
            is: true,
            then: yup
              .string()
              .matches(URL, "Enter a valid link")
              .required("Affiliated link is required"),
          })
          .when("affilietedProductUrl", {
            is: false,
            then: yup.string().notRequired(),
          }),
        whatsAppNumber: yup
          .string()
          .when("affilietedProductUrl", {
            is: false,
            then: yup
              .string()
              .length(10, "Enter a valid WhatsApp number")
              .required("WhatsApp number is required"),
          })
          .when("affilietedProductUrl", {
            is: true,
            then: yup.string().notRequired(),
          }),
      })
    ),
  });
};

/******************* 
@purpose : Used for purchase video ad schema
@Parameter : {lang}
@Author : INIC
******************/
export const PublishVideoAdSchema = () =>
  yup.object().shape({
    title: yup
      .string()
      .min(3, "Title may not be less than 3 characters")
      .max(50, "Title may not be greater than 50 characters")
      .matches(
        /^(?=.*[a-zA-Z ])[a-zA-Z\d ]+$/,
        "Title should contain both characters and numerics."
      )
      .required("Enter the title"),
    category: yup.object().required("Select the category"),
    subCategory: yup.mixed().required("Select the sub-category").nullable(),
    affilietedProductUrl: yup
      .mixed()
      .required("Please enter the Affiliated link or Whatsapp number"),

    affiliatedLink: yup.string().when("affilietedProductUrl", {
      is: "true",
      then: yup
        .string()
        .matches(URL, "Enter valid url")
        .required("Affiliated link is required"),
    }),
    whatsappNumber: yup.string().when("affilietedProductUrl", {
      is: "false",
      then: yup
        .string()
        .length(10, "Enter the valid whatapp number")
        .required("WhatsApp number is required"),
    }),
    description: yup
      .string()
      .max(500, "The address may not be greater than 500 characters"),
    videoData: yup.mixed().required("Upload a video"),
  });

/******************* 
@purpose : Used for purchase video ad schema
@Parameter : {lang}
@Author : INIC
******************/
export const EditPublishVideoAdSchema = () =>
  yup.object().shape({
    videoTitle: yup
      .string()
      .min(3, "Title may not be less than 3 characters")
      .max(50, "Title may not be greater than 50 characters")
      .matches(
        /^(?=.*[a-zA-Z ])[a-zA-Z\d ]+$/,
        "Title should contain both characters and numerics."
      )
      .required("Enter the title"),
    category: yup.mixed().required("Select the category"),
    subcategories: yup.mixed().required("Select the sub-category"),

    affiliatedLink: yup
      .mixed()
      .required("Please enter the Affiliated link or Whatsapp number"),

    affilietedProductUrl: yup.string().when("affiliatedLink", {
      is: "true",
      then: yup.string().matches(URL, "Enter valid url").required(""),
    }),
    whatsAppNumber: yup.string().when("affiliatedLink", {
      is: "false",
      then: yup
        .string()
        .length(10, "Enter the valid whatapp number")
        .required(""),
    }),
    caption: yup
      .string()
      .max(500, "The address may not be greater than 500 characters"),
    videoData: yup.mixed(),
    productSku: yup.string(),
  });
