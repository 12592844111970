import { callApi } from "../../Api";
import Routes from "../../Api/Routes";

const {
  CELEBRITY_LIST,
  PURCHASE_HISTORY,
  CELEB_SPECIFIC_VIDS,
  HIDE_VIDEO,
  VIDEO_APPROVAL_QUEUE,
  APPROVE_VIDEO,
  REJECT_VIDEO,
  CELEB_DROPDOWN,
  TRANSACTION_LIST,
  GET_CATEGORIES,
} = Routes;
/*******************
@purpose :  used for celebrity list display videos
@Parameter : {data}
@Author : INIC
******************/

export const celebrityList = (data) => async (dispatch) => {
  const res = await callApi(`${CELEBRITY_LIST}`, data, "post", "", true);
  if (res) {
    await dispatch(setCelebVideoDisplay(res));
    return Promise.resolve(res);
  } else return Promise.reject(res);
};

export const getPurchaseHistory = (data) => async (dispatch) => {
  const res = await callApi(`${PURCHASE_HISTORY}`, data, "post", "", true);
  if (res) {
    await dispatch(setPurchaseHistory(res));
    return Promise.resolve(res);
  } else return Promise.reject(res);
};

export const getSpecificVideos = (data) => async (dispatch) => {
  const res = await callApi(`${CELEB_SPECIFIC_VIDS}`, data, "post", "", true);
  if (res) {
    await dispatch(setSpecificVideos(res));
    return Promise.resolve(res);
  } else return Promise.reject(res);
};

export const getHideVideo = (data) => async (dispatch) => {
  const res = await callApi(`${HIDE_VIDEO}`, data, "post", "", true);
  if (res) {
    await dispatch(setVideoHide(res?.data));
    return Promise.resolve(res);
  } else return Promise.reject(res);
};

export const getAllVideos = (data) => async (dispatch) => {
  const res = await callApi(`${VIDEO_APPROVAL_QUEUE}`, data, "post", "", true);
  if (res) {
    await dispatch(setAllVideos(res));
    return Promise.resolve(res);
  } else return Promise.reject(res);
};

export const getVideoApprove = (data) => async (dispatch) => {
  const res = await callApi(`${APPROVE_VIDEO}`, data, "post", "", true);
  if (res) {
    await dispatch(setVideoApprove(res));
    return Promise.resolve(res);
  } else return Promise.reject(res);
};

export const getRejectVideo = (data) => async (dispatch) => {
  const res = await callApi(`${REJECT_VIDEO}`, data, "post", "", true);
  if (res) {
    await dispatch(setRejectVideo(res));
    return Promise.resolve(res);
  } else return Promise.reject(res);
};

export const getCelebDropdown = (data) => async (dispatch) => {
  const res = await callApi(`${CELEB_DROPDOWN}`, data, "post", "", true);
  if (res) {
    await dispatch(setCelebrities(res?.data));
    return Promise.resolve(res);
  } else return Promise.reject(res);
};

export const getTransactionList = (data) => async (dispatch) => {
  const res = await callApi(`${TRANSACTION_LIST}`, data, "post", "", true);
  if (res) {
    await dispatch(getTransactions(res));
    return Promise.resolve(res);
  } else return Promise.reject(res);
};

export const getCategories =
  (number = 1) =>
  async (dispatch) => {
    const res = await callApi(
      `${GET_CATEGORIES}?=pageNumber=${number}`,
      "",
      "get",
      "",
      true
    );

    if (res) {
      await dispatch(setCategories(res));
      return Promise.resolve(res);
    } else return Promise.reject(res);
  };

export const setCelebVideoDisplay = (data) => ({
  type: "CELEBRITY_DISPLAY",
  payload: data,
});
export const setPurchaseHistory = (data) => ({
  type: "PURCHASE_HISTORY",
  payload: data,
});
export const setSpecificVideos = (data) => ({
  type: "SPECIFIC_VIDEOS",
  payload: data,
});
export const setVideoHide = (data) => ({
  type: "HIDE_VIDEO",
  payload: data,
});
export const setAllVideos = (data) => ({
  type: "ALL_VIDEOS_DISPLAY",
  payload: data,
});
export const setVideoApprove = (data) => ({
  type: "APPROVE_VIDEO",
  payload: data,
});
export const setRejectVideo = (data) => ({
  type: "REJECT_VIDEO",
  payload: data,
});
export const setCelebrities = (data) => ({
  type: "CELEB_LIST",
  payload: data,
});
export const getTransactions = (data) => ({
  type: "TRANS_LIST",
  payload: data,
});
export const setCategories = (data) => ({
  type: "CATEGORIES_LIST",
  payload: data,
});
