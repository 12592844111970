import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import Select from "react-select";

import { Layout, Footer } from "../../Components/Layout";
import CardData from "../../Components/Cards/CardData";
import Chart from "../../Components/Charts/Chart";
import Trending from "../../Components/Charts/Trending";
import VideoViewCharts from "../../Components/Charts/VideoViewCharts";
import { DASHBOARD, route } from "../../Utils";
import {
  getBrandDashboardChartData,
  getBrandDashboardData,
} from "../../Store/Actions/BrandDashboard";
import VideoCard from "../../Components/BrandVideos/VideoCard";
import FilterModel from "../../Components/Model/FilterModel";
import { getCategories } from "../../Store/Actions/Categories";
import { getCelebDropdown } from "../../Store/Actions/CelebrityVideos";
import { getReportsdata } from "../../Store/Actions/Reports";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

/******************* 
@Purpose :  used for filter select dropdown value for key
@Parameter : {}
@Author : INIC
******************/
const filterTypeFieldValueoption = [
  { value: "creatorId", label: "Celebrity" },
  { value: "categoryId", label: "Category" },
];
/******************* 
@Purpose : Used for Dashboard
@Parameter : {}
@Author : INIC
******************/
function Dashboard() {
  const dispatch = useDispatch();
  const defaultFilter = {
    key: "",
    type: "contains",
    value: "",
    filterType: "",
  };
  const [filterFields, setFilterFields] = useState("categoryId");
  const field = 1;
  const [category, setCategory] = useState("");
  const [celeb, setCeleb] = useState("");
  const [isSearchButtonDisabled, setIsSearchButtonDisabled] = useState(true);
  const [date, setDate] = useState(new Date());
  const [subCatTrendDate, setSubCatTrendDate] = useState(new Date());
  const [viewTrendDate, setViewTrendDate] = useState(new Date());
  const { categoryInfo } = useSelector((data) => data.user);
  const { celeblist } = useSelector((data) => data?.celebVideos);
  const [filterData, setFilterData] = useState([defaultFilter]);
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);
  const { branddahboarddata } = useSelector((data) => data?.branddashboard);
  const [modalShow, setModalShow] = useState(false);
  const [currentVideo, setCurrentVideo] = useState();
  const [oldVideo, setOldVideo] = useState();

  useEffect(() => {
    let data = {
      year: date.getFullYear() ?? new Date().getFullYear(),
    };
    dispatch(getBrandDashboardData());
    dispatch(getBrandDashboardChartData(data));
    dispatch(getReportsdata());
  }, [date]);

  useEffect(() => {
    if (oldVideo) {
      setCurrentVideo(oldVideo);
    }
    if (currentVideo) {
      currentVideo.pause();
    }
  }, [oldVideo]);

  const playEventHandler = (video) => {
    setOldVideo(video);
  };
  /******************* 
  @Purpose :  React hook used for get categories and sub categories
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    let body = { searchText: "" };
    dispatch(getCategories());
    dispatch(getCelebDropdown(body)).then((res) => {
      if (res) {
        celebValue();
      }
    });
  }, []);

  useEffect(() => {
    celebValue();
  }, [celeblist]);

  useEffect(() => {
    categoryValue();
  }, [categoryInfo]);

  /******************* 
  @Purpose :  used for category value maping
  @Parameter : {}
  @Author : INIC
  ******************/
  const categoryValue = () => {
    let categoryArr = [];
    categoryInfo?.length > 0 &&
      categoryInfo?.forEach((optiondata) => {
        let tempObj = {
          label: optiondata?.title,
          value: optiondata?._id,
          ...optiondata,
        };
        categoryArr.push(tempObj);
        setCategory(categoryArr);
      });
  };

  /******************* 
  @Purpose :  used for category value maping
  @Parameter : {}
  @Author : INIC
  ******************/
  const celebValue = () => {
    let celebArr = [];
    celeblist?.length > 0 &&
      celeblist?.forEach((optiondata) => {
        let tempObj = {
          label: optiondata?.Celebrity,
          value: optiondata?._id,
        };
        celebArr.push(tempObj);
        setCeleb(celebArr);
      });
  };

  /******************* 
@Purpose : Used for Bread crumb
@Parameter : {}
@Author : INIC
******************/
  const breadcrumb = [
    {
      title: DASHBOARD,
      url: route.DASHBOARD,
    },
  ];

  /******************* 
  @Purpose : Used for filter key selection and set value 
  @Parameter : {filterField, index}
  @Author : INIC
  ******************/
  const handleFilterTypeFields = (filterField, index) => {
    setFilterFields(filterField);
    const filterRow = filterData;
    filterRow[index].key = filterField;
    setFilterData([...filterRow]);
    switch (filterField) {
      case "categoryId":
        filterRow[index].filterOptions = category;
        setFilterData([...filterRow]);
        break;
      case "creatorId":
        filterRow[index].filterOptions = celeb;
        setFilterData([...filterRow]);
        break;
    }
  };

  /******************* 
  @Purpose : Used for handle filter type field
  @Parameter : {index}
  @Author : INIC
  ******************/
  const handleFilterTypeField = (index) => {
    if (index === 0) {
      return filterTypeFieldValueoption;
    } else {
      return [
        { value: "creatorId", label: "Celebrity" },
        { value: "categoryId", label: "Category" },
      ];
    }
  };

  /******************* 
    @Purpose :  used for api calling for filter
    @Parameter : {}
    @Author : INIC
    ******************/
  const handleSubmit = async () => {
    let body = {
      year: date.getFullYear() ?? new Date().getFullYear(),
      filter: filterData,
    };

    const res = dispatch(getBrandDashboardChartData(body));
    if (res) {
      setModalShow(false);
      setFilterData([defaultFilter]);
      setSelectedFilterItems([]);
    }
  };

  const handleReset = () => {
    setDate(new Date());
    let body = {
      year: new Date().getFullYear(),
    };
    const res = dispatch(getBrandDashboardChartData(body));
    if (res) {
      onHide();
    }
  };

  /******************* 
  @Purpose :  React hook used for filter search button disable and enable
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    filterData?.forEach((data) => {
      if (data.key != "" && data.value != "") {
        setIsSearchButtonDisabled(false);
      } else {
        setIsSearchButtonDisabled(true);
      }
    });
  }, [filterData]);
  /******************* 
  @Purpose :  Used for render field typeds
  @Parameter : {index}
  @Author : INIC
  ******************/
  const renderSelect = (options, index, style) => {
    return (
      <Select
        options={options}
        className="custom-select-container"
        classNamePrefix="custom-select"
        onChange={(e) => handleValueChange(e.value, index)}
        style={style}
      />
    );
  };

  const handleFiltertags = (index) => {
    switch (filterData[index].key) {
      case "categoryId":
        return renderSelect(filterData[index].filterOptions, index);
      case "creatorId":
        return renderSelect(filterData[index].filterOptions, index, {
          position: "absolute",
        });
      default:
        return renderSelect(null, null);
    }
  };

  /******************* 
  @Purpose : Used for hide modal and clear it
  @Parameter : {}
  @Author : INIC
  ******************/
  const onHide = () => {
    setModalShow(false);
    setFilterData([defaultFilter]);
    setSelectedFilterItems([]);
  };
  /******************* 
  @Purpose :  used for set filter value
  @Parameter : {fieldValue,index}
  @Author : INIC
  ******************/
  const handleValueChange = (fieldValue, index) => {
    handleFilterArray();
    const filterRow = filterData;
    filterRow[index].value = fieldValue;
    setFilterData([...filterRow]);
  };

  /******************* 
  @Purpose : Used for handle filter data
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleFilterArray = () => {
    let selected = filterData.map((item) => item.key);
    setSelectedFilterItems(selected);
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Dashboard">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />

          <div className="main-content-block">
            {/* Payment Success: START */}
            {/* <div className="payment-msg">
              <Card>
                <Card.Body className="p-5">
                  <i className="bx bxs-check-circle payment-status-icon text-msg mb-1"></i>
                  <h3 className="fw-bold mt-2 text-msg status-msg">
                    Payment Successful!
                  </h3>
                  <p className="text-gray">
                    order_id: <span>14251627912482</span>
                  </p>
                  <hr></hr>
                  <div className="d-flex mx-5 mt-5">
                    <div
                      className="w-50 text-start text-gray
                    "
                    >
                      <span>Amount Paid</span>
                    </div>
                    <div className="w-50 text-end text-gray">
                      <span>$250</span>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div> */}
            {/* Payment Success: END */}
            <div className="row">
              <div className="mb-5 ">
                {/* <div className="card">
                  <div className="card-body"> */}
                <CardData />
                {/* </div>
                </div> */}
              </div>
              <div className="mb-5 col-lg-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-between align-items-center pb-0">
                    <h5 className="card-title align-self-center">Trend</h5>
                    <div className="action-wrap d-flex flex-wrap align-item-center">
                      <Button
                        variant="primary"
                        active
                        className="btn-bordered me-2 d-flex align-items-center justify-content-center"
                        onClick={() => setModalShow(true)}
                      >
                        <i className="bx bx-filter-alt align-text-top me-1"></i>
                        Filter
                      </Button>
                      <div className="custom-datepicker">
                        <DatePicker
                          selected={date}
                          onChange={(date) => setDate(date)}
                          showYearPicker
                          dateFormat="yyyy"
                          onClick={() => {
                            document.querySelectorAll("input")[0].click();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-body chart-wrapper pt-2 ">
                    <div className="chart-details d-flex align-items-center justify-content-center">
                      <div className="video-view d-none">
                        <span className="video-identify me-2"></span>
                        Video View
                      </div>
                    </div>
                    <div class="trend-chart-container">
                      <Chart />
                    </div>
                  </div>
                </div>
              </div>
              {/* starts here */}
              <div className="mb-5 col-lg-6">
                <div className="card h-100">
                  <div className="card-header d-flex justify-content-between pb-0">
                    <h5 className="card-title align-self-center">
                      Trending Sub-Categories
                    </h5>
                    <div className="action-wrap d-flex flex-wrap align-item-center">
                      <div className="custom-datepicker">
                        <DatePicker
                          selected={subCatTrendDate}
                          onChange={(date) => setSubCatTrendDate(date)}
                          showYearPicker
                          dateFormat="yyyy"
                          onClick={() => {
                            document.querySelectorAll("input")[1].click();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-body chart-wrapper ">
                    <div className="chart-details d-flex align-items-center justify-content-center">
                      <div className="video-view d-none">
                        <span className="video-identify me-2"></span>
                        Video View
                      </div>
                    </div>
                    <Trending
                      subCatTrendDate={subCatTrendDate}
                      setSubCatTrendDate={setSubCatTrendDate}
                    />
                  </div>
                </div>
              </div>
              {/* top 5 video view count */}
              {/* starts here */}
              <div className="mb-5 col-lg-6">
                <div className="card h-100">
                  <div className="card-header d-flex justify-content-between pb-0">
                    <h6 className="card-title align-self-center">
                      Trending Sub-Categories on Video Views
                    </h6>
                    <div className="action-wrap d-flex flex-wrap align-item-center">
                      <div className="custom-datepicker">
                        <DatePicker
                          selected={viewTrendDate}
                          onChange={(date) => setViewTrendDate(date)}
                          showYearPicker
                          dateFormat="yyyy"
                          onClick={() => {
                            document.querySelectorAll("input")[2].click();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-body chart-wrapper ">
                    <div className="chart-details d-flex align-items-center justify-content-center">
                      <div className="video-view d-none">
                        <span className="video-identify me-2"></span>
                        Video Views
                      </div>
                    </div>
                    <VideoViewCharts
                      viewTrendDate={viewTrendDate}
                      setViewTrendDate={setViewTrendDate}
                    />
                  </div>
                </div>
              </div>
              {/* ends here */}

              <div className="mb-5 col-lg-12">
                <div className="card ">
                  <div className="card-header">
                    <h5 className="card-title">Top Highest Viewed Video </h5>
                  </div>
                  <div className="card-body row">
                    <div className="card-body">
                      <div className="row video-list dashboard-video-list">
                        {
                          branddahboarddata?.videos?.length > 0 ? (
                            branddahboarddata?.videos?.map((data) => (
                              <div
                                className="col-lg-4 col-xl-3 col-md-4 col-6"
                                key={data.id}
                              >
                                <VideoCard
                                  caption={data?.videoTitle}
                                  createdAt={data?.createdAt}
                                  videoLink={data?.videoLink}
                                  celebrityDetails={data?.celebrityDetails}
                                  viewCount={data?.viewCount}
                                  productViews={data?.affiliateClickCount}
                                  playEventHandler={playEventHandler}
                                  Brand={data?.postedByBrand}
                                />
                              </div>
                            ))
                          ) : (
                          <div className="col-12 text-center">
                            <h6 className="text-danger mt-5 mb-0">Videos Not Found</h6>
                          </div>
                          )
                          // <h6 className="error-msg">{notFound}</h6>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        <FilterModel
          show={modalShow}
          filterData={filterData}
          setFilterData={setFilterData}
          defaultFilter={defaultFilter}
          filterTypeField={handleFilterTypeField}
          handleFilterTypeFields={handleFilterTypeFields}
          handleValueChange={handleValueChange}
          handleSubmit={handleSubmit}
          handleFiltertags={handleFiltertags}
          handleFilterArray={handleFilterArray}
          selectedFilterItems={selectedFilterItems}
          isSearchButtonDisabled={isSearchButtonDisabled}
          onHide={onHide}
          handleReset={handleReset}
          filterFields={filterFields}
          field={field}
        />
      </div>
    </Layout>
  );
}

export default Dashboard;
