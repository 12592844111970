import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";

import { Layout } from "../../Components/Layout";
import { paymentData } from "../../Store/Actions/User";
import {
  PROFILE_DETAILS,
  route,
  SELECT_PLAN,
  SUBSCRIPTION_FEES,
} from "../../Utils";

/******************* 
@Purpose : Used for brand payout
@Parameter : {}
@Author : Vista app
******************/
const BrandPayout = (props) => {
  const dispatch = useDispatch();
  const paymentId = useSelector((data) => data.user.planId);
  const id = paymentId;
  const [loading, setLoading] = useState(false);

  /******************* 
@Purpose : Used for Breadcrumb 
@Parameter : {}
@Author : Vista app
******************/
  const breadcrumb = [
    {
      title: PROFILE_DETAILS,
      url: route.BRAND_ACCOUNT,
    },
    {
      title: SELECT_PLAN,
      url: route.SELECT_PLAN,
    },
    {
      title: SUBSCRIPTION_FEES,
      url: route.BRAND_PAYOUT,
    },
  ];

  /******************* 
  @Purpose : Used for Banking options 
  @Parameter : {}
  @Author : Vista app
  ******************/
  const options = [
    { value: "credit card", label: "credit card" },
    { value: "debit card", label: "debit card" },
    { value: "net banking", label: "netbanking" },
  ];

  /******************* 
  @Purpose : Used for Payment intigration
  @Parameter : {}
  @Author : Vista app
  ******************/
  const hadlePayment = () => {
    setLoading(true);
    let data = {
      planId: id,
    };

    dispatch(paymentData(data)).then((res) => {
      if (res) {
        setLoading(false);
        props.history.push("/dashboard");
      }
    });
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="complete Profile">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <form className="mb-7">
              <div className="card">
                <div className="card-header">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb p-0">
                      <span className="link">Upload Video Ad</span>
                      <li aria-current="page">
                        <React.Fragment>
                          <span className="icon-arrow-right"></span>
                          Select Duration
                        </React.Fragment>
                      </li>
                      <li aria-current="page">
                        <React.Fragment>
                          <span className="icon-arrow-right"></span>
                          Select Payment Method
                        </React.Fragment>
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <div className="form-group">
                        <label className="form-label" htmlFor="Name">
                          Payment Methods*
                        </label>
                        <div className="w-100">
                          <Select
                            options={options}
                            className="custom-select-container"
                            classNamePrefix="custom-select"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Name on card*
                      </label>
                      <input
                        type="name"
                        className="form-control"
                        id="nameoncard"
                        placeholder="Enter Name On Card"
                      />
                    </div>
                    <div className="col-md-4 mb-4">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Card number*
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="cardnumber"
                          placeholder="Enter Card number"
                        />
                      </div>
                    </div>

                    <div className="col-md-4 mb-4">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Expiry Date*
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="expirydate"
                        placeholder="MM/YY"
                      />
                    </div>
                    <div className="col-md-4 mb-4">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        CVV *
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="cvv"
                        placeholder="***"
                      />
                    </div>
                    <div className="col-md-12">
                      <p>
                        <b>Note *:</b> By continuing system will save the card
                        detail for the future payment and this plan will be
                        autorenewed.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 ">
                    <div className="pt-3 d-flex justify-content-end align-item-center flex-wrap">
                      {/* <Button
                        className="me-2"
                        variant="primary"
                        onClick={backhandle}
                      >
                        Back
                      </Button> */}
                      <Button
                        variant="primary"
                        onClick={() => hadlePayment()}
                        disabled={loading}
                      >
                        {loading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Saving...
                          </>
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <footer>
            <div className="footer-text f justify-content-between">
              <span className="">2022 © Vista Brand Panel</span>
              <span>All Rights Reserved</span>
            </div>
          </footer>
        </div>
      </div>
    </Layout>
  );
};

export default BrandPayout;
