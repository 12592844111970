import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";

import { Layout, Footer } from "../../Components/Layout";
import {
  route,
  showMessageNotification,
  PUBLISH_VIDEO,
  PublishVideoAdSchema,
} from "../../Utils";
import { SelectField, TextField } from "../../Components/FormFields";
import { getuploadid } from "../../Store/Actions/UploadVideos";
import { getVideoInfo } from "../../Store/Actions/BrandVideos";
import { PublishVideos } from "../../Store/Actions/PublishVideo";

/******************* 
@Purpose : used for purchase video add
@Parameter : {props}
@Author : INIC
******************/
const PublishVideo = () => {
  const videoRef = useRef(null);
  const param = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const inputRef = useRef();
  const { categoryInfo } = useSelector((data) => data.user);

  const [loading, setLoading] = useState({
    msg: "",
    status: false,
    disable: true,
  });
  const [fileName, setFileName] = useState();
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState("");
  const [transactionRadio, setTranactioRadio] = useState("affiliatedlink");
  const [numberDisplay, setNumberDisplay] = useState(false);
  const [productDisplay, setProductDisplay] = useState(true);
  const [thumbImg, setThumbImg] = useState("");
  const publishVideoAdDetails = {
    title: "",
    category: "",
    subCategory: "",
    videoData: "",
    affiliatedlink: "",
    whatsAppNumber: "",
    description: "",
    productSku: "",
    // affilietedProductUrl: "",
  };
  /******************* 
  @Purpose :  used for get video info
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    if (param.slug) {
      dispatch(getVideoInfo(param.slug)).then((res) => {
        if (!res.status) {
          history.push("/ManageVideoAd");
        }
      });
    }
    categoryValue();
  }, []);

  /*******************
  @Purpose : used for categories value
  @Parameter : {}
  @Author : INIC
  ******************/
  const categoryValue = () => {
    let categoryArr = [];
    categoryInfo?.length > 0 &&
      categoryInfo?.forEach((optiondata) => {
        let tempObj = {
          label: optiondata?.title,
          value: optiondata?._id,
          ...optiondata,
        };
        categoryArr.push(tempObj);
        setCategory(categoryArr);
      });
  };

  /******************* 
  @Purpose :  used for sub category value 
  @Parameter : {list}
  @Author : INIC
  ******************/
  const subCategoryValue = (catData) => {
    let subCategoryArr = [];
    catData?.subCategoryDetails?.length > 0 &&
      catData?.subCategoryDetails?.forEach((subCategory) => {
        let tempObj2 = {
          label: subCategory?.title,
          value: subCategory?._id,
        };
        subCategoryArr.push(tempObj2);
        setSubCategory(subCategoryArr);
      });
    return subCategoryArr;
  };

  /******************* 
  @Purpose : Used for Bread crumb
  @Parameter : {}
  @Author : INIC
  ******************/
  const breadcrumb = [
    {
      title: "Video List",
      url: route.MANAGE_POST_VIDEO,
    },
    {
      title: PUBLISH_VIDEO,
      url: route.MANAGE_VIDEO_ADD,
    },
  ];

  /******************* 
   @purpose : set formik validation schema
   @Parameter : {}
   @Author :INIC
   ******************/
  const formik = useFormik({
    initialValues: publishVideoAdDetails,
    enableReinitialize: true,
    validationSchema: PublishVideoAdSchema(),

    onSubmit: async (values) => {
      const {
        title,
        category,
        subCategory,
        videoData,
        whatsappNumber,
        affiliatedLink,
        affilietedProductUrl,
      } = values;

      const subCatVal = subCategory?.value;
      const data = {
        videoTitle: title,
        categoryId: category._id,
        videoLink: videoData?.data?.key,
        videoThumb: videoData?.data?.thumbnailLocation,
        subCategoriedIds: [subCatVal],

        caption: values?.description,
        productSku: values?.productSku,
      };

      if (affilietedProductUrl === "true" && affiliatedLink) {
        data.affilietedProductUrl = affiliatedLink;
        data.whatsAppNumber = "";
      } else if (whatsappNumber) {
        data.whatsAppNumber = Number(whatsappNumber);
        data.affilietedProductUrl = "";
      }

      const res = await dispatch(PublishVideos(data));

      if (res) {
        history.push("/postvideo");
      } else {
        showMessageNotification(res.message, "error");
      }
    },
  });

  /******************* 
  @Purpose :  used for handle video upload
  @Parameter : {}
  @Author : INIC
  ******************/

  const handleVideoUpload = async (event) => {
    const file = event.target.files[0];
    const file_extension = file.type.split("/")[1];
    const array_of_allowed_files = ["mp4", "mov", "avi"];

    if (!array_of_allowed_files.includes(file_extension)) {
      formik.setFieldError("durationError", "Unsupported video format");
      return;
    }

    const videoElement = document.createElement("video");
    videoElement.src = URL.createObjectURL(file);

    videoElement.onloadedmetadata = () => {
      const duration = videoElement.duration;
      if (duration < 10) {
        formik.setFieldError(
          "durationError",
          "Upload the video according to the given criteria"
        );
      } else if (duration > 20) {
        formik.setFieldError(
          "durationError",
          "Upload the video according to the given criteria"
        );
      } else {
        setLoading({ msg: "Uploading...", status: true });
        const data = new FormData();
        setFileName(file.name);
        data.append("uploaded_file", file, file.name);
        dispatch(getuploadid(data))
          .then((res) => {
            setThumbImg(res?.data?.thumbnailLocation);

            setLoading({ msg: "Uploaded successfully", status: false });
            formik.setFieldValue("videoData", res);
            setTimeout(() => {
              setLoading({ msg: "", status: false });
            }, 3000);
          })
          .catch((err) => {
            console.error(err);
            throw err;
          });
      }
    };
  };
  // display whatapp number
  const whatsAppNumberDisplay = (data) => {
    setNumberDisplay(true);
    setProductDisplay(false);
  };
  // url display
  const urlDisplay = () => {
    setProductDisplay(true);
    setNumberDisplay(false);
  };
  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <>
      <Layout breadcrumb={breadcrumb} breadcrumbTitle="Post Video">
        <div className="dashboard-container">
          <div className="main-content-area">
            <div className="overlay" />
            <div className="main-content-block">
              <div className="row mb-5">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title"> Post A Video</h5>
                    </div>
                    <div className="card-body">
                      <form className="mb-5">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-12">
                                <TextField
                                  type="text"
                                  label="Title"
                                  placeholder="Enter Title"
                                  name="title"
                                  formik={formik}
                                />
                              </div>
                              <div className="col-md-6">
                                <SelectField
                                  className="custom-select-container"
                                  classNamePrefix={"custom-select"}
                                  options={category}
                                  label="Category"
                                  name="category"
                                  placeholder="Select Category"
                                  onChange={(e) =>
                                    subCategoryValue(e.target.value)
                                  }
                                  formik={formik}
                                />
                              </div>
                              <div className="col-md-6">
                                <SelectField
                                  className="custom-select-container"
                                  classNamePrefix={"custom-select"}
                                  options={subCategory}
                                  label="Subcategory"
                                  name="subCategory"
                                  placeholder="Select Sub-Category"
                                  formik={formik}
                                />
                              </div>

                              {/* radio starts here */}
                              {productDisplay ? (
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label
                                      className="form-label"
                                      htmlFor="affiliatedLink"
                                    >
                                      Affiliated Product URL{" "}
                                      <sup className="text-danger">*</sup>
                                    </label>
                                    <div className="">
                                      <div className="input-group-prepend">
                                        <div
                                          className="input-group-text d-none"
                                          style={{ height: "40px" }}
                                        >
                                          <input
                                            type="radio"
                                            value={"true"}
                                            checked={
                                              formik?.values
                                                ?.affilietedProductUrl ===
                                              "true"
                                            }
                                            name="affilietedProductUrl"
                                            id="affilietedProductUrl_true"
                                            className="me-2 "
                                          />
                                        </div>
                                      </div>
                                      <input
                                        type="text"
                                        className="form-control"
                                        aria-label="affiliatedLink"
                                        id="affiliatedLink"
                                        name="affiliatedLink"
                                        placeholder="Enter Product URL"
                                        autoComplete="off"
                                        onChange={(e) => {
                                          formik.setFieldValue(
                                            "affilietedProductUrl",
                                            "true"
                                          );
                                          formik.setFieldValue(
                                            "affiliatedLink",
                                            e.target.value
                                          );
                                          formik.setFieldValue(
                                            "whatsappNumber",
                                            ""
                                          );
                                        }}
                                        value={formik.values.affiliatedLink}
                                      />
                                    </div>
                                    {formik.touched.affiliatedLink &&
                                    formik.errors.affiliatedLink ? (
                                      <div
                                        className="error text-danger form-text"
                                        style={{ fontSize: "0.875rem" }}
                                      >
                                        {formik.errors.affiliatedLink}
                                      </div>
                                    ) : null}
                                    {formik.errors.affilietedProductUrl ? (
                                      <div
                                        className="error text-danger form-text"
                                        style={{ fontSize: "0.875rem" }}
                                      >
                                        {formik.errors.affilietedProductUrl}
                                      </div>
                                    ) : null}
                                  </div>
                                  <p
                                    onClick={() => whatsAppNumberDisplay()}
                                    className="text-primary fs-14 fw-normal text-decoration-none mt-1 mb-0 cursor-pointer border py-1 px-3 d-inline-block"
                                    style={{ fontWeight: 600 }}
                                  >
                                    Instead Use Whatsapp Number
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}

                              {numberDisplay ? (
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label
                                      className="form-label"
                                      htmlFor="whatsappNumber"
                                    >
                                      Whatapp Number
                                      <sup className="text-danger">*</sup>
                                    </label>
                                    <div className="">
                                      <div className="input-group-prepend">
                                        <div
                                          className="input-group-text d-none"
                                          style={{ height: "40px" }}
                                        >
                                          <input
                                            type="radio"
                                            value={"false"}
                                            checked={
                                              formik?.values
                                                ?.affilietedProductUrl ===
                                              "false"
                                            }
                                            name="affilietedProductUrl"
                                            id="affilietedProductUrl_false"
                                            className="me-2 "
                                          />
                                        </div>
                                      </div>
                                      <input
                                        type="tel"
                                        className="form-control"
                                        aria-label="whatsappNumber"
                                        id="whatsappNumber"
                                        name="whatsappNumber"
                                        placeholder="Enter Whatsapp Number"
                                        onChange={(e) => {
                                          formik.setFieldValue(
                                            "affilietedProductUrl",
                                            "false"
                                          );
                                          formik.setFieldValue(
                                            "affiliatedLink",
                                            ""
                                          );
                                          formik.setFieldValue(
                                            "whatsappNumber",
                                            e.target.value
                                          );
                                        }}
                                        autoComplete="off"
                                        value={formik.values.whatsappNumber}
                                      />
                                    </div>
                                    <small>
                                      {formik.errors.whatsappNumber ? (
                                        <div
                                          className="error text-danger form-text"
                                          style={{ fontSize: "0.875rem" }}
                                        >
                                          {formik.errors.whatsappNumber}
                                        </div>
                                      ) : null}
                                    </small>
                                  </div>
                                  <p
                                    onClick={() => urlDisplay()}
                                    className="text-primary fs-14 fw-normal text-decoration-none mt-1 mb-0 cursor-pointer border py-1 px-3 d-inline-block"
                                    style={{ fontWeight: 600 }}
                                  >
                                    Instead Use Product URL
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}

                              {/* radio ends here */}
                              {/* product sku */}
                              <div className="col-md-6">
                                <TextField
                                  rows={1}
                                  label="Product Sku"
                                  placeholder="Enter product sku..."
                                  name="productSku"
                                  formik={formik}
                                  required={false}
                                  autoComplete="off"
                                />
                              </div>
                              {/* product sku ends here */}
                              <div className="col-md-12">
                                <TextField
                                  as="textarea"
                                  rows={3}
                                  label="Short Description/Bio"
                                  placeholder="Enter The description here..."
                                  name="description"
                                  formik={formik}
                                  required={false}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="notification-form">
                              <div className="form-group mb-4">
                                <label className="form-label" htmlFor="Video">
                                  Video &nbsp;(Minimum 10 seconds and maximum 20
                                  seconds video is allowed to publish) &nbsp;
                                  <sup className="text-danger">*</sup>
                                </label>
                                <div className="text-danger">
                                  {formik.errors.durationError ? (
                                    <div className="text-danger">
                                      {formik.errors.durationError}
                                    </div>
                                  ) : null}
                                  {/* Minimum 10 seconds and maximum 20 seconds
                                    video is allowed to publish */}
                                </div>
                                <div className="edit-image">
                                  <div className="modal-img-wrapper">
                                    {thumbImg ? (
                                      <div className="icon-wrapper ">
                                        <img
                                          src={thumbImg}
                                          style={{ height: "130px" }}
                                        ></img>
                                      </div>
                                    ) : (
                                      <div className="icon-wrapper ">
                                        <i className="icon-cloud-upload bx-lg"></i>
                                      </div>
                                    )}
                                    {/* <div className="icon-wrapper ">
                                      <i className="icon-cloud-upload bx-lg"></i>
                                    </div> */}
                                    <div className="img-content-wrapper d-flex">
                                      <input
                                        ref={inputRef}
                                        name="videoData"
                                        className="VideoInput_input"
                                        type="file"
                                        onChange={handleVideoUpload}
                                        accept=".mov,.mp4"
                                        formik={formik}
                                      />

                                      {fileName ? (
                                        <span>{fileName}</span>
                                      ) : (
                                        <span className="my-2">
                                          no file choose
                                        </span>
                                      )}
                                    </div>
                                    <div className="img-file-text">
                                      Please note that .Mp4 .MKV .MOV Video file
                                      is allowed.
                                      <sup>*</sup>
                                    </div>
                                    <span disabled>
                                      {loading.status ? (
                                        <span
                                          className="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                      ) : null}
                                      <span
                                        className={
                                          loading.status
                                            ? "text-primary mt-2"
                                            : "text-success mt-2"
                                        }
                                      >
                                        {loading.msg}
                                      </span>
                                    </span>
                                    <div className="text-danger mt-2">
                                      {loading.msg
                                        ? " "
                                        : formik.errors.videoData}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>

                      <div className="col-md-12">
                        <div className="text-end">
                          <Link to="/postvideo">
                            <Button
                              variant="light"
                              className="me-2"
                              type="reset"
                            >
                              Cancel
                            </Button>
                          </Link>
                          <Button
                            variant="primary"
                            onClick={() => formik.handleSubmit()}
                          >
                            Publish
                          </Button>
                        </div>
                      </div>
                      <div className="mt-4 disclamer">
                        Note: If you can't locate or find the appropriate
                        sub-category, please contact Vista Admin at &nbsp;
                        <a href="mailto:support@vistareels.com">
                          support@vistareels.com
                        </a>
                        &nbsp; and provide the details (sub-category name and
                        its parent category) in the email to request the
                        addition for the same.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default PublishVideo;
