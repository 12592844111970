const INTIAL_STATE = {
  branddahboarddata: [],
  branddahboardchartdata: [],
  dashboardTrendData: [],
};
/******************* 
@Purpose : Used for store data in redux
@Parameter : state, action
@Author : INIC
******************/
export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case "SET_BRAND_DASHBOARD_DATA":
      return Object.assign({}, state, {
        branddahboarddata: action.payload,
      });
    case "SET_BRAND_DASHBOARD_CHART_DATA":
      return Object.assign({}, state, {
        branddahboardchartdata: action.payload,
      });
    case "SET_TREND_DATA":
      return Object.assign({}, state, {
        dashboardTrendData: action.payload,
      });

    default:
      return state;
  }
};
