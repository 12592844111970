import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";

import { Layout, Footer } from "../../Components/Layout";

import {
  LIST_OF_VIDEO_AD,
  route,
  CHANGE_OF_VIDEO_AD,
  changeVideoAdSchema,
  showMessageNotification,
} from "../../Utils";
import { SelectField, TextField } from "../../Components/FormFields";
import {
  changeUploadVideo,
  getuploadid,
} from "../../Store/Actions/UploadVideos";
import {
  getVideoInfo,
  setPurchaseDetails,
} from "../../Store/Actions/BrandVideos";

/******************* 
@Purpose : used for purchase video add
@Parameter : {props}
@Author : INIC
******************/
const ChangePurchasevideo = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const inputRef = useRef();
  const param = useParams();
  const { celebvideoinfo } = useSelector((data) => data?.brandvideos);
  const [loading, setLoading] = useState({
    msg: "",
    status: false,
    disable: true,
  });
  const [fileName, setFileName] = useState();
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState("");
  const [videolink, setVideoLink] = useState();
  const [thumbImg, setThumbImg] = useState();
  /******************* 
  @Purpose :  used for get video info
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    if (param.slug) {
      dispatch(getVideoInfo(param.slug)).then((res) => {
        setThumbImg(res?.data[0]?.videoThumb);
        if (!res.status) {
          history.push("/ManageVideoAd");
        }
      });
    }
  }, []);

  /******************* 
  @Purpose :  used for set category and subcategory
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    setCategory([
      {
        value: celebvideoinfo?.[0]?.category?.[0]?._id,
        label: celebvideoinfo?.[0]?.category?.[0]?.title,
      },
    ]);
    setSubCategory([
      {
        value: celebvideoinfo?.[0]?.subcategories?.[0]?._id,
        label: celebvideoinfo?.[0]?.subcategories?.[0]?.title,
      },
    ]);
    setVideoLink(celebvideoinfo?.[0]?.videoLink);
  }, [celebvideoinfo]);

  /******************* 
  @Purpose : Used for Bread crumb
  @Parameter : {}
  @Author : INIC
  ******************/
  const breadcrumb = [
    {
      title: LIST_OF_VIDEO_AD,
      url: route.MANAGE_VIDEO_ADD,
    },
    {
      title: CHANGE_OF_VIDEO_AD,
      url: route.MANAGE_VIDEO_ADD,
    },
  ];

  /******************* 
   @purpose : set formik validation schema
   @Parameter : {}
   @Author :INIC
   ******************/
  const formik = useFormik({
    initialValues: celebvideoinfo,
    enableReinitialize: true,
    validationSchema: changeVideoAdSchema(),
    onSubmit: async (values) => {
      const { videoData } = values;

      const data = {
        videoTitle: values?.[0].videoTitle,
        categoryId: values?.[0].category,
        videoLink: videoData?.data?.key,
        videoThumb: videoData?.data?.thumbnailLocation,
        subCategoriedIds: values?.[0].subcategories,
      };
      const res = await dispatch(setPurchaseDetails(data));
      if (res) {
        let body = {
          videoId: param.slug,
          videoLink: res?.payload?.videoLink
            ? res?.payload?.videoLink
            : celebvideoinfo?.[0]?.videoLink,
          videoThumb: res?.payload?.videoThumb,
        };
        const resp = await dispatch(changeUploadVideo(body));

        {
          if (resp.status) {
            showMessageNotification(resp?.message, "success");
            history.push("/ManageVideoAd");
          } else {
            showMessageNotification(resp.message, "error");
          }
        }
      }
    },
  });

  /******************* 
  @Purpose :  used for handle video upload
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleVideoUpload = async (event) => {
    setLoading({ msg: "Uploading...", status: true });
    let data = new FormData();
    const file = event.target.files[0].name;
    setFileName(event.target.files[0].name);
    data.append("uploaded_file", event.target.files[0], file);
    dispatch(getuploadid(data))
      .then((res) => {
        if (res) {
          if (!res || loading.status) {
            setLoading({ msg: "Video Not uploaded", status: false });
          } else {
            setLoading({ msg: "Uploaded successfully", status: false });
            setThumbImg(res?.data?.thumbnailLocation);
            formik.setFieldValue("videoData", res);
            setTimeout(() => {
              setLoading({ msg: "", status: false });
            }, 3000);
          }
        }
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <>
      <Layout breadcrumb={breadcrumb} breadcrumbTitle="Manage Video AD">
        <div className="dashboard-container">
          <div className="main-content-area">
            <div className="overlay" />
            <div className="main-content-block">
              <div className="row mb-5">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb p-0">
                          <li className="link breadcrumb-item active">
                            Change Upload Video Ad
                          </li>
                        </ol>
                      </nav>
                    </div>
                    <div className="card-body">
                      <form className="mb-5">
                        <div className="row">
                          <div className="col-md-6">
                            <TextField
                              type="text"
                              label="Title"
                              className="back-color"
                              placeholder="Enter Title"
                              name="title"
                              value={celebvideoinfo?.[0]?.videoTitle}
                              disabled={true}
                            />
                            <div className="mb-4">
                              <SelectField
                                options={category}
                                label="Category"
                                name="category"
                                values={category}
                                isDisabled
                              />
                            </div>
                            <div className="mb-4">
                              <SelectField
                                className="custom-select-container"
                                classNamePrefix={"custom-select"}
                                options={subCategory}
                                label="Subcategory"
                                name="subCategory"
                                values={subCategory}
                                isDisabled
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="notification-form">
                              <div className="form-group mb-4">
                                <label className="form-label" htmlFor="Video">
                                  Video
                                  <sup className="text-danger">*</sup>
                                </label>
                                <div className="edit-image">
                                  <div className="modal-img-wrapper">
                                    {/* <div className="icon-wrapper ">
                                      <i className="icon-cloud-upload bx-lg"></i>
                                    </div> */}
                                    {thumbImg ? (
                                      <div className="icon-wrapper ">
                                        <img
                                          src={thumbImg}
                                          style={{ height: "130px" }}
                                        ></img>
                                      </div>
                                    ) : (
                                      <div className="icon-wrapper ">
                                        <i className="icon-cloud-upload bx-lg"></i>
                                      </div>
                                    )}
                                    <div className="img-content-wrapper d-flex">
                                      <input
                                        ref={inputRef}
                                        name="videoData"
                                        className="VideoInput_input"
                                        type="file"
                                        onChange={handleVideoUpload}
                                        accept=".mov,.mp4"
                                        formik={formik}
                                      />
                                      {!fileName ? (
                                        <span className="card w-100">
                                          {fileName
                                            ? fileName
                                            : videolink?.slice(55)}
                                        </span>
                                      ) : (
                                        <span>
                                          {fileName
                                            ? fileName
                                            : "no file choose"}
                                        </span>
                                      )}
                                    </div>
                                    <div className="img-file-text">
                                      Please note that .Mp4 .MKV .MOV Video file
                                      is allowed.
                                      <sup>*</sup>
                                    </div>
                                    <span disabled>
                                      {loading.status ? (
                                        <span
                                          className="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                      ) : null}
                                      <span
                                        className={
                                          loading.status
                                            ? "text-primary"
                                            : "text-success"
                                        }
                                      >
                                        {loading.msg}
                                      </span>
                                    </span>
                                    <div className="text-danger">
                                      {formik.errors.videoData}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>

                      <div className="col-md-12">
                        <div className="text-end">
                          <Link to="/ManageVideoAd">
                            <Button variant="light" type="reset" className="me-2">
                              Cancel
                            </Button>
                          </Link>
                          <Button
                            variant="primary"
                            onClick={() => formik.handleSubmit()}
                            disabled={
                              loading.disable ? loading.disable : loading.status
                            }
                          >
                            Change
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ChangePurchasevideo;
