import React from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router";

/*******************
@purpose : used for card info
@Parameter : {}
@Author : INIC
******************/
function CardData() {
  const { branddahboarddata } = useSelector((data) => data?.branddashboard);
  const history = useHistory();

  /*******************
  @purpose : used for dashboard cards
  @Parameter : {}
  @Author : INIC
  ******************/
  const cardData = [
    {
      id: "video_tags",
      name: "Video Tags",
      count: branddahboarddata?.videoTags ?? 0,
    },
    {
      id: "video_views",
      name: "Video Views",
      count: branddahboarddata?.videoViews ?? 0,
    },
    {
      id: "Total_video_Purchase",
      name: "Total Video Purchase",
      count: branddahboarddata?.totalNoOfVideosOrdered ?? 0,
    },
    { id: "total_purchase", name: "Total Product Purchase", count: 0 },
  ];

  /*******************
  @purpose : used for dashboard card icon
  @Parameter : {}
  @Author : INIC
  ******************/
  const cardIcon = {
    video_tags: "bx-video-recording",
    video_views: "bx-show",
    Total_video_Purchase: "bx-video-recording",
    total_purchase: "bx-rupee",
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
      <Row className="g-md-5 g-3">
        {cardData.map((value) => (
          <Col xs={12} sm={6} xl={3}  key={value?.id}>
            <div
              className={
                value.id === "Total_video_Purchase"
                  ? "user-info-card col d-flex flex-wrap align-items-center p-3 cursor-pointer"
                  : "user-info-card col d-flex flex-wrap align-items-center p-3"
              }
              onClick={() => {
                if (value.id === "Total_video_Purchase") {
                  history.push("/report");
                }
              }}
            >
              <div className="d-flex align-items-center">
                <div className="icon-box-wrap">
                  <i className={`bx ${cardIcon[value?.id]}`}></i>
                </div>
                <div className="w-100 font-32">
                  <h3 className="text-black m-0 fw-bold">{value?.count}</h3>
                  <h6 className="text-orange mb-0 pt-1">{value?.name}</h6>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
  );
}

export default CardData;
