const INTIAL_STATE = {
  allVideos: [],
};
/******************* 
  @Purpose : Used for store data in redux
  @Parameter : state, action
  @Author : INIC
  ******************/
export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case "ALL_VIDEOS_DISPLAY":
      return Object.assign({}, state, {
        allVideos: action.payload,
      });

    default:
      return state;
  }
};
