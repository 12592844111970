import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { isEmpty } from "lodash";
import CreditCardInput from "react-credit-card-input";
import Select from "react-select";

import { savecardInfo } from "../../Store/Actions/CardInfo";
import { showMessageNotification } from "../../Utils/Functions";
import errorMessages from "../../Utils/ErrorMessages";

/******************* 
@Purpose : Used for Brand cards
@Parameter : {}
@Author : INIC
******************/
const BrandCard = ({ setShowEditBrand }) => {
  const dispatch = useDispatch();
  const [cardDetails, setCardDetails] = useState({
    cardNumber: "",
    expiry: "",
    cvc: "",
    paymentCard: "",
    cardName: "",
  });
  const [errors, setErrors] = useState({
    paymentCard: "",
    cardName: "",
    cardNumber: "",
    expiry: "",
    cvc: "",
  });

  const { cardNumber, expiry, cvc, paymentCard, cardName } = cardDetails;

  /******************* 
  @Purpose : Used for handle Payment Details 
  @Parameter : {name, value}
  @Author : INIC
  ******************/
  const handlePaymentDetails = (name, value) => {
    setCardDetails({
      ...cardDetails,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" });
  };

  /******************* 
  @Purpose : Used for Brand cards
  @Parameter : {}
  @Author : INIC
  ******************/
  const options = [
    { value: "Credit Card", label: "Credit Card" },
    { value: "Debit Card", label: "Debit Card" },
  ];

  /******************* 
  @Purpose : Used for validate form
  @Parameter : {}
  @Author : INIC
  ******************/
  const validateForm = () => {
    let errors = {
      paymentCard: "",
      cardName: "",
      cardNumber: "",
      expiry: "",
      cvc: "",
    };
    let isFormValid = true;

    if (isEmpty(paymentCard))
      errors.paymentCard = errorMessages.PROVIDE_PAYMENT_CARD;
    else errors.paymentCard = "";

    if (isEmpty(cardName)) errors.cardName = errorMessages.PROVIDE_NAME_ON_CARD;
    else errors.cardName = "";

    if (isEmpty(cardNumber))
      errors.cardNumber = errorMessages.PROVIDE_CARD_NUMBER;
    else if (cardNumber.length > 16 && cardNumber.length < 16)
      errors.cardNumber = "Card number should be 16 number";
    else errors.cardNumber = "";

    if (isEmpty(expiry)) errors.expiry = errorMessages.PROVIDE_CARD_EXPIRY;
    else errors.expiry = "";

    if (isEmpty(cvc)) errors.cvc = errorMessages.provide_CARD_CVV;
    else errors.cvc = "";

    if (
      errors.cardName !== "" ||
      errors.cardNumber !== "" ||
      errors.expiry !== "" ||
      errors.cvc !== ""
    )
      isFormValid = false;
    setErrors(errors);

    if (errors) {
      console.error(errors);
    }
    return isFormValid;
  };

  /******************* 
  @Purpose : Used for Handle card expiry
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleSaveCardInfo = () => {
    if (validateForm()) {
      let data = {
        cardType: paymentCard,
        name: cardName,
        cardNumber: cardNumber,
        expiry: expiry,
      };
      dispatch(savecardInfo(data))
        .then((res) => {
          if (res.status) {
            setShowEditBrand(false);
          } else showMessageNotification(errors, "error");
        })
        .catch((err) => {
          console.error(err);
          throw err;
        });
    }
  };

  /******************* 
  @Purpose : Used for toggle component
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleCancel = () => {
    setShowEditBrand(false);
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title">Add Card</h5>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-lg-6 mb-4">
            <div className="form-group">
              <label className="form-label" htmlFor="payment-method">
                Payment Card Type<span className="error-msg">*</span>
              </label>
              <Select
                options={options}
                className="custom-select-container"
                classNamePrefix="custom-select"
                placeholder="Select Payment Card Type"
                onChange={(e) => handlePaymentDetails("paymentCard", e.value)}
              />
              <span className="error-msg">{errors.paymentCard}</span>
            </div>
          </div>
          <div className="col-lg-6 mb-4">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              Name on card<span className="error-msg">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="nameoncard"
              placeholder="Enter Name On Card"
              onChange={(e) => {
                const newName = e.target.value.replace(/[^A-Za-z ]/g, "");
                handlePaymentDetails("cardName", newName);
              }}
              value={cardDetails.cardName}
            />
            <span className="error-msg">{errors.cardName}</span>
          </div>
          <div className="col-lg-12 credit-card-inpt">
            <CreditCardInput
              className="d-block"
              cardNumberInputProps={{
                value: cardNumber,
                className: "form-control",
                onChange: (e) =>
                  handlePaymentDetails("cardNumber", e.target.value),
              }}
              cardExpiryInputProps={{
                value: expiry,
                onChange: (e) => handlePaymentDetails("expiry", e.target.value),
                className: "form-control",
              }}
              cardCVCInputProps={{
                value: cvc,
                onChange: (e) => handlePaymentDetails("cvc", e.target.value),
                className: "form-control",
              }}
            />
            <div className="row col-md-6">
              <div className="col-md-1 w-25">
                <span className="error-msg">{errors.cardNumber}</span>
              </div>
              <div className="col-md-1 mx-2  w-25">
                <span className="error-msg ">{errors.expiry}</span>
              </div>
              <div className="col-md-1  w-25">
                <span className="error-msg ">{errors.cvc}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 text-end">
            <Button
              className="me-2"
              variant="primary"
              onClick={() => handleSaveCardInfo()}
            >
              Save
            </Button>
            <Button variant="light" onClick={() => handleCancel()}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandCard;
