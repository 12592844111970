import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { EditPostedVideos } from "../../Store/Actions/PublishVideo";
import { Layout, Footer } from "../../Components/Layout";

import {
  route,
  EditPublishVideoAdSchema,
  showMessageNotification,
} from "../../Utils";
import { SelectField, TextField } from "../../Components/FormFields";
import { getuploadid } from "../../Store/Actions/UploadVideos";
import { getpostedVideoInfo } from "../../Store/Actions/BrandVideos";

/******************* 
@Purpose : used for purchase video add
@Parameter : {props}
@Author : INIC
******************/
const EditPostedVideo = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { categoryInfo } = useSelector((data) => data?.user);
  const inputRef = useRef();
  const param = useParams();
  const { postedvideoinfo } = useSelector((data) => data?.brandvideos);

  const [loading, setLoading] = useState({
    msg: "",
    status: false,
    disable: true,
  });
  const [fileName, setFileName] = useState(
    postedvideoinfo?.videoLink?.slice(100)
  );
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState("");
  const [thumbImg, setThumbImg] = useState("");
  const [numberDisplay, setNumberDisplay] = useState();
  const [productDisplay, setProductDisplay] = useState();

  /******************* 
  @Purpose :  used for get video info
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    if (param.slug) {
      dispatch(getpostedVideoInfo(param.slug)).then((res) => {
        setThumbImg(res?.data[0]?.videoThumb);
        if (res?.data[0]?.affilietedProductUrl) {
          setProductDisplay(true);
        } else {
          setNumberDisplay(true);
        }
        if (!res.status) {
          history.push("/ManageVideoAd");
        }
      });
    }
    categoryValue();
  }, []);

  /******************* 
  @Purpose : Used for Bread crumb
  @Parameter : {}
  @Author : INIC
  ******************/
  const breadcrumb = [
    {
      title: "Video List",
      url: route.MANAGE_POST_VIDEO,
    },
    {
      title: "Edit Published Video",
      url: route.MANAGE_VIDEO_ADD,
    },
  ];

  /******************* 
   @purpose : set formik validation schema
   @Parameter : {}
   @Author :INIC
   ******************/
  const subCategoryFormData = postedvideoinfo?.subcategories
    ? postedvideoinfo?.subcategories?.map((data) => ({
        value: data?._id,
        label: data?.title,
      }))
    : [];

  const formData = {
    ...postedvideoinfo,
    subcategories: subCategoryFormData,
  };

  const formik = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema: EditPublishVideoAdSchema(),
    onSubmit: async (values) => {
      const {
        _id,
        videoData,
        videoTitle,
        category,
        subcategories,
        affilietedProductUrl,
        productSku,
        whatsAppNumber,
        caption,
      } = values;

      let subData = subcategories.map((test) => test?.value);

      const data = {
        videoId: _id,
        categoryId: category?._id,
        subCategoriedIds: subData,
        videoLink: videoData?.data?.key
          ? videoData?.data?.key
          : formik?.values?.videoLink,
        videoThumb: videoData?.data?.thumbnailKey
          ? videoData?.data?.thumbnailKey
          : formik?.values?.videoThumb,
        videoTitle: videoTitle,
        affilietedProductUrl: affilietedProductUrl,
        whatsAppNumber: whatsAppNumber,
        caption: caption,
        productSku: productSku,
      };

      const resp = await dispatch(EditPostedVideos(data));
      if (resp.status) {
        showMessageNotification(resp?.message, "success");
        history.push("/postvideo");
      } else {
        showMessageNotification(resp.message, "error");
      }
      // }
    },
  });

  /******************* 
  @Purpose :  used for handle video upload
  @Parameter : {}
  @Author : INIC
  ******************/

  const handleVideoUpload = async (event) => {
    const file = event.target.files[0];
    const file_extension = file.type.split("/")[1];
    const array_of_allowed_files = ["mp4", "mov", "avi"];

    if (!array_of_allowed_files.includes(file_extension)) {
      formik.setFieldError("durationError", "Unsupported video format");
      return;
    }

    const videoElement = document.createElement("video");
    videoElement.src = URL.createObjectURL(file);

    videoElement.onloadedmetadata = () => {
      const duration = videoElement.duration;
      if (duration < 10) {
        formik.setFieldError(
          "durationError",
          "Upload the video according to the given criteria"
        );
      } else if (duration > 20) {
        formik.setFieldError(
          "durationError",
          "Upload the video according to the given criteria"
        );
      } else {
        setLoading({ msg: "Uploading...", status: true });
        const data = new FormData();
        setFileName(file.name);
        data.append("uploaded_file", file, file.name);
        dispatch(getuploadid(data))
          .then((res) => {
            setThumbImg(res?.data?.thumbnailLocation);
            setLoading({ msg: "Uploaded successfully", status: false });
            formik.setFieldValue("videoData", res);
            setTimeout(() => {
              setLoading({ msg: "", status: false });
            }, 3000);
          })
          .catch((err) => {
            console.error(err);
            throw err;
          });
      }
    };
  };
  /*******************
  @Purpose : used for categories value
  @Parameter : {}
  @Author : INIC
  ******************/
  const categoryValue = () => {
    let categoryArr = [];
    categoryInfo?.length > 0 &&
      categoryInfo?.forEach((optiondata) => {
        let tempObj = {
          label: optiondata?.title,
          value: optiondata?._id,
          ...optiondata,
        };
        categoryArr.push(tempObj);
        setCategory(categoryArr);
      });
  };

  useEffect(() => {
    const selectedCategory = categoryInfo.find(
      ({ title }) => title == postedvideoinfo?.category?.[0]?.title
    );
    const subCategory = selectedCategory?.subCategoryDetails?.map((data) => ({
      label: data?.title,
      value: data?._id,
    }));
    setSubCategory(subCategory);
  }, [postedvideoinfo?.category]);

  /******************* 
  @Purpose :  used for sub category value 
  @Parameter : {list}
  @Author : INIC
  ******************/
  const subCategoryValue = (catData) => {
    let subCategoryArr = [];
    catData?.subCategoryDetails?.length > 0 &&
      catData?.subCategoryDetails?.forEach((subCategory) => {
        let tempObj2 = {
          label: subCategory?.title,
          value: subCategory?._id,
        };
        subCategoryArr.push(tempObj2);
        setSubCategory(subCategoryArr);
      });
  };
  // display whatapp number
  const whatsAppNumberDisplay = () => {
    setNumberDisplay(true);
    setProductDisplay(false);
  };
  // url display
  const urlDisplay = () => {
    setProductDisplay(true);
    setNumberDisplay(false);
  };
  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <>
      <Layout breadcrumb={breadcrumb} breadcrumbTitle="Post A Video">
        <div className="dashboard-container">
          <div className="main-content-area">
            <div className="overlay" />
            <div className="main-content-block">
              <div className="row mb-5">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Edit Published Video</h5>
                    </div>
                    <div className="card-body">
                      <form className="mb-5">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-12">
                                <TextField
                                  type="text"
                                  label="Title"
                                  placeholder="Enter Title"
                                  name="videoTitle"
                                  formik={formik}
                                />
                              </div>

                              <div className="col-md-6">
                                <SelectField
                                  options={category}
                                  label="Category"
                                  name="category"
                                  className="custom-select-container"
                                  classNamePrefix={"custom-select"}
                                  onChange={(e) => {
                                    formik.setFieldValue("subcategories", "");
                                    subCategoryValue(e.target.value);
                                  }}
                                  formik={formik}
                                  values={
                                    formik?.values?.category?.length
                                      ? formik?.values?.category?.map(
                                          (data) => {
                                            return {
                                              value: data?._id,
                                              label: data?.title,
                                            };
                                          }
                                        )
                                      : [
                                          {
                                            label:
                                              formik?.values?.category?.title,
                                            value:
                                              formik?.values?.category?._id,
                                          },
                                        ]
                                  }
                                />
                              </div>

                              <div className="col-md-6">
                                <SelectField
                                  isMulti
                                  options={subCategory}
                                  label="Subcategory"
                                  name="subcategories"
                                  values={formik?.values?.subcategories}
                                  formik={formik}
                                  className="custom-select-container"
                                  classNamePrefix={"custom-select"}
                                />
                              </div>

                              {/* radio code starts here */}
                              {productDisplay ? (
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label
                                      className="form-label"
                                      htmlFor="affiliatedLink"
                                    >
                                      Affiliated Link
                                      <span className="error text-danger">
                                        *
                                      </span>
                                    </label>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <div
                                          className="input-group-text d-none"
                                          style={{ height: "40px" }}
                                        >
                                          <input
                                            type="radio"
                                            value={"true"}
                                            checked={
                                              formik?.values
                                                ?.affilietedProductUrl
                                                ? true
                                                : false
                                            }
                                            name="affiliatedLink"
                                            id="affiliatedLink_true"
                                            className="me-2"
                                            onChange={(e) => {
                                              formik.setFieldValue(
                                                "affiliatedLink",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <input
                                        type="text"
                                        className="form-control"
                                        aria-label="affilietedProductUrl"
                                        id="affilietedProductUrl"
                                        name="affilietedProductUrl"
                                        placeholder="Enter Affiliated Link"
                                        onChange={(e) => {
                                          formik.setFieldValue(
                                            "affilietedProductUrl",
                                            e.target.value
                                          );
                                          formik.setFieldValue(
                                            "whatsAppNumber",
                                            ""
                                          );
                                          formik.setFieldValue(
                                            "affiliatedLink",
                                            "true"
                                          );
                                        }}
                                        value={
                                          formik?.values?.affilietedProductUrl
                                        }
                                      />
                                    </div>
                                    {formik?.values?.affilietedProductUrl ===
                                      "" &&
                                    formik.values?.whatsAppNumber == "" ? (
                                      <div
                                        className="error text-danger form-text"
                                        style={{ fontSize: "0.875rem" }}
                                      >
                                        <div>
                                          Please enter the affiliatedLink or
                                          whatsAppNumber
                                        </div>
                                      </div>
                                    ) : null}
                                    {formik.errors.affilietedProductUrl ? (
                                      <div
                                        className="error text-danger form-text"
                                        style={{ fontSize: "0.875rem" }}
                                      >
                                        {formik.errors.affilietedProductUrl}
                                      </div>
                                    ) : null}
                                  </div>
                                  <p
                                    onClick={() => whatsAppNumberDisplay()}
                                    className="text-primary fs-14 fw-normal text-decoration-none mt-1 mb-0 cursor-pointer border py-1 px-3 d-inline-block"
                                    style={{ fontWeight: "600" }}
                                  >
                                    Instead Use Whatsapp Number
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}

                              {numberDisplay ? (
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label
                                      className="form-label"
                                      htmlFor="whatsAppNumber"
                                    >
                                      Whatapp Number{" "}
                                      <span className="error text-danger">
                                        *
                                      </span>
                                    </label>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <div
                                          className="input-group-text d-none"
                                          style={{ height: "40px" }}
                                        >
                                          <input
                                            type="radio"
                                            value={"false"}
                                            checked={
                                              formik?.values?.whatsAppNumber
                                                ? true
                                                : false
                                            }
                                            name="affiliatedLink"
                                            id="affiliatedLink_false"
                                            className="me-2"
                                            onChange={(e) => {
                                              formik.setFieldValue(
                                                "affiliatedLink",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <input
                                        type="tel"
                                        className="form-control"
                                        aria-label="whatsAppNumber"
                                        id="whatsAppNumber"
                                        name="whatsAppNumber"
                                        placeholder="Enter Whatapp Number"
                                        onChange={(e) => {
                                          formik.setFieldValue(
                                            "affiliatedLink",
                                            "false"
                                          );
                                          formik.setFieldValue(
                                            "whatsAppNumber",
                                            e.target.value
                                          );
                                          formik.setFieldValue(
                                            "affilietedProductUrl",
                                            ""
                                          );
                                        }}
                                        value={formik.values.whatsAppNumber}
                                      />
                                    </div>
                                    {formik?.errors &&
                                    formik.errors.whatsAppNumber ? (
                                      <div
                                        className="error text-danger form-text"
                                        style={{ fontSize: "0.875rem" }}
                                      >
                                        {formik.errors.whatsAppNumber}
                                      </div>
                                    ) : null}
                                    <p
                                      onClick={() => urlDisplay()}
                                      className="text-primary fs-14 fw-normal text-decoration-none mt-1 mb-0 cursor-pointer border py-1 px-3 d-inline-block"
                                      style={{ fontWeight: "600" }}
                                    >
                                      Instead Use Product Url
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {/* radio code ends ends here */}
                              {/* product sku starts here */}
                              <div className="col-md-6">
                                <TextField
                                  as="textarea"
                                  rows={1}
                                  label="ProductSku"
                                  placeholder="Enter productsku"
                                  name="productSku"
                                  formik={formik}
                                  required={false}
                                />
                              </div>

                              <div className="col-md-12">
                                <TextField
                                  as="textarea"
                                  rows={3}
                                  label="Short Description/Bio"
                                  placeholder="Enter The description here..."
                                  name="caption"
                                  formik={formik}
                                  required={false}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="notification-form">
                              <div className="form-group mb-4">
                                Video &nbsp;(Minimum 10 seconds and maximum 20
                                seconds video is allowed to publish) &nbsp;
                                <sup className="text-danger">*</sup>
                                <div className="text-danger">
                                  {formik.errors.durationError ? (
                                    <div className="text-danger">
                                      {formik.errors.durationError}
                                    </div>
                                  ) : null}
                                  {/* Minimum 10 seconds and maximum 20 seconds
                                    video is allowed to publish */}
                                </div>
                                <div className="edit-image">
                                  <div className="modal-img-wrapper">
                                    {/* <div className="icon-wrapper ">
                                      <i className="icon-cloud-upload bx-lg"></i>
                                    </div> */}
                                    {thumbImg ? (
                                      <div className="icon-wrapper ">
                                        <img
                                          src={thumbImg}
                                          style={{ height: "130px" }}
                                        ></img>
                                      </div>
                                    ) : (
                                      <div className="icon-wrapper ">
                                        <i className="icon-cloud-upload bx-lg"></i>
                                      </div>
                                    )}
                                    <div className="img-content-wrapper d-flex">
                                      <input
                                        ref={inputRef}
                                        name="videoData"
                                        className="VideoInput_input"
                                        type="file"
                                        onChange={handleVideoUpload}
                                        accept=".mov,.mp4"
                                        formik={formik}
                                        values={formik?.values?.videoLink}
                                      />

                                      {!fileName ? (
                                        <span className="card w-100">
                                          {fileName
                                            ? fileName
                                            : formik?.values?.videoLink?.slice(
                                                100
                                              )}
                                        </span>
                                      ) : (
                                        <span>
                                          {fileName
                                            ? fileName
                                            : "no file choose"}
                                        </span>
                                      )}
                                    </div>
                                    <div className="img-file-text">
                                      Please note that .Mp4 .MKV .MOV Video file
                                      is allowed.
                                      <sup>*</sup>
                                    </div>
                                    <span disabled>
                                      {loading.status ? (
                                        <span
                                          className="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                      ) : null}
                                      <span
                                        className={
                                          loading.status
                                            ? "text-primary"
                                            : "text-success"
                                        }
                                      >
                                        {loading.msg}
                                      </span>
                                    </span>
                                    <div className="text-danger">
                                      {formik.errors.videoData}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>

                      <div className="col-md-12">
                        <div className="text-end">
                          <Link to="/postvideo">
                            <Button
                              variant="light"
                              type="reset"
                              className="me-2"
                            >
                              Cancel
                            </Button>
                          </Link>
                          <Button
                            variant="primary"
                            onClick={() => formik.handleSubmit()}
                            disabled={loading.status}
                          >
                            Change
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default EditPostedVideo;
