import React, { useState, useEffect } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { useDispatch, connect } from "react-redux";

import { callApi } from "../../Api";
import Theme from "../../Components/Layout/Theme";
import { validatePassword } from "../../Utils/Functions";
import errorMessages from "../../Utils/ErrorMessages";
import { setPassword } from "../../Store/Actions/User";
import { route } from "../../Utils";

const queryString = require("query-string");

/******************* 
@Purpose : Used for set password
@Parameter : props
@Author : INIC
******************/
function SetPassword(props) {
  const dispatch = useDispatch();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [, setIsFormValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  let [errors, setErrors] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [hiddenNewPassword, setHiddenNewPassword] = useState(true);
  const [hiddenConfirmPassword, setHiddenConfirmPassword] = useState(true);
  const url = props.location.search;
  let params = queryString.parse(url);

  /******************* 
  @Purpose : Used for set token
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    setToken(params && params.token);
  }, []);

  /******************* 
  @Purpose : Used for form field validation
  @Parameter : {}
  @Author : INIC
  ******************/
  const validateForm = () => {
    let isFormValid = true;
    let error = errors;
    //for new password
    if (!newPassword.trim()) {
      error.newPassword = errorMessages.PROVIDE_PASSWORD;
    } else if (!validatePassword(newPassword)) {
      error.newPassword = errorMessages.PROVIDE_VALID_PASSWORD;
    } else if (newPassword?.length < 8) {
      error.newPassword = errorMessages.PROVIDE_VALID_PASSWORD;
    } else {
      error.newPassword = "";
    }
    //for confirm password
    if (!confirmPassword.trim()) {
      error.confirmPassword = errorMessages.PROVIDE_CONFIRM_PASSWORD;
    } else if (newPassword !== confirmPassword) {
      error.confirmPassword = errorMessages.PASSWORD_NOT_MATCHED;
    } else {
      error.confirmPassword = "";
    }
    if (error.newPassword !== "" || error.confirmPassword !== "") {
      isFormValid = false;
    }
    setErrors(error);
    setIsFormValid(isFormValid);
    return isFormValid;
  };

  /******************* 
  @Purpose : Used for reset password validation
  @Parameter : event
  @Author : INIC
  ******************/
  const setpassword = async (event) => {
    event.preventDefault();
    let body = {
      password: newPassword,
      token: token,
    };
    try {
      if (validateForm()) {
        setLoading(true);
        dispatch(setPassword(body)).then((res) => {
          if (res.status) {
            setLoading(false);

            if (res.data.firstName === "") {
              props.history.push(route.BRAND_ACCOUNT);
            } else if (res.data.isPlanExpired) {
              props.history.push(route.SELECT_PLAN);
            } else {
              props.history.push(route.DASHBOARD);
            }
          }
        });
      }
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  /******************* 
  @Purpose : Used for rander tooltip
  @Parameter : event
  @Author : INIC
  ******************/
  const renderTooltip = (tooltipProps) =>
    !hiddenConfirmPassword ? (
      <Tooltip id="button-tooltip" {...tooltipProps}>
        Hide Password
      </Tooltip>
    ) : (
      <Tooltip id="button-tooltip" {...tooltipProps}>
        Show Password
      </Tooltip>
    );

  /******************* 
  @Purpose : Used for rander tooltip
  @Parameter : event
  @Author : INIC
  ******************/
  const renderTooltip1 = (tooltipProps) =>
    !hiddenNewPassword ? (
      <Tooltip id="button-tooltip" {...tooltipProps}>
        Hide Password
      </Tooltip>
    ) : (
      <Tooltip id="button-tooltip" {...tooltipProps}>
        Show Password
      </Tooltip>
    );

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <div className="App">
      <header className="App-header">
        <div
          className="login-wrapper"
          style={{
            background:
              "url(assets/images/login-bg.jpg) no-repeat center center",
            backgroundSize: "cover",
          }}
        >
          <div className="login-body">
            <section className="login-container row m-0">
              <div className="col-xl-7 col-11">
                <div className="card bg-login-card">
                  <div className="row g-0">
                    <div className="col-md-7 col-12 pl-0 pr-0">
                      <div className="card rounded-left mb-0 p-4 d-flex justify-content-center h-100">
                        <div className="card-header mb-4">
                          <h5 className="card-title text-center">
                            Set Your Password
                          </h5>
                        </div>
                        <div className="card-content">
                          <div className="card-body pb-0">
                            <form autoComplete="off">
                              <div className="form-group position-relative mb-4">
                                <label className="form-label">
                                  New Password
                                  {/* <sup className="text-danger">*</sup> */}
                                </label>
                                <input
                                  className="form-control"
                                  type={hiddenNewPassword ? "password" : "text"}
                                  placeholder="New Password"
                                  id="newPassword"
                                  name="newPassword"
                                  value={newPassword}
                                  required
                                  onChange={(e) => {
                                    setNewPassword(e.target.value);
                                    let error = Object.assign(errors, {
                                      newPassword: "",
                                    });
                                    setErrors(error);
                                  }}
                                />
                                <OverlayTrigger
                                  placement="top"
                                  delay={{
                                    show: 250,
                                    hide: 400,
                                  }}
                                  overlay={renderTooltip1}
                                >
                                  <span
                                    onClick={() =>
                                      setHiddenNewPassword(!hiddenNewPassword)
                                    }
                                    toggle="#password-field"
                                    className={
                                      hiddenNewPassword
                                        ? "bx bx-hide field-icon toggle-password"
                                        : "bx bx-show field-icon toggle-password"
                                    }
                                  ></span>
                                </OverlayTrigger>
                                <span
                                  className="error-msg"
                                  style={{
                                    color: "red",
                                  }}
                                >
                                  {errors.newPassword}
                                </span>
                              </div>
                              <div className="form-group position-relative mb-4">
                                <label className="form-label">
                                  Confirm Password:
                                  {/* <sup className="text-danger">*</sup> */}
                                </label>
                                <input
                                  className="form-control"
                                  type={
                                    hiddenConfirmPassword ? "password" : "text"
                                  }
                                  placeholder="Confirm Password"
                                  id="confirmpassword"
                                  name="confirmPassword"
                                  value={confirmPassword}
                                  onChange={(e) => {
                                    setConfirmPassword(e.target.value);
                                    let error = Object.assign(errors, {
                                      confirmPassword: "",
                                    });
                                    setErrors(error);
                                  }}
                                />
                                <OverlayTrigger
                                  placement="top"
                                  delay={{
                                    show: 250,
                                    hide: 400,
                                  }}
                                  overlay={renderTooltip}
                                >
                                  <span
                                    onClick={() =>
                                      setHiddenConfirmPassword(
                                        !hiddenConfirmPassword
                                      )
                                    }
                                    toggle="#password-field"
                                    className={
                                      hiddenConfirmPassword
                                        ? "bx bx-hide field-icon toggle-password"
                                        : "bx bx-show field-icon toggle-password"
                                    }
                                  ></span>
                                </OverlayTrigger>
                                <span
                                  className="error-msg"
                                  style={{
                                    color: "red",
                                  }}
                                >
                                  {errors.confirmPassword}
                                </span>
                              </div>
                              <div className="mt-5 text-center">
                                <button
                                  className="btn btn-primary w-100 glow position-relative btn-block"
                                  type="submit"
                                  onClick={setpassword}
                                >
                                  {loading ? "Loading..." : "Set my password "}
                                  <i className="ms-2 align-middle text-white icon-arrow-right"></i>
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5 d-md-block d-none text-center align-self-center p-7">
                      <div className="card-content">
                        <picture>
                          <source
                            srcSet="assets/images/forgot-password.webp"
                            type="image/webp"
                          />
                          <source
                            srcSet="assets/images/forgot-password.png"
                            type="image/png"
                          />
                          <img
                            className="img-fluid"
                            src="assets/images/forgot-password.png"
                            alt="forgot-password"
                            width={300}
                          />
                        </picture>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </header>
      <Theme />
    </div>
  );
}
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(null, {
  callApi,
})(SetPassword);
