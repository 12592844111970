const INTIAL_STATE = {
  celebrityLists: [],
  purchaseHistory: [],
  celebVideos: {},
  videoHide: {},
  videosList: {},
  videoApprove: {},
  rejectVideo: {},
  celeblist: [],
  transactionlist: {},
  categories: {},
};

/******************* 
  @Purpose : Used for store data in redux
  @Parameter : state, action
  @Author : INIC
  ******************/
export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    //for display celibrity videos
    case "CELEBRITY_DISPLAY":
      return Object.assign({}, state, {
        celebrityLists: action.payload,
      });

    case "PURCHASE_HISTORY":
      return Object.assign({}, state, {
        purchaseHistory: action.payload,
      });

    case "SPECIFIC_VIDEOS":
      return Object.assign({}, state, {
        celebVideos: action.payload,
      });

    case "HIDE_VIDEO":
      return Object.assign({}, state, {
        videoHide: action.payload,
      });

    case "ALL_VIDEOS_DISPLAY":
      return Object.assign({}, state, {
        videosList: action.payload,
      });

    case "APPROVE_VIDEO":
      return Object.assign({}, state, {
        videoApprove: action.payload,
      });

    case "REJECT_VIDEO":
      return Object.assign({}, state, {
        rejectVideo: action.payload,
      });

    case "CELEB_LIST":
      return Object.assign({}, state, {
        celeblist: action.payload,
      });

    case "TRANS_LIST":
      return Object.assign({}, state, {
        transactionlist: action.payload,
      });

    case "CATEGORIES_LIST":
      return Object.assign({}, state, {
        categories: action.payload,
      });

    default:
      return state;
  }
};
