const INTIAL_STATE = {
  downloadReports: {},
  reportsData: {},
};
/******************* 
  @Purpose : Used for store data in redux
  @Parameter : state, action
  @Author : INIC
  ******************/
export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case "DOWNLOAD_REPORTS":
      return Object.assign({}, state, {
        downloadReports: action.payload,
      });
    case "REPORTS":
      return Object.assign({}, state, {
        reportsData: action.payload,
      });
    default:
      return state;
  }
};
