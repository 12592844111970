import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useParams, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";

import { getVideoInfo } from "../../Store/Actions/BrandVideos";
import { Layout, Footer } from "../Layout";
import {
  VIDEO_LIST,
  VIDEO_DETAILS,
  route,
  showMessageNotification,
} from "../../Utils";

import moment from "moment/moment";
import { getHideVideo } from "../../Store/Actions/CelebrityVideos";
import VideoCardData from "../Cards/VideoCardData";

/******************* 
@Purpose : Used for Video info page
@Parameter : {}
@Author : INIC
******************/
const VideoInfo = () => {
  const history = useHistory();
  const { celebvideoinfo } = useSelector((data) => data.brandvideos);
  const dispatch = useDispatch();
  const param = useParams();

  useEffect(() => {
    if (param.slug) {
      dispatch(getVideoInfo(param.slug)).then((res) => {
        if (!res.status) {
          history.push("/BrandVideos");
        }
      });
    } else {
      history.back();
    }
  }, []);

  /******************* 
  @Purpose : Used for handle status of brand
  @Parameter : {e,id}
  @Author : inic
  ******************/

  const handleStatus = async (id) => {
    if (celebvideoinfo[0]?.status === true) {
      swal({
        title: "Are you sure want to hide video?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        cancelButtonColor: "#d33",
        timer: 9000,
      }).then(async (result) => {
        if (result) {
          let body = {
            videoId: id,
          };
          dispatch(getHideVideo(body))
            .then((res) => {
              if (res) {
                dispatch(getVideoInfo(param.slug));
                showMessageNotification(res.message, "success");
              }
            })
            .catch((err) => {
              console.error(err);
              throw err;
            });
        }
      });
    } else if (celebvideoinfo[0]?.status === false) {
      let body = {
        videoId: id,
      };
      dispatch(getHideVideo(body))
        .then((res) => {
          if (res) {
            dispatch(getVideoInfo(param.slug));
            showMessageNotification(res.message, "success");
          }
        })
        .catch((err) => {
          console.error(err);
          throw err;
        });
    }
  };

  /******************* 
  @Purpose : Used for Bradcrumb View
  @Parameter : {}
  @Author : INIC
  ******************/
  const breadcrumb = [
    {
      title: VIDEO_LIST,
      url: route.BRAND_VIDEOS,
    },
    {
      title: VIDEO_DETAILS,
    },
  ];
  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Tagged Video's">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <div className="row">
              <div className="col-lg-12 mb-4">
                <div className="card">
                  <div className="card-header pb-0">
                    <h5 className="card-title">Video Detail</h5>
                  </div>
                  <div className="card-body user-details">
                    {celebvideoinfo &&
                      celebvideoinfo?.length > 0 &&
                      celebvideoinfo?.map((data, key) => (
                        <div className="row" key={key}>
                          <div className="col-lg-2 col-md-12 align-item-center">
                            <div className="video-card border-0 mb-0">
                              <div className="video-img position-relative">
                                <video className="br-12" width="100%;" height="170" controls>
                                  <source
                                    src={data?.videoLink}
                                    type="video/mp4"
                                  ></source>
                                </video>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-5 col-md-6">
                            <div className="input-group row g-0">
                              <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                                TITLE :
                              </div>
                              <div className="col-md-8 p-2 request-data text-secondary">
                                {data?.videoTitle}
                              </div>
                            </div>

                            <div className="input-group row g-0">
                              <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                                {" "}
                                SUB CATEGORY:
                              </div>
                              <div className="col-md-8 p-2 request-data text-secondary">
                                {data?.subcategories &&
                                  data.subcategories.length > 0 &&
                                  data.subcategories
                                    .map((subcat) => subcat?.title)
                                    .toString()}
                              </div>
                            </div>

                            <div className="input-group row g-0">
                              <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                                {data?.affilietedProductUrl
                                  ? "PRODUCT LINK :"
                                  : "WhatsApp Number :"}
                              </div>

                              <div className="col-md-8 p-2 request-data text-secondary">
                                {data?.affilietedProductUrl ? (
                                  <a
                                    href={data?.affilietedProductUrl}
                                    target="_blank"
                                  >
                                    {data?.affilietedProductUrl}
                                  </a>
                                ) : (
                                  data?.whatsAppNumber
                                )}
                              </div>
                            </div>

                            <div className="input-group row g-0">
                              <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                                HIDE VIDEO ?
                              </div>
                              <div className="col-md-8 p-2 request-data align-self-end">
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  checked={data?.status}
                                  onChange={() => handleStatus(data?._id)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-5 col-md-6">
                            <div className="input-group row g-0">
                              {data?.category &&
                                data?.category.length > 0 &&
                                data?.category.map((category, catKey) => (
                                  <>
                                    <div
                                      className="col-md-4 p-2 text-capitalize fw-bold request-title"
                                      key={category.id}
                                    >
                                      CATEGORY:
                                    </div>
                                    <div className="col-md-8 p-2 request-data text-secondary">
                                      {category?.title}
                                    </div>
                                  </>
                                ))}
                            </div>
                            <div className="input-group row g-0">
                              {data.celebrityDetails &&
                                data.celebrityDetails.length > 0 &&
                                data.celebrityDetails.map((name) => (
                                  <>
                                    <div
                                      className="col-md-4 p-2 text-capitalize fw-bold request-title"
                                      key={name.id}
                                    >
                                      CELEBRITY:
                                    </div>
                                    <div className="col-md-8 p-2 request-data text-secondary">
                                      {`${name.firstname} ${name.lastname}`}
                                    </div>
                                  </>
                                ))}
                            </div>

                            <div className="input-group row g-0">
                              <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                                PUBLISH DATE:
                              </div>
                              <div className="col-md-8 p-2 request-data text-secondary">
                                {moment(data?.createdAt).format("l, h:mm:ss a")}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-5">
              <div className="card-header pb-0">
                <h5 className="card-title">Summary</h5>
              </div>
              <div className="card-body">
                <div className="video-list">
                  <VideoCardData />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </Layout>
  );
};

export default VideoInfo;
