import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { fetchPlanHistory } from "../../Store/Actions/Plan";

/******************* 
@Purpose : Used for Manage Plan
@Parameter : {}
@Author : INIC
******************/
const ManagePlan = () => {
  const dispatch = useDispatch();
  const { planHistory } = useSelector((data) => data.user);
  const history = useHistory();

  /******************* 
  @Purpose : React hook Used for fetch plan history
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    dispatch(fetchPlanHistory());
  }, []);

  const handlePlan = () => {
    history.push("/changeplan");
  };
  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <>
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between">
          <h5 className="card-title">Manage Plan</h5>
          <Button onClick={() => handlePlan()}>Switch to new plan</Button>
        </div>
        <div className="card-body">
          {/* <div className="row mb-4"> */}
          {/* <div className="col-lg-12"> */}
          {/* <div className="card border rounded-3 plan-cards active"> */}
          {/* <div className="card-header pb-0"> */}
          {/* <h5 className="card-title">Monthly/ Quarterly/ Yearly</h5> */}
          {/* <p className="card-text">₹ 100/Month</p> */}
          {/* </div> */}
          {/* <div className="card-body">
                
              </div>
            </div> */}
          {/* </div> */}

          {/* <div className="col-lg-4">
             
              <div className="card border rounded-3 plan-cards active">
                <div className="card-header pb-0">
                  <h5 className="card-title">Quaterly</h5>
                  <p className="card-text">₹ 250/3 Month</p>
                </div>
                <div className="card-body">
                  <Button>Switch to Monthly Plan on 1st Aug</Button>
                </div>
              </div>
              
            </div>

            <div className="col-lg-4">
            
              <div className="card border rounded-3 plan-cards">
                <div className="card-header pb-0">
                  <h5 className="card-title">Yearly</h5>
                  <p className="card-text">₹ 900/Year</p>
                </div>
                <div className="card-body">
                  <Button>Switch to Monthly Plan on 1st Aug</Button>
                </div>
              </div>
             
            </div> */}
          {/* </div> */}
          <div className="table-responsive mb-3">
            <table
              className="table row-border nowrap common-datatable col-wrap-profile"
              id="user-listing"
            >
              <thead>
                <tr>
                  <th>
                    Transaction #<i aria-hidden="true"></i>
                  </th>
                  <th>
                    Plan
                    <i aria-hidden="true"></i>
                  </th>
                  <th>
                    Amount
                    <i aria-hidden="true"></i>
                  </th>
                  <th>
                    No Of Months
                    <i aria-hidden="true"></i>
                  </th>
                  <th>
                    No Of Video Per Month
                    <i aria-hidden="true"></i>
                  </th>
                  <th>
                    Total videos
                    <i aria-hidden="true"></i>
                  </th>
                  <th>
                    Start Date & Time
                    <i aria-hidden="true"></i>
                  </th>
                  <th>
                    End Date & Time
                    <i aria-hidden="true"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                {planHistory?.length > 0 &&
                  planHistory?.map((data) => (
                    <tr key={data.id}>
                      <td>2cd54454</td>
                      <td
                        style={{
                          background: data?.currentPlan
                            ? "rgb(209 212 220)"
                            : "transparent",
                        }}
                      >
                        {data?.planDetalis?.planTitle}
                      </td>

                      <td>{data.amountReceived}</td>
                      <td>{data.planDetalis.noOfMonths}</td>
                      <td>{data.planDetalis.videosPerMonth}</td>
                      <td>{data.planDetalis.totalVideos}</td>
                      <td>{moment(data.startDate).format("l, h:mm:ss a")}</td>
                      <td>{moment(data.endDate).format("l, h:mm:ss a")}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagePlan;
