import { combineReducers } from "redux";
import Admin from "./Admin";
import BrandVideos from "./BrandVideos";
import Tabs from "./Tabs";
import User from "./User";
import BrandPromotedVideoAd from "./BrandPromotedVideoAd";
import CelebrityVideos from "./CelebrityVideos";
import Reports from "./Reports";
import VideoQueue from "./VideoQueue";
import BrandDashboard from "./BrandDashboard";
import StateandCities from "./StateandCities";
import VideoTitleList from "./VideoTitleList";
import PostedVideos from "./PostedVideos";

/******************* 
@Purpose : Used for combine all reducer in single place
@Parameter : {Admin}
@Author : INIC
******************/
export default combineReducers({
  admin: Admin,
  user: User,
  tabs: Tabs,
  brandvideos: BrandVideos,
  brandpromotedvideoad: BrandPromotedVideoAd,
  celebVideos: CelebrityVideos,
  reports: Reports,
  videoqueue: VideoQueue,
  branddashboard: BrandDashboard,
  statecities: StateandCities,
  allvideostitle: VideoTitleList,
  postedvideos: PostedVideos,
});
