import React, { useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { State, City } from "country-state-city";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useHistory } from "react-router";

import { SignUpBrandDetails } from "../../Utils/ValidationSchema";
import { PhoneField, SelectField } from "../../Components/FormFields";
import { AddBrand } from "../../Store/Actions/SaveBrand";
import { route } from "../../Utils";
import { Uploadimages } from "../../Store/Actions/User";
import TextField from "../../Components/FormFields/TextField";
import PhoneInput2 from "react-phone-input-2";

import "react-phone-input-2/lib/style.css";

/******************* 
@Purpose : Used for edit brand profile 
@Parameter : {props,setShowEditBrand}
@Author : INIC
******************/
const SignUp = () => {
  const history = useHistory();
  const formData1 = new FormData();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [brandImage, setBrandImage] = useState("");
  const [isOptional, setIsOptional] = useState(true);
  const [isoCode, setIsoCode] = useState();
  const brandData = {
    organization: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    address: "",
    emailId: "",
    country: "india",
    state: "",
    city: "",
    pin: "",
    photo: "",
    isBusinessOnline: "",
  };
  const uploadedImage = useRef(null);
  const imageUploader = useRef(null);

  /******************* 
  @Purpose : Used for get state list
  @Parameter : {countrycode}
  @Author : INIC
  ******************/
  const getStateList = (contry = "IN") =>
    State.getStatesOfCountry(contry).map((state) => ({
      label: state.name,
      value: state.name,
      ...state,
    }));

  /******************* 
  @Purpose : Used for get states cities list
  @Parameter : {countrycode,statecode}
  @Author : INIC
  ******************/
  const getCitiesList = (contry = "IN", stateCode = isoCode) =>
    City.getCitiesOfState(contry, stateCode).map((city) => ({
      label: city.name,
      value: city.name,
      ...city,
    }));

  /******************* 
  @Purpose : Used for handle image select
  @Parameter : {e}
  @Author : INIC
  ******************/
  const handleImageUpload = (e) => {
    try {
      const [file] = e.target.files;
      if (file) {
        const reader = new FileReader();
        const { current } = uploadedImage;
        current.file = file;
        setBrandImage(current.file);

        formData1.append("image", current.file);
        reader.onload = (e) => {
          current.src = e.target.result;
        };
        reader.readAsDataURL(file);
        e.target.value = null;
      }
    } catch (error) {}
  };

  /******************* 
   @purpose : set formik validation schema
   @Parameter : {}
   @Author :INIC
   ******************/
  const formik = useFormik({
    initialValues: brandData,
    enableReinitialize: true,
    validationSchema: SignUpBrandDetails(),
    onSubmit: async (values) => {
      setLoading(true);
      // Check if brandImage is empty
      // if (!brandImage) {
      //   formik.setErrors({
      //     ...formik.errors,
      //     brandImage: "Image is required.",
      //   });
      //   return;
      // } else {
      const photo = await uploadFile();
      const { state, city, pin } = values;

      const data = {
        ...values,
        state: state.name,
        city: city.name,
        pin: pin.toString(),
        photo: photo?.imagePath,
        compressedImage: photo?.compressedImage,
        isBusinessOnline: values.isBusinessOnline === "true",
        gstNumber: values.gstNumber ? values.gstNumber.toString() : "",
      };

      dispatch(AddBrand(data))
        .then((res) => {
          if (res) {
            setLoading(false);
            history.push(route.SIGNIN);
          }
        })
        .catch((err) => {
          throw err;
        });
    },
    // },
  });

  /******************* 
  @Purpose : Used for post image 
  @Parameter : {}
  @Author : INIC
  ******************/
  const uploadFile = () => {
    let formData2 = new FormData();
    formData2.append("image", brandImage);
    return dispatch(Uploadimages(formData2)).then((response) => {
      if (response.imagePath) {
        return response;
      }
    });
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/

  return (
    <>
      <div
        className="login-wrapper"
        style={{
          background:
            "url(../assets/images/login-bg.jpg) no-repeat center center",
        }}
      >
        <div className="login-body">
          <div className="login-container row m-0">
            <div className="col-xl-10 col-md-12 col-11">
              <div className="card bg-login-card">
                <div className="row g-0">
                  <div className="col-md-12 col-12 pl-0 pr-0">
                    <div className="card rounded-left mb-0 p-4 d-flex justify-content-center h-100">
                      <div className="card-header mb-4">
                        <h5 className="card-title text-center mb-0">
                          Create your Account
                        </h5>
                      </div>

                      <div className="card-body">
                        <form>
                          <Row>
                            <Col
                              xxl={2}
                              lg={3}
                              md={12}
                              className="text-md-center"
                            >
                              <div className="edit-image">
                                <div>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                    ref={imageUploader}
                                    style={{
                                      display: "none",
                                    }}
                                  />
                                  <div className="user-image overflow-hidden mb-2 mx-md-auto">
                                    <img
                                      ref={uploadedImage}
                                      src="./assets/images/user.png"
                                      alt="Icon"
                                    />
                                  </div>
                                  <Button
                                    className="btn btn-primary"
                                    onClick={() =>
                                      imageUploader.current.click()
                                    }
                                  >
                                    Upload a Logo
                                  </Button>
                                </div>
                              </div>
                              <div className="text-danger">
                                {formik?.errors?.brandImage}
                              </div>
                            </Col>
                            {/* form */}
                            <Col xxl={10} lg={9} md={12}>
                              <Row>
                                <Col lg={12} xl={6}>
                                  <TextField
                                    type="text"
                                    label="Organization Name"
                                    placeholder="Enter Organization Name"
                                    name="organization"
                                    value={formik?.values?.organization}
                                    formik={formik}
                                    autoComplete="off"
                                    isOptional={isOptional}
                                  />
                                </Col>
                                {/* gst no */}
                                <Col lg={12} xl={6}>
                                  <TextField
                                    type="text"
                                    label="GST Number"
                                    placeholder="Enter GST Number"
                                    name="gstNumber"
                                    formik={formik}
                                    required={false}
                                    autoComplete="off"
                                    maxLength={15}
                                    onInput={(e) => {
                                      if (
                                        e.target.value.length >
                                        e.target.maxLength
                                      )
                                        e.target.value = e.target.value.slice(
                                          0,
                                          e.target.maxLength
                                        );
                                    }}
                                  />
                                </Col>
                                {/* gst ends here */}
                                <Col lg={12} xl={6}>
                                  <TextField
                                    type="text"
                                    label="First Name"
                                    placeholder="Enter first Name"
                                    name="firstName"
                                    value={formik?.values?.firstName}
                                    formik={formik}
                                    autoComplete="off"
                                  />
                                </Col>

                                <Col lg={12} xl={6}>
                                  <TextField
                                    type="text"
                                    label="Last Name"
                                    placeholder="Enter last Name"
                                    name="lastName"
                                    formik={formik}
                                    value={formik?.values?.lastName}
                                    autoComplete="off"
                                  />
                                </Col>
                                <Col lg={12} xl={6}>
                                  {/* <TextField
                                    type="number"
                                    label="Phone number"
                                    placeholder="Enter Phone Number"
                                    name="mobileNumber"
                                    formik={formik}
                                    required={false}
                                    autoComplete="off"
                                  /> */}
                                  <label>Phone Number</label>
                                  <PhoneInput2
                                    country="in" // Set default country to India
                                    onlyCountries={["in"]} // Limit available countries to India
                                    label="Phone number"
                                    placeholder="Enter Phone Number"
                                    name="mobileNumber"
                                    formik={formik}
                                    required={false}
                                    autoComplete="off"
                                    countryCodeEditable={false}
                                  />
                                </Col>

                                <Col lg={12} xl={6}>
                                  <TextField
                                    type="text"
                                    label="Address"
                                    placeholder="Enter Address"
                                    name="address"
                                    value={formik?.values?.address}
                                    formik={formik}
                                    autoComplete="off"
                                  />
                                </Col>

                                <Col lg={12} xl={6}>
                                  <TextField
                                    type="text"
                                    label="Email"
                                    placeholder="Enter email Address"
                                    name="emailId"
                                    value={formik?.values?.emailId}
                                    formik={formik}
                                    autoComplete="off"
                                  />
                                </Col>

                                <Col lg={12} xl={6}>
                                  <TextField
                                    type="text"
                                    label="country"
                                    formik={formik}
                                    value="India"
                                    disabled
                                  />
                                </Col>
                                <Col lg={12} xl={6}>
                                  <SelectField
                                    label="State"
                                    name="state"
                                    placeholder="Select State"
                                    options={getStateList()}
                                    className="custom-select-container"
                                    classNamePrefix={"custom-select"}
                                    formik={formik}
                                    values={
                                      formik?.values?.state.label && [
                                        {
                                          label: formik?.values?.state.label,
                                          value: formik?.values?.state.value,
                                        },
                                      ]
                                    }
                                    onChange={() =>
                                      formik.setFieldValue("city", [
                                        {
                                          value: null,
                                          label: null,
                                        },
                                      ])
                                    }
                                    setIsoCode={setIsoCode}
                                  />
                                </Col>

                                <Col lg={12} xl={6}>
                                  <SelectField
                                    label="City"
                                    name="city"
                                    placeholder="Select City"
                                    className="custom-select-container"
                                    classNamePrefix={"custom-select"}
                                    options={getCitiesList()}
                                    formik={formik}
                                    values={
                                      formik?.values?.city.label && [
                                        {
                                          value: formik?.values?.city.label,
                                          label: formik?.values?.city.value,
                                        },
                                      ]
                                    }
                                  />
                                </Col>

                                <Col lg={12} xl={6}>
                                  <TextField
                                    type="number"
                                    label="pin"
                                    placeholder="Enter Pincode"
                                    name="pin"
                                    value={formik?.values?.pin}
                                    formik={formik}
                                    autoComplete="off"
                                  />
                                </Col>
                                {/* starts here */}
                                <Col xs={12}>
                                  <div>
                                    <div className="col-xl-12 px-0">
                                      <div className="row">
                                        <label
                                          for="isBusinessOnline"
                                          className="m-0"
                                        >
                                          Select Is Your Business Online / Is
                                          Your Business Offline{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                {/* ends here */}

                                <Col xs={12}>
                                  <div
                                    value={formik?.values?.isBusinessOnline}
                                    {...formik.getFieldProps(
                                      "isBusinessOnline"
                                    )}
                                  >
                                    <div className="col-xl-6 px-0">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="d-flex align-items-center">
                                            <input
                                              type="radio"
                                              value={true}
                                              name="isBusinessOnline"
                                              id="isBusinessOnline"
                                              className="me-2"
                                              autoComplete="off"
                                            />
                                            <label
                                              for="isBusinessOnline"
                                              className="m-0"
                                            >
                                              Is your business online
                                            </label>
                                          </div>
                                          <br />

                                          {formik?.values?.isBusinessOnline ===
                                          "true" ? (
                                            <TextField
                                              type="url"
                                              label="Enter website Url"
                                              placeholder="Enter website Url"
                                              name="websiteUrl"
                                              formik={formik}
                                              autoComplete="off"
                                            />
                                          ) : null}
                                        </div>

                                        <div className="col-md-6">
                                          <div className="d-flex align-items-center">
                                            <input
                                              type="radio"
                                              name="isBusinessOnline"
                                              id="isBusinessOffline"
                                              value={false}
                                              className="me-2"
                                            />
                                            <label
                                              for="isBusinessOffline"
                                              className="m-0"
                                            >
                                              Is your business Offline
                                            </label>
                                          </div>
                                          <br />

                                          {formik?.values?.isBusinessOnline ===
                                          "false" ? (
                                            <PhoneField
                                              name="whatsAppBusinessNumber"
                                              country={"in"}
                                              label="whats-app number"
                                              placeholder="9988776655"
                                              masks={""}
                                              countryCodeEditable={false}
                                              formik={formik}
                                            />
                                          ) : null}
                                          {formik?.errors
                                            ?.whatsAppBusinessNumber ? (
                                            <span className="error text-danger">
                                              {
                                                formik?.errors
                                                  ?.whatsAppBusinessNumber
                                              }
                                            </span>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* Display error message for isBusinessOnline */}
                                  {formik?.errors?.isBusinessOnline ? (
                                    <div className="text-danger">
                                      <small>
                                        {formik?.errors?.isBusinessOnline}
                                      </small>
                                    </div>
                                  ) : null}
                                </Col>
                              </Row>
                            </Col>

                            <div className="col-md-12 text-end">
                              <Button
                                variant="primary"
                                onClick={() => formik.handleSubmit()}
                                className="me-2 "
                              >
                                Send Activation Link
                              </Button>
                            </div>
                          </Row>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
