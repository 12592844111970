const INTIAL_STATE = {
  brandpromotedvideoad: [],
  brandpromotedvideoaddetail: [],
};
/******************* 
@Purpose : Used for store data in redux
@Parameter : state, action
@Author : INIC
******************/
export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case "SET_BRAND_VIDEO_AD":
      return Object.assign({}, state, {
        brandpromotedvideoad: action.payload,
      });
    case "SET_BRAND_VIDEO_AD_details":
      return Object.assign({}, state, {
        brandpromotedvideoaddetail: action.payload,
      });

    default:
      return state;
  }
};
