import React, { useState, useEffect } from "react";
import Pagination from "rc-pagination";
import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";
import Select from "react-select";

import { Footer } from "../../Components/Layout";
import { getPromotedAdVideos } from "../../Store/Actions/BrandPromotedVideoAd";
import {
  getUtcTime,
  LIST_OF_VIDEO_AD,
  route,
  showMessageNotification,
} from "../../Utils";
import Layout from "../../Components/Layout/Layout";
import FilterModel from "../../Components/Model/FilterModel";
import moment from "moment";
import { getCategories } from "../../Store/Actions/Categories";
import { DatePicker } from "antd";
import VideoPlayerModal from "../../Components/Model/VideoPlayerModal";

/******************* 
@Purpose :  used for filter select dropdown value for gender
@Parameter : {}
@Author : INIC
******************/
const gender = [
  { value: "MALE", label: "Male" },
  { value: "FEMALE", label: "Female" },
  { value: "OTHERS", label: "Others" },
];

/******************* 
@Purpose :  used for filter select dropdown value for key
@Parameter : {}
@Author : INIC
******************/
const filterTypeFieldValueoption = [
  { value: "date", label: "Date" },
  { value: "categoryId", label: "Category" },
];

const { RangePicker } = DatePicker;
/******************* 
  @Purpose : Used for Manage videos ad
  @Parameter : {}
  @Author : INIC
  ******************/
const ManageVideoAd = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const defaultFilter = {
    key: "",
    type: "contains",
    value: "",
    filterType: "",
    filterOptions: [],
    valueFieldType: "date",
  };
  const { data, pageNumber, totalVideos, message } = useSelector(
    (data) => data?.brandpromotedvideoad?.brandpromotedvideoad
  );
  const field = 2;
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [modalShow, setModalShow] = useState(false);
  const [filterData, setFilterData] = useState([defaultFilter]);
  const { categoryInfo } = useSelector((data) => data.user);
  const [category, setCategory] = useState("");
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);
  const [filterFields, setFilterFields] = useState("categoryId");
  const [filterTypeField, setFilterTypeField] = useState(
    filterTypeFieldValueoption
  );
  const [modal, setModal] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const dateFormat = "DD/MM/YYYY";

  const [isSearchButtonDisabled, setIsSearchButtonDisabled] = useState(true);

  /******************* 
  @Purpose :  React hook used for get categories and sub categories
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    dispatch(getCategories());
    categoryValue();
  }, []);

  /******************* 
  @Purpose :  React hook used for filter search button disable and enable
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    filterData?.forEach((data) => {
      if (data.key != "" && data.value != "") {
        setIsSearchButtonDisabled(false);
      } else {
        setIsSearchButtonDisabled(true);
      }
    });
  }, [filterData]);

  /******************* 
  @Purpose :  React hook used for get promoted videos list
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    let body = {
      searchText: searchText,
      pageNumber: page,
      pageSize: pageSize,
      filter: [],
    };
    dispatch(getPromotedAdVideos(body));
  }, [searchText, page, pageSize]);

  /******************* 
  @Purpose :  used for set filter value
  @Parameter : {fieldValue,index}
  @Author : INIC
  ******************/
  const handleValueChange = (fieldValue, index) => {
    handleFilterArray();
    const filterRow = filterData;
    if (filterRow[index].key === "categoryId") {
      const catValue = filterData.findIndex(
        (obj) => obj.key === "subCategoryId"
      );
      if (catValue !== -1) {
        filterRow.splice(catValue, 1);
      }
    }
    filterRow[index].value = fieldValue;
    setFilterData([...filterRow]);
  };

  const onChange = (date, dateString) => {
    const newStartDate = getUtcTime(dateString[0]);
    const newEndDate = getUtcTime(dateString[1]);

    const filter = {
      key: "date",
      value: {
        startDate: newStartDate,
        endDate: newEndDate,
      },
      type: "date",
    };

    setFilterData([filter]);
  };
  /******************* 
  @Purpose :  Used for render field typeds
  @Parameter : {index}
  @Author : INIC
  ******************/
  const handleFiltertags = (index) => {
    switch (filterData[index].key) {
      case "categoryId":
        return (
          <Select
            options={filterData[index].filterOptions}
            className="custom-select-container"
            classNamePrefix={"custom-select"}
            onChange={(e) => handleValueChange(e.value, index)}
          />
        );
      case "subCategoryId":
        return (
          <Select
            options={filterData[index].filterOptions}
            className="custom-select-container"
            classNamePrefix={"custom-select"}
            onChange={(e) => handleValueChange(e.value, index)}
            style={{ position: "absolute" }}
          />
        );
      case "date":
        return (
          <>
            <RangePicker
              className="rounded "
              placeholderText="dateRange"
              format={dateFormat}
              onChange={onChange}
            />
          </>
        );
      default:
        return (
          <Select
            className="custom-select-container"
            classNamePrefix={"custom-select"}
          />
        );
    }
  };

  /******************* 
  @Purpose : Used for filter key selection and set value 
  @Parameter : {filterField, index}
  @Author : INIC
  ******************/
  const handleFilterTypeFields = (filterField, index) => {
    setFilterFields(filterField);
    const filterRow = filterData;
    filterRow[index].key = filterField;
    setFilterData([...filterRow]);
    switch (filterField) {
      case "date":
        filterRow[index].filterOptions = gender;
        setFilterData([...filterRow]);
        break;
      case "categoryId":
        filterRow[index].filterOptions = category;
        setFilterData([...filterRow]);
        addSubCategory();
        break;
      case "subCategoryId":
        const subCat = subCategoryValue();
        filterRow[index].filterOptions = subCat;
        setFilterData([...filterRow]);
        break;
    }
  };

  /******************* 
  @Purpose :  used for category value maping
  @Parameter : {}
  @Author : INIC
  ******************/
  const categoryValue = () => {
    let categoryArr = [];
    categoryInfo?.length > 0 &&
      categoryInfo?.forEach((optiondata) => {
        let tempObj = {
          label: optiondata?.title,
          value: optiondata?._id,
          ...optiondata,
        };
        categoryArr.push(tempObj);
        setCategory(categoryArr);
      });
  };

  /******************* 
  @Purpose :  used for sub category value maping
  @Parameter : {}
  @Author : INIC
  ******************/
  const subCategoryValue = () => {
    const catValue = filterData.filter((obj) => obj.key === "categoryId")[0]
      .value;
    const catData = categoryInfo.filter((obj) => obj._id === catValue)[0];
    let subCategoryArr = [];
    catData?.subCategoryDetails?.length > 0 &&
      catData?.subCategoryDetails?.forEach((subCategory) => {
        let tempObj2 = {
          label: subCategory?.title,
          value: subCategory?._id,
        };
        subCategoryArr.push(tempObj2);
      });
    return subCategoryArr;
  };

  /******************* 
  @Purpose : Used for add sub category
  @Parameter : {}
  @Author : INIC
  ******************/
  const addSubCategory = () => {
    const count = filterData.filter((obj) => obj.key === "categoryId").length;
    if (count >= 1) {
      let field = [
        { value: "date", label: "Date" },
        { value: "subCategoryId", label: "Sub-Category" },
      ];
      setFilterTypeField(field);
    }
  };

  /******************* 
  @Purpose : Used for bread crumb view
  @Parameter : {}
  @Author : INIC
  ******************/
  const breadcrumb = [
    {
      title: LIST_OF_VIDEO_AD,
      url: route.MANAGE_VIDEO_ADD,
    },
  ];

  /******************* 
  @Purpose : Used for pagination
  @Parameter : {page, pagesize}
  @Author : INIC
  ******************/
  const paginationChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  /******************* 
  @Purpose : Used for handle filter type field
  @Parameter : {index}
  @Author : INIC
  ******************/
  const handleFilterTypeField = (index) => {
    if (index === 0) {
      return filterTypeFieldValueoption;
    } else {
      return [
        { value: "date", label: "Date" },
        { value: "categoryId", label: "Category" },
        { value: "subCategoryId", label: "Sub-Category" },
      ];
    }
  };

  /******************* 
    @Purpose :  used for api calling for filter
    @Parameter : {}
    @Author : INIC
    ******************/
  const handleSubmit = async () => {
    let body = {
      searchText: searchText,
      pageNumber: page,
      pageSize: "10",
      filter: filterData,
    };
    const res = dispatch(getPromotedAdVideos(body));
    if (res) {
      setModalShow(false);
      setFilterData([defaultFilter]);
      setSelectedFilterItems([]);
    }
  };

  /******************* 
  @Purpose : Used for hide modal and clear it
  @Parameter : {}
  @Author : INIC
  ******************/
  const onHide = () => {
    setModalShow(false);
    setFilterData([defaultFilter]);
    setSelectedFilterItems([]);
  };

  /******************* 
  @Purpose : Used for handle filter data
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleFilterArray = () => {
    let selected = filterData.map((item) => item.key);
    setSelectedFilterItems(selected);
  };

  const handleReset = () => {
    let body = {
      searchText: "",
      pageNumber: 1,
      pageSize: 10,
      filter: [],
    };

    dispatch(getPromotedAdVideos(body))
      .then((res) => {
        if (res) {
          onHide();
        }
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  };

  const openPlayer = () => {
    setModal(true);
  };

  /******************* 
  @Purpose : Used for debounce for the search
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleDebouncedChange = React.useMemo(
    () => debounce((evt) => setSearchText(evt), 500),
    []
  );
  /******************* 
  @Purpose : Used for search on change
  @Parameter : {e}
  @Author : INIC
  ******************/
  const handleSearchChange = (e) => {
    e.persist();
    handleDebouncedChange(e.target.value);
  };
  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <>
      <Layout breadcrumb={breadcrumb} breadcrumbTitle="Manage Video AD">
        <div className="dashboard-container">
          <div className="main-content-area">
            <div className="overlay" />
            <div className="main-content-block overflow-auto">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header pb-0">
                      <div className="user-listing-filterOptions row">
                        <div className="col-lg-6">
                          <div className="action-wrap d-flex">
                            <label className="me-3">
                              <div className="search-input-wrapper position-relative">
                                <i className="icon-magnifier"></i>{" "}
                                <input
                                  type="search"
                                  className="form-control text-capitalize"
                                  placeholder="search"
                                  onChange={(e) => handleSearchChange(e)}
                                />
                              </div>
                            </label>
                            <Button
                              variant="primary"
                              active
                              className="btn-bordered position-relative d-flex align-items-center justify-content-center"
                              onClick={() => setModalShow(true)}
                            >
                              <i className="bx bx-filter-alt align-text-top me-1"></i>
                              Filter
                            </Button>
                          </div>
                        </div>

                        <div className="col-lg-6 text-end">
                          <Link to={route.PURCHASE_VIDEO_ADD}>
                            <Button variant="primary" className="btn">
                              Purchase Video Ad
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive  mb-3">
                        <table
                          className="table row-border nowrap common-datatable"
                          id="user-listing"
                        >
                          <thead>
                            <tr>
                              <th>
                                Transaction #<i aria-hidden="true"></i>
                              </th>
                              <th>
                                Purchase Date
                                <i aria-hidden="true"></i>
                              </th>
                              <th>
                                Video AD Title
                                <i aria-hidden="true"></i>
                              </th>
                              <th>
                                Category
                                <i aria-hidden="true"></i>
                              </th>
                              <th>
                                Sub-Category
                                <i aria-hidden="true"></i>
                              </th>
                              <th>
                                Start Date
                                <i aria-hidden="true"></i>
                              </th>
                              <th>
                                To Date
                                <i aria-hidden="true"></i>
                              </th>
                              <th>
                                Price (₹)
                                <i aria-hidden="true"></i>
                              </th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data && data?.length > 0 ? (
                              data?.map((promotedVideoAd) => (
                                <tr key={promotedVideoAd.id}>
                                  <td>2cd54454</td>
                                  <td>
                                    {promotedVideoAd?.promotionStartDate
                                      ? moment(
                                          promotedVideoAd?.promotionStartDate
                                        ).format("DD/MM/YYYY, h:mm:ss a")
                                      : "-"}
                                  </td>
                                  <td>{promotedVideoAd?.videoTitle}</td>
                                  {promotedVideoAd &&
                                    promotedVideoAd?.category?.length > 0 &&
                                    promotedVideoAd?.category?.map(
                                      (category) => (
                                        <td key={category.id}>
                                          {category?.title
                                            ? category?.title
                                            : "-"}
                                        </td>
                                      )
                                    )}
                                  <td>
                                    {promotedVideoAd &&
                                      promotedVideoAd?.subcategories?.length >
                                        0 &&
                                      promotedVideoAd?.subcategories
                                        .map((subcategory) => subcategory.title)
                                        .toString()}
                                  </td>
                                  <td>
                                    {promotedVideoAd
                                      ? moment(
                                          promotedVideoAd?.promotionStartDate
                                        ).format("DD/MM/YYYY, h:mm:ss a")
                                      : "-"}
                                  </td>
                                  <td>
                                    {promotedVideoAd?.promotionEndDate
                                      ? moment(
                                          promotedVideoAd?.promotionEndDate
                                        ).format("DD/MM/YYYY, h:mm:ss a")
                                      : "-"}
                                  </td>

                                  <td>
                                    {promotedVideoAd &&
                                      promotedVideoAd?.transactionDetails
                                        ?.length > 0 &&
                                      promotedVideoAd?.transactionDetails?.map(
                                        (transactionDetail) =>
                                          transactionDetail?.amountReceived
                                            ? transactionDetail?.amountReceived
                                            : "-"
                                      )}
                                  </td>
                                  <td>
                                    <div className="d-flex ml-3">
                                      <Link
                                        onClick={() =>
                                          history.push(
                                            `/managevideodetails/${promotedVideoAd?._id}`
                                          )
                                        }
                                        className="cursor-pointer m-2"
                                      >
                                        <i className="icon-eye"></i>
                                      </Link>

                                      <Link
                                        onClick={() => {
                                          let a = moment(
                                            promotedVideoAd?.promotionStartDate
                                          );
                                          let b = moment(new Date());
                                          if (a.diff(b, "days") >= 1) {
                                            history.push(
                                              `/ChangePurchasevideo/${promotedVideoAd?._id}`
                                            );
                                          } else {
                                            showMessageNotification(
                                              "You are not eligible for edit",
                                              "error"
                                            );
                                          }
                                        }}
                                        className="cursor-pointer m-2"
                                      >
                                        <i className="icon-note "></i>
                                      </Link>

                                      <a
                                        className="cursor-pointer m-2"
                                        variant="light"
                                        onClick={() => {
                                          openPlayer();
                                          setVideoLink(
                                            promotedVideoAd?.videoLink
                                          );
                                        }}
                                      >
                                        <i className="icon-control-play"></i>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <td className="error-msg">{message}</td>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="table-footer d-flex flex-wrap align-items-center justify-content-between">
                        <p className="mb-0 align-self-center text-primary-light fs-14">
                          Showing <b>{totalVideos ? totalVideos : 0}</b> Out of
                          <b> {data?.length ?? 0} </b> Entries
                        </p>
                        <div className="pagination-list ms-1">
                          <Pagination
                            className="ant-pagination"
                            pageSize={pageSize ? pageSize : 0}
                            current={pageNumber ? Number(pageNumber) : 0}
                            total={totalVideos}
                            onChange={paginationChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
        <FilterModel
          show={modalShow}
          filterData={filterData}
          setFilterData={setFilterData}
          defaultFilter={defaultFilter}
          filterTypeField={handleFilterTypeField}
          handleFilterTypeFields={handleFilterTypeFields}
          handleValueChange={handleValueChange}
          handleSubmit={handleSubmit}
          handleFiltertags={handleFiltertags}
          handleFilterArray={handleFilterArray}
          selectedFilterItems={selectedFilterItems}
          isSearchButtonDisabled={isSearchButtonDisabled}
          onHide={onHide}
          handleReset={handleReset}
          filterFields={filterFields}
          field={field}
        />
        <VideoPlayerModal
          show={modal}
          onHide={() => setModal(false)}
          videoLink={videoLink}
        />
      </Layout>
    </>
  );
};
export default ManageVideoAd;
