import { callApi } from "../../Api";
import Routes from "../../Api/Routes";

const { DASHBOARD_DATA, CHART_DATA, TREND_SUBCAT } = Routes;

/*******************
@purpose :  get brand videos
@Parameter : {data}
@Author : INIC
******************/
export const getBrandDashboardData = () => async (dispatch) => {
  const res = await callApi(`${DASHBOARD_DATA}`, "", "get", "", true);
  if (res) {
    await dispatch(setBrandDashboardData(res));
    return Promise.resolve(res);
  } else {
    dispatch(setBrandDashboardData([res.message]));
    return Promise.reject(res);
  }
};
/*******************
@purpose :  set brand videos
@Parameter : {data}
@Author : INIC
******************/
export const setBrandDashboardData = (data) => ({
  type: "SET_BRAND_DASHBOARD_DATA",
  payload: data,
});

/*******************
@purpose :  get brand videos
@Parameter : {data}
@Author : INIC
******************/
export const getBrandDashboardChartData = (data) => async (dispatch) => {
  const res = await callApi(`${CHART_DATA}`, data, "post", "", true);
  if (res) {
    await dispatch(setBrandDashboardChartData(res));
    return Promise.resolve(res);
  } else {
    dispatch(setBrandDashboardChartData([res.message]));
    return Promise.reject(res);
  }
};
//
export const getDashboardsTrendData = (data) => async (dispatch) => {
  const res = await callApi(`${TREND_SUBCAT}`, data, "post", "", true);
  if (res) {
    await dispatch(setDashboardTrend(res));
    return Promise.resolve(res);
  } else {
    dispatch(setDashboardTrend([res.message]));
    return Promise.reject(res);
  }
};
/*******************
@purpose :  set brand videos
@Parameter : {data}
@Author : INIC
******************/
export const setBrandDashboardChartData = (data) => ({
  type: "SET_BRAND_DASHBOARD_CHART_DATA",
  payload: data,
});
// trnd type
export const setDashboardTrend = (data) => ({
  type: "SET_TREND_DATA",
  payload: data,
});
