import { callApi } from "../../Api";
import Routes from "../../Api/Routes";
const { VIDEOTITLELIST } = Routes;
/*******************
@purpose :  used for of video queue videos
@Parameter : {data}
@Author : INIC
******************/
export const getAllVideosTitle = (data) => async (dispatch) => {
  const res = await callApi(`${VIDEOTITLELIST}`, data, "post", "", true);
  if (res) {
    await dispatch(setAllVideosTitle(res));
    return Promise.resolve(res);
  } else return Promise.reject(res);
};
export const setAllVideosTitle = (data) => ({
  type: "ALL_VIDEOS_TITLE",
  payload: data,
});
