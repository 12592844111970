import { callApi } from "../../Api";
import Routes from "../../Api/Routes"; // API endpoint routes
const { GET_PLANS } = Routes;

/*******************
@purpose :  get Plan History
@Parameter : {data}
@Author : INIC
******************/

export const fetchPlanHistory = () => async (dispatch) => {
  const res = await callApi(`${GET_PLANS}`, "", "get", "", true);
  if (res) {
    if (res.status) {
      dispatch(setPlanHistory(res.data || []));
    }
    return Promise.resolve(res);
  }
  return Promise.reject(res);
};

/*******************
@purpose :  set user profile
@Parameter : {data}
@Author : INIC
******************/
export const setPlanHistory = (data) => ({
  type: "SET_PLAN_HISTORY",
  payload: data,
});
