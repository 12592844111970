/*********************
@Purpose : Used for environment configuration
@Parameter : {API_URL, IMG_URL, PORT}
@Author : INIC
********************/
module.exports = {};

const env = {
  production: {
    API_URL: "https://vistaadminqa.devpress.net/api",
    IMAGE_PATH_URL: "https://d3juhcxa2or09v.cloudfront.net",
    PORT: process.env.PORT,
  },
  uat: {
    API_URL: "https://api.vistareels.com/api",
    IMAGE_PATH_URL: "https://d3juhcxa2or09v.cloudfront.net",
    PORT: process.env.PORT,
  },
  development: {
    API_URL: "https://vistaapidev.devpress.net/api",
    IMAGE_PATH_URL: "https://d3juhcxa2or09v.cloudfront.net",
    PORT: process.env.PORT,
  },
  qa: {
    API_URL: "https://vistaapiqa.devpress.net/api",
    IMAGE_PATH_URL: "https://d3juhcxa2or09v.cloudfront.net",
    PORT: process.env.PORT,
  },
  local: {
    API_URL: "https://vistaapidev.devpress.net/api",
    IMAGE_PATH_URL: "https://d3juhcxa2or09v.cloudfront.net",
    PORT: process.env.PORT,
  },
};

module.exports = env[process.env.REACT_APP_NODE_ENV];
