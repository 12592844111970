const INTIAL_STATE = {
  brandvideos: "",
  celebvideoinfo: "",
  purchasevideoDetail: "",
  postedvideoinfo: "",
};
/******************* 
@Purpose : Used for store data in redux
@Parameter : state, action
@Author : INIC
******************/
export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case "SET_BRAND_VIDEOS":
      return Object.assign({}, state, {
        brandvideos: action.payload,
      });
    case "SET_CELEB__INFO":
      return Object.assign({}, state, {
        celebvideoinfo: action.payload,
      });
    case "SET_POSTED_VIDEO_INFO":
      return Object.assign({}, state, {
        postedvideoinfo: action.payload,
      });
    case "SET_BRAND_PUR_VIDEOS_INFO":
      return Object.assign({}, state, {
        purchasevideoDetail: action.payload,
      });

    default:
      return state;
  }
};
