import { callApi } from "../../Api";
import Routes from "../../Api/Routes";

const { GET_BRAND_VIDEOS, GET_BRAND_VIDEO_INFO } = Routes;

/*******************
@purpose :  get brand videos
@Parameter : {data}
@Author : INIC
******************/
export const getBrandVideos = (data) => async (dispatch) => {
  const res = await callApi(`${GET_BRAND_VIDEOS}`, data, "post", "", true);
  if (res) {
    await dispatch(setBrandVideos(res));
    return Promise.resolve(res);
  } else {
    dispatch(setBrandVideos([res.message]));
    return Promise.reject(res);
  }
};
/*******************
@purpose :  set brand videos
@Parameter : {data}
@Author : INIC
******************/
export const setBrandVideos = (data) => ({
  type: "SET_BRAND_VIDEOS",
  payload: data,
});

/*******************
@purpose :  get brand tag video info
@Parameter : {data}
@Author : INIC
******************/
export const getVideoInfo = (data) => async (dispatch) => {
  await dispatch(setcelebVideosDetails([]));
  const res = await callApi(
    `${GET_BRAND_VIDEO_INFO}?videoId=${data}`,
    "",
    "get",
    "",
    true
  );
  if (res) {
    await dispatch(setcelebVideosDetails(res?.data));
    return Promise.resolve(res);
  } else return Promise.reject(res);
};

/*******************
@purpose :  set brand videos
@Parameter : {data}
@Author : INIC
******************/
export const setcelebVideosDetails = (data) => ({
  type: "SET_CELEB__INFO",
  payload: data,
});

/*******************
@purpose :  get brand tag video info
@Parameter : {data}
@Author : INIC
******************/
export const getpostedVideoInfo = (data) => async (dispatch) => {
  await dispatch(setPostedVideosDetails([]));
  const res = await callApi(
    `${GET_BRAND_VIDEO_INFO}?videoId=${data}`,
    "",
    "get",
    "",
    true
  );
  if (res) {
    await dispatch(setPostedVideosDetails(res?.data?.[0]));
    return Promise.resolve(res);
  } else return Promise.reject(res);
};

/*******************
@purpose :  set brand videos
@Parameter : {data}
@Author : INIC
******************/
export const setPostedVideosDetails = (data) => ({
  type: "SET_POSTED_VIDEO_INFO",
  payload: data,
});

/*******************
@purpose :  set brand videos
@Parameter : {data}
@Author : INIC
******************/
export const setPurchaseDetails = (data) => ({
  type: "SET_BRAND_PUR_VIDEOS_INFO",
  payload: data,
});
