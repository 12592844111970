import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";

/******************* 
@Purpose : Used for Brand Details
@Parameter : {}
@Author : INIC
******************/
const BrandDetail = ({ setShowEditBrand }) => {
  const profileData = useSelector((data) => data.user.userProfile);
  let [photo] = useState(profileData.photo);

  /******************* 
  @Purpose : Used for Back Handle
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleEdit = () => {
    setShowEditBrand(true);
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <div className="card">
      <div className="card-header  d-flex justify-content-between">
        <h5 className="card-title">Brand Information</h5>
        <Button className="" variant="primary" onClick={() => handleEdit()}>
          Edit
        </Button>
      </div>
      <div className="card-body user-details">
        <div className="video-list">
          <div className="row">
            <div className="col-xxl-2 col-lg-3 col-md-12 text-center">
              {photo ? (
                <img
                  className="user-image img-fluid rounded-circle"
                  src={profileData?.photo}
                  alt="Brand Image"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "/assets/images/brandImage.png";
                  }}
                />
              ) : (
                <img
                  className="user-image img-fluid rounded-circle"
                  src="/assets/images/user.png"
                  alt="Brand Image"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "/assets/images/brandImage.png";
                  }}
                />
              )}
            </div>
            <div className="col-xxl-5 col-lg-9 col-md-12">
              <div className="input-group row g-0">
                <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                  ORGANIZATION NAME
                </div>
                <div className="col-md-8 p-2 request-data">
                  {profileData?.organization}
                </div>
              </div>
              <div className="input-group row g-0">
                <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                  EMAIL ID
                </div>
                <div className="col-md-8 p-2 request-data">
                  {profileData?.emailId}
                </div>
              </div>
              <div className="input-group row g-0">
                <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                  ADDRESS
                </div>
                <div className="col-md-8 p-2 request-data">
                  {profileData?.address}
                </div>
              </div>
              <div className="input-group row g-0">
                <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                  CITY
                </div>
                <div className="col-md-8 p-2 request-data">
                  {profileData?.city}
                </div>
              </div>
              <div className="input-group row g-0">
                <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                  TWITTER
                </div>
                <div className="col-md-8 p-2 request-data">
                  {profileData?.twitterId ? (
                    <a href={profileData?.twitterId} target="_blank">
                      {profileData?.twitterId}
                    </a>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
              <div className="input-group row g-0">
                <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                  INSTAGRAM
                </div>
                <div className="col-md-8 p-2 request-data">
                  {profileData?.instagramId ? (
                    <a href={profileData?.instagramId} target="_blank">
                      {profileData?.instagramId}
                    </a>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
              <div className="input-group row g-0">
                <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                  LINKEDIN
                </div>
                <div className="col-md-8 p-2 request-data">
                  {profileData?.linkdnId ? (
                    <a href={profileData?.linkdnId} target="_blank">
                      {profileData?.linkdnId}
                    </a>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
              <div className="input-group row g-0">
                <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                  DESCRIPTION
                </div>
                <div className="col-md-8 p-2 request-data">
                  {profileData?.bio ? profileData?.bio : "-"}
                </div>
              </div>
            </div>
            <div className="col-xxl-5 offset-xxl-0 offset-lg-3 col-lg-9 col-md-12">
              <div className="input-group row g-0">
                <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                  GST Number
                </div>
                <div className="col-md-8 p-2 request-data">
                  {profileData?.gstNumber ? profileData?.gstNumber : "-"}
                </div>
              </div>

              <div className="input-group row g-0">
                <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                  CONTACT NAME
                </div>
                <div className="col-md-8 p-2 request-data">
                  {profileData?.firstName ? profileData?.firstName : "-"}
                </div>
              </div>
              <div className="input-group row g-0">
                <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                  PHONE NUMBER
                </div>
                <div className="col-md-8 p-2 request-data">
                  {profileData?.mobileNumber ? profileData?.mobileNumber : "-"}
                </div>
              </div>
              <div className="input-group row g-0">
                <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                  WHATSAPP BUSINESS NUMBER
                </div>
                <div className="col-md-8 p-2 request-data">
                  {profileData?.whatsAppBusinessNumber
                    ? profileData?.whatsAppBusinessNumber
                    : "-"}
                </div>
              </div>
              <div className="input-group row g-0">
                <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                  STATE
                </div>
                <div className="col-md-8 p-2 request-data">
                  {profileData?.state}
                </div>
              </div>
              <div className="input-group row g-0">
                <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                  PINCODE
                </div>
                <div className="col-md-8 p-2 request-data">
                  {profileData?.pin}
                </div>
              </div>
              <div className="input-group row g-0">
                <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                  FACEBOOK
                </div>
                <div className="col-md-8 p-2 request-data">
                  {profileData?.fbId ? (
                    <a href={profileData?.fbId} target="_blank">
                      {profileData?.fbId}
                    </a>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
              <div className="input-group row g-0">
                <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                  YOUTUBE
                </div>
                <div className="col-md-8 p-2 request-data">
                  {profileData?.youTubeId ? (
                    <a href={profileData?.youTubeId} target="_blank">
                      {profileData?.youTubeId}
                    </a>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandDetail;
