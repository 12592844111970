import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { getVideoInfo } from "../../Store/Actions/BrandVideos";
import { Layout, Footer } from "../../Components/Layout";
import { LIST_OF_VIDEO_AD, VIDEO_DETAILS, route } from "../../Utils";

import moment from "moment/moment";

/******************* 
@Purpose : Used for Video info page
@Parameter : {}
@Author : INIC
******************/
const PurchasevideoAdDetail = () => {
  const history = useHistory();
  const { celebvideoinfo } = useSelector((data) => data.brandvideos);
  const dispatch = useDispatch();
  const param = useParams();

  /*******************
  @Purpose : used for get video info
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    if (param.slug) {
      dispatch(getVideoInfo(param.slug)).then((res) => {
        if (!res.status) {
          history.push("/ManageVideoAd");
        }
      });
    }
  }, [param]);

  /******************* 
  @Purpose : Used for Bradcrumb View
  @Parameter : {}
  @Author : INIC
  ******************/
  const breadcrumb = [
    {
      title: LIST_OF_VIDEO_AD,
      url: route.MANAGE_VIDEO_AD,
    },
    {
      title: VIDEO_DETAILS,
    },
  ];

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Brandvideos">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <div className="row">
              <div className="col-lg-12 mb-4">
                <div className="card">
                  <div className="card-header pb-0">
                    <h5 className="card-title">Video Detail</h5>
                  </div>
                  <div className="card-body user-details">
                    {celebvideoinfo &&
                      celebvideoinfo?.length > 0 &&
                      celebvideoinfo?.map((data) => (
                        <div className="row" key={data.id}>
                          <div className="col-lg-2 col-md-12 align-item-center">
                            <div className="video-card border-0 mb-0">
                              <div className="video-img position-relative">
                                <video className="br-12" width="100%;" height="170" controls>
                                  <source
                                    src={data?.videoLink}
                                    type="video/mp4"
                                  ></source>
                                </video>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-5 col-md-6">
                            <div className="input-group row g-0">
                              <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                                TITLE :
                              </div>
                              <div className="col-md-8 p-2 request-data text-secondary">
                                {data?.videoTitle}
                              </div>
                            </div>

                            <div className="input-group row g-0">
                              <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                                {" "}
                                SUB CATEGORY:
                              </div>
                              <div className="col-md-8 p-2 request-data text-secondary">
                                {data?.subcategories &&
                                  data.subcategories.length > 0 &&
                                  data.subcategories
                                    .map((subcat) => subcat?.title)
                                    .toString()}
                              </div>
                            </div>

                            <div className="input-group row g-0">
                              <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                                START DATE:
                              </div>
                              <div className="col-md-8 p-2 request-data text-secondary">
                                {moment(data?.promotionStartDate).format("l")}
                              </div>
                            </div>

                            <div className="input-group row g-0">
                              <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                                TRANSACTION:
                              </div>
                              <div className="col-md-8 p-2 request-data text-secondary">
                                {data?.transactionRef}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-5 col-md-6">
                            <div className="input-group row g-0">
                              {data?.category &&
                                data?.category.length > 0 &&
                                data?.category.map((category) => (
                                  <>
                                    <div
                                      className="col-md-4 p-2 text-capitalize fw-bold request-title"
                                      key={category.id}
                                    >
                                      CATEGORY:
                                    </div>
                                    <div className="col-md-8 p-2 request-data text-secondary">
                                      {category?.title ?? "-"}
                                    </div>
                                  </>
                                ))}
                            </div>
                            <div className="input-group row g-0">
                              <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                                DURATION:
                              </div>
                              <div className="col-md-8 p-2 request-data text-secondary">
                                {data?.PromotionDurationinDays ?? 0} Days
                              </div>
                            </div>
                            <div className="input-group row g-0">
                              <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                                END DATE:
                              </div>
                              <div className="col-md-8 p-2 request-data text-secondary">
                                {data?.promotionEndDate
                                  ? moment(data?.promotionEndDate).format("l")
                                  : "-"}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </Layout>
  );
};

export default PurchasevideoAdDetail;
