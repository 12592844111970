const INTIAL_STATE = {
  tabs: "",
  termcon: "",
};
/******************* 
@Purpose : Used for store data in redux
@Parameter : state, action
@Author : INIC
******************/
export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case "SET_TABS":
      return Object.assign({}, state, {
        tabs: action.payload,
      });
    case "SET_AGREE":
      return Object.assign({}, state, {
        termcon: action.payload,
      });

    default:
      return state;
  }
};
