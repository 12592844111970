import { callApi } from "../../Api";
import Routes from "../../Api/Routes";
import { showMessageNotification } from "../../Utils";

const { BRANDVIDEOADLIST, UPLOADADVIDEO } = Routes;

/*******************
@purpose :  get brand videos
@Parameter : {data}
@Author : INIC
******************/
export const getPromotedAdVideos = (data) => async (dispatch) => {
  const res = await callApi(`${BRANDVIDEOADLIST}`, data, "post", "", true);
  if (res) {
    await dispatch(setBrandVideosAd(res));
    return Promise.resolve(res);
  } else return Promise.reject(res);
};
/*******************
@purpose :  set brand videos
@Parameter : {data}
@Author : INIC
******************/
export const setBrandVideosAd = (data) => ({
  type: "SET_BRAND_VIDEO_AD",
  payload: data,
});

/*******************
@purpose :  get brand videos
@Parameter : {data}
@Author : INIC
******************/
export const uploadAdVideos = (data) => async (dispatch) => {
  const res = await callApi(`${UPLOADADVIDEO}`, data, "post", "", true);
  if (res) {
    dispatch(setBrandVideosAdDetails(res));
    showMessageNotification(res.message, "success");
    return res;
  } else return res;
};

/*******************
@purpose :  set brand videos
@Parameter : {data}
@Author : INIC
******************/
export const setBrandVideosAdDetails = (data) => ({
  type: "SET_BRAND_VIDEO_AD_details",
  payload: data,
});
