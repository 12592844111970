import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import clsx from "clsx";

/******************* 
@Purpose : Used for Filter Model
@Parameter : {props}
@Author : Vista app
******************/
const FilterModel = (props) => {
  const [filterType, setFilterType] = useState("Or");

  /******************* 
  @Purpose :  handle And/Or toggle condition
  @Parameter : {type=minus/Add,index}
  @Author : INIC
  ******************/
  const handleManageFilter = (type = "minus", index) => {
    props.handleFilterArray();
    let filterRow = props.filterData;

    if (type === "add") {
      props.defaultFilter.filterType = filterType;
      filterRow.push(props.defaultFilter);
    } else {
      if (filterRow?.length === 1) {
        props.handleReset();
        filterRow[0].key = "";
        filterRow[0].value = "";
        filterRow[0].filterOptions = [];
        // props.setFilterData([defaultFilter]);
      } else if (filterRow.length == 0) {
        props.setFilterData([
          {
            key: "",
            type: "",
            value: "",
            filterType: "",
            filterOptions: [],
            valueFieldType: "",
          },
        ]);
        // setFilterType(filterData?.filterOptions([]));
      } else {
        filterRow.splice(index, 1);
      }
    }

    props.setFilterData([...filterRow]);
    props.handleFilterArray([...filterRow]);
    props.setIsUpdateFilter(!props.isUpdateFilter);
    // props.setFilterData([props.defaultFilter]);
  };

  // console.log(props.selectedFilterItems, "props.select");
  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  const selectedKeys = props.filterData.map((item) => item.key);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="filter-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Filter</Modal.Title>
      </Modal.Header>
      <Modal.Body closeButton>
        <div className="dorpdown-inner date-picker-modal-wrap">
          {props?.filterData &&
            props?.filterData?.length > 0 &&
            props?.filterData?.map((filter, index) => (
              <div key={index}>
                <div className="main-input-wrapper justify-content-between align-items-center mb-4">
                  <div className="left-input-wrapper">
                    <div className="row w-100">
                      <div
                        className={clsx("col-lg-6", {
                          "col-md-4": filter.key !== "date",
                          "col-md-6": filter.key == "date",
                        })}
                      >
                        <Select
                          filterOption={(option) =>
                            !selectedKeys.includes(option.value)
                          }
                          options={props.filterTypeField(index)}
                          className="custom-select-container"
                          classNamePrefix={"custom-select"}
                          onChange={(e) =>
                            props.handleFilterTypeFields(e.value, index)
                          }
                          // value={props}
                          // value={
                          //   props.filterData.key ? props.filterData.key : null
                          // }
                        />
                      </div>
                      {/* {filter.key !== "date" && (
                        <div
                          className={clsx(" mb-2", {
                            "col-sm-4": filter.key !== "date",
                            "col-sm-6": filter.key == "date",
                          })}
                        >
                          <Select
                            options={containes}
                            value={containes}
                            isDisabled
                            classNamePrefix="react-select"
                            className="react-select-wrap"
                          />
                        </div>
                      )} */}

                      <div
                        className={clsx("col-md-6", {
                          "col-md-4": filter.key !== "date",
                          "col-md-6": filter.key == "date",
                        })}
                      >
                        {props.handleFiltertags(index)}
                      </div>
                    </div>
                  </div>
                  <div className="right-button-wrapper d-flex align-items-center justify-content-end">
                    <div
                      className="add-filed remove-filed "
                      onClick={() => handleManageFilter("minus", index)}
                    >
                      <span className="icon icon-remove mx-3">-</span>
                    </div>

                    <div
                      className="add-filed ms-2 "
                      onClick={() => handleManageFilter("add", index)}
                    >
                      <span className="icon icon-add mx-2 ">+</span>
                    </div>
                  </div>
                </div>
                {/* {props.filterData.length > 1 &&
                  index !== props.filterData.length - 1 && (
                    <div className="or-group-wrap mb-5 d-flex align-items-center">
                      {filter.filterType === "And" ? (
                        <Button variant="light">
                          <i className="icon-plus me-1"></i> And
                        </Button>
                      ) : (
                        <Button variant="light">
                          <i className="icon-plus me-1"></i> Or
                        </Button>
                      )}
                      <Button
                        variant="light"
                        onClick={() => handleManageFilter("minus", index)}
                      >
                        <i className="icon-minus"></i>
                      </Button>
                    </div>
                  )} */}
              </div>
            ))}
          {/* {props.filterData.length <= props.field && (
            <div className="or-group-wrap mb-5 d-flex align-items-center">
              <Button
                variant="light"
                onClick={() => setFilterType("And")}
                active={filterType === "And"}
              >
                <i className="icon-plus me-1"></i> And
              </Button>
              <Button
                variant="light"
                onClick={() => setFilterType("Or")}
                active={filterType === "Or"}
              >
                <i className="icon-plus me-1"></i> Or
              </Button>
              <Button variant="light" onClick={() => handleManageFilter("add")}>
                <i className="icon-plus"></i>
              </Button>
            </div>
          )} */}
        
          <div className="disclamer mt-5">
              Note: Please select the Category first and then click on "+" icon
              to select Sub-Category.
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
      <Button
              variant="light"
              className="me-2"
              onClick={() => props.handleReset()}
            >
              Reset
            </Button>
            <Button
              variant="primary"
              className="me-2"
              onClick={() => props.handleSubmit()}
              disabled={props.isSearchButtonDisabled}
            >
              Search
            </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FilterModel;
