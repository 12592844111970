import { Field, FieldArray, Formik, Form as FormicForm } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactFancyBox from "react-fancybox";
import "react-fancybox/lib/fancybox.css";
import { useHistory, useParams } from "react-router";
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  FormText,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Footer, Layout } from "../Layout";
import {
  purchaseCelebVideoSchema,
  PUR_HISTORY,
  PUR_VIDEO_AD,
  route,
  showMessageNotification,
} from "../../Utils";
import { SelectField } from "../../Components/FormFields";
import { PurchaseVideoOrder } from "../../Store/Actions/PurchaseVideoOrder";
import { cmsDetail } from "../../Store/Actions/User";
import { setAgreeValue } from "../../Store/Actions/Tabs";

/******************* 
@Purpose : Used for purchase celeb videos
@Parameter : {props}
@Author : Vista app
******************/
const PurchaseCelebVideos = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const param = useParams();
  const { categoryInfo, cmsData } = useSelector((data) => data.user);
  const { termcon } = useSelector((data) => data?.tabs);
  const [agree, setAgree] = useState(false);
  const { celeblist } = useSelector((data) => data?.celebVideos);
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [category, setCategory] = useState("");

  const [subCategory, setSubCategory] = useState("");

  const initialData = [
    {
      category: "",
      subCategory: "",
      videoTitle: "",
      caption: "",
      productSku: "",
      whatsAppNumber: "",
      affiliatedLink: "",
      affilietedProductUrl: "",
      linkValue: "",
      errorState: "",
    },
  ];

  /******************* 
  @Purpose :  React hook used for get categories and sub categories
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    if (termcon) {
      setModalShow(false);
    } else if (!termcon) {
      setModalShow(true);
      dispatch(cmsDetail());
      categoryValue();
    }
  }, []);

  useEffect(() => {
    const data = celeblist.filter((data) => data._id === param.slug1);
    if (data?.[0]?._id !== param.slug1) {
      dispatch(setAgreeValue(false));
      history.push(`/purchaseHistory/${param.slug}`);
    }
  }, []);

  /******************* 
  @Purpose : Used for category value
  @Parameter : {}
  @Author : Vista app
  ******************/
  const categoryValue = () => {
    let categoryArr = [];
    categoryInfo?.length > 0 &&
      categoryInfo?.forEach((optiondata) => {
        let tempObj = {
          label: optiondata?.title,
          value: optiondata?._id,
          ...optiondata,
        };
        categoryArr.push(tempObj);
        setCategory(categoryArr);
      });
  };

  /******************* 
  @Purpose :  used for sub category value 
  @Parameter : {list}
  @Author : INIC
  ******************/
  const subCategoryValue = (catData) => {
    let subCategoryArr = [];
    catData?.subCategoryDetails?.length > 0 &&
      catData?.subCategoryDetails?.forEach((subCategory) => {
        let tempObj2 = {
          label: subCategory?.title,
          value: subCategory?._id,
        };
        subCategoryArr.push(tempObj2);
        setSubCategory(subCategoryArr);
      });
    return subCategoryArr;
  };

  /******************* 
    @purpose : remove subjects
    @Parameter : {index , subjects}
    @Author :INIC
    ******************/
  const removeSubjectData = (arrayHelpers, index) => {
    arrayHelpers.remove(index);
  };

  /******************* 
  @purpose : handle register button
  @Parameter : {}
  @Author :INIC
  ******************/
  const onRegisterHandle = ({ purchase_celeb_details }) => {
    setLoading(true);
    let arr = purchase_celeb_details?.map((item) => {
      let tempArr = {
        categoryId: item.category.value,
        subCategoryId: item.subCategory.value,
        videoTitle: item.videoTitle,
        caption: item.caption,
        productSku: item.productSku,
        whatsAppNumber: item.whatsAppNumber,

        // affiliatedLink: item.affiliatedLink,
        affilietedProductUrl: item.affiliatedLink,
        linkData: "",
      };
      return tempArr;
    });

    let body = {
      creatorId: param.slug1,
      lineItems: arr,
    };
    if (body) {
      dispatch(PurchaseVideoOrder(body))
        .then((res) => {
          if (res) {
            setLoading(false);
            dispatch(setAgreeValue(false));
            history.push(`/purchaseHistory/${param.slug}`);
          } else {
            setLoading(false);
            showMessageNotification(res.message, "error");
          }
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    }
  };

  /******************* 
  @Purpose : Used for cancel redirect
  @Parameter : {props}
  @Author : Vista app
  ******************/
  const handleCancel = () => {
    dispatch(setAgreeValue(false));
    history.push(`/purchaseHistory/${param.slug}`);
  };

  /******************* 
  @Purpose : Used for Bread crumb
  @Parameter : {}
  @Author : INIC
  ******************/
  const breadcrumb = [
    {
      title: PUR_HISTORY,
      url: `/purchaseHistory/${param.slug}`,
    },
    {
      title: PUR_VIDEO_AD,
      url: route.PURCHASE_VIDEO_ADD,
    },
  ];

  function MyVerticallyCenteredModal(props) {
    const [agree, setAgree] = useState(false);
    const checkboxHandler = () => {
      setAgree(!agree);
    };

    const desc = cmsData?.description;

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {cmsData?.pageTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="dynamic-contetn-section"
            // dangerouslySetInnerHTML={{ __html: cmsData?.description }}
            dangerouslySetInnerHTML={{
              __html: desc,
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Form.Check
            className="container w-75 p-0 "
            type="checkbox"
            // id={`default-${type}`}
            label="I agree to the Terms and Conditions"
            onChange={checkboxHandler}
          />
          <Button onClick={props.onAgree} disabled={!agree}>
            Agree to continue
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const handleHide = () => {
    setModalShow(false);
    dispatch(setAgreeValue(false));
    history.push(`/purchaseHistory/${param.slug}`);
  };

  const handleAgree = () => {
    dispatch(setAgreeValue(true));
    setAgree(true);
    setModalShow(false);
  };
  // assign the link values here

  /******************* 
  @purpose : Rander HTML/ React Components
  @Author : INIC
  ******************/

  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="My Celebrity">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay"></div>
          <div className="main-content-block overflow-auto">
            <div className="content-area position-relative">
              <div className="card profile-card mb-5 p-0">
                <div className="card-header  p-3">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb p-0">
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        <React.Fragment>
                          At a time One video can be ordered only
                        </React.Fragment>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        <React.Fragment>Select Payment Method</React.Fragment>
                      </li>
                    </ol>
                  </nav>
                </div>

                <div className="card-body p-3">
                  <div className="row">
                    <Formik
                      initialValues={{
                        purchase_celeb_details: initialData,
                      }}
                      enableReinitialize={true}
                      validationSchema={purchaseCelebVideoSchema}
                      onSubmit={(values) => onRegisterHandle(values)}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        handleSubmit,
                      }) => (
                        <FormicForm>
                          <FieldArray
                            name="purchase_celeb_details"
                            render={(arrayHelpers) => {
                              const { purchase_celeb_details } = values;
                              return (
                                <div className="add-filed-item sample-wrapper p-2">
                                  <div className="sample-video-wrapper">
                                    <div className="field-wrapper">
                                      {purchase_celeb_details &&
                                        purchase_celeb_details.length > 0 &&
                                        purchase_celeb_details.map(
                                          (qualifications, index) => (
                                            <div
                                              className="main-input-wrapper select-edit"
                                              key={index}
                                            >
                                              <div className="left-input-wrapper">
                                                <div className="row ">
                                                  <div className="col-xl-4 col-sm-6">
                                                    <Field
                                                      name={`purchase_celeb_details.${index}.category`}
                                                    >
                                                      {({
                                                        field,
                                                        form: { handleChange },
                                                      }) => (
                                                        <SelectField
                                                          name="category"
                                                          label="category "
                                                          searchable={false}
                                                          className="custom-select-container"
                                                          classNamePrefix={
                                                            "custom-select"
                                                          }
                                                          placeholder="Select Category"
                                                          options={category}
                                                          values={field.value}
                                                          touched={
                                                            touched &&
                                                            touched.purchase_celeb_details &&
                                                            touched
                                                              .purchase_celeb_details[
                                                              index
                                                            ] &&
                                                            touched
                                                              .purchase_celeb_details[
                                                              index
                                                            ].category
                                                          }
                                                          errors={
                                                            errors &&
                                                            errors.purchase_celeb_details &&
                                                            errors
                                                              .purchase_celeb_details[
                                                              index
                                                            ] &&
                                                            errors
                                                              .purchase_celeb_details[
                                                              index
                                                            ].category
                                                          }
                                                          {...field}
                                                          onChange={(e) => {
                                                            handleChange(e);
                                                            subCategoryValue(
                                                              e.target.value
                                                            );
                                                          }}
                                                        />
                                                      )}
                                                    </Field>
                                                  </div>
                                                  <div className="col-xl-4 col-sm-6">
                                                    <Field
                                                      name={`purchase_celeb_details.${index}.subCategory`}
                                                    >
                                                      {({
                                                        field,
                                                        form: { handleChange },
                                                        meta,
                                                      }) => (
                                                        <SelectField
                                                          name="subCategory"
                                                          label="Sub Category"
                                                          className="custom-select-container"
                                                          classNamePrefix={
                                                            "custom-select"
                                                          }
                                                          placeholder="Select Sub Category"
                                                          isOptionDisabled={(
                                                            option
                                                          ) =>
                                                            !values
                                                              .purchase_celeb_details[
                                                              index
                                                            ].category
                                                          }
                                                          options={
                                                            values
                                                              .purchase_celeb_details[
                                                              index
                                                            ].category
                                                              ? subCategory
                                                              : []
                                                          }
                                                          touched={
                                                            touched &&
                                                            touched.purchase_celeb_details &&
                                                            touched
                                                              .purchase_celeb_details[
                                                              index
                                                            ] &&
                                                            touched
                                                              .purchase_celeb_details[
                                                              index
                                                            ].subCategory
                                                          }
                                                          errors={
                                                            errors &&
                                                            errors.purchase_celeb_details &&
                                                            errors
                                                              .purchase_celeb_details[
                                                              index
                                                            ] &&
                                                            errors
                                                              .purchase_celeb_details[
                                                              index
                                                            ].subCategory
                                                          }
                                                          {...field}
                                                          onChange={(e) => {
                                                            handleChange(e);
                                                          }}
                                                        />
                                                      )}
                                                    </Field>
                                                  </div>

                                                  {/* video title */}
                                                  <div className="col-xl-4 col-sm-6 mb-3">
                                                    <Field
                                                      name={`purchase_celeb_details.${index}.videoTitle`}
                                                    >
                                                      {({
                                                        field,
                                                        form: { handleChange },
                                                        meta,
                                                      }) => (
                                                        <FormGroup>
                                                          <FormLabel className="">
                                                            Video Title
                                                          </FormLabel>
                                                          <sup className="text-danger">
                                                            *
                                                          </sup>
                                                          <FormControl
                                                            className="video title me-2"
                                                            type="text"
                                                            placeholder="Enter Shop Name/Price"
                                                            autoComplete="off"
                                                            onChange={(e) =>
                                                              setFieldValue(
                                                                `purchase_celeb_details.${index}.videoTitle`,

                                                                e.target.value
                                                              )
                                                            }
                                                            value={field.value}
                                                            name="videoTitle"
                                                            touched={
                                                              touched &&
                                                              touched.purchase_celeb_details &&
                                                              touched
                                                                .purchase_celeb_details[
                                                                index
                                                              ] &&
                                                              touched
                                                                .purchase_celeb_details[
                                                                index
                                                              ].videoTitle
                                                            }
                                                          ></FormControl>

                                                          <FormText className="error text-danger">
                                                            {touched &&
                                                              touched.purchase_celeb_details &&
                                                              touched
                                                                .purchase_celeb_details[
                                                                index
                                                              ] &&
                                                              touched
                                                                .purchase_celeb_details[
                                                                index
                                                              ].videoTitle &&
                                                              errors &&
                                                              errors.purchase_celeb_details &&
                                                              errors
                                                                .purchase_celeb_details[
                                                                index
                                                              ] &&
                                                              errors
                                                                .purchase_celeb_details[
                                                                index
                                                              ].videoTitle}
                                                          </FormText>
                                                        </FormGroup>
                                                      )}
                                                    </Field>
                                                  </div>

                                                  {/* sample data */}
                                                  {/* affilaated link starts */}

                                                  {/* affliated link ends here */}
                                                  {/* whatapp number */}
                                                  {/* whatapp number ends here */}
                                                  {values
                                                    .purchase_celeb_details[
                                                    index
                                                  ]?.affilietedProductUrl ==
                                                  false ? (
                                                    <div className="col-xl-4 col-sm-6 mb-3">
                                                      <div className="form-group">
                                                        <label
                                                          className="form-label"
                                                          htmlFor="affiliatedLink"
                                                        >
                                                          WhatApp Number
                                                          <sup className="text-danger">
                                                            *
                                                          </sup>
                                                        </label>

                                                        <div
                                                          className=""
                                                          // style={{
                                                          //   width: "130px",
                                                          // }}
                                                        >
                                                          <div className="input-group-prepend">
                                                            <div
                                                              className="input-group-text d-none"
                                                              style={{
                                                                height: "40px",
                                                              }}
                                                            >
                                                              <input
                                                                type="radio"
                                                                value={
                                                                  values?.affilietedProductUrl
                                                                }
                                                                checked={
                                                                  values
                                                                    ?.purchase_celeb_details[
                                                                    index
                                                                  ]
                                                                    .whatsAppNumber
                                                                    ? true
                                                                    : false
                                                                }
                                                                name={`purchase_celeb_details.${index}.affilietedProductUrl`}
                                                                id="affilietedProductUrl_false"
                                                                className="me-2"
                                                              />
                                                            </div>
                                                          </div>
                                                          <input
                                                            type="number"
                                                            className="form-control"
                                                            aria-label="whatsAppNumber"
                                                            id="whatsAppNumber"
                                                            name="whatsAppNumber"
                                                            placeholder="WhatsApp No."
                                                            autoComplete="off"
                                                            maxLength={10}
                                                            onInput={(e) => {
                                                              if (
                                                                e.target.value
                                                                  .length >
                                                                e.target
                                                                  .maxLength
                                                              )
                                                                e.target.value =
                                                                  e.target.value.slice(
                                                                    0,
                                                                    e.target
                                                                      .maxLength
                                                                  );
                                                            }}
                                                            onChange={(e) => {
                                                              setFieldValue(
                                                                `purchase_celeb_details.${index}.affilietedProductUrl`,
                                                                false
                                                              );
                                                              setFieldValue(
                                                                `purchase_celeb_details.${index}.affiliatedLink`,
                                                                ""
                                                              );
                                                              setFieldValue(
                                                                `purchase_celeb_details.${index}.whatsAppNumber`,
                                                                e.target.value
                                                              );
                                                            }}
                                                            value={
                                                              values
                                                                .purchase_celeb_details[
                                                                index
                                                              ]
                                                                .whatsAppNumber ||
                                                              ""
                                                            }
                                                            touched={
                                                              touched &&
                                                              touched.purchase_celeb_details &&
                                                              touched
                                                                .purchase_celeb_details[
                                                                index
                                                              ] &&
                                                              touched
                                                                .purchase_celeb_details[
                                                                index
                                                              ].whatsAppNumber
                                                            }
                                                          />
                                                        </div>
                                                        <FormText className="error text-danger">
                                                          {touched &&
                                                            touched.purchase_celeb_details &&
                                                            touched
                                                              .purchase_celeb_details[
                                                              index
                                                            ] &&
                                                            touched
                                                              .purchase_celeb_details[
                                                              index
                                                            ].whatsAppNumber &&
                                                            errors &&
                                                            errors.purchase_celeb_details &&
                                                            errors
                                                              .purchase_celeb_details[
                                                              index
                                                            ] &&
                                                            errors
                                                              .purchase_celeb_details[
                                                              index
                                                            ].whatsAppNumber}
                                                        </FormText>
                                                        <p
                                                          className="text-primary fs-14 fw-normal text-decoration-none mt-1 mb-0 cursor-pointer border py-1 px-3 d-inline-block"
                                                          style={{
                                                            fontWeight: "600",
                                                          }}
                                                          onClick={() =>
                                                            setFieldValue(
                                                              `purchase_celeb_details.${index}.affilietedProductUrl`,
                                                              true
                                                            )
                                                          }
                                                        >
                                                          Instead Use Product
                                                          URL
                                                        </p>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <div className="col-xl-4 col-sm-6 mb-3">
                                                      <div className="form-group">
                                                        <label
                                                          className="form-label"
                                                          htmlFor="affiliatedLink"
                                                        >
                                                          Affiliated Link
                                                          <sup className="text-danger">
                                                            *
                                                          </sup>
                                                        </label>
                                                        <div className="">
                                                          <div className="input-group-prepend">
                                                            <div
                                                              className="input-group-text d-none"
                                                              style={{
                                                                height: "40px",
                                                              }}
                                                            >
                                                              <input
                                                                type="radio"
                                                                value={
                                                                  values?.affilietedProductUrl
                                                                }
                                                                checked={
                                                                  values
                                                                    ?.purchase_celeb_details[
                                                                    index
                                                                  ]
                                                                    .affiliatedLink
                                                                    ? true
                                                                    : false
                                                                }
                                                                name={`purchase_celeb_details.${index}.affilietedProductUrl`}
                                                                id="affilietedProductUrl_true"
                                                                className="me-2"
                                                              />
                                                            </div>
                                                          </div>
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            aria-label="affiliatedLink"
                                                            id="affiliatedLink"
                                                            name="affiliatedLink"
                                                            placeholder="Enter Product URL"
                                                            autoComplete="off"
                                                            onChange={(e) => {
                                                              setFieldValue(
                                                                `purchase_celeb_details.${index}.affilietedProductUrl`,
                                                                true
                                                              );
                                                              setFieldValue(
                                                                `purchase_celeb_details.${index}.affiliatedLink`,
                                                                e.target.value
                                                              );
                                                              setFieldValue(
                                                                `purchase_celeb_details.${index}.whatsAppNumber`,
                                                                ""
                                                              );
                                                            }}
                                                            value={
                                                              values
                                                                .purchase_celeb_details[
                                                                index
                                                              ]
                                                                .affiliatedLink ||
                                                              ""
                                                            }
                                                            touched={
                                                              touched &&
                                                              touched.purchase_celeb_details &&
                                                              touched
                                                                .purchase_celeb_details[
                                                                index
                                                              ] &&
                                                              touched
                                                                .purchase_celeb_details[
                                                                index
                                                              ]
                                                                .affilietedProductUrl
                                                            }
                                                          />
                                                        </div>
                                                        <FormText className="error text-danger">
                                                          {touched &&
                                                            touched.purchase_celeb_details &&
                                                            touched
                                                              .purchase_celeb_details[
                                                              index
                                                            ] &&
                                                            touched
                                                              .purchase_celeb_details[
                                                              index
                                                            ]
                                                              .affilietedProductUrl &&
                                                            errors &&
                                                            errors.purchase_celeb_details &&
                                                            errors
                                                              .purchase_celeb_details[
                                                              index
                                                            ] &&
                                                            errors
                                                              .purchase_celeb_details[
                                                              index
                                                            ]
                                                              .affilietedProductUrl}
                                                        </FormText>
                                                        <FormText className="error text-danger">
                                                          {touched &&
                                                            touched.purchase_celeb_details &&
                                                            touched
                                                              .purchase_celeb_details[
                                                              index
                                                            ] &&
                                                            touched
                                                              .purchase_celeb_details[
                                                              index
                                                            ].affiliatedLink &&
                                                            errors &&
                                                            errors.purchase_celeb_details &&
                                                            errors
                                                              .purchase_celeb_details[
                                                              index
                                                            ] &&
                                                            errors
                                                              .purchase_celeb_details[
                                                              index
                                                            ].affiliatedLink}
                                                        </FormText>
                                                        <h6
                                                          className="text-primary fs-14 fw-normal text-decoration-none mt-1 mb-0 cursor-pointer border py-1 px-3 d-inline-block"
                                                          style={{
                                                            fontWeight: "600",
                                                          }}
                                                          onClick={() =>
                                                            setFieldValue(
                                                              `purchase_celeb_details.${index}.affilietedProductUrl`,
                                                              false
                                                            )
                                                          }
                                                        >
                                                          Instead Use Whatsapp
                                                          Number
                                                        </h6>
                                                      </div>
                                                    </div>
                                                  )}

                                                  {/* product sku */}
                                                  <div className="col-xl-4 col-sm-6 mb-3">
                                                    <Field
                                                      name={`purchase_celeb_details.${index}.productSku`}
                                                    >
                                                      {({
                                                        field,
                                                        form: { handleChange },
                                                        meta,
                                                      }) => (
                                                        <FormGroup>
                                                          <FormLabel className="">
                                                            Product SKU
                                                          </FormLabel>
                                                          <FormControl
                                                            className="no-of-videos"
                                                            type="text"
                                                            placeholder="Enter product SKU"
                                                            onChange={(e) =>
                                                              setFieldValue(
                                                                `purchase_celeb_details.${index}.productSku`,

                                                                e.target.value
                                                              )
                                                            }
                                                            autoComplete="off"
                                                            value={field.value}
                                                            name="productSku"
                                                            touched={
                                                              touched &&
                                                              touched.purchase_celeb_details &&
                                                              touched
                                                                .purchase_celeb_details[
                                                                index
                                                              ] &&
                                                              touched
                                                                .purchase_celeb_details[
                                                                index
                                                              ].productSku
                                                            }
                                                          ></FormControl>

                                                          <FormText className="error text-danger">
                                                            {touched &&
                                                              touched.purchase_celeb_details &&
                                                              touched
                                                                .purchase_celeb_details[
                                                                index
                                                              ] &&
                                                              touched
                                                                .purchase_celeb_details[
                                                                index
                                                              ].productSku &&
                                                              errors &&
                                                              errors.purchase_celeb_details &&
                                                              errors
                                                                .purchase_celeb_details[
                                                                index
                                                              ] &&
                                                              errors
                                                                .purchase_celeb_details[
                                                                index
                                                              ].productSku}
                                                          </FormText>
                                                        </FormGroup>
                                                      )}
                                                    </Field>
                                                  </div>
                                                  {/* product sku ends here */}
                                                  {/* caption */}
                                                  <div className="col-md-12">
                                                    <Field
                                                      name={`purchase_celeb_details.${index}.caption`}
                                                    >
                                                      {({
                                                        field,
                                                        form: { handleChange },
                                                        meta,
                                                      }) => (
                                                        <FormGroup>
                                                          <FormLabel className="">
                                                            Description
                                                            <sup className="error-msg"></sup>
                                                          </FormLabel>
                                                          <textarea
                                                            className="caption me-2 form-control"
                                                            type="text"
                                                            rows={4}
                                                            placeholder="Enter description"
                                                            onChange={(e) =>
                                                              setFieldValue(
                                                                `purchase_celeb_details.${index}.caption`,

                                                                e.target.value
                                                              )
                                                            }
                                                            value={field.value}
                                                            name="caption"
                                                            touched={
                                                              touched &&
                                                              touched.purchase_celeb_details &&
                                                              touched
                                                                .purchase_celeb_details[
                                                                index
                                                              ] &&
                                                              touched
                                                                .purchase_celeb_details[
                                                                index
                                                              ].caption
                                                            }
                                                          ></textarea>

                                                          <FormText className="error text-danger">
                                                            {touched &&
                                                              touched.purchase_celeb_details &&
                                                              touched
                                                                .purchase_celeb_details[
                                                                index
                                                              ] &&
                                                              touched
                                                                .purchase_celeb_details[
                                                                index
                                                              ].caption &&
                                                              errors &&
                                                              errors.purchase_celeb_details &&
                                                              errors
                                                                .purchase_celeb_details[
                                                                index
                                                              ] &&
                                                              errors
                                                                .purchase_celeb_details[
                                                                index
                                                              ].caption}
                                                          </FormText>
                                                        </FormGroup>
                                                      )}
                                                    </Field>
                                                  </div>
                                                  {/* caption ends here */}
                                                </div>
                                              </div>
                                              <div className="right-button-wrapper">
                                                {/* sample data ends herer */}
                                                <div className="d-flex align-items-center justify-content-end  mb-3 mb-lg-0  mt-0 mt-4">
                                                  {purchase_celeb_details &&
                                                    purchase_celeb_details.length >
                                                      1 && (
                                                      <div
                                                        className="add-filed remove-filed "
                                                        onClick={() =>
                                                          removeSubjectData(
                                                            arrayHelpers,
                                                            index
                                                          )
                                                        }
                                                      >
                                                        <span className="icon icon-remove mx-3">
                                                          -
                                                        </span>
                                                      </div>
                                                    )}

                                                  {purchase_celeb_details && (
                                                    <div
                                                      className="add-filed ms-2"
                                                      onClick={() =>
                                                        arrayHelpers.push({
                                                          category: "",
                                                          subCategory: "",
                                                          videoTitle: "",
                                                          caption: "",
                                                          whatsAppNumber: "",
                                                          affiliatedLink: "",
                                                          affilietedProductUrl:
                                                            "",
                                                        })
                                                      }
                                                    >
                                                      <span className="icon icon-add mx-2 ">
                                                        +
                                                      </span>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                    </div>
                                    <div className="sample-image">
                                      <span className="click-text mb-1 d-block">
                                        Click on image for zoom
                                      </span>
                                      <ReactFancyBox
                                        thumbnail="/assets/images/sample-video-small.png"
                                        image="/assets/images/sample-video-image.png"
                                      />
                                    </div>
                                  </div>

                                  <div className="text-end page-btn mt-5">
                                    <Button
                                      variant="light"
                                      className="btn-md d-inline-block br-18 mx-2"
                                      onClick={() => handleCancel()}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      variant="primary"
                                      className="btn-md d-inline-block br-18"
                                      onClick={() => handleSubmit(values)}
                                    >
                                      {/* {loading ? (
                                        <>
                                          <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                          Paying...
                                        </>
                                      ) : (
                                        
                                      )} */}
                                      Pay
                                    </Button>
                                  </div>
                                  <span className="disclamer d-block mt-4">
                                    Note: If you can't locate or find the
                                    appropriate sub-category, please contact
                                    Vista Admin at &nbsp;
                                    <a href="mailto:support@vistareels.com">
                                      support@vistareels.com
                                    </a>
                                    &nbsp; and provide the details (sub-category
                                    name and its parent category) in the email
                                    to request the addition for the same.
                                  </span>
                                </div>
                              );
                            }}
                          />
                        </FormicForm>
                      )}
                    </Formik>
                    <MyVerticallyCenteredModal
                      show={modalShow}
                      onHide={() => handleHide()}
                      onAgree={() => handleAgree()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </Layout>
  );
};

export default PurchaseCelebVideos;
