import Pagination from "rc-pagination";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";
import Select from "react-select";
import VideoCard from "../../Components/BrandVideos/VideoCard";
import { Footer, Layout } from "../../Components/Layout";
import { getUtcTime, route, showMessageNotification } from "../../Utils";

import { DatePicker } from "antd";
import FilterModel from "../../Components/Model/FilterModel";
import {
  DeletePostedVideos,
  PostedVideos,
  RemainVideos,
} from "../../Store/Actions/PublishVideo";

import swal from "sweetalert";

const { RangePicker } = DatePicker;

const PostVideo = () => {
  const defaultFilter = {
    key: "",
    type: "contains",
    value: "",
    filterType: "",
    filterOptions: [],
    valueFieldType: "date",
  };
  const dispatch = useDispatch();
  const tooltiipShow = true;
  const { categoryInfo } = useSelector((data) => data?.user);
  const { postedvideos } = useSelector((data) => data?.postedvideos);
  const { postremainvideos } = useSelector((data) => data?.postedvideos);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState();
  const [filterData, setFilterData] = useState([defaultFilter]);
  const [filterFields, setFilterFields] = useState("categoryId");
  const [currentVideo, setCurrentVideo] = useState();
  const [oldVideo, setOldVideo] = useState();
  const [category, setCategory] = useState("");
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  const [isSearchButtonDisabled, setIsSearchButtonDisabled] = useState(true);
  const history = useHistory();

  const dateFormat = "DD/MM/YYYY";
  const field = 2;

  useEffect(() => {
    categoryValue();
    dispatch(RemainVideos());
  }, []);

  /******************* 
  @Purpose :  React hook used for filter search button disable and enable
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    filterData?.forEach((data) => {
      if (data.key != "" && data.value != "") {
        setIsSearchButtonDisabled(false);
      } else {
        setIsSearchButtonDisabled(true);
      }
    });
  }, [filterData]);

  /******************* 
  @Purpose :  Used for handle fetch posted video
  @Parameter : {index}
  @Author : INIC
  ******************/
  useEffect(async () => {
    let data = {
      searchText: searchText,
      pageNumber: page,
      pageSize: pageSize,
      filter: [],
    };
    const res = await dispatch(PostedVideos(data));
    if (res.status) {
      setPage(res.pageNumber);
      setPageSize(res.pageSize);
      setTotal(res.totalVideos);
    } else {
      setPage(1);
      setPageSize(10);
      setTotal(0);
    }
  }, [page, pageSize, searchText]);

  /******************* 
  @Purpose :  used for filter select dropdown value for gender
  @Parameter : {}
  @Author : INIC
  ******************/
  const gender = [
    { value: "MALE", label: "Male" },
    { value: "FEMALE", label: "Female" },
    { value: "OTHERS", label: "Others" },
  ];

  /******************* 
  @Purpose :  used for filter select dropdown value for key
  @Parameter : {}
  @Author : INIC
  ******************/
  const filterTypeFieldValueoption = [
    { value: "date", label: "Date" },
    { value: "categoryId", label: "Category" },
  ];

  /******************* 
  @Purpose : Used for Bread crumb 
  @Parameter : {}
  @Author : Vista app
  ******************/
  const breadcrumb = [
    {
      title: "Video List",
      url: route.CELIBRITY_LIST,
    },
  ];

  /******************* 
  @Purpose :  Used for handle onchange
  @Parameter : {date,datestring}
  @Author : INIC
  ******************/
  const onChange = (date, dateString) => {
    const newStartDate = getUtcTime(dateString[0]);
    const newEndDate = getUtcTime(dateString[1]);

    const filter = {
      key: "date",
      value: {
        startDate: newStartDate,
        endDate: newEndDate,
      },
      type: "date",
    };

    setFilterData([filter]);
  };

  /******************* 
  @Purpose : Used for hide modal and clear it
  @Parameter : {}
  @Author : INIC
  ******************/
  const onHide = () => {
    setModalShow(false);
    setFilterData([defaultFilter]);
    setSelectedFilterItems([]);
  };

  /******************* 
  @Purpose :  Used for handle reset
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleReset = () => {
    let body = {
      searchText: "",
      pageNumber: 1,
      pageSize: 10,
      filter: [],
    };

    dispatch(PostedVideos(body))
      .then((res) => {
        if (res) {
          onHide();
        }
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  };

  /******************* 
  @Purpose :  used for sub category value maping
  @Parameter : {}
  @Author : INIC
  ******************/
  const subCategoryValue = () => {
    const catValue = filterData.filter((obj) => obj.key === "categoryId")[0]
      .value;
    const catData = categoryInfo.filter((obj) => obj._id === catValue)[0];
    let subCategoryArr = [];
    catData?.subCategoryDetails?.length > 0 &&
      catData?.subCategoryDetails?.forEach((subCategory) => {
        let tempObj2 = {
          label: subCategory?.title,
          value: subCategory?._id,
        };
        subCategoryArr.push(tempObj2);
      });
    return subCategoryArr;
  };

  /******************* 
  @Purpose : Used for pagination
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const paginationChange = (page, pagesize) => {
    setPage(page);
    setPageSize(pagesize);
  };

  /******************* 
  @Purpose : Used for debounce for the search
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleDebouncedChange = React.useMemo(
    () => debounce((evt) => setSearchText(evt), 500),
    []
  );

  /******************* 
  @Purpose :  Used for handle current video
  @Parameter : {index}
  @Author : INIC
  ******************/
  useEffect(() => {
    if (oldVideo) {
      setCurrentVideo(oldVideo);
    }
    if (currentVideo) {
      currentVideo.pause();
    }
  }, [oldVideo]);

  /******************* 
  @Purpose :  Used for handle play event
  @Parameter : {video}
  @Author : INIC
  ******************/
  const playEventHandler = (video) => {
    setOldVideo(video);
  };
  /******************* 
  @Purpose : Used for search on change
  @Parameter : {e}
  @Author : INIC
  ******************/
  const handleSearchChange = (e) => {
    e.persist();
    handleDebouncedChange(e.target.value);
  };

  /******************* 
  @Purpose : Used for add sub category
  @Parameter : {}
  @Author : INIC
  ******************/
  const addSubCategory = () => {
    const count = filterData.filter((obj) => obj.key === "categoryId").length;
    if (count >= 1) {
      return [
        { value: "date", label: "Date" },
        { value: "subCategoryId", label: "Sub-Category" },
      ];
    }
    return [];
  };

  /******************* 
  @Purpose :  used for category value maping
  @Parameter : {}
  @Author : INIC
  ******************/
  const categoryValue = () => {
    let categoryArr = [];
    categoryInfo?.length > 0 &&
      categoryInfo?.forEach((optiondata) => {
        let tempObj = {
          label: optiondata?.title,
          value: optiondata?._id,
          ...optiondata,
        };
        categoryArr.push(tempObj);
        setCategory(categoryArr);
      });
  };

  /******************* 
  @Purpose : Used for filter key selection and set value 
  @Parameter : {filterField, index}
  @Author : INIC
  ******************/
  const handleFilterTypeFields = (filterField, index) => {
    setFilterFields(filterField);
    const filterRow = filterData;
    filterRow[index].key = filterField;
    setFilterData([...filterRow]);
    switch (filterField) {
      case "date":
        filterRow[index].filterOptions = gender;
        setFilterData([...filterRow]);
        break;
      case "categoryId":
        filterRow[index].filterOptions = category;
        setFilterData([...filterRow]);
        addSubCategory();
        break;
      case "subCategoryId":
        const subCat = subCategoryValue();
        filterRow[index].filterOptions = subCat;
        setFilterData([...filterRow]);
        break;
    }
  };

  /******************* 
  @Purpose : Used for handle filter data
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleFilterArray = () => {
    let selected = filterData.map((item) => item.key);
    setSelectedFilterItems(selected);
  };

  /******************* 
  @Purpose :  used for set filter value
  @Parameter : {fieldValue,index}
  @Author : INIC
  ******************/
  const handleValueChange = (fieldValue, index) => {
    handleFilterArray();
    const filterRow = filterData;
    if (filterRow[index].key === "categoryId") {
      const catValue = filterData.findIndex(
        (obj) => obj.key === "subCategoryId"
      );
      if (catValue !== -1) {
        filterRow.splice(catValue, 1);
      }
    }
    filterRow[index].value = fieldValue;
    setFilterData([...filterRow]);
  };

  /******************* 
  @Purpose : Used for handle filter type field
  @Parameter : {index}
  @Author : INIC
  ******************/
  const handleFilterTypeField = (index) => {
    if (index === 0) {
      return filterTypeFieldValueoption;
    } else {
      return [
        { value: "date", label: "Date" },
        { value: "categoryId", label: "Category" },
        { value: "subCategoryId", label: "Sub-Category" },
      ];
    }
  };

  /******************* 
  @Purpose :  used for api calling for filter
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleSubmit = async () => {
    const data = filterData?.map((value) => {
      delete value.filterOptions;
      delete value.valueFieldType;
      return value;
    });

    let body = {
      searchText: searchText,
      pageNumber: page,
      pageSize: pageSize,
      filter: data,
    };

    dispatch(PostedVideos(body))
      .then((res) => {
        if (res) {
          onHide();
        }
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  };

  /******************* 
  @Purpose :  Used for render field typeds
  @Parameter : {index}
  @Author : INIC
  ******************/
  const handleFiltertags = (index) => {
    switch (filterData[index].key) {
      case "categoryId":
        return (
          <Select
            options={filterData[index].filterOptions}
            className="custom-select-container"
            classNamePrefix={"custom-select"}
            onChange={(e) => handleValueChange(e.value, index)}
          />
        );
      case "subCategoryId":
        return (
          <Select
            options={filterData[index].filterOptions}
            className="custom-select-container"
            classNamePrefix={"custom-select"}
            onChange={(e) => handleValueChange(e.value, index)}
            style={{ position: "absolute" }}
          />
        );
      case "date":
        return (
          <>
            <RangePicker
              className="rounded "
              placeholderText="dateRange"
              format={dateFormat}
              onChange={onChange}
            />
          </>
        );
      default:
        return (
          <Select
            className="custom-select-container"
            classNamePrefix="custom-select"
          />
        );
    }
  };

  /******************* 
  @Purpose :  Used for handle delete video
  @Parameter : {id}
  @Author : INIC
  ******************/
  const confirmDelete = async (id, dispatch, searchText, page, pageSize) => {
    let data = {
      videoId: id,
    };
    const res = await dispatch(DeletePostedVideos(data));
    if (res.status === 1) {
      showMessageNotification(res.message, "success");
      const body = {
        searchText: searchText,
        pageNumber: page,
        pageSize: pageSize,
        filter: data,
      };
      const response = await dispatch(PostedVideos(body));
      if (response.status) {
        setPage(response.pageNumber);
        setPageSize(response.pageSize);
        setTotal(response.totalVideos);
      } else {
        setPage(1);
        setPageSize(10);
        setTotal(0);
      }
    } else {
      showMessageNotification(res.message, "error");
    }
  };

  const handleDeleteVideo = async (id) => {
    if (id) {
      await swal({
        title: "Are you sure,You Want To Delete ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          await confirmDelete(id, dispatch, searchText, page, pageSize).catch(
            (error) => {
              console.error(error);
              throw error;
            }
          );
        }
      });
    }
  };

  /******************* 
  @Purpose :  Used for handleEditPostedvideo
  @Parameter : {id}
  @Author : INIC
  ******************/
  const editPostedVideo = (id) => {
    history.push(`/editpostedvideo/${id}`);
  };

  /******************* 
  @Purpose :  Used for handle publish video
  @Parameter : {}
  @Author : INIC
  ******************/
  const handlePublishVideo = () => {
    if (
      postremainvideos?.videoPostingLimitPerMonth -
        postremainvideos?.noOfVideosPostedThisMonth ===
      0
    ) {
      showMessageNotification(
        "You have reached your video publishing limit for this month. Please try again next month.",
        "error"
      );
    } else {
      history.push("/publishvideo");
    }
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Post A Videos">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <div className="row mb-5">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header pb-0">
                    <div className="user-listing-filterOptions d-flex flex-wrap justify-content-between align-items-center">
                      <div className="d-flex flex-wrap  me-3 mb-3">
                        <div className="search-input-wrapper position-relative me-2 mb-1">
                          <i className="icon-magnifier"></i>{" "}
                          <input
                            type="search"
                            className="form-control text-capitalize"
                            placeholder="search"
                            onChange={(e) => handleSearchChange(e)}
                          />
                        </div>
                        <Button
                          variant="primary"
                          active
                          className="btn-bordered position-relative me-2 mb-1 d-flex align-items-center justify-content-center"
                          onClick={() => setModalShow(true)}
                        >
                          <i className="bx bx-filter-alt align-text-top me-1"></i>
                          Filter
                        </Button>
                        {postedvideos?.data?.length !== 0 && (
                          <Button className="mb-1" onClick={handlePublishVideo}>
                            Publish Video
                          </Button>
                        )}
                      </div>

                      <div className="text-end mb-3 ms-auto">
                        {console.log(
                          "postremainvideos?.noOfVideosPostedThisMonth",
                          total,
                          postremainvideos?.noOfVideosPostedThisMonth
                        )}
                        {/* <span class="badge-md danger-light me-2 mb-1">
                          Deleted Videos :{" "}
                          {postremainvideos?.noOfVideosPostedThisMonth
                            ? total -
                              postremainvideos?.noOfVideosPostedThisMonth
                            : 0}
                        </span> */}
                        <span class="badge-md warning-light me-2 mb-1">
                          Remain Videos :{" "}
                          {postremainvideos?.videoPostingLimitPerMonth
                            ? postremainvideos?.videoPostingLimitPerMonth -
                              postremainvideos?.noOfVideosPostedThisMonth
                            : 0}
                        </span>
                        <span class="badge-md success-light mb-1">
                          Posted Videos :{" "}
                          {postremainvideos?.noOfVideosPostedThisMonth
                            ? postremainvideos?.noOfVideosPostedThisMonth
                            : 0}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <div className="row video-list">
                      {postedvideos?.data?.length > 0 ? (
                        postedvideos?.data?.map((data, id) => (
                          <div
                            className="col-lg-4 col-xl-3 col-md-4 col-12"
                            key={data.id}
                          >
                            <VideoCard
                              link={`${route.PUBLISH_VIDEO_DETAIL}/${data?._id}`}
                              caption={data?.videoTitle}
                              createdAt={data?.createdAt}
                              videoLink={data?.videoLink}
                              celebrityDetails={data?.celebrityDetails}
                              viewCount={data?.viewCount}
                              productViews={data?.affiliateClickCount}
                              playEventHandler={playEventHandler}
                              currentVideo={currentVideo}
                              isActionMenuActive={true}
                              id={data?._id}
                              handleDelete={handleDeleteVideo}
                              handleEdit={editPostedVideo}
                              tooltiipShow={tooltiipShow}
                            />
                          </div>
                        ))
                      ) : (
                        <div className="col-12 text-center">
                          <h6 className="text-danger mt-5">Videos Not Found</h6>
                          <Button className="mb-1" onClick={handlePublishVideo}>
                            Publish Video
                          </Button>
                        </div>
                      )}
                    </div>

                    <div className="table-footer d-flex flex-wrap align-items-center justify-content-between">
                      <p className="mb-0 align-self-center text-primary-light fs-14">
                        Showing <b>{postedvideos?.data?.length ?? 0}</b> Out of
                        <b> {total ?? 0}</b>
                      </p>
                      <div className="pagination-list ms-2">
                        <Pagination
                          className="ant-pagination"
                          pageSize={pageSize}
                          current={page}
                          total={total}
                          onChange={paginationChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>

        <FilterModel
          show={modalShow}
          filterData={filterData}
          setFilterData={setFilterData}
          defaultFilter={defaultFilter}
          filterTypeField={handleFilterTypeField}
          handleFilterTypeFields={handleFilterTypeFields}
          handleValueChange={handleValueChange}
          handleSubmit={handleSubmit}
          handleFiltertags={handleFiltertags}
          handleFilterArray={handleFilterArray}
          selectedFilterItems={selectedFilterItems}
          isSearchButtonDisabled={isSearchButtonDisabled}
          onHide={onHide}
          handleReset={handleReset}
          filterFields={filterFields}
          field={field}
        />
      </div>
    </Layout>
  );
};

export default PostVideo;
