export const route = {
  DASHBOARD: "/dashboard",
  PROFILE: "/profile",
  REPORT: "/report",
  MANAGE_VIDEO_ADD: "/ManageVideoAd",
  MANAGE_POST_VIDEO: "/postvideo",
  PURCHASE_VIDEO_ADD: "/PurchaseVideoAd",
  BRAND_ACCOUNT: "/brandaccount",
  SELECT_PLAN: "/selectplan",
  BRAND_PAYOUT: "/brandpayout",
  CELEBRITY: "/celebrityList",
  VIDEO_APPROVAL_QUEUE: "/videoapprovalqueue",
  BRAND_VIDEOS: "/BrandVideos",
  MANAGE_VIDEO_AD: "/ManageVideoAd",
  VIDEO_INFO: "/VideoInfo",
  SET_PASSWORD: "/resetpassword",
  FORGOT_PASSWORD: "/forgotpassword",
  EDIT_BRAND: "/editbrand",
  SELECT_DURATION: "/SelectDuration",
  MANAGE_PASSWORD: "/ManagePassword",
  SIGNUP: "/SignUp",
  SIGNIN: "/",
  CELIBRITY_LIST: "/celebrityList",
  VIDEO_QUEUE: "/videoapprovalqueue",
  PURCHASE_HISTORY: "/purchaseHistory",
  VIDEO_DETAIL: "/videodetail",
  PUBLISH_VIDEO_DETAIL: "/publishvideodetail",
  PRIVACY_POLICY: "/privacy-policy",
};
