import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Pagination from "rc-pagination";
import Select from "react-select";
import debounce from "lodash.debounce";
import moment from "moment";
import FilterModel from "../../Components/Model/FilterModel";
import { DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getDownloadReports,
  getReportsdata,
} from "../../Store/Actions/Reports";
import { Footer } from "../../Components/Layout";
import { LIST_OF_REPORTS, route } from "../../Utils";
import Layout from "../../Components/Layout/Layout";
import {
  getUtcTime,
  showMessageNotification,
  showModalNotification,
} from "../../Utils/Functions";
import { getCategories } from "../../Store/Actions/Categories";
/******************* 
@Purpose : Used for Report 
@Parameter : {}
@Author : INIC
******************/
const Report = () => {
  const defaultFilter = {
    key: "",
    type: "contains",
    value: "",
    filterOptions: [],
  };
  const dispatch = useDispatch();

  const [searchText, setSearchtext] = useState("");
  const { categoryInfo } = useSelector((data) => data.user);
  const [category, setCategory] = useState("");
  const [filterFields, setFilterFields] = useState("categoryId");
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [downloadSelect, setDownloadSelect] = useState("");
  const [loading, setLoading] = useState(false);
  const [request, setRequests] = useState([]);
  const [filterData, setFilterData] = useState([defaultFilter]);
  const [modalShow, setModalShow] = useState(false);
  const [isSearchButtonDisabled, setIsSearchButtonDisabled] = useState(true);
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);
  const [celebFilter, setCelebFilter] = useState([]);

  const dateFormat = "DD/MM/YYYY";
  const { RangePicker } = DatePicker;

  /******************* 
  @Purpose : Used for handle filter type field
  @Parameter : {index}
  @Author : INIC
  ******************/
  const handleFilterTypeField = (index) => {
    if (index === 0) {
      return filterTypeFieldValueoption;
    } else {
      return [
        { value: "date", label: "Date" },
        { value: "categoryId", label: "Category" },
      ];
    }
  };

  /******************* 
  @Purpose : Used for handle date change
  @Parameter : {date, dateString}
  @Author : INIC
  ******************/
  function onChange(date, dateString) {
    const newStartDate = getUtcTime(dateString[0]);
    const newEndDate = getUtcTime(dateString[1]);

    const filter = {
      key: "date",
      value: {
        startDate: newStartDate,
        endDate: newEndDate,
      },
      type: "date",
    };

    setFilterData([filter]);
  }

  /******************* 
  @Purpose :  used for category value maping
  @Parameter : {}
  @Author : INIC
  ******************/
  const categoryValue = () => {
    let categoryArr = [];
    categoryInfo?.length > 0 &&
      categoryInfo?.forEach((optiondata) => {
        let tempObj = {
          label: optiondata?.title,
          value: optiondata?._id,
          ...optiondata,
        };
        categoryArr.push(tempObj);
        setCategory(categoryArr);
      });
  };

  /******************* 
  @Purpose :  used for set filter value
  @Parameter : {fieldValue,index}
  @Author : INIC
  ******************/
  const handleValueChange = (fieldValue, index) => {
    handleFilterArray();
    const filterRow = filterData;
    if (filterRow[index].key === "categoryId") {
      const catValue = filterData.findIndex(
        (obj) => obj.key === "subCategoryId"
      );
      if (catValue !== -1) {
        filterRow.splice(catValue, 1);
      }
    }
    filterRow[index].value = fieldValue;
    setFilterData([...filterRow]);
  };

  /******************* 
  @Purpose :  Used for render field typeds
  @Parameter : {index}
  @Author : INIC
  ******************/
  const handleFiltertags = (index) => {
    switch (filterData[index].key) {
      case "categoryId":
        return (
          <Select
            options={filterData[index].filterOptions}
            className="custom-select-container"
            classNamePrefix={"custom-select"}
            onChange={(e) => handleValueChange(e.value, index)}
          />
        );

      case "date":
        return (
          <>
            <RangePicker
              className="rounded "
              placeholderText="dateRange"
              format={dateFormat}
              onChange={onChange}
            />
          </>
        );
      default:
        return (
          <Select
            className="custom-select-container"
            classNamePrefix={"custom-select"}
          />
        );
    }
  };

  /******************* 
  @Purpose :  used for api calling for filter
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleSubmit = async () => {
    let body = {
      searchText: "",
      pageNumber: page,
      pageSize: pageSize,
      filter: filterData,
    };

    const response = await dispatch(getReportsdata(body));
    if (response) {
      setCelebFilter(filterData);
      setModalShow(false);
      setFilterData([defaultFilter]);
      setSelectedFilterItems([]);
      setRequests(response?.data);
      setPage(response?.pageNumber);
      setTotal(response?.totalNoOfOrders);
    } else if (response.status === 0) {
      showModalNotification("No data was found", "error");
    }
  };

  /******************* 
  @Purpose : Used for handle filter data
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleFilterArray = () => {
    let selected = filterData.map((item) => item.key);

    setSelectedFilterItems(selected);
  };

  /******************* 
  @Purpose :  used for filter select dropdown value for key
  @Parameter : {}
  @Author : INIC
  ******************/
  const filterTypeFieldValueoption = [
    { value: "date", label: "Date" },
    { value: "categoryId", label: "Category" },
  ];

  /******************* 
  @Purpose : Used for Bread crumb
  @Parameter : {}
  @Author : INIC
  ******************/
  const breadcrumb = [
    {
      title: LIST_OF_REPORTS,
      url: route.REPORT,
    },
  ];

  /******************* 
  @Purpose :  used for filter select dropdown value for gender
  @Parameter : {}
  @Author : INIC
  ******************/
  const gender = [
    { value: "MALE", label: "Male" },
    { value: "FEMALE", label: "Female" },
  ];

  /******************* 
  @Purpose : Used for filter key selection and set value 
  @Parameter : {filterField, index}
  @Author : INIC
  ******************/
  const handleFilterTypeFields = (filterField, index) => {
    setFilterFields(filterField);
    const filterRow = filterData;
    filterRow[index].key = filterField;
    setFilterData([...filterRow]);
    switch (filterField) {
      case "date":
        filterRow[index].filterOptions = gender;
        setFilterData([...filterRow]);
        break;
      case "categoryId":
        filterRow[index].filterOptions = category;
        setFilterData([...filterRow]);
        break;
    }
  };

  /******************* 
  @Purpose : Used for pagination
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const paginationChange = (page, pagesize) => {
    setPage(page);
    setPageSize(pagesize);
    celebrities(page, pageSize);
  };

  /******************* 
  @Purpose : Used for reset
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleReset = async () => {
    let body = {
      pageNumber: 1,
      pageSize: 10,
      searchText: "",
      filter: [],
    };
    const response = await dispatch(getReportsdata(body));
    if (response.status === 1) {
      setPage(1);
      setRequests(response?.data);
      setTotal(response?.totalNoOfOrders);
      setCelebFilter([]);
      onHide();
    }
  };

  /******************* 
  @Purpose : Used for celebrities list
  @Parameter : {}
  @Author : INIC
  ******************/
  const celebrities = async (
    pageprops = page,
    pageSizeprops = pageSize,
    searchTextprops = searchText,
    filterprops = celebFilter
  ) => {
    let body = {
      pageNumber: pageprops,
      pageSize: pageSizeprops,
      searchText: searchTextprops ?? "",
      filter: filterprops || [],
    };
    const response = await dispatch(getReportsdata(body));
    if (response.status === 1) {
      setRequests(response?.data);
      setPage(response?.pageNumber);
      setTotal(response?.totalNoOfOrders);
    }
  };
  const celebrities1 = async () => {
    setFilterData([defaultFilter]);
    let body = {
      pageNumber: 1,
      pageSize: 10,
      searchText: "",
      filter: [],
    };
    const response = await dispatch(getReportsdata(body));
    if (response.status === 1) {
      onHide();

      setRequests(response?.data);

      setTotal(response?.totalNoOfOrders);
    }
  };

  /******************* 
  @Purpose : Used for download options
  @Parameter : {}
  @Author : INIC
  ******************/
  const options = [
    { value: "Excel", label: "Excel" },
    { value: "CSV", label: "CSV" },
  ];

  /******************* 
  @Purpose : Used for download apis
  @Parameter : {e}
  @Author : INIC
  ******************/
  const handleDownload = async (e) => {
    setDownloadSelect(e);
    let requiredDownload = e.value;

    if (requiredDownload === "Excel") {
      let body = {
        format: "xls",
      };

      try {
        const response = await dispatch(getDownloadReports(body));
        if (response.status === 1) {
          window.open(response.data);

          showMessageNotification(response?.message, "success");
        } else {
          showMessageNotification(response?.message, "error");
        }
      } catch (error) {
        setLoading(false);
        throw error;
      }
    }
    if (requiredDownload === "CSV") {
      let body = {
        format: "csv",
      };
      try {
        const response = await dispatch(getDownloadReports(body));
        if (response.status === 1) {
          window.open(response.data);

          showMessageNotification(response?.message, "success");
        } else {
          showMessageNotification(response?.message, "error");
        }
      } catch (error) {
        setLoading(false);
        throw error;
      }
    }
  };

  /******************* 
  @Purpose : Used for search on change
  @Parameter : {e}
  @Author : INIC
  ******************/
  const handleSearchChange = (e) => {
    e.persist();
    setSearchtext(e.target.value);
    handleDebouncedChange(e.target.value);
  };

  /******************* 
  @Purpose : Used for debounce for the search
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleDebouncedChange = React.useMemo(
    () => debounce((evt) => searchField(evt), 1000),
    []
  );

  /******************* 
  @Purpose :  React hook used for get categories and sub categories
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    dispatch(getCategories());
    categoryValue();
    celebrities();
  }, []);

  /******************* 
  @Purpose :  React hook used for filter search button disable and enable
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    filterData?.forEach((data) => {
      if (data.key != "" && data.value != "") {
        setIsSearchButtonDisabled(false);
      } else {
        setIsSearchButtonDisabled(true);
      }
    });
  }, [filterData]);

  /******************* 
  @Purpose : Used for search fields
  @Parameter : {evtKey}
  @Author : INIC
  ******************/
  const searchField = async (evtKey) => {
    try {
      if (evtKey) {
        let body = {
          searchText: evtKey,
          pageNumber: page,
          pageSize: pageSize,
          filter: [],
        };
        const response = await dispatch(getReportsdata(body));
        if (response.status === 1) {
          setRequests(response?.data);
          setPage(1);
          setPageSize(10);

          setTotal(response?.totalNoOfOrders);
        } else if (response.status === 0) {
          showMessageNotification(response.message, "error");
        }
      }
    } catch (error) {
      console.error();
    }
  };

  /******************* 
  @Purpose : Used for clear all form field value
  @Parameter : {}
  @Author : INIC
  ******************/
  const clearInput = () => {
    setSearchtext("");
    celebrities1();
    setPage(1);
  };

  /******************* 
  @Purpose : Used for hide modal and clear it
  @Parameter : {}
  @Author : INIC
  ******************/
  const onHide = () => {
    setModalShow(false);
    setFilterData([defaultFilter]);
    setSelectedFilterItems([]);
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Reports">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <div className="card">
              <div className="card-header d-flex justify-content-between pb-0">
                <div className="search-filter-content d-flex">
                  <div className="search-input-wrapper position-relative d-inline-block align-top">
                    <i className="icon-magnifier"></i>{" "}
                    <input
                      type="search"
                      value={searchText}
                      className="form-control text-capitalize"
                      placeholder="search"
                      onChange={(evt) => handleSearchChange(evt)}
                    />
                    {searchText && searchText.length > 0 ? (
                      <div className="clear">
                        <span onClick={clearInput}>
                          <em
                            className="icon-close"
                            style={{ left: "-20px", top: "8px" }}
                          ></em>
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <Button
                    variant="primary"
                    active
                    className="btn-bordered ms-2 d-flex align-items-center justify-content-center btn"
                    onClick={() => setModalShow(true)}
                  >
                    <i className="bx bx-filter-alt align-text-top me-1"></i>
                    Filter
                  </Button>
                </div>
                <div style={{ width: "150px" }}>
                  <Select
                    id="download"
                    name="download"
                    options={options}
                    placeholder="Download"
                    onChange={(e) => {
                      handleDownload(e);
                    }}
                    value={downloadSelect}
                    className="download-csv"
                  />
                </div>
              </div>
              <div className="card-body">
                <div className="search-label">
                  <div className="multiple-text">
                    <React.Fragment>
                      <div className="text-label mr-2">
                        <label className="mb-0">
                          <span className="text-success"></span>
                        </label>
                      </div>
                    </React.Fragment>
                  </div>
                </div>
                <div className="table-responsive  mb-3 reports-table">
                  <table
                    className="table row-border nowrap common-datatable  col-wrap-profile"
                    id="user-listing"
                  >
                    <thead>
                      <tr>
                        <th>
                          <b>Transaction#</b>
                        </th>

                        <th>
                          <b>Celebrity </b>
                        </th>

                        <th>
                          <b>Category</b>
                        </th>
                        <th>
                          <b>Total Video </b>
                          <span className="fs-10 ">
                            (Including Vista Charges)
                          </span>
                        </th>
                        <th>
                          <b>Remaining Video</b>
                        </th>
                        <th>
                          <b>Price per Video</b>
                        </th>
                        <th>
                          <b>Total Paid</b>
                        </th>
                        <th className="all">
                          <b>Purchased Date & Time</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {request && request.length === 0 ? (
                        <tr className="text-center text-danger not-found-txt">
                          <td colSpan="6">
                            {/* {!loading ? ( */}
                            <h6
                              className="text-center text-danger not-found-txt"
                              colSpan="6"
                            ></h6>
                            {/* ) : null} */}
                          </td>
                        </tr>
                      ) : (
                        request &&
                        Array.isArray(request) &&
                        request.map((user) => {
                          return (
                            <tr key={user._id}>
                              <td>{user?.transactionId}</td>

                              <td>
                                <div className="email-col d-flex align-items-center">
                                  <span className="avtar-md">
                                    <img
                                      src={
                                        user?.creatorDetails[0]
                                          ?.creatorProfilephoto
                                          ? user?.creatorDetails[0]
                                              ?.creatorProfilephoto
                                          : "/assets/images/no-user.png"
                                      }
                                    />
                                  </span>

                                  <span className="ps-3">
                                    {user?.creatorDetails[0]?.firstname}&nbsp;
                                    {user?.creatorDetails[0]?.lastname}
                                  </span>
                                </div>
                              </td>

                              <td>
                                <div className="eamil-col d-flex align-items-center">
                                  <span>{user?.category[0]?.title}</span>
                                </div>
                              </td>

                              <td>
                                <div className="eamil-col d-flex align-items-center">
                                  <span>{user?.noOfVideosOrdered}</span>
                                </div>
                              </td>

                              <td>
                                <div className="eamil-col d-flex align-items-center">
                                  <span>
                                    {user?.noOfVideosOrdered -
                                      user?.noOfVideoPublished}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="custom-control custom-switch light">
                                  {user?.creatorDetails[0]?.pricePerVideo}
                                  <label
                                    className="custom-control-label"
                                    for={user?._id}
                                  ></label>
                                </div>
                              </td>
                              <td>
                                <div className="custom-control custom-switch light">
                                  {user?.price}
                                  <label
                                    className="custom-control-label"
                                    for={user?._id}
                                  ></label>
                                </div>
                              </td>
                              <td>
                                <div className="custom-control custom-switch light">
                                  {moment(user?.createdAt).format(
                                    "l, h:mm:ss a"
                                  )}

                                  <label
                                    className="custom-control-label"
                                    for={user?._id}
                                  ></label>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="table-footer d-flex flex-wrap align-items-end justify-content-between ">
                  <p className="mb-0 align-self-center  text-primary-light fs-14"></p>
                  <div className="pagination-list ms-1">
                    <Pagination
                      className="ant-pagination"
                      pageSize={pageSize}
                      current={page}
                      total={total}
                      onChange={paginationChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <FilterModel
        show={modalShow}
        filterData={filterData}
        setFilterData={setFilterData}
        defaultFilter={defaultFilter}
        filterTypeField={handleFilterTypeField}
        handleFilterTypeFields={handleFilterTypeFields}
        handleValueChange={handleValueChange}
        handleSubmit={handleSubmit}
        handleFiltertags={handleFiltertags}
        handleFilterArray={handleFilterArray}
        selectedFilterItems={selectedFilterItems}
        isSearchButtonDisabled={isSearchButtonDisabled}
        onHide={onHide}
        filterFields={filterFields}
        handleReset={handleReset}

        // handleReset={celebrities1}
      />
    </Layout>
  );
};

export default Report;
