import React from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";

/*******************
@purpose : used for card info
@Parameter : {}
@Author : INIC
******************/
function VideoCardData() {
  const { celebvideoinfo } = useSelector((data) => data.brandvideos);

  /*******************
  @purpose : used for dashboard cards
  @Parameter : {}
  @Author : INIC
  ******************/
  const cardData = [
    {
      id: "video_views",
      name: "Video Views",
      count: celebvideoinfo?.[0]?.viewCount ?? 0,
    },
    {
      id: "video_tags",
      name: "Product Clicks",
      count: celebvideoinfo?.[0]?.affiliateClickCount ?? 0,
    },
    { id: "total_purchase", name: "Total Purchase", count: 0 },
  ];

  /*******************
  @purpose : used for dashboard card icon
  @Parameter : {}
  @Author : INIC
  ******************/
  const cardIcon = {
    video_tags: "bx-video-recording",
    video_views: "bx-show",
    total_purchase: "bx-rupee",
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (

        <Row className="g-5">
          {cardData.map((value) => (
            <Col xs={12} sm={6} xl={4} xxl={4} key={value?.id}>
              <div className="user-info-card col d-flex flex-wrap align-items-center p-3">
                <div className="d-flex align-items-center">
                  <div className="icon-box-wrap">
                    <i className={`text-orange bx ${cardIcon[value?.id]}`}></i>
                  </div>
                  <div className="w-100 font-32">
                    <h3 className="text-black m-0 fw-bold">{value?.count}</h3>
                    <h6 className="text-orange mb-0 pt-1">{value?.name}</h6>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>

  );
}

export default VideoCardData;
