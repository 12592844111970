import { callApi } from "../../Api";
import Routes from "../../Api/Routes"; // API endpoint routes
import { showMessageNotification } from "../../Utils";

const { ADD_BRAND } = Routes;

/*******************
@purpose :  send payment detail
@Parameter : {data}
@Author : INIC
******************/
export const AddBrand = (data) => async () => {
  const res = await callApi(ADD_BRAND, data, "post", "");
  if (res.data) {
    showMessageNotification(res.message, "success");
    return Promise.resolve(res);
  } else showMessageNotification(res.message, "error");
  return Promise.reject(res);
};
