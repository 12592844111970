import React, { useState } from "react";
import { validatePassword } from "../../Utils/Functions";
import errorMessages from "../../Utils/ErrorMessages";
import { changePassword } from "../../Store/Actions/User";
import { Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";

/******************* 
@Purpose : Used for profile view
@Parameter : {}
@Author : INIC
******************/
function ManagePassword() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [hiddenNewPassword, setHiddenNewPassword] = useState(true);
  const [hiddenOldPassword, setHiddenOldPassword] = useState(true);
  const [hiddenConfirmPassword, setHiddenConfirmPassword] = useState(true);
  const [changeErrors, setChangeErrors] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  /******************* 
  @Purpose : Used for form validation password
  @Parameter : {}
  @Author : INIC
  ******************/
  const validPasswords = () => {
  

    let changeError = { newPassword: "", confirmPassword: "", oldPassword: "" };
    let isFormValidPassword = true;

    if (!oldPassword.trim())
      changeError.oldPassword = errorMessages.PROVIDE_OLD_PASSWORD;
    else if (!validatePassword(oldPassword))
      changeError.oldPassword = errorMessages.PROVIDE_VALID_PASSWORD;
    else changeError.oldPassword = "";

    if (!newPassword.trim())
      changeError.newPassword = errorMessages.PROVIDE_NEW_PASSWORD;
    else if (!validatePassword(newPassword))
      changeError.newPassword = errorMessages.PROVIDE_VALID_PASSWORD;
    else if (newPassword.length < 8)
      changeError.newPassword = errorMessages.PROVIDE_VALID_PASSWORD;
    else changeError.newPassword = "";
    if (!confirmPassword.trim())
      changeError.confirmPassword = errorMessages.PROVIDE_CON_PASSWORD;
    else if (!validatePassword(confirmPassword))
      changeError.confirmPassword = errorMessages.PROVIDE_VALID_PASSWORD;
    else changeError.confirmPassword = "";
    if (newPassword !== confirmPassword)
      changeError.confirmPassword = errorMessages.PASSWORD_NOT_MATCHED;
    if (changeError.newPassword !== "" || changeError.confirmPassword !== "")
      isFormValidPassword = false;

    setChangeErrors(changeError);
    return isFormValidPassword;
  };

  /******************* 
  @Purpose : Used for update password
  @Parameter : {}
  @Author : INIC
  ******************/
  const updatePassword = async (evt) => {
    evt.preventDefault();
    let data = {
      oldPassword: oldPassword,
      newPassword: confirmPassword,
    };

    if (validPasswords()) {
      setLoading(true);
      dispatch(changePassword(data))
        .then((response) => {
          if (response.status) {
            setConfirmPassword("");
            setNewPassword("");
            setOldPassword("");
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    }
  };

  /******************* 
  @Purpose : Used for rander tooltip
  @Parameter : event
  @Author : INIC
  ******************/
  const renderTooltip = (tooltipProps) =>
    !hiddenConfirmPassword ? (
      <Tooltip id="button-tooltip" {...tooltipProps}>
        Hide Password
      </Tooltip>
    ) : (
      <Tooltip id="button-tooltip" {...tooltipProps}>
        Show Password
      </Tooltip>
    );

  /******************* 
  @Purpose : Used for rander tooltip
  @Parameter : event
  @Author : INIC
  ******************/
  const renderTooltip1 = (tooltipProps) =>
    !hiddenOldPassword ? (
      <Tooltip id="button-tooltip" {...tooltipProps}>
        Hide Password
      </Tooltip>
    ) : (
      <Tooltip id="button-tooltip" {...tooltipProps}>
        Show Password
      </Tooltip>
    );

  /******************* 
  @Purpose : Used for rander tooltip
  @Parameter : event
  @Author : INIC
  ******************/
  const renderTooltip2 = (tooltipProps) =>
    !hiddenNewPassword ? (
      <Tooltip id="button-tooltip" {...tooltipProps}>
        Hide Password
      </Tooltip>
    ) : (
      <Tooltip id="button-tooltip" {...tooltipProps}>
        Show Password
      </Tooltip>
    );
  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title">Change Password</h5>
      </div>
      <div className="card-body">
        <div className="row mb-4">
          <div className="col-lg-4">
            <div className="form-group">
              <label className="form-label" htmlFor="Old Password">
                Old Password<span className="text-danger">*</span>
              </label>
              <div className="w-100">
                <input
                  type={hiddenOldPassword ? "password" : "text"}
                  className="form-control"
                  value={oldPassword}
                  name=" Old Password"
                  placeholder="Enter old password"
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                    let changeError = Object.assign(changeErrors, {
                      OldPassword: "",
                    });
                    setChangeErrors(changeError);
                  }}
                />
                <OverlayTrigger
                  placement="top"
                  delay={{
                    show: 250,
                    hide: 400,
                  }}
                  overlay={renderTooltip1}
                >
                  <span
                    onClick={() => setHiddenOldPassword(!hiddenOldPassword)}
                    toggle="#password-field"
                    className={
                      hiddenOldPassword
                        ? "bx bx-hide field-icon toggle-password"
                        : "bx bx-show field-icon toggle-password"
                    }
                  ></span>
                </OverlayTrigger>
                <span className="text-danger d-block error-msg">
                  {changeErrors.oldPassword}
                </span>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="form-group">
              <label className="form-label" htmlFor="New Password">
                New Password<span className="text-danger">*</span>
              </label>
              <div className="w-100">
                <input
                  type={hiddenNewPassword ? "password" : "text"}
                  className="form-control"
                  value={newPassword}
                  placeholder="Enter new password"
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    let changeError = Object.assign(changeErrors, {
                      newPassword: "",
                    });
                    setChangeErrors(changeError);
                  }}
                />
                <OverlayTrigger
                  placement="top"
                  delay={{
                    show: 250,
                    hide: 400,
                  }}
                  overlay={renderTooltip2}
                >
                  <span
                    onClick={() => setHiddenNewPassword(!hiddenNewPassword)}
                    toggle="#password-field"
                    className={
                      hiddenNewPassword
                        ? "bx bx-hide field-icon toggle-password"
                        : "bx bx-show field-icon toggle-password"
                    }
                  ></span>
                </OverlayTrigger>
                <span className="text-danger d-block error-msg">
                  {changeErrors.newPassword}
                </span>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="form-group">
              <label className="form-label" htmlFor="Confirm Password">
                Confirm Password<span className="text-danger">*</span>
              </label>
              <div className="w-100">
                <input
                  type={hiddenConfirmPassword ? "password" : "text"}
                  className="form-control"
                  id="confirmpassword"
                  name="confirmPassword"
                  value={confirmPassword}
                  placeholder="Enter confirm password"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    let changeError = Object.assign(changeErrors, {
                      confirmPassword: "",
                    });
                    setChangeErrors(changeError);
                  }}
                />
                <OverlayTrigger
                  placement="top"
                  delay={{
                    show: 250,
                    hide: 400,
                  }}
                  overlay={renderTooltip}
                >
                  <span
                    onClick={() =>
                      setHiddenConfirmPassword(!hiddenConfirmPassword)
                    }
                    toggle="#password-field"
                    className={
                      hiddenConfirmPassword
                        ? "bx bx-hide field-icon toggle-password"
                        : "bx bx-show field-icon toggle-password"
                    }
                  ></span>
                </OverlayTrigger>
                <span className="text-danger d-block error-msg">
                  {changeErrors.confirmPassword}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12 text-end">
          <Button
            className="me-2"
            variant="primary"
            onClick={(evt) => updatePassword(evt)}
            disabled={loading}
          >
            {loading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>{" "}
                Saving...
              </>
            ) : (
              "Save Changes"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ManagePassword;
