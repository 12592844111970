import { Link, useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import moment from "moment/moment";

import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

/******************* 
@Purpose : Used for video card
@Parameter : {props}
@Author : INIC
******************/
function VideoCard(props) {
  const history = useHistory();
  const { isActionMenuActive = false } = props;
  useEffect(() => {
    const video = document.getElementById(props.caption);
    const source = document.getElementById(`${props.caption}-source`);

    video.load();
    source.setAttribute("src", props.videoLink);
    video.onplay = function () {
      props.playEventHandler(video);
    };
  }, [props?.videoLink]);
  // button click
  const handleButtonClick = () => {
    if (props.link) {
      history.push(props.link);
    }
  };

  return (
    <div className="video-card">
      <div className="video-img position-relative ">
        {props.tooltiipShow == true ? (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>Click here to open video details</Tooltip>}
          >
            <Link to={props.link}>
              <video
                width="100%;"
                // className="border border-secondary border-1 video-box"
                height="250"
                id={props.caption}
                controls
              >
                <source
                  src={props.videoLink}
                  id={`${props.caption}-source`}
                  type="video/mp4"
                ></source>
              </video>
            </Link>
          </OverlayTrigger>
        ) : (
          <Link to={props.link}>
            <video
              width="100%;"
              // className="border border-secondary border-1 video-box"
              height="250"
              id={props.caption}
              controls
            >
              <source
                src={props.videoLink}
                id={`${props.caption}-source`}
                type="video/mp4"
              ></source>
            </video>
          </Link>
        )}
      </div>

      <div className="video-info">
        <h6 className="video-title fw-semibold text-secondary">{props.caption}</h6>
        <p className="video-date mb-1">
          Published on {moment(props.createdAt).format("l, h:mm:ss a")}
        </p>

        {props?.celebrityDetails?.length > 0 ? (
          props?.celebrityDetails?.map((celebrity) => (
            <p
              className="video-title-name text-secondary mb-1"
              key={celebrity.id}
            >
              {`By - ${celebrity?.firstname} ${celebrity?.lastname}`}
            </p>
          ))
        ) : (
          <p className="video-title-name text-secondary mb-1">
            By - Brand
          </p>
        )}
        <div className="d-flex flex-wrap">
          <div className="text-orange fs-14 me-3">
            <span className="fw-semibold">Views : </span> {props?.viewCount}
          </div>
          <div className="text-orange fs-14">
            <span className="fw-semibold">Product Click : </span>{" "}
            {props?.productViews}
          </div>
        </div>
        <div className="d-block video-btns text-end">
          {props?.showBtn ? (
            <button
              onClick={handleButtonClick}
              className="btn btn-primary cnt-btn mt-3 w-100"
            >
              Contact
            </button>
          ) : null}

          {isActionMenuActive && (
            <div className="justify-content-end  mt-2">
              <a className="approval-status" style={{ fontSize: "16px" }}>
                <OverlayTrigger
                  placement="left"
                  overlay={<Tooltip id="Approve">Click here to Edit</Tooltip>}
                >
                  <i
                    className="icon-pencil me-2"
                    onClick={() => props.handleEdit(props?.id)}
                  ></i>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="left"
                  overlay={<Tooltip id="Reject">Click here to Delete</Tooltip>}
                >
                  <i
                    className="icon-trash "
                    onClick={() => props.handleDelete(props?.id)}
                  ></i>
                </OverlayTrigger>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default VideoCard;
