/*******************
@purpose :  set tabs
@Parameter : {data}
@Author : INIC
******************/
export const setTabsValue = (data) => ({
  type: "SET_TABS",
  payload: data,
});
/*******************
@purpose :  set agree
@Parameter : {data}
@Author : INIC
******************/
export const setAgreeValue = (data) => ({
  type: "SET_AGREE",
  payload: data,
});
