import React, { useState, useRef, useEffect } from "react";
import { State, City } from "country-state-city";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";

import { saveBrandProfile, Uploadimages } from "../../Store/Actions/User";
import { PROFILE_DETAILS, route, showMessageNotification } from "../../Utils";
import Layout from "../../Components/Layout/Layout";
import errorMessages from "../../Utils/ErrorMessages";
import { Footer } from "../../Components/Layout";

/******************* 
@Purpose : Used for Create Brand Account
@Parameter : {props}
@Author : INIC
******************/
const BrandAccount = (props) => {
  const formData1 = new FormData();
  const profileData = useSelector((user) => user.user?.userProfile);
  const dispatch = useDispatch();
  const uploadedImage = useRef(null);
  const imageUploader = useRef(null);
  const [state, setState] = useState("");
  const [firstName, setFirstName] = useState("" || profileData.firstName);
  const [lastName, setLastName] = useState("" || profileData.lastName);
  const [gstNumber, setgstNumber] = useState("" || profileData?.gstNumber);
  let emailId = "" || profileData.emailId;
  const [organization, setOrganization] = useState(
    false || profileData.organization
  );
  const [mobileNumber, setMobileNumber] = useState(
    "" || profileData.mobileNumber
  );
  const [city, setCity] = useState();
  const [stateCode, setStateCode] = useState("");
  const [pin, setPin] = useState("" || profileData.pin);
  const [address, setAddress] = useState("" || profileData.address);
  const [errors, setErrors] = useState("");

  const [brandImage, setBrandImage] = useState("" || profileData.photo);
  const [isBusinessOnline, setIsBusinessOnline] = useState(
    profileData.isBusinessOnline || false
  );
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [whatsAppBusinessNumber, setWhatsAppBusinessNumber] = useState(
    "" || profileData?.whatsAppBusinessNumber
  );

  useEffect(() => {
    if (profileData) {
      setCity(profileData?.city);
      setState(profileData?.state);
      setWebsiteUrl(profileData.websiteUrl);
      setWhatsAppBusinessNumber(profileData?.whatsAppBusinessNumber);
      setOrganization(profileData?.organization);
      setFirstName(profileData.firstName);
      setLastName(profileData.lastName);
      setAddress(profileData.address);
    }
  }, [profileData]);

  /******************* 
@Purpose : Used for get india's state
@Parameter : {}
@Author : INIC
******************/
  const updatedStates = () =>
    State.getStatesOfCountry("IN").map((state) => ({
      label: state.name,
      value: state.id,
      ...state,
    }));

  /******************* 
@Purpose : Used for get cities
@Parameter : props
@Author : INIC
******************/
  const updatedCities = () =>
    City.getCitiesOfState("IN", stateCode).map((city) => ({
      label: city.name,
      value: city.id,
      ...city,
    }));

  /******************* 
@Purpose : Used for  bread crumb
@Parameter : {}
@Author : INIC
******************/
  const breadcrumb = [
    {
      title: PROFILE_DETAILS,
      url: route.BRAND_ACCOUNT,
    },
  ];

  /******************* 
  @Purpose : Used for validate form field
  @Parameter : {}
  @Author : INIC
  ******************/
  const validateForm = () => {
    let errors = {
      firstName: "",
      lastName: "",
      emailId: "",
      mobileNumber: "",
      organization: "",
      country: "",
      address: "",
      state: "",
      city: "",
      pin: "",
      websiteUrl: "",
      whatsAppBusinessNumber: "",
    };

    let isFormValid = true;

    let error = errors;
    if (isEmpty(firstName)) error.firstName = errorMessages.PROVIDE_FIRST_NAME;
    else error.firstName = "";

    if (isEmpty(lastName)) error.lastName = errorMessages.PROVIDE_LAST_NAME;
    else error.lastName = "";

    if (isEmpty(emailId)) error.emailId = errorMessages.PROVIDE_EMAIL;
    else error.emailId = "";

    if (isEmpty(city)) error.city = errorMessages.PROVIDE_CITY;
    else error.city = "";

    if (isEmpty(pin)) error.pin = errorMessages.PROVIDE_PIN;
    else if (pin.length != 6) error.pin = "Please Enter 6 Digit Pincode";
    else error.pin = "";

    if (isEmpty(state)) error.state = errorMessages.PROVIDE_STATE;
    else error.state = "";

    if (isEmpty(address)) error.address = errorMessages.PROVIDE_ADDRESS;
    else error.address = "";

    if (isEmpty(organization))
      error.organization = errorMessages.PROVIDE_ORGANIZATION;
    else error.organization = "";

    if (isEmpty(mobileNumber) || mobileNumber.length !== 12)
      error.mobileNumber = errorMessages.PROVIDE_MOBILE_NUMBER;
    else error.mobileNumber = "";
    if (isBusinessOnline && isEmpty(websiteUrl))
      error.websiteUrl = errorMessages.PROVIDE_WEBSITE_URL;
    else error.websiteUrl = "";
    if (!isBusinessOnline && isEmpty(whatsAppBusinessNumber))
      error.whatsAppBusinessNumber = errorMessages.PROVIDE_WHATSAPP_NUMBER;
    else if (!isBusinessOnline && whatsAppBusinessNumber.length !== 12) {
      error.whatsAppBusinessNumber = "please enter valid whats app number";
    } else error.whatsAppBusinessNumber = "";

    if (
      error.firstName !== "" ||
      error.lastName !== "" ||
      error.emailId !== "" ||
      error.organization !== "" ||
      error.address !== "" ||
      error.state !== "" ||
      error.pin !== "" ||
      error.city !== "" ||
      error.mobileNumber !== "" ||
      error.whatsAppBusinessNumber !== "" ||
      error.websiteUrl !== ""
    )
      isFormValid = false;

    setErrors(error);

    return isFormValid;
  };

  /******************* 
@Purpose : Used for post brand details
@Parameter : {e}
@Author : INIC
******************/
  const addBrand = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const photo = await uploadFile();
      let body = {
        firstName: firstName,
        lastName: lastName,
        emailId: emailId || profileData?.emailId,
        mobileNumber: mobileNumber,
        organization: organization || profileData?.organization,
        country: "India",
        address: address,
        state: state,
        city: city,
        pin: pin,
        photo: photo?.imagePath ? photo?.imagePath : profileData?.photo,
        compressedImage: photo?.compressedImage
          ? photo?.compressedImage
          : profileData?.compressedImage,
        isBusinessOnline: isBusinessOnline,
        websiteUrl: websiteUrl,
        whatsAppBusinessNumber: whatsAppBusinessNumber,
        gstNumber: gstNumber,
      };

      try {
        dispatch(saveBrandProfile(body)).then((res) => {
          if (res) {
            if (res.status === 1) {
              props.history.push("/selectplan");
            } else {
              showMessageNotification(res?.message?.[0].msg, "error");
            }
          }
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
  };

  /******************* 
@Purpose : Used for image upload
@Parameter : {e}
@Author : INIC
******************/
  const handleImageUpload = (e) => {
    try {
      const [file] = e.target.files;
      if (file) {
        const reader = new FileReader();
        const { current } = uploadedImage;
        current.file = file;
        setBrandImage(current.file);

        formData1.append("image", current.file);
        reader.onload = (e) => {
          current.src = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {}
  };

  /******************* 
  @Purpose : Used for post image 
  @Parameter : {}
  @Author : INIC
  ******************/
  const uploadFile = () => {
    let formData2 = new FormData();
    formData2.append("image", brandImage);
    return dispatch(Uploadimages(formData2)).then((response) => {
      if (response.imagePath) {
        return response;
      }
    });
  };

  /******************* 
  @Purpose : Used for change input data value
  @Parameter : value, data
  @Author : INIC
  ******************/
  const handleOnChanges = (value, data) => {
    setMobileNumber(value);
  };

  /******************* 
  @Purpose : Used for change input data value
  @Parameter : value, data
  @Author : INIC
  ******************/
  const handleOnChangesWhatsappNumber = (value, data) => {
    setWhatsAppBusinessNumber(value);
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Profile Detail">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <form className="mb-7">
              <div className="card mb-5">
                <div className="card-body">
                  <div className="row">
                    <div className="col-xxl-2 col-lg-3 col-md-12 text-center">
                      <div className="">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleImageUpload}
                          ref={imageUploader}
                          style={{
                            display: "none",
                          }}
                        />
                        <div className="user-image overflow-hidden mb-2 mx-md-auto">
                          {profileData?.photo ? (
                            <img
                              ref={uploadedImage}
                              src={profileData?.photo}
                              alt="Icon"
                            />
                          ) : (
                            <img
                              ref={uploadedImage}
                              src="./assets/images/user.png"
                              alt="Icon"
                            />
                          )}
                        </div>
                        <Button
                          className="mx-auto d-block btn btn-primary"
                          onClick={() => imageUploader.current.click()}
                        >
                          Change
                        </Button>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="row">
                        <div className="col-lg-12 col-xl-6 mb-4">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="Organization"
                            >
                              Organization
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="organizationName"
                              name="organizationName"
                              placeholder="Enter Organization Name"
                              value={organization}
                              onChange={(e) => {
                                setOrganization(e.target.value);
                                let error = Object.assign(errors, {
                                  organization: "",
                                });
                                setErrors(error);
                              }}
                            />
                            <span className="error-msg text-danger">
                              {errors.organization}
                            </span>
                          </div>
                        </div>
                        {/* gst starts */}
                        <div className="col-lg-12 col-xl-6 mb-4">
                          <div className="form-group">
                            <label className="form-label" htmlFor="FirstName">
                              GST Number
                            </label>
                            <input
                              className="form-control"
                              id="gstNumber"
                              name="gstNumber"
                              placeholder="Enter GST Number"
                              type="first name"
                              value={gstNumber}
                              onChange={(e) => {
                                setgstNumber(e.target.value);
                                let error = Object.assign(errors, {
                                  gstNumber: "",
                                });
                                setErrors(error);
                              }}
                            />
                            <span className="error-msg text-danger">
                              {errors.gstNumber}
                            </span>
                          </div>
                        </div>
                        {/* gst ends here */}

                        <div className="col-lg-12 col-xl-6 mb-4">
                          <div className="form-group">
                            <label className="form-label" htmlFor="FirstName">
                              First Name
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              id="firstname"
                              name="firstname"
                              placeholder="Enter First Name"
                              type="first name"
                              value={firstName}
                              onChange={(e) => {
                                setFirstName(e.target.value);
                                let error = Object.assign(errors, {
                                  firstName: "",
                                });
                                setErrors(error);
                              }}
                            />
                            <span className="error-msg text-danger">
                              {errors.firstName}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-12 col-xl-6 mb-4">
                          <div className="form-group">
                            <label className="form-label" htmlFor="Last Name">
                              Last name
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="contactLastName"
                              name="contactLastName"
                              value={lastName}
                              onChange={(e) => {
                                setLastName(e.target.value);
                                let error = Object.assign(errors, {
                                  lastName: "",
                                });
                                setErrors(error);
                              }}
                              placeholder="Enter Contact Last Name"
                            />
                            <span className="error-msg text-danger">
                              {errors.lastName}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-12 col-xl-6 mb-4">
                          <div className="form-group">
                            <label className="form-label" htmlFor="PhoneNumber">
                              Mobile number
                              <span className="text-danger">*</span>
                            </label>
                            <PhoneInput
                              className="form-group"
                              name="phone"
                              country={"in"}
                              placeholder="9988776655"
                              masks={""}
                              countryCodeEditable={false}
                              onChange={handleOnChanges}
                              value={"" || profileData.mobileNumber}
                            />
                            <span className="text-danger error-msg h-3">
                              {errors.mobileNumber}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-12 col-xl-6 mb-4">
                          <div className="form-group">
                            <label className="form-label" htmlFor="Address">
                              Address <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="address"
                              name="address"
                              placeholder="Enter Address"
                              value={address}
                              onChange={(e) => {
                                setAddress(e.target.value);
                                let error = Object.assign(errors, {
                                  address: "",
                                });
                                setErrors(error);
                              }}
                            />

                            <span className="error-msg text-danger">
                              {errors.address}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-12 col-xl-6 mb-4 ">
                          <div className="form-group">
                            <label className="form-label" htmlFor="Email">
                              Email
                              <span className="text-danger">*</span>
                            </label>

                            <input
                              className="form-control back-color form-control"
                              id="emailId"
                              name="emailId"
                              placeholder="Enter email"
                              type="email"
                              value={emailId || profileData.emailId}
                              disabled
                            />
                            <span className="error-msg text-danger">
                              {errors.emailId}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-12 col-xl-6 mb-4">
                          <div className="form-group">
                            <label className="form-label" htmlFor="Country">
                              Country
                            </label>
                            <input
                              disabled
                              type="text"
                              className="form-control back-color form-control"
                              id="country"
                              name="country"
                              value="India"
                            />
                          </div>
                        </div>

                        <div className="col-lg-12 col-xl-6 mb-4">
                          <label className="form-label" htmlFor="state">
                            State<span className="text-danger">*</span>
                          </label>

                          <Select
                            id="state"
                            name="state"
                            options={updatedStates()}
                            onChange={(value) => {
                              setState(value.name);
                              setStateCode(value.isoCode);
                              setCity("");
                            }}
                            value={
                              state && [
                                {
                                  label: state,
                                  value: state,
                                },
                              ]
                            }
                          />

                          <span className="error-msg text-danger">
                            {errors.state}
                          </span>
                        </div>
                        <div className="col-lg-12 col-xl-6 mb-4">
                          <div className="form-group">
                            <label className="form-label" htmlFor="Name">
                              City <span className="text-danger">*</span>
                            </label>
                            <Select
                              id="city"
                              name="city"
                              options={updatedCities()}
                              placeholder="Select City"
                              value={
                                city && [
                                  {
                                    label: city,
                                    value: city,
                                  },
                                ]
                              }
                              onChange={(e) => {
                                setCity(e.name);
                              }}
                            />

                            <span className="error-msg text-danger">
                              {errors.city}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-12 col-xl-6 mb-4">
                          <div className="form-group">
                            <label className="form-label" htmlFor="Email">
                              Pincode
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              name="zipcode"
                              value={pin || profileData.pin}
                              id="zipPostalCode"
                              placeholder="Enter Pincode"
                              type="number"
                              onChange={(e) => {
                                if (e.target.value.length < 7) {
                                  setPin(e.target.value);
                                }
                                let error = Object.assign(errors, {
                                  pin: "",
                                });
                                setErrors(error);
                              }}
                              onKeyDown={(evt) =>
                                evt.key === "e" && evt.preventDefault()
                              }
                            />
                            <span className="error-msg text-danger">
                              {errors.pin}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="col-xl-6 px-0">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="d-flex align-items-center">
                                <input
                                  type="radio"
                                  name="isBusinessOnline"
                                  id="isBusinessOnline"
                                  className="me-2"
                                  onChange={() => {
                                    setIsBusinessOnline(true);
                                    setWebsiteUrl("");
                                  }}
                                  value={isBusinessOnline}
                                  checked={isBusinessOnline}
                                />
                                <label for="isBusinessOnline" className="m-0">
                                  Is your business online
                                </label>
                              </div>
                              <br />
                              {isBusinessOnline && (
                                <>
                                  <input
                                    type="url"
                                    className="form-control"
                                    id="websiteUrl"
                                    name="websiteUrl"
                                    placeholder="Enter website URL"
                                    value={websiteUrl}
                                    onChange={(e) => {
                                      setWebsiteUrl(e.target.value);
                                      let error = Object.assign(errors, {
                                        websiteUrl: "",
                                      });

                                      // Check if websiteUrl is a number
                                      if (
                                        !isNaN(parseFloat(e.target.value)) &&
                                        isFinite(e.target.value)
                                      ) {
                                        error.websiteUrl =
                                          "Please enter a valid URL";
                                      } else {
                                        error.websiteUrl = "";
                                      }

                                      setErrors(error);
                                    }}
                                    required
                                  />

                                  <span className="error-msg text-danger">
                                    {errors.websiteUrl}
                                  </span>
                                </>
                              )}
                            </div>

                            <div className="col-md-6">
                              <div className="d-flex align-items-center">
                                <input
                                  type="radio"
                                  name="isBusinessOnline"
                                  id="isBusinessOffline"
                                  value={!isBusinessOnline}
                                  className="me-2"
                                  onChange={() => {
                                    setIsBusinessOnline(false);
                                    setWhatsAppBusinessNumber(""); // Clear the whatsAppBusinessNumber field
                                  }}
                                  checked={!isBusinessOnline}
                                />

                                <label for="isBusinessOffline" className="m-0">
                                  Is your business Offline
                                </label>
                              </div>
                              <br />
                              {!isBusinessOnline && (
                                <>
                                  <PhoneInput
                                    className="form-group"
                                    name="whatsAppBusinessNumber"
                                    country={"in"}
                                    placeholder="9988776655"
                                    countryCodeEditable={false}
                                    onChange={handleOnChangesWhatsappNumber}
                                    value={whatsAppBusinessNumber}
                                  />
                                  <span className="text-danger error-msg h-3">
                                    {errors.whatsAppBusinessNumber}
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 ">
                      <div className="pt-3 d-flex justify-content-end align-item-center flex-wrap">
                        <Button
                          className="me-2"
                          variant="primary"
                          onClick={(e) => addBrand(e)}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <Footer />
        </div>
      </div>
    </Layout>
  );
};
export default BrandAccount;
