import { callApi } from "../../Api";
import Routes from "../../Api/Routes"; // API endpoint routes
const { GET_CATEGORIES } = Routes;

/*******************
@purpose :  save card info
@Parameter : {data}
@Author : INIC
******************/
export const getCategories =
  (number = 1) =>
  async (dispatch) => {
    const res = await callApi(
      `${GET_CATEGORIES}?=pageNumber=${number}`,
      "",
      "get",
      "",
      true
    );
    if (res) {
      if (res.status) {
        dispatch(setCategoryInfo(res.data));
      }
      return Promise.resolve(res);
    }
    return Promise.reject(res);
  };
/*******************
@purpose :  set user profile
@Parameter : {data}
@Author : INIC
******************/
export const setCategoryInfo = (data) => ({
  type: "SET_CATEGORY_INFO",
  payload: data,
});
