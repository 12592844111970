import React from "react";

/*******************
@purpose : used for footer
@Parameter : {}
@Author : INIC
******************/
const Footer = () => {
  let date = new Date();

  /******************* 
  @purpose : Rander HTML/ React Components
  @Author :INIC
  ******************/
  return (
    <div className="footer-text d-flex align-items-centerf justify-content-between">
      <span>{date.getFullYear()} © Vista Brand Panel</span>
      <span> All Rights Reserved. </span>
    </div>
  );
};

export default Footer;
