/******************* 
@Purpose : We can use following api calls and can change api name easily
@Author : INIC
******************/
export default {
  //Authentication
  LOGOUT: "/brands/logout",
  LOGIN: "/brands/login",
  FORGOT: "/brands/forgotPassword",
  SET_PASSWORD: "/brands/setPassword",
  RESET_PASSWORD: "/brands/resetPassword",
  CHANGE_PASSWORD: "/brands/changePassword",
  SAVE_BRAND_PROFILE: "/brands/saveBrandProfile",
  EDIT_BRAND_PROFILE: "/brands/editProfile",
  ADD_BRAND: "/brands/registerByOwn",
  // celebrity
  CELEBRITY_LIST: "/brandManagement/getVideosOfAllCelebrity",
  PURCHASE_HISTORY: "/brandManagement/getPurchaseVideoHistory",
  CELEB_SPECIFIC_VIDS: "/brandManagement/getCelebritySpecificVideos",
  HIDE_VIDEO: "/brandManagement/hideVideo",

  // dashboard
  DASHBOARD: "/dashboard",
  //brand
  BRAND_IMAGE: "/images/brands",
  SELECT_PLAN: "/getPlanConfig",
  //payout
  PAYMENT: "/brands/paymentReceived",
  SAVE_CARD_INFO: "/brands/saveCardInfo",
  DELETE_CARD_DETAIL: "/brands/deleteCardInfo",
  GET_CARDS: "/brands/fetchCardInfo",
  GET_PLANS: "/brands/fetchPlansHistory",
  //categories
  GET_CATEGORIES: "/categories/getcategories",
  //brand videos
  GET_BRAND_VIDEOS: "/brandManagement/getVideosOfBrand",
  GET_BRAND_VIDEO_INFO: "/admin/getVideoInfo",
  //brand promoted video ad
  BRANDVIDEOADLIST: "/brandManagement/getPromotedVideosOfBrand",
  UPLOADADVIDEO: "/brandManagement/addVideoAd",
  // reports
  DOWNLOAD_REPORTS: "/brandManagement/downloadVideoPurchaseReports",
  REPORTS_DATA: "/brandManagement/getVideoPurchaseReports",
  // GET_UPLOAD_ID: "/getUploadId",
  // video queue
  VIDEO_APPROVAL_QUEUE: "/brandManagement/getVideoApprovalQueueList",
  APPROVE_VIDEO: "/brandManagement/approveVideo",
  REJECT_VIDEO: "/brandManagement/rejectVideo",
  CELEB_DROPDOWN: "/brandManagement/getCelebrityList",
  TRANSACTION_LIST: "/brandManagement/getTransactionIds",
  UPLOAD_VIDEO: "/uploadFile",
  DASHBOARD_DATA: "/brandManagement/dasboardData",
  CHART_DATA: "/brandManagement/monthWiseViewData",
  CREATE_VIDEO_ORDER: "/brandManagement/createVideoOrder",
  GET_MISC_INFO: "/getSettingsMiscInfo",
  CHANGE_VIDEO: "/brandManagement/changeVideo",
  STATEANDCITIES: "/celebrity/getCitiesAndStates",
  VIDEOTITLELIST: "/brandManagement/getVideoTitleList",
  PUBLISH_VIDEO: "/brandManagement/publishVideo",
  POSTED_VIDEO: "/brandManagement/getPostedVideosOfBrand",
  DELETE_POSTED_VIDEO: "/brandManagement/deleteBrandVideo",
  EDIT_POSTED_VIDEO: "/brandManagement/editBrandVideo",
  REMAIN_VIDEO: "/brandManagement/videoCountPerMonth",
  CMS: "/cmsList",
  TREND_SUBCAT: "brandManagement/monthWiseTopFiveSubCtegories",
};
