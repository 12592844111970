import React, { useState } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import { Button, Tooltip, OverlayTrigger, Form } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";

import { changeResize, changeTheme } from "../../Store/Actions";
import { callApi } from "../../Api";
import errorMessages from "../../Utils/ErrorMessages";
import Theme from "../../Components/Layout/Theme";
import { login } from "../../Store/Actions/User";
import { emailRegex } from "../../Utils/Regex";
import {
  validatePassword,
  setItem,
  showMessageNotification,
} from "../../Utils/Functions";
import { route } from "../../Utils";

/******************* 
@Purpose : Used for admin login view
@Parameter : {}
@Author : INIC
******************/
function BrandLogin() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [emailId, setEmailId] = useState(localStorage.getItem("emailId"));
  const [errors, setErrors] = useState({ emailId: "", password: "" });
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [hidden, setHidden] = useState(true);

  /******************* 
  @Purpose : Used for validate form data
  @Parameter : {}
  @Author : INIC
  ******************/
  const validateForm = () => {
    let mailFormat = emailRegex;
    let tempErrors = { emailId: "", password: "" };
    let isFormValid = true;
    //for Email
    if (!emailId) tempErrors.emailId = "Please enter your email ";
    else if (!mailFormat.test(emailId))
      tempErrors.emailId = "Please enter vaild email";
    else tempErrors.emailId = "";

    // for password
    if (!password.trim()) tempErrors.password = errorMessages.PROVIDE_PASSWORD;
    else if (!validatePassword(password))
      tempErrors.password = errorMessages.PROVIDE_VALID_PASSWORD;
    else tempErrors.password = "";

    if (tempErrors.emailId !== "" || tempErrors.password !== "")
      isFormValid = false;

    setErrors(tempErrors);
    return isFormValid;
  };
  /******************* 
  @Purpose : Used for rander tooltip data
  @Parameter : passwordProps
  @Author : INIC
  ******************/
  const renderTooltip = (passwordProps) =>
    !hidden ? (
      <Tooltip id="button-tooltip" {...passwordProps}>
        Hide Password
      </Tooltip>
    ) : (
      <Tooltip id="button-tooltip" {...passwordProps}>
        Show Password
      </Tooltip>
    );

  /******************* 
  @Purpose : Used for login action
  @Parameter : passwordProps
  @Author : INIC
  ******************/
  const adminLogin = (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    setLoading(true);

    const data = { emailId, password };
    dispatch(login(data))
      .then((res) => {
        if (res.status === 1) {
          localStorage.setItem("id", res.data._id);
          localStorage.setItem("accessToken", res.access_token);
          localStorage.setItem("emailId", emailId);
          setItem("accessToken", res.access_token);

          let destination;

          if (res.data.firstName === "") {
            destination = route.BRAND_ACCOUNT;
          } else if (res.data.isPlanExpired) {
            destination = route.SELECT_PLAN;
          } else {
            destination = route.DASHBOARD;
          }
          history.push(destination);
        } else {
          showMessageNotification(res.message, "error");
        }
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // handle signup
  const handleSignUp = () => {
    history.push("/SignUp");
  };
  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <div className="App">
      <header className="App-header"></header>
      <Theme />
      <div
        className="login-wrapper"
        style={{
          background:
            "url(../assets/images/login-bg.jpg) no-repeat center center",
        }}
      >
        <div className="login-body">
          <div className="login-container row m-0">
            <div className="col-xl-8 col-11">
              <div className="card bg-login-card">
                <div className="row g-0">
                  <div className="col-md-6 col-12 pl-0 pr-0">
                    <div className="card rounded-left mb-0 p-4 d-flex justify-content-center h-100">
                      <div className="card-title text-center">
                        <img
                          className="img-fluid"
                          src="/assets/images/logo.svg"
                          alt="Vista Brand"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/assets/images/brandImage.png";
                          }}
                        ></img>
                      </div>
                      <div className="card-header mb-4">
                        <h5 className="card-title text-center mb-0">Welcome</h5>
                      </div>
                      <div className="card-content">
                        <div className="card-body pb-0">
                          <form onSubmit={(evt) => adminLogin(evt)}>
                            <div className="form-group mb-4">
                              <label
                                className="form-label"
                                htmlFor="EmailAddress"
                              >
                                Email Address{" "}
                                {/* <sup className="text-danger">*</sup> */}
                              </label>
                              <input
                                type="email"
                                id="EmailAddress"
                                aria-describedby="emailHelp"
                                placeholder="Enter email"
                                className={
                                  errors.emailId
                                    ? "form-control input-error"
                                    : "form-control"
                                }
                                name="emailId"
                                autoComplete="off"
                                value={emailId}
                                onChange={(e) => {
                                  setEmailId(e.target.value);
                                  let error = Object.assign(errors, {
                                    emailId: "",
                                  });
                                  setErrors(error);
                                }}
                              />
                              <span className="error-msg ">
                                {errors.emailId}
                              </span>
                            </div>
                            <div className="form-group mb-4 position-relative">
                              <label className="form-label" htmlFor="Password">
                                Password
                                {/* <sup className="text-danger">*</sup> */}
                              </label>
                              <input
                                className={
                                  errors.password
                                    ? "form-control input-error"
                                    : "form-control"
                                }
                                name="password"
                                value={password}
                                id="password"
                                type={hidden ? "password" : "text"}
                                placeholder="Password"
                                onChange={(e) => {
                                  setPassword(e.target.value);
                                  let error = Object.assign(errors, {
                                    password: "",
                                  });
                                  setErrors(error);
                                }}
                              />
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                              >
                                <span
                                  onClick={() => setHidden(!hidden)}
                                  toggle="#password-field"
                                  className={
                                    hidden
                                      ? "bx bx-hide field-icon toggle-password"
                                      : "bx bx-show field-icon toggle-password"
                                  }
                                ></span>
                              </OverlayTrigger>
                              <em className="fa fa-lock" aria-hidden="true" />

                              <span className="error-msg">
                                {errors.password}
                              </span>
                            </div>
                            <div className="form-group mb-4 d-flex flex-md-row flex-column justify-content-between align-items-center mb-3">
                              <Form.Check
                                type="checkbox"
                                name="group1"
                                id="group-1"
                                label="Keep me logged in"
                              />
                              <Link
                                to={route.FORGOT_PASSWORD}
                                className="card-link"
                              >
                                Forgot password?
                              </Link>
                            </div>
                            <Button
                              variant="primary"
                              className="btn w-100  glow btn-block"
                              type="submit"
                              onClick={(evt) => adminLogin(evt)}
                            >
                              {loading ? "Loading..." : "Sign In"}
                              <i className="ms-2 text-white align-middle icon-login"></i>
                            </Button>
                            <Button
                              variant="secondary"
                              className="btn w-100  glow btn-block mt-3"
                              onClick={() => handleSignUp()}
                            >
                              Sign Up
                              <i className="ms-2 text-white align-middle bx bx-user-plus font-20"></i>
                            </Button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 d-md-block d-none text-center align-self-center p-7">
                    <div className="card-content">
                      <img
                        className="img-fluid"
                        src="../assets/images/login.png"
                        alt="login"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(null, { callApi, changeTheme, changeResize })(
  withRouter(BrandLogin)
);
