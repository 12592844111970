import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";

/*******************
@purpose : used for register chartjs-2 with chart js
@Parameter : {}
@Author : INIC
******************/
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

/*******************
@purpose : used for chart options
@Parameter : {}
@Author : INIC
******************/
// const options = {
//   responsive: true,
//   plugins: {
//     legend: {
//       position: "top",
//       // labels: {
//       //   usePointStyle: true,
//       //   pointStyle: "circle",
//       //   font: 20,
//       // },
//     },
//     title: {
//       display: true,
//     },
//   },
// };
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: "Month",
      },
    },
    y: {
      title: {
        display: true,
        text: "View Count ",
      },
    },
  },
};
/*******************
@purpose : used for chart
@Parameter : {}
@Author : INIC
******************/
const Chart = () => {
  const { branddahboardchartdata } = useSelector(
    (data) => data?.branddashboard
  );

  /*******************
  @purpose : used for get month
  @Parameter : {}
  @Author : INIC
  ******************/
  function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString([], { month: "short" });
  }

  /*******************
  @purpose : used for chart label
  @Parameter : {}
  @Author : INIC
  ******************/
  const labels = branddahboardchartdata?.viewCountByMonth?.map((data) =>
    getMonthName(data?.month ?? null)
  );

  /*******************
  @purpose : used for chart dataset
  @Parameter : {}
  @Author : INIC
  ******************/
  const dataset = branddahboardchartdata?.viewCountByMonth?.map(
    (data) => data?.videoViewCount ?? null
  );
  const affliatedClickCount =
    branddahboardchartdata?.affiliateClickCountByMonth?.map(
      (data) => data?.affiliateClickCount ?? null
    );

  /*******************
  @purpose : used for chart data
  @Parameter : {}
  @Author : INIC
  ******************/
  const data = {
    labels,
    datasets: [
      {
        label: "Video View",
        data: dataset,
        backgroundColor: "rgb(9, 55, 95)",
        borderRadius: 10,
        barThickness: 30,
      },
      // its used for product purchase and product view count
      {
        label: "Product Clicks",
        data: affliatedClickCount,
        backgroundColor: "#ff4d4f",
        borderRadius: 10,
        barThickness: 30,
      },
      // {
      //   label: "Product Purchased",
      //   data: [150, 200, 320, 400, 200, 100],
      //   backgroundColor: "rgb(162, 207, 246)",
      //   borderRadius: 10,
      //   barThickness: 30,
      // },
    ],
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return <Bar options={options} data={data} />;
};

export default Chart;
