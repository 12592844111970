import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";

/******************* 
@Purpose : Used for Filter Model
@Parameter : {props}
@Author : Vista app
******************/
const VideoPlayerModal = (props) => {
  useEffect(() => {
    if (props.modal) {
      document.getElementById("vid").play();
    }
  }, []);

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="filter-modal "
    >
      <Modal.Body closeButton>
        <video
          width="100%;"
          height="450"
          loop="true"
          autoplay="autoplay"
          controls="controls"
          id="vid"
        >
          <source src={props?.videoLink} type="video/mp4"></source>
        </video>
      </Modal.Body>
    </Modal>
  );
};

export default VideoPlayerModal;
