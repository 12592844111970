import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { Button, Modal, Form } from "react-bootstrap";
import errorMessages from "../../Utils/ErrorMessages";
import { useDispatch, useSelector } from "react-redux";

import {
  showMessageNotification,
  showModalNotification,
} from "../../Utils/Functions";

import {
  getVideoApprove,
  getRejectVideo,
  getHideVideo,
} from "../../Store/Actions/CelebrityVideos";
import { getVideoInfo } from "../../Store/Actions/BrandVideos";
import { Layout, Footer } from "../../Components/Layout";
import { route } from "../../Utils";

import moment from "moment/moment";
import swal from "sweetalert";

/******************* 
@Purpose : Used for Video info pageNumber
@Parameter : {}
@Author : INIC
******************/
const VideoDetail = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const param = useParams();
  const pageNumber = 1;
  const pageSize = 5;
  const { celebvideoinfo } = useSelector((data) => data.brandvideos);
  const [changeErrors, setChangeErrors] = useState("");
  const [show1, setShow1] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [id, setId] = useState();
  const [compleateData, setCompleateData] = useState();
  const [brandHistory, setBrandHistory] = useState([]);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    emailId: "",
    gender: "",
    dob: "",
    mobileNumber: "",
    status: "",
    address: "",
    city: "",
    twitter: "",
    instagramId: "",
    aadhar: "",
    state: "",
    pincode: "",
    fbId: "",
    youtubeId: "",
    creatorProfilephoto: "",
    _id: "",
    pricePerVideo: "",
  });

  /******************* 
  @Purpose : Used for celeb card details
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    videoCardDisplay();
  }, [pageNumber, pageSize]);
  const videoCardDisplay = async (id) => {
    try {
      const response = await dispatch(getVideoInfo(param.slug));
      if (response.status === 1) {
        let {
          firstname,
          lastname,
          emailId,
          gender,
          dob,
          mobileNumber,
          status,
          address,
          city,
          twitter,
          instagramId,
          aadhar,
          state,
          pincode,
          fbId,
          youtubeId,
          creatorProfilephoto,
          _id,
          pricePerVideo,
        } = response?.data[0]?.celebrityDetails[0];

        setBrandHistory(response?.data[0]?.brandActionHistory);

        setCompleateData(response?.data[0]);
        setFormData({
          ...formData,
          firstname,
          lastname,
          emailId,
          gender,
          dob,
          mobileNumber,
          status,
          address,
          city,
          twitter,
          instagramId,
          aadhar,
          state,
          pincode,
          fbId,
          youtubeId,
          creatorProfilephoto,
          _id,
          pricePerVideo,
        });
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  /******************* 
  @Purpose :handle modal
  @Parameter : {}
  @Author : Vista app
 ******************/
  const handleRejectVideo = async (id) => {
    setShow1(true);
    setId(id);
  };

  /******************* 
  @Purpose : Used for modal in the rejection
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const modelCloseFun1 = () => {
    setConfirmPassword("");
    setShow1(false);
  };

  /******************* 
  @Purpose : validate modal fields
  @Parameter : {}
  @Author : Vista app
  ******************/
  const validPasswords = () => {
    let changeError = { confirmPassword: "" };
    let isFormValidPassword = true;
    if (!confirmPassword.trim())
      changeError.confirmPassword = errorMessages.PROVIDE_COMMENT;
    else changeError.confirmPassword = "";
    if (changeError.confirmPassword !== "") isFormValidPassword = false;
    setChangeErrors(changeError);
    return isFormValidPassword;
  };

  /******************* 
  @Purpose : reject celeb
  @Parameter : {}
  @Author : Vista app
  ******************/
  const rejectVideo = async (id) => {
    let body = {
      videoId: id,
      comment: confirmPassword,
    };

    try {
      if (validPasswords()) {
        const response = await dispatch(getRejectVideo(body));
        if (response.status === 1) {
          showModalNotification("Rejected successfully", "success");
          setConfirmPassword("");
          modelCloseFun1();
          history.push(`/videoapprovalqueue`);
        }
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  /******************* 
  @Purpose : Used for Bradcrumb View
  @Parameter : {}
  @Author : INIC
  ******************/
  const breadcrumb = [
    {
      title: "Video Queue",
      url: route.VIDEO_APPROVAL_QUEUE,
    },
    {
      title: "Video Detail",
    },
  ];

  /******************* 
  @Purpose : approve celebrity
  @Parameter : {}
  @Author : Vista app
  ******************/
  const videoApprove = async (id) => {
    let body = {
      videoId: id,
    };
    try {
      const response = await dispatch(getVideoApprove(body));
      if (response.status === 1) {
        showMessageNotification("This video is approved", "success");
        history.push(`/videoapprovalqueue`);
      }
    } catch (error) {
      console.error();
      throw error;
    }
  };

  const handleStatus = async (id) => {
    if (celebvideoinfo[0]?.status === true) {
      swal({
        title: "Are you sure want to hide video?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        cancelButtonColor: "#d33",
        timer: 3000,
      }).then(async (result) => {
        if (result) {
          let body = {
            videoId: id,
          };
          dispatch(getHideVideo(body))
            .then((res) => {
              if (res) {
                dispatch(getVideoInfo(param.slug));
                showMessageNotification(res.message, "success");
              }
            })
            .catch((err) => {
              console.error(err);
              throw err;
            });
        }
      });
    } else if (celebvideoinfo[0]?.status === false) {
      let body = {
        videoId: id,
      };
      dispatch(getHideVideo(body))
        .then((res) => {
          if (res) {
            dispatch(getVideoInfo(param.slug));
            showMessageNotification(res.message, "success");
          }
        })
        .catch((err) => {
          console.error(err);
          throw err;
        });
    }
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Video Queue">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <div className="row">
              <div className="col-lg-12 mb-4">
                <div className="card mb-5">
                  <div className="card-header">
                    <h5 className="card-title">Celebrity Information</h5>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-between mb-2 flex-wrap"
                    style={{ gap: "12px", fontSize: "15px" }}
                  >
                    <Modal
                      show={show1}
                      onHide={modelCloseFun1}
                      className="border  md={{ span: 3, offset: 3 }}"
                    >
                      <Modal.Header closeButton>
                        <h5 className="modal-title" id="exampleModalLongTitle">
                          Rejection Comment
                        </h5>
                      </Modal.Header>
                      <Modal.Body closeButton>
                        <div className="notification-form">
                          <div className="row">
                            <div className="col-md-12 mb-4">
                              <div className="form-group">
                                <label className="form-label" for="CountryName">
                                  {"Comment"}
                                  <sup className="text-danger">*</sup>
                                </label>

                                <textarea
                                  type="text"
                                  className="form-control"
                                  id="confirmpassword"
                                  name="confirmPassword"
                                  value={confirmPassword}
                                  placeholder="Enter Rejected Comment"
                                  rows={3}
                                  onChange={(e) => {
                                    setConfirmPassword(e.target.value);
                                    let changeError = Object.assign(
                                      changeErrors,
                                      {
                                        confirmPassword: "",
                                      }
                                    );
                                    setChangeErrors(changeError);
                                  }}
                                />
                                <span className="text-danger d-block error-msg">
                                  {changeErrors.confirmPassword}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="light"
                          onClick={(evt) => modelCloseFun1()}
                        >
                          Close
                        </Button>
                        <Button
                          className="me-2"
                          variant="primary"
                          onClick={(evt) => rejectVideo(id)}
                        >
                          Reject
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <div className="card-body user-details">
                    <div className="row">
                      <div className="col-lg-2">
                        <div className="user-image">
                          <img
                            src={
                              formData?.creatorProfilephoto
                                ? formData?.creatorProfilephoto
                                : "/assets/images/no-user.png"
                            }
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-5 align-self-center">
                        <div className="input-group row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            NAME:
                          </div>
                          <div className="col-md-8 p-2 request-data">
                            {formData?.firstname}&nbsp; {formData?.lastname}
                          </div>
                        </div>

                        <div className="input-group row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            EMAIL ID:
                          </div>
                          <div className="col-md-8 p-2 request-data">
                            {formData?.emailId}
                          </div>
                        </div>

                        <div className="input-group row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            GENDER:
                          </div>
                          <div className="col-md-8 p-2 request-data">
                            {formData?.gender}
                          </div>
                        </div>
                        <div className="input-group row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            ADDRESS:
                          </div>
                          <div className="col-md-8 p-2 request-data">
                            {formData?.address}
                          </div>
                        </div>
                        <div className="input-group row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            CITY:
                          </div>
                          <div className="col-md-8 p-2 request-data">
                            {formData?.city}
                          </div>
                        </div>
                        <div className="input-group row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            INSTAGRAM:
                          </div>
                          <div className="col-md-8 p-2 request-data">
                            <a href={formData?.instagramId} target="_blank">
                              {formData?.instagramId}
                            </a>
                          </div>
                        </div>
                        <div className="input-group row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            STATUS:
                          </div>
                          <div className="col-md-8 p-2 request-data">
                            <span className="badge text-white bg-secondary">
                              {formData?.status ? "Active" : "Inactive"}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-5 align-self-center">
                        <div className="input-group row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            PHONE NUMBER:
                          </div>
                          <div className="col-md-8 p-2 request-data">
                            {formData?.mobileNumber}
                          </div>
                        </div>
                        <div className="input-group row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            DOB:
                          </div>
                          <div className="col-md-8 p-2 request-data">
                            {formData?.dob}
                          </div>
                        </div>
                        <div className="input-group row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            STATE:
                          </div>
                          <div className="col-md-8 p-2 request-data">
                            {formData?.state}
                          </div>
                        </div>
                        <div className="input-group row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            PINCODE:
                          </div>
                          <div className="col-md-8 p-2 request-data">
                            {formData?.pincode}
                          </div>
                        </div>
                        <div className="input-group row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            YOUTUBE:
                          </div>
                          <div className="col-md-8 p-2 request-data">
                            {formData?.youtubeId}
                          </div>
                        </div>
                        <div className="input-group row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            PRICE PER VIDEO:
                          </div>
                          <div className="col-md-8 p-2 request-data">
                            {formData?.pricePerVideo
                              ? `₹ ${formData?.pricePerVideo} `
                              : "-"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* video detail */}

                <div className="card mb-5">
                  <div className="card-header pb-0">
                    <h5 className="card-title">Video Detail</h5>
                  </div>
                  <div className="card-body user-details">
                    {celebvideoinfo &&
                      celebvideoinfo?.length > 0 &&
                      celebvideoinfo?.map((data, key) => (
                        <div className="row" key={data.id}>
                          <div className="col-lg-2 col-md-12 align-item-center">
                            <div className="video-card border-0 mb-0">
                              <div className="video-img position-relative">
                                <video className="br-12" width="100%;" height="170" controls>
                                  <source
                                    src={data?.videoLink}
                                    type="video/mp4"
                                  ></source>
                                </video>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-5 col-md-6">
                            <div className="input-group row g-0">
                              <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                                TITLE :
                              </div>
                              <div className="col-md-8 p-2 request-data text-secondary">
                                {data?.videoTitle}
                              </div>
                            </div>

                            <div className="input-group row g-0">
                              <>
                                <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                                  SUB CATEGORY:
                                </div>
                                <div className="col-md-8 p-2 request-data text-secondary">
                                  {data?.subcategories &&
                                    data.subcategories.length > 0 &&
                                    data.subcategories.map((subcat) => (
                                      <span
                                        key={subcat.id}
                                      >
                                        {subcat?.title},
                                      </span>
                                    ))}
                                </div>
                              </>
                            </div>

                            <div className="input-group row g-0">
                              <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                                PRODUCT LINK:
                              </div>
                              <div className="col-md-8 p-2 request-data text-secondary">
                                {data?.affilietedProductUrl ? (
                                  <a
                                    href={data?.affilietedProductUrl}
                                    target="_blank"
                                  >
                                    {data?.affilietedProductUrl}
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </div>
                            </div>
                            {/* hide video */}

                            {data.brandApprovalStatus === "approved" ? (
                              <div className="input-group row g-0">
                                <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                                  HIDE VIDEO ? :
                                </div>
                                <div className="col-md-8 p-2 request-data align-self-end">
                                  <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    checked={data?.status}
                                    onChange={() => handleStatus(data?._id)}
                                  />
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="col-lg-5 col-md-6">
                            <div className="input-group row g-0">
                              {data?.category &&
                                data?.category.length > 0 &&
                                data?.category.map((category) => (
                                  <>
                                    <div
                                      className="col-md-4 p-2 text-capitalize fw-bold request-title"
                                      key={category.id}
                                    >
                                      CATEGORY:
                                    </div>
                                    <div className="col-md-8 p-2 request-data text-secondary">
                                      {category?.title}
                                    </div>
                                  </>
                                ))}
                            </div>
                            <div className="input-group row g-0">
                              {data.celebrityDetails &&
                                data.celebrityDetails.length > 0 &&
                                data.celebrityDetails.map((name) => (
                                  <>
                                    <div
                                      className="col-md-4 p-2 text-capitalize fw-bold request-title"
                                      key={name.id}
                                    >
                                      CELEBRITY:
                                    </div>
                                    <div className="col-md-8 p-2 request-data text-secondary">
                                      {`${name.firstname} ${name.lastname}`}
                                    </div>
                                  </>
                                ))}
                            </div>

                            <div className="input-group row g-0">
                              <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                                PUBLISH DATE:
                              </div>
                              <div className="col-md-8 p-2 request-data text-secondary">
                                {moment(data?.createdAt).format("l, h:mm:ss a")}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                <div className="card mb-5">
                  <div className="card-header">
                    <h5 className="card-title">History</h5>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table
                        className="table row-border nowrap common-datatable"
                        id="master-country-listing"
                      >
                        <thead>
                          <tr>
                            <th>
                              <b>Status</b>
                              <i
                                aria-hidden="true"
                                className="icon-arrow-down ps-3 fs-10"
                              ></i>
                            </th>

                            <th>
                              <b>Comment</b>
                              <i
                                aria-hidden="true"
                                className="icon-arrow-down ps-3 fs-10"
                              ></i>
                            </th>

                            <th>
                              <b>
                                {brandHistory[0]?.action == "approved"
                                  ? "Approved Date & Time"
                                  : brandHistory[0]?.action === "rejected"
                                    ? "Rejected Date & Time"
                                    : "Action Date & Time"}
                              </b>
                              <i
                                aria-hidden="true"
                                className="icon-arrow-down ps-3 fs-10"
                              ></i>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {brandHistory && brandHistory?.length === 0 ? (
                            <tr className="text-center text-danger not-found-txt">
                              <td colSpan="6">
                                <h6
                                  className="text-center text-danger not-found-txt mt-3"
                                  colSpan="6"
                                >
                                  {"No data  found "}
                                </h6>
                              </td>
                            </tr>
                          ) : (
                            brandHistory &&
                            Array.isArray(brandHistory) &&
                            brandHistory?.map((list, key) => {
                              return (
                                <tr key={list.id}>
                                  <td>
                                    <div className="eamil-col d-flex align-items-center">
                                      <span>{list?.action}</span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="eamil-col d-flex align-items-center">
                                      <span>{list?.brandRejectionComment}</span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="eamil-col d-flex align-items-center">
                                      <span>
                                        {moment(
                                          list?.brandexecutionTime
                                        ).format("l, h:mm:ss a")}
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* approve and reject buttons */}
                <div className="col-md-12 text-end pt-4">
                  <div className="">
                    {compleateData?.brandApprovalStatus === "request_sent" ? (
                      <>
                        <button
                          type="button"
                          className="btn btn-light me-2"
                          onClick={() => handleRejectVideo(param.slug)}
                        >
                          Reject
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => videoApprove(param.slug)}
                        >
                          Approve
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </Layout>
  );
};

export default VideoDetail;
