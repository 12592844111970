import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useParams, useHistory } from "react-router";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "rc-pagination";

import {
  getPurchaseHistory,
  getSpecificVideos,
  getCategories,
} from "../../Store/Actions/CelebrityVideos";
import { getVideoInfo } from "../../Store/Actions/BrandVideos";
import { Layout, Footer } from "../../Components/Layout";
import { route } from "../../Utils";

import moment from "moment/moment";
import FilterModel from "../../Components/Model/FilterModel";
import { DatePicker } from "antd";
import { debounce } from "lodash";
import { setAgreeValue } from "../../Store/Actions/Tabs";

const { RangePicker } = DatePicker;
/******************* 
@Purpose : Used for Video info pageNumber
@Parameter : {}
@Author : INIC
******************/
const PurchaseHistory = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const param = useParams();
  const field = 2;
  const defaultFilter = {
    key: "",
    type: "contains",
    value: "",
    filterType: "",
    filterOptions: [],
    valueFieldType: "",
  };
  const [filterData, setFilterData] = useState([defaultFilter]);
  const { celebvideoinfo } = useSelector((data) => data?.brandvideos);
  const { purchaseHistory, celebVideos } = useSelector(
    (data) => data?.celebVideos
  );
  const [isSearchButtonDisabled, setIsSearchButtonDisabled] = useState(true);
  const pageNumber = 1;
  const pageSize = 5;
  const [pageSizePur, setPageSizePur] = useState(5);
  const [pageNumberPur, setPageNumberPur] = useState(1);
  const [pageNumberVid, setPageNumberVid] = useState(1);
  const [totalPur, setTotalPur] = useState(1);
  const [category, setCategory] = useState("");
  const [total, setTotal] = useState();
  const { categoryInfo } = useSelector((data) => data.user);
  const [modalShow, setModalShow] = useState(false);
  const [searchText, setSearchtext] = useState("");
  const [searchPubVid, setSearchPubVid] = useState("");
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);
  const [currentVideo, setCurrentVideo] = useState();
  const [oldVideo, setOldVideo] = useState();
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    emailId: "",
    gender: "",
    dob: "",
    mobileNumber: "",
    status: "",
    address: "",
    city: "",
    twitter: "",
    instagramId: "",
    aadhar: "",
    state: "",
    pincode: "",
    fbId: "",
    youtubeId: "",
    creatorProfilephoto: "",
    _id: "",
    pricePerVideo: "",
    followerCount: "",
  });

  /******************* 
  @Purpose : Used for celeb card details
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    videoCardDisplay();
  }, [pageNumber, pageSize]);

  useEffect(() => {
    if (formData._id) videosOfCeleb(formData._id);
  }, [searchPubVid, pageNumberVid]);

  useEffect(() => {
    if (formData && formData._id) {
      purchaseHistoryData();
    }
  }, [pageNumberPur, pageSizePur, formData, searchText]);

  /******************* 
  @Purpose :  React hook used for get category value
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    dispatch(getCategories());
    categoryValue();
  }, []);

  /******************* 
  @Purpose :  React hook used for filter search button disable and enable
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    filterData?.forEach((data) => {
      if (data.key != "" && data.value != "") {
        setIsSearchButtonDisabled(false);
      } else {
        setIsSearchButtonDisabled(true);
      }
    });
  }, [filterData]);

  /******************* 
  @Purpose : Used for clear all form field value
  @Parameter : status, timezoneId
  @Author : INIC
  ******************/
  const clearInput = () => {
    setSearchtext("");
  };

  /******************* 
  @Purpose :  used for purchase video redirect
  @Parameter : {}
  @Author : INIC
  ******************/
  const handlePurVideo = () => {
    dispatch(setAgreeValue(false));
    if (formData._id) {
      history.push(`/purchasevideo/${param.slug}/${formData._id}`);
    }
  };

  /******************* 
  @Purpose :  used for category value maping
  @Parameter : {}
  @Author : INIC
  ******************/
  const categoryValue = () => {
    let categoryArr = [];
    categoryInfo?.length > 0 &&
      categoryInfo?.forEach((optiondata) => {
        let tempObj = {
          label: optiondata?.title,
          value: optiondata?._id,
          ...optiondata,
        };
        categoryArr.push(tempObj);
        setCategory(categoryArr);
      });
  };

  /******************* 
  @Purpose :  used for hide modal
  @Parameter : {}
  @Author : INIC
  ******************/
  const onHide = () => {
    setModalShow(false);
  };

  /******************* 
  @Purpose :  used for sub category value maping
  @Parameter : {list}
  @Author : INIC
  ******************/
  const subCategoryValue = () => {
    const catValue = filterData.filter((obj) => obj.key === "categoryId")[0]
      .value;
    const catData = categoryInfo.filter((obj) => obj._id === catValue)[0];
    let subCategoryArr = [];
    catData?.subCategoryDetails?.length > 0 &&
      catData?.subCategoryDetails?.forEach((subCategory) => {
        let tempObj2 = {
          label: subCategory?.title,
          value: subCategory?._id,
        };
        subCategoryArr.push(tempObj2);
      });
    return subCategoryArr;
  };

  /******************* 
  @Purpose : Used for filter key selection and set value 
  @Parameter : {filterField, index}
  @Author : INIC
  ******************/
  const handleFilterTypeFields = (filterField, index) => {
    const filterRow = filterData;
    filterRow[index].key = filterField;
    setFilterData([...filterRow]);
    switch (filterField) {
      case "date":
        setFilterData([...filterRow]);
        break;
      case "categoryId":
        filterRow[index].filterOptions = category;
        setFilterData([...filterRow]);
        break;
      case "subCategoryId":
        const subCat = subCategoryValue();
        filterRow[index].filterOptions = subCat;
        setFilterData([...filterRow]);
        break;
    }
  };

  /******************* 
  @Purpose :  used for date range select
  @Parameter : {}
  @Author : INIC
  ******************/
  function onChange(date, dateString) {
    const filter = {
      key: "date",
      value: {
        startDate: new Date(dateString[0]).toISOString(),
        endDate: new Date(dateString[1]).toISOString(),
      },
      type: "date",
    };

    setFilterData([filter]);
  }

  /******************* 
  @Purpose :  used for handle filter tags
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleFiltertags = (index) => {
    switch (filterData[index].key) {
      case "date":
        return <RangePicker className=" w-100 " onChange={onChange} />;
      case "categoryId":
        return (
          <Select
            options={filterData[index].filterOptions}
            className="custom-select-container"
            classNamePrefix={"custom-select"}
            onChange={(e) => handleValueChange(e.value, index)}
          />
        );
      case "subCategoryId":
        return (
          <Select
            options={filterData[index].filterOptions}
            className="custom-select-container"
            classNamePrefix="custom-select"
            onChange={(e) => handleValueChange(e.value, index)}
          />
        );

      default:
        return (
          <Select
            className="custom-select-container"
            classNamePrefix="custom-select"
            onChange={(e) => handleValueChange(e.value, index)}
          />
        );
    }
  };

  /******************* 
  @Purpose :  used for filter select dropdown value for key
  @Parameter : {}
  @Author : INIC
  ******************/
  const filterTypeFieldValueoption = [
    { value: "date", label: "Date" },
    { value: "categoryId", label: "Category" },
  ];

  /******************* 
  @Purpose : Used for handle filter data
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleFilterArray = () => {
    let selected = filterData.map((item) => item.key);
    setSelectedFilterItems(selected);
  };

  /******************* 
  @Purpose :  used for set filter value
  @Parameter : {fieldValue,index}
  @Author : INIC
  ******************/
  const handleValueChange = (fieldValue, index) => {
    handleFilterArray();
    const filterRow = filterData;
    if (filterRow[index].key === "categoryId") {
      const catValue = filterData.findIndex(
        (obj) => obj.key === "subCategoryId"
      );
      if (catValue !== -1) {
        filterRow.splice(catValue, 1);
      }
    }
    filterRow[index].value = fieldValue;
    setFilterData([...filterRow]);
  };

  /******************* 
  @Purpose :  used for handle filter type field
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleFilterTypeField = (index) => {
    if (index === 0) {
      return filterTypeFieldValueoption;
    } else {
      return [
        { value: "gender", label: "Gender" },
        { value: "categoryId", label: "Category" },
        { value: "subCategoryId", label: "Sub-Category" },
      ];
    }
  };

  /******************* 
  @Purpose : Used for pagination
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const paginationChange = (pageNumber, pageSize) => {
    setPageNumberPur(pageNumber);
    setPageSizePur(pageSize);
  };

  const paginationChangeVid = (pageNumber, pageSize) => {
    setPageNumberVid(pageNumber);
    setPageSizePur(pageSize);
  };

  /******************* 
  @Purpose :  used for video card display
  @Parameter : {}
  @Author : INIC
  ******************/
  const videoCardDisplay = () => {
    try {
      dispatch(getVideoInfo(param.slug)).then((res) => {
        if (!res.status) {
          history.push("/celebrityList");
        }
        if (res.status === 1) {
          let {
            firstname,
            lastname,
            emailId,
            gender,
            dob,
            mobileNumber,
            status,
            address,
            city,
            twitter,
            instagramId,
            aadhar,
            state,
            pincode,
            fbId,
            youtubeId,
            creatorProfilephoto,
            _id,
            pricePerVideo,
            followerCount,
          } = res?.data[0]?.celebrityDetails[0];

          videosOfCeleb(_id);
          setFormData({
            ...formData,
            firstname,
            lastname,
            emailId,
            gender,
            dob,
            mobileNumber,
            status,
            address,
            city,
            twitter,
            instagramId,
            aadhar,
            state,
            pincode,
            fbId,
            youtubeId,
            creatorProfilephoto,
            _id,
            pricePerVideo,
            followerCount,
          });
        }
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    if (oldVideo) {
      setCurrentVideo(oldVideo);
    }
    if (currentVideo) {
      currentVideo.pause();
    }
  }, [oldVideo]);

  const playEventHandler = (video) => {
    setOldVideo(video);
  };
  /******************* 
  @Purpose : Used for purchase history
  @Parameter : {}
  @Author : INIC
  ******************/
  const purchaseHistoryData = async (id) => {
    try {
      let body = {
        creatorId: formData._id,
        searchText: searchText,
        pageNumber: pageNumberPur,
        pageSize: pageSizePur,
      };

      dispatch(getPurchaseHistory(body)).then((res) => {
        if (res.status) {
          setTotalPur(res?.totalNoOfOrders);
        } else {
          setPageNumberPur(1);
          setPageSizePur(5);
          setTotalPur(0);
        }
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  /******************* 
  @Purpose :  used for specific celeb video
  @Parameter : {}
  @Author : INIC
  ******************/
  //  pageNumberVid
  const videosOfCeleb = async (id) => {
    try {
      let body = {
        creatorId: id,
        searchText: searchPubVid,
        pageNumber: pageNumberVid,
        pageSize: pageSize,
        filter: [],
      };
      const resp = dispatch(getSpecificVideos(body)).then((res) => {
        if (res.status) {
          setTotal(res?.totalVideos);
        } else {
          setPageNumberVid(1);
          setPageSizePur(5);
          setTotal(0);
        }
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  /******************* 
  @Purpose :  used for reset filter
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleReset = async () => {
    let body = {
      creatorId: formData?._id,
      searchText: searchPubVid,
      pageNumber: pageNumber,
      pageSize: pageSize,
      filter: [],
    };
    dispatch(getSpecificVideos(body))
      .then((res) => {
        if (res) {
          onHide();
        }
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  };

  /******************* 
  @Purpose :  used for handle submit
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleSubmit = async () => {
    let body = {
      creatorId: formData?._id,
      searchText: searchText,
      pageNumber: pageNumber,
      pageSize: pageSize,
      filter: filterData,
    };

    dispatch(getSpecificVideos(body))
      .then((res) => {
        if (!res) {
          setModalShow(false);
          setFilterData([defaultFilter]);
          setSelectedFilterItems([]);
        }
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });

    setModalShow(false);
    setFilterData([defaultFilter]);
    setSelectedFilterItems([]);
  };

  /******************* 
  @Purpose : Used for Bradcrumb View
  @Parameter : {}
  @Author : INIC
  ******************/
  const breadcrumb = [
    {
      title: "Celebrity List",
      url: route.CELIBRITY_LIST,
    },
    {
      title: "Celebrity Profile",
      url: route.PURCHASE_HISTORY,
    },
  ];

  /******************* 
  @Purpose : Used for debounce for the search
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleDebouncedChange = React.useMemo(
    () => debounce((evt) => setSearchtext(evt), 100),
    []
  );
  /******************* 
  @Purpose : Used for search on change
  @Parameter : {e}
  @Author : INIC
  ******************/
  const handleSearchChange = (e) => {
    e.persist();
    handleDebouncedChange(e.target.value);
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Celebrity">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <div className="row">
              <div className="col-lg-12 mb-4">
                <div className="card mb-5">
                  <div className="card-header">
                    <h5 className="card-title">Celebrity Information</h5>
                  </div>
                  <div className="card-body user-details">
                    <div className="row">
                      <div className="col-lg-2">
                        <div className="user-image">
                          <img
                            src={
                              formData?.creatorProfilephoto
                                ? formData?.creatorProfilephoto
                                : "/assets/images/no-user.png"
                            }
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-5 align-self-center">
                        <div className="input-group row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            NAME:
                          </div>
                          <div className="col-md-8 p-2 request-data">
                            {formData?.firstname}&nbsp; {formData?.lastname}{" "}
                          </div>
                        </div>

                        <div className="input-group row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            EMAIL ID:
                          </div>
                          <div className="col-md-8 p-2 request-data">
                            <a href="mailto:contact@vistareels.com">
                              {formData?.emailId}
                            </a>
                          </div>
                        </div>

                        <div className="input-group row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            GENDER:
                          </div>
                          <div className="col-md-8 p-2 request-data">
                            {" "}
                            {formData?.gender}
                          </div>
                        </div>
                        {/* <div className="input-group row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            ADDRESS:
                          </div>
                          <div className="col-md-8 p-2 request-data">
                            {" "}
                            {formData?.address}
                          </div>
                        </div> */}
                        <div className="input-group row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            CITY:
                          </div>
                          <div className="col-md-8 p-2 request-data">
                            {" "}
                            {formData?.city}
                          </div>
                        </div>
                        <div className="input-group row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            INSTAGRAM:
                          </div>
                          <div className="col-md-8 p-2 request-data">
                            <a href={formData?.instagramId} target="_blank">
                              {formData?.instagramId}
                            </a>
                          </div>
                        </div>
                        <div className="input-group row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            STATUS:
                          </div>
                          <div className="col-md-8 p-2 request-data">
                            <span className="badge text-white bg-secondary">
                              {formData?.status ? "Active" : "Inactive"}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-5 align-self-center">
                        <div className="input-group row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            PHONE NUMBER:
                          </div>
                          <div className="col-md-8 p-2 request-data">
                            {formData?.mobileNumber}
                          </div>
                        </div>
                        {/* <div className="input-group row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            DOB:
                          </div>
                          <div className="col-md-8 p-2 request-data">
                            {formData?.dob}
                          </div>
                        </div> */}
                        <div className="input-group row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            STATE:
                          </div>
                          <div className="col-md-8 p-2 request-data">
                            {formData?.state}
                          </div>
                        </div>
                        <div className="input-group row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            PINCODE:
                          </div>
                          <div className="col-md-8 p-2 request-data">
                            {formData?.pincode}
                          </div>
                        </div>
                        <div className="input-group row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            YOUTUBE:
                          </div>
                          <div className="col-md-8 p-2 request-data">
                            {formData?.youtubeId}
                          </div>
                        </div>
                        <div className="input-group row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            PRICE PER VIDEO:
                          </div>

                          <div className="col-md-8 p-2 request-data">
                            {formData?.pricePerVideo
                              ? `₹ ${formData?.pricePerVideo} `
                              : "-"}
                            <p className="text-danger">
                              ₹ 100 Additional Vista Charge per Video
                            </p>
                          </div>
                        </div>
                        {/* follower count */}
                        <div className="input-group row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            No. of Followers:
                          </div>

                          <div className="col-md-8 p-2 request-data">
                            {formData?.followerCount
                              ? `${formData?.followerCount} `
                              : "0"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* table  purchase vids*/}
                <div className="card mb-5">
                  <div className="card-header">
                    <h5 className="card-title">Purchase Vids</h5>
                  </div>

                  <div className="row card-header d-flex align-items-center">
                    <div className="col-md-2 search position-relative has-icon-left">
                      <input
                        type="search"
                        value={searchText}
                        className="form-control text-capitalize"
                        placeholder="search"
                        onChange={(e) => handleSearchChange(e)}
                      />

                      {searchText && searchText.length > 0 ? (
                        <div className="clear mx-2">
                          <span onClick={clearInput}>
                            <em className="icon-close"></em>
                          </span>
                        </div>
                      ) : null}

                      <div className="form-control-position">
                        <em className="icon-magnifier mx-3"></em>
                      </div>
                    </div>

                    <div className="col-md-10 d-flex  justify-content-end">
                      <Button onClick={() => handlePurVideo()}>
                        Purchase Video
                      </Button>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table
                        className="table row-border nowrap common-datatable col-wrapper-table"
                        id="master-country-listing"
                      >
                        <thead>
                          <tr>
                            <th>
                              <b>Transaction #</b>
                            </th>

                            <th>
                              <b>Category</b>
                            </th>

                            <th>
                              <b>Purchase Date</b>
                            </th>

                            <th>
                              <b>Number Of Video</b>
                            </th>
                            <th>
                              <b>Price (₹)</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {purchaseHistory?.data &&
                          purchaseHistory?.data?.length === 0 ? (
                            <tr className="text-center text-danger not-found-txt">
                              <td colSpan="6">
                                <h6
                                  className="text-center text-danger not-found-txt"
                                  colSpan="6"
                                >
                                  {"No requested records are found "}
                                </h6>
                              </td>
                            </tr>
                          ) : (
                            purchaseHistory?.data &&
                            Array.isArray(purchaseHistory?.data) &&
                            purchaseHistory?.data?.map((list, key) => (
                              <tr key={list.id}>
                                <td>
                                  <div className="eamil-col d-flex align-items-center">
                                    <span>{list?.transactionId}</span>
                                  </div>
                                </td>

                                <td>
                                  <div className="eamil-col d-flex align-items-center">
                                    <span>{list?.category[0]?.title}</span>
                                  </div>
                                </td>
                                <td>
                                  <div className="eamil-col d-flex align-items-center">
                                    <span>
                                      {moment(list?.updatedAt).format(
                                        "l, h:mm:ss a"
                                      )}
                                    </span>
                                  </div>
                                </td>

                                <td>
                                  <div className="eamil-col d-flex align-items-center">
                                    <div>{list?.noOfVideosOrdered}</div>
                                  </div>
                                </td>
                                <td>
                                  <div className="eamil-col d-flex align-items-center">
                                    <span>{list?.price}</span>
                                  </div>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                      <p className="mb-0 align-self-center text-primary-light fs-14">
                        Showing <b>{purchaseHistory?.data?.length ?? 0}</b> Out
                        of
                        <b> {totalPur ?? 0}</b>
                      </p>
                      <div className="pagination-list ms-2">
                        <Pagination
                          className="ant-pagination"
                          pageSize={pageSizePur}
                          current={pageNumberPur}
                          total={totalPur}
                          onChange={paginationChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card mb-5">
                  <div className="card-header">
                    <h5 className="card-title">Video Detail</h5>
                  </div>
                  <div className="card-body user-details">
                    {celebvideoinfo &&
                      celebvideoinfo?.length > 0 &&
                      celebvideoinfo?.map((data, key) => (
                        <div className="row" key={data.id}>
                          <div className="col-lg-2 col-md-12 align-item-center">
                            <div className="video-card border-0 mb-0">
                              <div className="video-img position-relative">
                                <video
                                  className="br-12"
                                  width="100%;"
                                  height="170"
                                  controls
                                >
                                  <source
                                    src={data?.videoLink}
                                    type="video/mp4"
                                  ></source>
                                </video>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-5 col-md-6">
                            <div className="input-group row g-0">
                              <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                                TITLE :
                              </div>
                              <div className="col-md-8 p-2 request-data text-secondary">
                                {data?.videoTitle}
                              </div>
                            </div>

                            <div className="input-group row g-0">
                              <>
                                <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                                  SUB CATEGORY:
                                </div>
                                <div className="col-md-8 p-2 request-data text-secondary">
                                  {data?.subcategories &&
                                    data.subcategories.length > 0 &&
                                    data.subcategories
                                      .map((subcat) => subcat?.title)
                                      .toString()}
                                </div>
                              </>
                            </div>

                            <div className="input-group row g-0">
                              <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                                PRODUCT LINK:
                              </div>
                              <div className="col-md-8 p-2 request-data text-secondary">
                                {data?.affilietedProductUrl ? (
                                  <a
                                    href={data?.affilietedProductUrl}
                                    target="_blank"
                                  >
                                    {data?.affilietedProductUrl}
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-5 col-md-6">
                            <div className="input-group row g-0">
                              {data?.category &&
                                data?.category.length > 0 &&
                                data?.category.map((category, catKey) => (
                                  <>
                                    <div
                                      className="col-md-4 p-2 text-capitalize fw-bold request-title"
                                      key={category.id}
                                    >
                                      CATEGORY:
                                    </div>
                                    <div className="col-md-8 p-2 request-data text-secondary">
                                      {category?.title}
                                    </div>
                                  </>
                                ))}
                            </div>
                            <div className="input-group row g-0">
                              {data.celebrityDetails &&
                                data.celebrityDetails.length > 0 &&
                                data.celebrityDetails.map((name, celebKey) => (
                                  <>
                                    <div
                                      className="col-md-4 p-2 text-capitalize fw-bold request-title"
                                      key={name.id}
                                    >
                                      CELEBRITY:
                                    </div>
                                    <div className="col-md-8 p-2 request-data text-secondary">
                                      {`${name.firstname} ${name.lastname}`}
                                    </div>
                                  </>
                                ))}
                            </div>

                            <div className="input-group row g-0">
                              <div className="col-md-4 p-2 text-capitalize fw-bold request-title">
                                PUBLISH DATE:
                              </div>
                              <div className="col-md-8 p-2 request-data text-secondary">
                                {moment(data?.createdAt).format("l, h:mm:ss a")}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                <div className="card mb-5 ">
                  <div className="card-header">
                    <h5 className="card-title">Published Videos</h5>
                  </div>
                  <div className="card-header d-flex justify-content-between">
                    <div className="search position-relative has-icon-left d-flex ">
                      <input
                        type="search"
                        value={searchPubVid}
                        className="form-control text-capitalize me-2"
                        placeholder="search"
                        onChange={(e) => setSearchPubVid(e.target.value)}
                      />

                      {searchText && searchText.length > 0 ? (
                        <div className="clear">
                          <span onClick={clearInput}>
                            <em className="icon-close"></em>
                          </span>
                        </div>
                      ) : null}

                      <div className="form-control-position left-0">
                        <em className="icon-magnifier mx-3"></em>
                      </div>
                    </div>
                    <Button
                      variant="primary"
                      active
                      className="btn-bordered d-flex align-items-center justify-content-center"
                      onClick={() => setModalShow(true)}
                    >
                      <i className="bx bx-filter-alt align-text-top me-1"></i>
                      Filter
                    </Button>
                    <FilterModel
                      show={modalShow}
                      filterData={filterData}
                      setFilterData={setFilterData}
                      defaultFilter={defaultFilter}
                      filterTypeField={handleFilterTypeField}
                      handleFilterTypeFields={handleFilterTypeFields}
                      handleValueChange={handleValueChange}
                      handleSubmit={handleSubmit}
                      handleFiltertags={handleFiltertags}
                      handleFilterArray={handleFilterArray}
                      selectedFilterItems={selectedFilterItems}
                      isSearchButtonDisabled={isSearchButtonDisabled}
                      onHide={onHide}
                      handleReset={handleReset}
                      field={field}
                    />
                  </div>
                  <div className="card-body">
                    <div className="row">
                      {celebVideos && celebVideos?.data?.length > 0 ? (
                        celebVideos?.data?.map((video, id) => {
                          return (
                            <div
                              className="col-lg-4 col-xl-3 col-xxxl-2 col-md-4 col-6"
                              key={video?._id}
                            >
                              <div className="video-card">
                                <div className="video-img position-relative">
                                  <video
                                    controls={true}
                                    width="100%;"
                                    // className="border border-secondary border-1 video-box"
                                    height="250"
                                  >
                                    <source
                                      src={video?.videoLink}
                                      type="video/mp4"
                                    />
                                  </video>
                                </div>
                                <div className="video-info">
                                  <p className="video-title fw-semibold text-secondary">
                                    {video?.videoTitle}
                                  </p>

                                  <p className="video-date">
                                    {" "}
                                    {moment(video.createdAt).format("L")}
                                  </p>
                                  <p className="video-title-name text-secondary mb-1">
                                    By - {video?.celebrityDetails[0]?.firstname}
                                    &nbsp;
                                    {video?.celebrityDetails[0]?.lastname}
                                  </p>

                                  <div className="d-flex flex-wrap">
                                    <div className="text-orange fs-14 me-3">
                                      <span className="fw-semibold">
                                        Views :{" "}
                                      </span>{" "}
                                      {video?.viewCount}
                                    </div>
                                    <div className="text-orange fs-14">
                                      <span className="fw-semibold">
                                        Product Click :{" "}
                                      </span>
                                      {video?.affiliateClickCount}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="col-lg-4 col-xl-3 col-md-4 col-6">
                          <span className="text-danger">Videos not Found</span>
                        </div>
                      )}
                    </div>
                    <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                      <p className="mb-0 align-self-center text-primary-light fs-14">
                        Showing <b>{celebVideos?.data?.length ?? 0}</b> Out of
                        <b> {celebVideos?.totalVideos ?? 0}</b>
                      </p>
                      <div className="pagination-list ms-2">
                        <Pagination
                          className="ant-pagination"
                          pageSize={pageSize}
                          current={pageNumberVid}
                          total={celebVideos?.totalVideos}
                          onChange={paginationChangeVid}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </Layout>
  );
};

export default PurchaseHistory;
