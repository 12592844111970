import React, { useEffect } from "react";
import { FormLabel, FormText } from "react-bootstrap";
import clsx from "clsx";
import Select from "react-select";

/*******************
@purpose : used for common select field
@Parameter : {}
@Author : INIC
******************/
function SelectField({
  disabled,
  label,
  required = true,
  name,
  labelClassName,
  formik,
  feedbackClassName,
  onChange,
  touched,
  values,
  errors,
  optionValue = "name",
  ...others
}) {
  /*******************
  @purpose : used for assign error and touched 
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    if (formik && !touched && !errors) {
      touched = formik?.touched[name];
      errors = formik?.errors[name];
    }
  }, [formik]);

  /*******************
  @purpose : used for onselect change
  @Parameter : {}
  @Author : INIC
  ******************/
  const onSelectChange = (selectedOption) => {
    if (others.setIsoCode) {
      others.setIsoCode(selectedOption?.isoCode);
    }

    if (!selectedOption || !selectedOption.Disabled) {
      const event = { target: { name, value: "" } };
      if (selectedOption == null) event.target.value = "";
      else event.target.value = selectedOption;

      if (onChange) onChange(event);
      if (formik) formik?.handleChange(event);
    }
  };

  /*******************
  @purpose : used for dynamic classes 
  @Parameter : {}
  @Author : INIC
  ******************/
  const getValidateClass = () => {
    if (!touched) {
      return "w-100";
    }
    if (
      (formik?.touched[name] && formik?.errors[name]) ||
      (touched && errors)
    ) {
      return "w-100 error-block";
    }
    return " w-100 success-block";
  };

  /******************* 
  @purpose : Rander HTML/ React Components
  @Author :INIC
  ******************/
  return (
    <div
      className={clsx("position-relative mb-3 input-wrapper", {
        "error-block":
          !disabled &&
          ((formik?.touched[name] && formik?.errors[name]) ||
            (touched && errors)),
      })}
    >
      {label && (
        <FormLabel className={labelClassName}>
          {label}
          {required && <sup className="sub-asterisk text-danger">*</sup>}
        </FormLabel>
      )}
      <div className={getValidateClass()}>
        <Select
          {...formik?.getFieldProps(name)}
          {...others}
          value={values}
          onChange={(selectedOption) => onSelectChange(selectedOption)}
        />
        {((formik?.touched[name] && formik?.errors[name]) ||
          (touched && errors)) && (
          <span className="icon-error-icon error-icon" />
        )}
        {formik?.touched[name] && !formik?.errors[name] && (
          <span className="icon-ic-check-mark error-icon" />
        )}
      </div>
      {((formik?.touched[name] && formik?.errors[name]) ||
        (touched && errors)) && (
        <FormText className={(feedbackClassName, "error text-danger")}>
          {formik?.errors[name] ?? errors}
        </FormText>
      )}
    </div>
  );
}

export default SelectField;
