import React, { useState, useEffect } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "rc-pagination";
import { Layout, Footer } from "../../Components/Layout";
import { celebrityList } from "../../Store/Actions/CelebrityVideos";

import { route } from "../../Utils";
import VideoCard from "../../Components/BrandVideos/VideoCard";

import { SelectField } from "../../Components/FormFields";
import { StateCities } from "../../Store/Actions/Stateandcities";
import { getAllVideosTitle } from "../../Store/Actions/VideoTitleList";

/******************* 
@Purpose :  used for age selection
@Parameter : {}
@Author : INIC
******************/
const ages = [
  {
    value: {
      minAge: 1,
      maxAge: 9,
    },
    label: "< 10",
  },
  {
    value: {
      minAge: 10,
      maxAge: 18,
    },
    label: "10 - 18",
  },
  {
    value: {
      minAge: 18,
      maxAge: 35,
    },
    label: "18 - 35",
  },
  {
    value: {
      minAge: 35,
      maxAge: 150,
    },
    label: "35 +",
  },
];

/******************* 
@Purpose :  used for gender selection
@Parameter : {}
@Author : INIC
******************/
const gender = [
  {
    value: "MALE",
    label: "Male",
  },
  {
    value: "FEMALE",
    label: "Female",
  },
  {
    value: "OTHER",
    label: "Other",
  },
];

/******************* 
@Purpose : Used for Brand videos View
@Parameter : {}
@Author : Vista app
******************/
const CelebrityList = () => {
  const dispatch = useDispatch();
  const { celebrityLists } = useSelector((data) => data?.celebVideos);
  const tooltiipShow = true;
  const { celeblist } = useSelector((data) => data?.celebVideos);
  const { statecitieslist } = useSelector((data) => data?.statecities);
  const { allVideosTitle } = useSelector((data) => data?.allvideostitle);
  const [cities, setCities] = useState([]);
  const { categoryInfo } = useSelector((data) => data.user);
  const [category, setCategory] = useState("");
  const [celeb, setCeleb] = useState("");
  const [video, setVideo] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [subCategory, setSubCategory] = useState();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [currentVideo, setCurrentVideo] = useState();
  const [oldVideo, setOldVideo] = useState();
  const [data, setData] = useState([]);

  const showBtn = true;

  /******************* 
  @Purpose : React hook Used for get Brand videos 
  @Parameter : {}
  @Author : Vista app
  ******************/
  useEffect(() => {
    getCelebrityVideos();
  }, [page, pageSize, data]);

  useEffect(() => {
    dispatch(StateCities());
    dispatch(getAllVideosTitle());
  }, []);

  /******************* 
  @Purpose :  used for get celeb videos
  @Parameter : {}
  @Author : INIC
  ******************/
  const getCelebrityVideos = async (tempPage = null) => {
    let filterValues = data.map((value) => {
      if (value.value.value) {
        let arrData = {
          key: value.key,
          type: value.type,
          value: value.value.filterValue ?? value.value.value,
        };
        return arrData;
      } else {
        let arrData = {
          key: value.key,
          type: value.type,
          value: value.filterValue,
        };
        return arrData;
      }
    });

    let body = {
      pageNumber: tempPage ?? page,
      pageSize: pageSize,
      filter: filterValues,
    };

    try {
      dispatch(celebrityList(body)).then((response) => {
        if (response.status) {
          setPage(response?.pageNumber);
          setPageSize(response?.pageSize);
          setTotal(response?.totalVideos);
        } else {
          setPage(1);
          setPageSize(10);
          setTotal(0);
        }
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  /******************* 
  @Purpose :  used for handle select field value
  @Parameter : {fieldValue,index}
  @Author : INIC
  ******************/
  const onHandleMultiChange = (e, key) => {
    let filterData = data;
    filterData = filterData.filter((val) => val.key !== key);
    if (e.target.value) {
      e.target.filterValue =
        e.target.value && e.target.value.map((val) => val.value);
      e.target.type = "contains";
      e.target.key = key;
      setData([...filterData, e.target]);
    } else {
      setData(filterData);
    }
  };

  const onHandleChange = (e, key) => {
    let filterData = data;
    filterData = filterData.filter((val) => val.key !== key);
    e.target.filterValue = e.target.value;
    e.target.type = "contains";
    e.target.key = key;
    setData([...filterData, e.target]);
  };

  /******************* 
  @Purpose :  used for celeb value 
  @Parameter : {}
  @Author : INIC
  ******************/
  const celebValue = () => {
    let celebArr = [];
    celeblist?.length > 0 &&
      celeblist?.forEach((optiondata) => {
        let tempObj = {
          label: optiondata?.Celebrity,
          value: optiondata?._id,
        };
        celebArr.push(tempObj);
        setCeleb(celebArr);
      });
  };

  /******************* 
  @Purpose :  used for video select by title
  @Parameter : {fieldValue,index}
  @Author : INIC
  ******************/
  const videoValue = () => {
    let videoArr = [];
    allVideosTitle?.data?.length > 0 &&
      allVideosTitle?.data?.forEach((optiondata) => {
        let tempObj = {
          label: optiondata?.videoTitle,
          value: optiondata?._id,
        };
        videoArr.push(tempObj);
        setVideo(videoArr);
      });
  };

  /******************* 
  @Purpose :  used for category value 
  @Parameter : {}
  @Author : INIC
  ******************/
  const categoryValue = () => {
    let categoryArr = [];
    categoryInfo?.length > 0 &&
      categoryInfo?.forEach((optiondata) => {
        let tempObj = {
          label: optiondata?.title,
          value: optiondata?._id,
          ...optiondata,
        };
        categoryArr.push(tempObj);
        setCategory(categoryArr);
      });
  };

  /******************* 
  @Purpose :  used for sub category value 
  @Parameter : {list}
  @Author : INIC
  ******************/
  const subCategoryValue = (catData) => {
    let subCategoryArr = [];
    catData?.subCategoryDetails?.length > 0 &&
      catData?.subCategoryDetails?.forEach((subCategory) => {
        let tempObj2 = {
          label: subCategory?.title,
          value: subCategory?._id,
        };
        subCategoryArr.push(tempObj2);
        setSubCategory(subCategoryArr);
      });
    return subCategoryArr;
  };

  /******************* 
  @Purpose :  React hook used for get categories , sub categories and videos
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    categoryValue();
    celebValue();
    videoValue();
  }, []);

  /******************* 
  @Purpose : Used for Bread crumb 
  @Parameter : {}
  @Author : Vista app
  ******************/
  const breadcrumb = [
    {
      title: "Celebrity List",
      url: route.CELIBRITY_LIST,
    },
  ];

  /******************* 
  @Purpose : Used for get state list
  @Parameter : {countrycode}
  @Author : INIC
  ******************/
  const getStateList = () => {
    return statecitieslist?.map((state) => ({
      label: state?.StateName,
      value: state?.StateName,
      ...state,
    }));
  };

  /******************* 
  @Purpose : Used for get states cities list
  @Parameter : {countrycode,statecode}
  @Author : INIC
  ******************/
  const getCitiesList = (data) => {
    const cities = data?.map((city) => ({
      label: city,
      value: city,
    }));
    setCities(cities);
  };

  /******************* 
  @Purpose : Used for pagination
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const paginationChange = (page, pagesize) => {
    setPage(page);
    setPageSize(pagesize);
  };

  /******************* 
    @Purpose :  used for api calling for filter
    @Parameter : {}
    @Author : INIC
    ******************/
  const handleReset = async () => {
    if (data.length) {
      let body = {
        pageNumber: 1,
        pageSize: 10,
        filter: [],
      };
      try {
        dispatch(celebrityList(body)).then((response) => {
          setData([]);
          if (response.status) {
            setTotal(response?.totalVideos);
            setPage(response.pageNumber);
            setPageSize(response.pageSize);
          } else {
            setPage(1);
            setPageSize(10);
            setTotal(0);
          }
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
  };

  useEffect(() => {
    if (oldVideo) {
      setCurrentVideo(oldVideo);
    }
    if (currentVideo) {
      currentVideo.pause();
    }
  }, [oldVideo]);

  const playEventHandler = (video) => {
    setOldVideo(video);
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Celebrity">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <div className="row mb-5">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header ">
                    <Row>
                      <Col lg={3} md={6}>
                        <SelectField
                          id="celeb"
                          isMulti
                          className="custom-select-container"
                          classNamePrefix={"custom-select"}
                          name="celeb"
                          options={celeb}
                          placeholder="Select By Celebrity"
                          onChange={(e) => {
                            onHandleMultiChange(e, "creatorId");
                          }}
                          values={
                            (data &&
                              data.filter((dat) => dat.key === "creatorId")
                                .length > 0 &&
                              data.filter((dat) => dat.key === "creatorId")[0]
                                .value) ||
                            null
                          }
                        />{" "}
                      </Col>
                      <Col lg={3} md={6}>
                        <SelectField
                          id="gender"
                          name="gender"
                          isMulti
                          className="custom-select-container"
                          classNamePrefix={"custom-select"}
                          placeholder="Select By Gender"
                          options={gender}
                          onChange={(e) => {
                            onHandleMultiChange(e, "gender");
                          }}
                          values={
                            (data &&
                              data.filter((dat) => dat.key === "gender")
                                .length > 0 &&
                              data.filter((dat) => dat.key === "gender")[0]
                                .value) ||
                            null
                          }
                        />
                      </Col>
                      <Col lg={3} md={6}>
                        <SelectField
                          id="age"
                          name="age"
                          placeholder="Select By Age"
                          className="custom-select-container"
                          classNamePrefix={"custom-select"}
                          options={ages}
                          onChange={(e) => {
                            onHandleChange(e, "age");
                          }}
                          values={
                            (data &&
                              data.filter((dat) => dat.key === "age").length >
                                0 &&
                              data.filter((dat) => dat.key === "age")[0]
                                .value) ||
                            null
                          }
                        />
                      </Col>
                      <Col lg={3} md={6}>
                        <SelectField
                          id="category"
                          name="category"
                          placeholder="Select By Category"
                          className="custom-select-container"
                          classNamePrefix={"custom-select"}
                          options={category}
                          onChange={(e) => {
                            onHandleChange(e, "categoryId");
                            subCategoryValue(e.target.value);
                          }}
                          values={
                            (data &&
                              data.filter((dat) => dat.key === "categoryId")
                                .length > 0 &&
                              data.filter((dat) => dat.key === "categoryId")[0]
                                .value) ||
                            null
                          }
                        />{" "}
                      </Col>
                      <Col lg={3} md={6}>
                        <SelectField
                          id="subCategory"
                          name="subCategory"
                          isMulti
                          className="custom-select-container"
                          classNamePrefix={"custom-select"}
                          placeholder="Select By Sub Category"
                          options={subCategory}
                          onChange={(e) => {
                            onHandleMultiChange(e, "subCategoryId");
                          }}
                          isOptionDisabled={() => !subCategoryValue}
                          values={
                            (data &&
                              data.filter((dat) => dat.key === "subCategoryId")
                                .length > 0 &&
                              data.filter(
                                (dat) => dat.key === "subCategoryId"
                              )[0].value) ||
                            null
                          }
                        />
                      </Col>
                      <Col lg={3} md={6}>
                        <SelectField
                          options={getStateList()}
                          id="State"
                          name="State"
                          placeholder="Select By State"
                          className="custom-select-container"
                          classNamePrefix={"custom-select"}
                          onChange={(e) => {
                            onHandleChange(e, "state");
                            getCitiesList(e.target.value.cities);
                          }}
                          values={
                            (data &&
                              data.filter((dat) => dat.key === "state").length >
                                0 &&
                              data.filter((dat) => dat.key === "state")[0]
                                .value) ||
                            null
                          }
                        />
                      </Col>
                      <Col lg={3} md={6}>
                        <SelectField
                          options={cities}
                          id="City"
                          name="City"
                          isMulti
                          className="custom-select-container"
                          classNamePrefix={"custom-select"}
                          placeholder="Select By City"
                          onChange={(e) => {
                            onHandleMultiChange(e, "city");
                          }}
                          values={
                            (data &&
                              data.filter((dat) => dat.key === "city").length >
                                0 &&
                              data.filter((dat) => dat.key === "city")[0]
                                .value) ||
                            null
                          }
                        />
                      </Col>
                      <Col lg={3} md={6}>
                        <SelectField
                          id="video"
                          name="video"
                          isMulti
                          className="custom-select-container"
                          classNamePrefix={"custom-select"}
                          options={video}
                          placeholder="Select By Video Title"
                          onChange={(e) => {
                            onHandleMultiChange(e, "videoId");
                          }}
                          values={
                            (data &&
                              data.filter((dat) => dat.key === "videoId")
                                .length > 0 &&
                              data.filter((dat) => dat.key === "videoId")[0]
                                .value) ||
                            null
                          }
                        />
                      </Col>
                    </Row>
                    <div className="text-end">
                      <Button
                        className=" text-error h-25 btn-primary"
                        onClick={() => handleReset()}
                      >
                        Reset
                      </Button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row video-list">
                      {celebrityLists?.data?.length > 0 ? (
                        celebrityLists?.data?.map((data, key) => (
                          <div
                            className="col-lg-4 col-xl-3 col-xxxl-2 col-md-4 col-6"
                            key={data.id}
                          >
                            <VideoCard
                              link={`${route.PURCHASE_HISTORY}/${data?._id}`}
                              caption={data?.videoTitle}
                              createdAt={data?.createdAt}
                              videoLink={data?.videoLink}
                              celebrityDetails={data?.celebrityDetails}
                              viewCount={data?.viewCount}
                              productViews={data?.affiliateClickCount}
                              playEventHandler={playEventHandler}
                              currentVideo={currentVideo}
                              tooltiipShow={tooltiipShow}
                              showBtn={showBtn}
                            />
                          </div>
                        ))
                      ) : (
                        <div className="col-12 text-center">
                          <h6 className="text-danger mt-5 mb-0">
                            Videos Not Found
                          </h6>
                        </div>
                      )}

                      <div className="table-footer d-flex flex-wrap align-items-center justify-content-between">
                        <p className="mb-0 align-self-center text-primary-light fs-14">
                          Showing <b>{celebrityLists?.data?.length ?? 0}</b> Out
                          of
                          <b> {total ?? 0}</b>
                        </p>
                        <div className="pagination-list ms-2">
                          <Pagination
                            className="ant-pagination"
                            pageSize={pageSize}
                            current={page}
                            total={total}
                            onChange={paginationChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </Layout>
  );
};

export default CelebrityList;
