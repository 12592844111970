import { callApi } from "../../Api";
import Routes from "../../Api/Routes";
const { DOWNLOAD_REPORTS, REPORTS_DATA } = Routes;
/*******************
@purpose :  used for celebrity list display videos
@Parameter : {data}
@Author : INIC
******************/

export const getDownloadReports = (data) => async (dispatch) => {
  const res = await callApi(`${DOWNLOAD_REPORTS}`, data, "post", "", true);
  if (res) {
    await dispatch(setReportsDownload(res));
    return Promise.resolve(res);
  } else return Promise.reject(res);
};
export const getReportsdata = (data) => async (dispatch) => {
  const res = await callApi(`${REPORTS_DATA}`, data, "post", "", true);
  if (res) {
    await dispatch(setReportsData(res));
    return Promise.resolve(res);
  } else return Promise.reject(res);
};
export const setReportsDownload = (data) => ({
  type: "DOWNLOAD_REPORTS",
  payload: data,
});
export const setReportsData = (data) => ({
  type: "REPORTS",
  payload: data,
});
