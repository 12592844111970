import { callApi } from "../../Api";
import Routes from "../../Api/Routes"; // API endpoint routes
import { showMessageNotification } from "../../Utils/Functions";
const { SAVE_CARD_INFO, DELETE_CARD_DETAIL, GET_CARDS } = Routes;

/*******************
@purpose :  save card info
@Parameter : {data}
@Author : INIC
******************/

export const savecardInfo = (data) => async () => {
  data.cardNumber = data.cardNumber.replaceAll(" ", "");
  const res = await callApi(`${SAVE_CARD_INFO}`, data, "post", "", true);
  if (res) {
    if (res?.status) {
      showMessageNotification(res?.message, "success");
    } else showMessageNotification(res?.message, "error");
    return Promise.resolve(res);
  }
  return Promise.reject(res);
};
/*******************
@purpose :  set user profile
@Parameter : {data}
@Author : INIC
******************/
export const setCardInfo = (data) => ({
  type: "SET_CARD_INFO",
  payload: data,
});

/*******************
@purpose :  delete card info
@Parameter : {data}
@Author : INIC
******************/
export const deleteCard = (data) => async () => {
  const res = await callApi(`${DELETE_CARD_DETAIL}`, data, "delete", "", true);
  if (res) {
    if (res.status) {
      showMessageNotification(res.message, "success");
    } else showMessageNotification(res.message, "error");
    return Promise.resolve(res);
  }
  return Promise.reject(res);
};

/*******************
@purpose :  set user profile
@Parameter : {data}
@Author : INIC
******************/
export const removeCardInfo = (data) => ({
  type: "REMOVE_CARD_INFO",
  payload: data,
});

/*******************
@purpose :  get cards info
@Parameter : {data}
@Author : INIC
******************/

export const getAllCards = () => async (dispatch) => {
  const res = await callApi(`${GET_CARDS}`, "", "get", "", true);
  if (res) {
    if (res.status) {
      dispatch(setCardInfo(res.data));
    }
    return Promise.resolve(res);
  }
  return Promise.reject(res);
};
