import React from "react";
import { Link } from "react-router-dom";

/******************* 
@Purpose : Used for breadcrumb
@Parameter : { breadcrumb, breadcrumbTitle }
@Author : INIC
******************/
const BreadCrum = ({ breadcrumb, breadcrumbTitle }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb p-0">
        <li className="breadcrumb-item">
          <span className="link">{breadcrumbTitle}</span>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          <Link to="/dashboard">
            <i className="icon-home icon" />
          </Link>
          {breadcrumb?.map(({ url, title }, index) => (
            <React.Fragment key={url + index}>
              <span className="icon-arrow-right icon fs-10"></span>
              {breadcrumb.length - 1 === index ? (
                title
              ) : (
                <Link
                  to={url}
                  className="link"
                  key={title}
                >
                  {title}
                </Link>
              )}
            </React.Fragment>
          ))}
        </li>
      </ol>
    </nav>
  );
};

export default BreadCrum;
