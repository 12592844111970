import React, { useState, useEffect, useRef } from "react";
import {
  Tab,
  Tabs,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
  Row,
  Col,
} from "react-bootstrap";
import {
  showMessageNotification,
  showModalNotification,
} from "../../Utils/Functions";
import errorMessages from "../../Utils/ErrorMessages";
import {
  getAllVideos,
  getVideoApprove,
  getRejectVideo,
  getCelebDropdown,
  getTransactionList,
  getCategories,
} from "../../Store/Actions/CelebrityVideos";

import { useDispatch } from "react-redux";
import Select from "react-select";
import "rc-pagination/assets/index.css";
import Pagination from "rc-pagination";

import $ from "jquery";
import DatePicker from "react-datepicker";

import moment from "moment";
import { Footer } from "../../Components/Layout";
import { Link, useHistory } from "react-router-dom";
import { useOnClickOutside } from "../../Hooks";
import Layout from "../../Components/Layout/Layout";

import "antd/dist/antd.css";
import "react-datepicker/dist/react-datepicker.css";

$.DataTable = require("datatables.net");
require("datatables.net-responsive");

/******************* 
@Purpose : Used for users list view
@Parameter : {}
@Author : INIC
******************/
function VideoApprovalQueue() {
  let history = useHistory();
  const dispatch = useDispatch();
  const [celebList, setCelebList] = useState([]);
  const [pendingVideos, setPendingVideos] = useState([]);
  const [category, setCategory] = useState(null);
  const [subCategoryValue, setsubCategoryValue] = useState("");
  const [categories, setCategories] = useState([]);
  const [approvedVideos, setApprovedVideos] = useState([]);
  const [rejectedVideos, setRejectedVideos] = useState([]);
  const [reason, setReason] = useState("request_sent");
  const [pagesize, setPagesize] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [transaction, setTransaction] = useState("");
  const [transactionList, setTransactionList] = useState([]);
  const [, setisFormValidPassword] = useState(true);
  const [celeb, setCeleb] = useState("");
  const refFilter = useRef();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [changeErrors, setChangeErrors] = useState("");
  const [show1, setShow1] = useState(false);
  const [formData, setFormData] = useState({
    filterData: "",
    errorsFilterData: {},
  });
  const [errorState, setErrorState] = useState({});
  useOnClickOutside(refFilter);
  const [id, setId] = useState();
  let subcategoryData;
  /******************* 
  @Purpose : Used for Bread crumb
  @Parameter : {}
  @Author : INIC
  ******************/
  const breadcrumb = [
    {
      title: "Video Approval Queue",
    },
  ];

  /******************* 
  @Purpose : Used for get all roles
  @Parameter : {}
  @Author : INIC
  ******************/
  const getAllCelebrities = async () => {
    let body = { searchText: "" };
    try {
      const response = await dispatch(getCelebDropdown(body));
      if (response.status === 1) {
        setCelebList(
          response?.data?.map((el) => {
            return {
              value: el._id,
              label: el.Celebrity,
            };
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  /******************* 
  @Purpose : Used for getAllCategories
  @Parameter : {}
  @Author : INIC
  ******************/
  const getAllCategories = async () => {
    try {
      const response = await dispatch(getCategories());

      if (response.status === 1) {
        const categoryData = Array.isArray(response.data)
          ? response.data.map((item) => ({
              ...item,
              label: item.title,
              value: item.title,
            }))
          : [];
        setCategories(categoryData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /******************* 
  @Purpose : Used for sub category data
  @Parameter : {}
  @Author : INIC
  ******************/
  const getSubCategoryData = () => {
    let subcategoryData =
      typeof category === "object"
        ? category?.subCategoryDetails?.map((item) => ({
            ...item,
            label: item?.title,
            value: item?.title,
          }))
        : "select the category";

    return subcategoryData;
  };
  // useEffect Sub category

  /******************* 
  @Purpose : Used for get all roles
  @Parameter : {}
  @Author : INIC
  ******************/
  const getTransactions = async () => {
    let body = { searchText: "" };
    try {
      const response = await dispatch(getTransactionList(body));

      if (response.status === 1) {
        setTransactionList(
          response?.data?.map((el) => {
            return {
              value: el._id,
              label: el.transactionId,
            };
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  /******************* 
  @Purpose : Used for get panding,aprooved and reject as well celibrity and transactions
  @Parameter : status, timezoneId
  @Author : INIC
  ******************/
  useEffect(() => {
    getPendingVideos();
    getApprovedVideos();
    getRejectedVideos();
    getAllCelebrities();
    getTransactions();
    getAllCategories();
  }, [page, pagesize, reason]);

  /******************* 
  @Purpose : Used for getPendingVideos
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const getPendingVideos = async () => {
    let body = {
      pageNumber: page,
      pageSize: pagesize,
      brandApprovalStatus: reason,
      filter: [],
    };
    try {
      const response = await dispatch(getAllVideos(body));
      if (response.status === 1) {
        setPendingVideos(response?.data);
        setTotal(response?.totalVideos);
      } else {
        setPendingVideos([]);
        setPage(1);
        setTotal(0);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  /******************* 
  @Purpose : Used for getApprovedVideos
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const getApprovedVideos = async () => {
    let body = {
      pageNumber: page,
      pageSize: pagesize,
      brandApprovalStatus: reason,
      filter: [],
    };

    try {
      const response = await dispatch(getAllVideos(body));

      if (response.status === 1) {
        setApprovedVideos(response?.data);
        setTotal(response?.totalVideos);
      } else {
        setApprovedVideos([]);
        setPage(1);
        setTotal(0);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  /******************* 
  @Purpose : Used for getRejectedVideos
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const getRejectedVideos = async (e) => {
    let body = {
      pageNumber: page,
      pageSize: pagesize,
      brandApprovalStatus: reason,
      filter: [],
    };

    try {
      const response = await dispatch(getAllVideos(body));

      if (response.status === 1) {
        setRejectedVideos(response?.data);
        setTotal(response?.totalVideos);
      } else {
        setRejectedVideos([]);
        setPage(1);
        setTotal(0);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  /******************* 
  @Purpose : Used for pagination
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const paginationChange = (page, pagesize) => {
    setPage(page);
    setPagesize(pagesize);
  };

  /******************* 
  @Purpose : Used for modal in the rejection
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const modelCloseFun1 = () => {
    setConfirmPassword("");
    setShow1(false);
    getPendingVideos();
  };

  /******************* 
  @Purpose : approve celebrity
  @Parameter : {}
  @Author : Vista app
  ******************/
  const videoApprove = async (id) => {
    let body = {
      videoId: id,
    };

    try {
      const response = await dispatch(getVideoApprove(body));
      if (response.status === 1) {
        showMessageNotification("This video is approved", "success");
        getPendingVideos();
      } else if (response.status === 1) {
        showMessageNotification(response?.message, "error");
      }
    } catch (error) {
      console.error();
      throw error;
    }
  };

  /******************* 
  @Purpose : used for handle modal
  @Parameter : {}
  @Author : Vista app
 ******************/
  const handleRejectVideo = async (id) => {
    setShow1(true);
    setId(id);
  };

  /******************* 
  @Purpose : validate modal fields
  @Parameter : {}
  @Author : Vista app
  ******************/
  const validPasswords = () => {
    let changeError = { confirmPassword: "" };
    let isFormValidPassword = true;
    if (!confirmPassword.trim())
      changeError.confirmPassword = errorMessages.PROVIDE_COMMENT;
    else changeError.confirmPassword = "";
    if (changeError.confirmPassword !== "") isFormValidPassword = false;
    setChangeErrors(changeError);
    setisFormValidPassword(isFormValidPassword);
    return isFormValidPassword;
  };

  /******************* 
  @Purpose : reject celeb
  @Parameter : {}
  @Author : Vista app
  ******************/
  const rejectVideo = async (id) => {
    let body = {
      videoId: id,
      comment: confirmPassword,
    };

    try {
      if (validPasswords()) {
        const response = await dispatch(getRejectVideo(body));
        if (response.status === 1) {
          showModalNotification("Rejected successfully", "success");
          setConfirmPassword("");
          modelCloseFun1();
          getPendingVideos();
        } else if (response.status === 1) {
          showMessageNotification(response?.message, "error");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  /******************* 
  @Purpose : Used for applay filter
  @Parameter : {}
  @Author : INIC
  ******************/
  const resetFilter = () => {
    setCeleb("");
    setCategory("");
    setsubCategoryValue("");
    setTransaction("");
    setStartDate("");
    setEndDate("");
    setFormData("");
    getPendingVideos();
    getApprovedVideos();
    getRejectedVideos();
  };

  /******************* 
  @Purpose : Used for validate filter search
  @Parameter : status, timezoneId
  @Author : INIC
  ******************/
  const validateFilterSearch = () => {
    let errorsTemp = { ...formData };
    let isFormValidPassword = true;
    if (startDate && !endDate) {
      errorsTemp.errorsFilterData.endDate = errorMessages.END_DATE;
      isFormValidPassword = false;
    }
    setFormData(errorsTemp);
    return isFormValidPassword;
  };

  /******************* 
  @Purpose : Used for applay filter
  @Parameter : {}
  @Author : INICif
  ******************/
  const ApplyFilter = async () => {
    let reqPayload = [];

    try {
      if (validateFilterSearch()) {
        if (celeb) {
          let celebFilter = celeb?.map((item) => {
            return {
              key: "creatorId",
              type: "contains",
              value: item.value,
            };
          });

          reqPayload = [...celebFilter];
        }
        if (category) {
          reqPayload.push({
            key: "categoryId",
            type: "contains",
            value: category?._id,
          });
        }
        if (subCategoryValue) {
          let subCatFilterValues = subCategoryValue?.map((item) => {
            return {
              key: "subCategoryId",
              type: "contains",
              value: item?._id,
            };
          });

          reqPayload = [...reqPayload, ...subCatFilterValues];
        }
        if (transaction) {
          let transactionFilter = transaction?.map((item) => {
            return {
              key: "purchaseOrderId",
              type: "contains",
              value: item?.value,
            };
          });

          reqPayload = [...reqPayload, ...transactionFilter];
        }
        if (startDate && endDate) {
          reqPayload.push({
            key: "date",
            value: {
              startDate: moment(startDate).format("YYYY-MM-DDTHH:mm:ss"),

              endDate: moment(endDate).format("YYYY-MM-DDTHH:mm:ss"),
            },
            type: "date",
          });
        }

        let body = {
          pageNumber: 1,
          pageSize: pagesize,
          brandApprovalStatus: reason,
          filter: reqPayload,
        };

        const response = await dispatch(getAllVideos(body));

        if (response.status === 1) {
          setPendingVideos(response?.data);
          setPage(1);
          setTotal(response?.totalVideos);
        } else if (response.status === 0) {
          showModalNotification("No videos were found", "error");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  /******************* 
  @Purpose : Used for applay filter for approved videos
  @Parameter : {}
  @Author : INIC
  ******************/
  const ApplyApprovedFilter = async () => {
    let reqPayload = [];
    try {
      if (validateFilterSearch()) {
        if (celeb) {
          let celebFilter1 = celeb?.map((item) => {
            return {
              key: "creatorId",
              type: "contains",
              value: item.value,
            };
          });
          reqPayload = [...celebFilter1];
        }
        if (category) {
          reqPayload.push({
            key: "categoryId",
            type: "contains",
            value: category?._id,
          });
        }
        if (subCategoryValue) {
          let subCatFilterValues1 = subCategoryValue?.map((item) => {
            return {
              key: "subCategoryId",
              type: "contains",
              value: item?._id,
            };
          });
          reqPayload = [...reqPayload, ...subCatFilterValues1];
        }
        if (transaction) {
          let transactionFilter1 = transaction?.map((item) => {
            return {
              key: "purchaseOrderId",
              type: "contains",
              value: item?.value,
            };
          });
          reqPayload = [...reqPayload, ...transactionFilter1];
        }
        if (startDate && endDate) {
          reqPayload.push({
            key: "date",
            value: {
              startDate: moment(startDate).format("YYYY-MM-DDTHH:mm:ss"),

              endDate: moment(endDate).format("YYYY-MM-DDTHH:mm:ss"),
            },
            type: "date",
          });
        }

        let body = {
          pageNumber: 1,
          pageSize: pagesize,
          brandApprovalStatus: reason,
          filter: reqPayload,
        };

        const response = await dispatch(getAllVideos(body));

        if (response.status === 1) {
          setPage(1);
          setApprovedVideos(response?.data);
          setTotal(response?.totalVideos);
        } else if (response.status === 0) {
          showModalNotification("No videos were found", "error");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  /******************* 
  @Purpose : Used for applay filter for rejected videos
  @Parameter : {}
  @Author : INIC
  ******************/
  const ApplyRejectedFilter = async () => {
    let reqPayload = [];
    try {
      if (validateFilterSearch()) {
        if (celeb) {
          let celebFilter11 = celeb?.map((item) => {
            return {
              key: "creatorId",
              type: "contains",
              value: item.value,
            };
          });

          reqPayload = [...celebFilter11];
        }
        if (category) {
          reqPayload.push({
            key: "categoryId",
            type: "contains",
            value: category?._id,
          });
        }
        if (subCategoryValue) {
          let subCatFilterValues11 = subCategoryValue?.map((item) => {
            return {
              key: "subCategoryId",
              type: "contains",
              value: item?._id,
            };
          });
          reqPayload = [...reqPayload, ...subCatFilterValues11];
        }
        if (transaction) {
          let transactionFilter11 = transaction?.map((item) => {
            return {
              key: "purchaseOrderId",
              type: "contains",
              value: item?.value,
            };
          });
          reqPayload = [...reqPayload, ...transactionFilter11];
        }
        if (startDate && endDate) {
          reqPayload.push({
            key: "date",
            value: {
              startDate: moment(startDate).format("YYYY-MM-DDTHH:mm:ss"),

              endDate: moment(endDate).format("YYYY-MM-DDTHH:mm:ss"),
            },
            type: "date",
          });
        }

        let body = {
          pageNumber: 1,
          pageSize: pagesize,
          brandApprovalStatus: reason,
          filter: reqPayload,
        };

        const response = await dispatch(getAllVideos(body));

        if (response.status === 1) {
          setPage(1);
          setRejectedVideos(response?.data);
          setTotal(response?.totalVideos);
        } else if (response.status === 0) {
          showModalNotification("No videos were found", "error");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleTabs = (e) => {
    setErrorState("");
    setReason(e);
    setCeleb("");
    setCategory("");
    setsubCategoryValue("");
    setTransaction("");
    setStartDate("");
    setEndDate("");
  };

  const handleEndDateChange = (evt) => {
    setEndDate(evt);
    const { errorsFilterData } = formData;
    if (errorsFilterData && errorsFilterData.endDate) {
      const { endDate, ...rest } = errorsFilterData;
      setFormData({
        ...formData,
        errorsFilterData: rest,
      });
    }
  };
  //
  const errorOptions = [{ value: "", label: "Please Select the Category" }];
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Video Approval Queue">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <form className="mb-7">
              {/* <div
                className="card notification-card tabs-block p-5 mb-3 "
                style={{ display: "flex", flexDirection: "inherit" }}
              >
                <span> PLEASE LINK YOUR SELLER ACCOUNT WITH FLIPKART.</span>
                <span style={{ color: "red" }}>CLICK HERE</span>
              </div> */}
              <div className="card notification-card tabs-block p-0">
                <div className="card-body">
                  <Tabs
                    id="uncontrolled-tab-example"
                    className="pl-5  pb-0"
                    defaultActiveKey="request_sent"
                    onSelect={(eventKey) => handleTabs(eventKey)}
                  >
                    <Tab eventKey="request_sent" title={"Pending For Approval"}>
                      <div className="content-area position-relative pt-3">
                        <div className="user-listing-filterOptions mb-2 d-block">
                          <div className="row mb-2">
                            <div className="col-sm-8 position-static">
                              <div className="left-buttons d-flex "></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-center justify-content-between flex-wrap"
                        style={{ gap: "12px", fontSize: "15px" }}
                      >
                        <Modal
                          show={show1}
                          onHide={modelCloseFun1}
                          className="border  md={{ span: 3, offset: 3 }}"
                        >
                          <Modal.Header closeButton>
                            <h5
                              className="modal-title"
                              id="exampleModalLongTitle"
                            >
                              Rejection Comment
                            </h5>
                          </Modal.Header>
                          <Modal.Body closeButton>
                            <div className="notification-form">
                              <div className="row">
                                <div className="col-md-12 mb-4">
                                  <div className="form-group">
                                    <label
                                      className="form-label"
                                      for="CountryName"
                                    >
                                      {"Comment"}
                                      <sup className="text-danger mb-1">*</sup>
                                    </label>

                                    <textarea
                                      type="text"
                                      className="form-control"
                                      id="confirmpassword"
                                      name="confirmPassword"
                                      value={confirmPassword}
                                      placeholder="Enter Rejected Comment"
                                      rows={3}
                                      onChange={(e) => {
                                        setConfirmPassword(e.target.value);
                                        let changeError = Object.assign(
                                          changeErrors,
                                          { confirmPassword: "" }
                                        );
                                        setChangeErrors(changeError);
                                      }}
                                    />
                                    <span className="text-danger mb-1 d-block error-msg">
                                      {changeErrors.confirmPassword}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="light"
                              onClick={(evt) => modelCloseFun1()}
                            >
                              Close
                            </Button>
                            <Button
                              className="me-2"
                              variant="primary"
                              onClick={(evt) => rejectVideo(id)}
                            >
                              Reject
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </div>
                      {pendingVideos.length > 0 && (
                        <>
                          <Row>
                            <Col lg={3} md={6}>
                              <div className="input-wrapper mb-3">
                                <Select
                                  className="custom-select-container"
                                  classNamePrefix={"custom-select"}
                                  id="celeb"
                                  name="celeb"
                                  isMulti={true}
                                  placeholder="Select Celebrity"
                                  value={celeb}
                                  onChange={(e) => {
                                    setCeleb(e);
                                  }}
                                  options={celebList}
                                  isOptionDisabled={() => celeb?.length >= 5}
                                ></Select>
                              </div>
                            </Col>
                            <Col lg={3} md={6}>
                              <div className="input-wrapper mb-3">
                                <Select
                                  className="custom-select-container"
                                  classNamePrefix={"custom-select"}
                                  id="category"
                                  name="category"
                                  placeholder="Select Category"
                                  value={category}
                                  options={categories}
                                  onChange={(e) => {
                                    setCategory(e);
                                  }}
                                ></Select>
                              </div>
                            </Col>
                            <Col lg={3} md={6}>
                              <div className="input-wrapper mb-3">
                                <Select
                                  className="custom-select-container"
                                  classNamePrefix={"custom-select"}
                                  id="subCategory"
                                  name="subCategory"
                                  placeholder="Select SubCategory"
                                  isMulti={true}
                                  options={category ? getSubCategoryData() : []}
                                  value={subCategoryValue}
                                  onChange={(e) => {
                                    setsubCategoryValue(e);
                                    setErrorState({
                                      ...errorState,
                                      subCategory: "",
                                    });
                                  }}
                                  isOptionDisabled={() =>
                                    !category || subCategoryValue?.length >= 5
                                  }
                                  onBlur={() => {
                                    return !category
                                      ? setErrorState({
                                          ...errorState,
                                          subCategory:
                                            "Please Select the Category to get SubCategory list",
                                        })
                                      : "";
                                  }}
                                ></Select>
                                <div className="error-text">
                                  {errorState ? (
                                    <span className="text-danger mb-1">
                                      {errorState?.subCategory}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </Col>
                            <Col lg={3} md={6}>
                              <div className="input-wrapper mb-3">
                                <Select
                                  className="custom-select-container"
                                  classNamePrefix={"custom-select"}
                                  id="transaction"
                                  name="transaction"
                                  isMulti={true}
                                  placeholder="Select Transaction"
                                  options={transactionList}
                                  value={transaction}
                                  isOptionDisabled={() =>
                                    transaction?.length >= 5
                                  }
                                  onChange={(e) => {
                                    setTransaction(e);
                                  }}
                                ></Select>
                              </div>
                            </Col>
                            <Col lg={3} md={6}>
                              <div className="input-wrapper mb-3">
                                <DatePicker
                                  placeholderText="Select Start Date"
                                  dateFormat="MMM d yyyy"
                                  className="form-control "
                                  onChange={(evt) => {
                                    setStartDate(evt);
                                  }}
                                  selected={
                                    startDate ? new Date(startDate) : ""
                                  }
                                />
                              </div>
                            </Col>
                            <Col lg={3} md={6}>
                              <div className="input-wrapper mb-3">
                                <DatePicker
                                  placeholderText="Select End Date"
                                  dateFormat="MMM d yyyy"
                                  className={`form-control ${
                                    formData?.errorsFilterData?.endDate
                                      ? "endDateMarginTop"
                                      : null
                                  }`}
                                  onChange={handleEndDateChange}
                                  minDate={
                                    startDate ? new Date(startDate) : new Date()
                                  }
                                  disabled={startDate ? false : true}
                                  selected={endDate ? new Date(endDate) : ""}
                                />

                                <div style={{ color: "red" }}>
                                  {formData?.errorsFilterData?.endDate}
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <div className="d-flex justify-content-end mb-4">
                            <Button
                              className="me-2"
                              onClick={() => resetFilter()}
                              variant="light"
                            >
                              Reset
                            </Button>
                            <Button onClick={(evt) => ApplyFilter()}>
                              Search
                            </Button>
                          </div>
                          <div className="row d-flex text-error">
                            <div className="col-md-4"></div>

                            <div className="col-md-4"></div>
                          </div>
                        </>
                      )}
                      {pendingVideos && pendingVideos.length === 0 ? (
                        <div className="text-center text-danger mb-1 not-found-txt">
                          {" "}
                          No Records Found!
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="row">
                        {pendingVideos &&
                          pendingVideos.length > 0 &&
                          pendingVideos?.map((video, id) => {
                            return (
                              <div
                                className="col-lg-4 col-xl-3 col-md-6"
                                key={video.id}
                              >
                                <div className="video-card">
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="viewPending">
                                        Please click here to see the video
                                        details
                                      </Tooltip>
                                    }
                                  >
                                    <Link
                                      onClick={() =>
                                        history.push(
                                          `/videodetails/${video?._id}`
                                        )
                                      }
                                    >
                                      <div className="video-img position-relative">
                                        <video
                                          controls={true}
                                          width="100%;"
                                          // className="border border-secondary border-1 video-box"
                                          height="250"
                                        >
                                          <source
                                            src={video?.videoLink}
                                            type="video/mp4"
                                          />
                                        </video>
                                      </div>
                                    </Link>
                                  </OverlayTrigger>
                                  <div className="video-info">
                                    <h6 className="video-title fw-semibold">
                                      {video?.videoTitle}
                                    </h6>
                                    <p className="video-date">
                                      {moment(video?.createdAt).format("L")}
                                    </p>
                                    {/*    <div className="d-flex justify-content-between"> approve-status mt-3 d-flex align-items-center justify-content-between w-100*/}
                                    <div className="d-flex justify-content-between align-items-center">
                                      <p className="video-title-name text-secondary">
                                        {video?.celebrityDetails[0]?.firstname}
                                        &nbsp;
                                        {video?.celebrityDetails[0]?.lastname}
                                      </p>
                                      <div>
                                        {/* video approve */}
                                        <a
                                          className="approval-status"
                                          onClick={() =>
                                            videoApprove(video?._id)
                                          }
                                        >
                                          <OverlayTrigger
                                            placement="left"
                                            overlay={
                                              <Tooltip id="Approve">
                                                Click here to Approve
                                              </Tooltip>
                                            }
                                          >
                                            <i
                                              className="icon-check text-success me-2"
                                              style={{ fontSize: "32px" }}
                                            ></i>
                                          </OverlayTrigger>
                                        </a>
                                        {/* video reject */}
                                        <a
                                          className="approval-status-reject "
                                          onClick={() =>
                                            handleRejectVideo(video?._id)
                                          }
                                        >
                                          <OverlayTrigger
                                            placement="left"
                                            overlay={
                                              <Tooltip id="Reject">
                                                Click here to Reject
                                              </Tooltip>
                                            }
                                          >
                                            <i
                                              className="icon-close text-danger mb-1"
                                              style={{ fontSize: "32px" }}
                                            ></i>
                                          </OverlayTrigger>
                                        </a>
                                        {/* reject video */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>

                      {pendingVideos && pendingVideos.length < 10 ? (
                        <div>
                          <div className="table-footer mt-2 mt-sm-3 d-flex align-items-center flex-wrap justify-content-between">
                            <div className="mb-0 text-primary-light fs-14">
                              Showing
                              <b className="me-1">{pendingVideos.length}</b>
                              Out of <b>{pendingVideos.length}</b> Videos
                            </div>
                            <div className="pagination-list m-2">
                              <Pagination
                                className="ant-pagination"
                                pageSize={pagesize}
                                current={page}
                                total={pendingVideos.length}
                                onChange={paginationChange}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="table-footer mt-2 mt-sm-3 d-flex align-items-center flex-wrap justify-content-between">
                          <div className="mb-0 text-primary-light fs-14">
                            Showing
                            <b className="me-1">10</b>
                            Out of <b>{pendingVideos.length}</b> Videos
                          </div>
                          <div className="pagination-list m-2">
                            <Pagination
                              className="ant-pagination"
                              pageSize={pagesize}
                              current={page}
                              total={total}
                              onChange={paginationChange}
                            />
                          </div>
                        </div>
                      )}
                    </Tab>

                    <Tab eventKey="approved" title={"Approved"}>
                      <div className="content-area position-relative pt-3">
                        <div className="user-listing-filterOptions mb-2 d-block">
                          <div className="row mb-2">
                            <div className="col-sm-8 position-static"></div>
                          </div>
                        </div>
                      </div>
                      {approvedVideos.length > 0 && (
                        <>
                          <Row>
                            <Col lg={3} md={6}>
                              <div className="input-wrapper mb-3">
                                <Select
                                  className="custom-select-container"
                                  classNamePrefix={"custom-select"}
                                  id="celeb"
                                  name="celeb"
                                  isMulti={true}
                                  placeholder="Select Celebrity"
                                  options={celebList}
                                  value={celeb}
                                  onChange={(e) => {
                                    setCeleb(e);
                                  }}
                                  isOptionDisabled={() => celeb?.length >= 5}
                                ></Select>
                              </div>
                            </Col>
                            <Col lg={3} md={6}>
                              <div className="input-wrapper mb-3">
                                <Select
                                  className="custom-select-container"
                                  classNamePrefix={"custom-select"}
                                  id="category"
                                  name="category"
                                  placeholder="Select Category"
                                  value={category}
                                  options={categories}
                                  onChange={(e) => {
                                    setCategory(e);
                                  }}
                                ></Select>
                              </div>
                            </Col>
                            <Col lg={3} md={6}>
                              <div className="input-wrapper mb-3">
                                <Select
                                  className="custom-select-container"
                                  classNamePrefix={"custom-select"}
                                  id="subCategory"
                                  name="subCategory"
                                  placeholder="Select SubCategory"
                                  isMulti={true}
                                  value={subCategoryValue}
                                  options={category ? getSubCategoryData() : []}
                                  onChange={(e) => {
                                    setsubCategoryValue(e);
                                    setErrorState({
                                      ...errorState,
                                      subCategory: "",
                                    });
                                  }}
                                  isOptionDisabled={() =>
                                    !category || subCategoryValue?.length >= 5
                                  }
                                  onBlur={() => {
                                    return !category
                                      ? setErrorState({
                                          ...errorState,
                                          subCategory:
                                            "Please Select the Category to get SubCategory list",
                                        })
                                      : "";
                                  }}
                                ></Select>
                                <div className="error-text">
                                  {errorState ? (
                                    <span className="text-danger mb-1">
                                      {errorState?.subCategory}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </Col>
                            <Col lg={3} md={6}>
                              <div className="input-wrapper mb-3">
                                <Select
                                  className="custom-select-container"
                                  classNamePrefix={"custom-select"}
                                  id="transaction"
                                  name="transaction"
                                  isMulti={true}
                                  placeholder="Select Transaction"
                                  options={transactionList}
                                  value={transaction}
                                  isOptionDisabled={() =>
                                    transaction?.length >= 5
                                  }
                                  onChange={(e) => {
                                    setTransaction(e);
                                  }}
                                ></Select>
                              </div>
                            </Col>
                            <Col lg={3} md={6}>
                              <div className="input-wrapper mb-3">
                                <DatePicker
                                  placeholderText="Select Start Date"
                                  dateFormat="MMM d yyyy"
                                  className="form-control "
                                  onChange={(evt) => {
                                    setStartDate(evt);
                                  }}
                                  selected={
                                    startDate ? new Date(startDate) : ""
                                  }
                                />
                              </div>
                            </Col>
                            <Col lg={3} md={6}>
                              <div className="input-wrapper mb-3">
                                <DatePicker
                                  placeholderText="Select End Date"
                                  dateFormat="MMM d yyyy"
                                  className={`form-control ${
                                    formData?.errorsFilterData?.endDate
                                      ? "endDateMarginTop"
                                      : null
                                  }`}
                                  onChange={(evt) => {
                                    setEndDate(evt);
                                  }}
                                  minDate={
                                    startDate ? new Date(startDate) : new Date()
                                  }
                                  disabled={startDate ? false : true}
                                  selected={endDate ? new Date(endDate) : ""}
                                />
                              </div>
                            </Col>
                          </Row>
                          <div className="d-flex justify-content-end mb-4">
                            <Button
                              className="me-2"
                              onClick={() => resetFilter()}
                              variant="light"
                            >
                              Reset
                            </Button>
                            <Button onClick={(evt) => ApplyApprovedFilter()}>
                              Search
                            </Button>
                          </div>
                          <div className="row d-flex text-error">
                            <div className="col-md-4"></div>
                            <div className="col-md-4"></div>
                            <div className="col-md-4"></div>
                          </div>
                        </>
                      )}

                      {approvedVideos && approvedVideos.length === 0 ? (
                        <div className="text-center text-danger mb-1 not-found-txt">
                          {" "}
                          No Records Found!
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="row">
                        {approvedVideos &&
                          approvedVideos.length > 0 &&
                          approvedVideos?.map((video, id) => {
                            return (
                              <div
                                className="col-lg-4 col-xl-3 col-md-6"
                                key={video?._id}
                              >
                                <div className="video-card">
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="view">
                                        Please click here to see the video
                                        details
                                      </Tooltip>
                                    }
                                  >
                                    <Link
                                      onClick={() =>
                                        history.push(
                                          `/videodetails/${video?._id}`
                                        )
                                      }
                                    >
                                      <div className="video-img position-relative">
                                        <video
                                          controls={true}
                                          width="100%;"
                                          // className="border border-secondary border-1 video-box"
                                          height="250"
                                        >
                                          <source
                                            src={video?.videoLink}
                                            type="video/mp4"
                                          />
                                        </video>
                                      </div>
                                    </Link>
                                  </OverlayTrigger>
                                  <div className="video-info">
                                    <h6 className="video-title fw-semibold">
                                      {video?.videoTitle
                                        ? video?.videoTitle
                                        : "-"}
                                    </h6>
                                    <p className="video-date">
                                      {video?.createdAt
                                        ? moment(video?.createdAt).format("L")
                                        : "-"}
                                    </p>
                                    <p className="video-title-name text-secondary mb-1">
                                      {video?.celebrityDetails[0]?.firstname
                                        ? video?.celebrityDetails[0]?.firstname
                                        : "-"}
                                      &nbsp;
                                      {video?.celebrityDetails[0]?.lastname
                                        ? video?.celebrityDetails[0]?.lastname
                                        : "-"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>

                      {approvedVideos && approvedVideos.length < 10 ? (
                        <div>
                          <div className="table-footer mt-2 mt-sm-3 d-flex align-items-center flex-wrap justify-content-between">
                            <div className="mb-0 text-primary-light fs-14">
                              Showing
                              <b className="me-1">{approvedVideos.length}</b>
                              Out of <b>{approvedVideos.length}</b> Videos
                            </div>
                            <div className="pagination-list m-2">
                              <Pagination
                                className="ant-pagination"
                                pageSize={pagesize}
                                current={page}
                                total={approvedVideos.length}
                                onChange={paginationChange}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="table-footer mt-2 mt-sm-3 d-flex align-items-center flex-wrap justify-content-between">
                          <div className="mb-0 text-primary-light fs-14">
                            Showing
                            <b className="me-1">10</b>
                            Out of <b>{approvedVideos.length}</b> Videos
                          </div>
                          <div className="pagination-list m-2">
                            <Pagination
                              className="ant-pagination"
                              pageSize={pagesize}
                              current={page}
                              total={total}
                              onChange={paginationChange}
                            />
                          </div>
                        </div>
                      )}
                    </Tab>
                    <Tab eventKey="rejected" title={"Rejected"}>
                      <div className="content-area position-relative pt-3">
                        <div className="user-listing-filterOptions mb-2 d-block">
                          <div className="row mb-2"></div>
                        </div>
                      </div>
                      {rejectedVideos.length > 0 && (
                        <>
                          <Row>
                            <Col lg={3} md={6}>
                              <div className="input-wrapper mb-3">
                                <Select
                                  className="custom-select-container"
                                  classNamePrefix={"custom-select"}
                                  id="celeb"
                                  name="celeb"
                                  isMulti={true}
                                  placeholder="Select Celebrity"
                                  value={celeb}
                                  isOptionDisabled={() => celeb?.length >= 5}
                                  onChange={(e) => {
                                    setCeleb(e);
                                  }}
                                  options={celebList}
                                ></Select>
                              </div>
                            </Col>
                            <Col lg={3} md={6}>
                              <div className="input-wrapper mb-3">
                                <Select
                                  className="custom-select-container"
                                  classNamePrefix={"custom-select"}
                                  id="category"
                                  name="category"
                                  placeholder="Select Category"
                                  value={category}
                                  options={categories}
                                  onChange={(e) => {
                                    setCategory(e);
                                  }}
                                ></Select>
                              </div>
                            </Col>
                            <Col lg={3} md={6}>
                              <div className="input-wrapper mb-3">
                                <Select
                                  className="custom-select-container"
                                  classNamePrefix={"custom-select"}
                                  id="subCategory"
                                  name="subCategory"
                                  placeholder="Select SubCategory"
                                  isMulti={true}
                                  value={subCategoryValue}
                                  options={category ? getSubCategoryData() : []}
                                  onChange={(e) => {
                                    setsubCategoryValue(e);
                                    setErrorState({
                                      ...errorState,
                                      subCategory: "",
                                    });
                                  }}
                                  isOptionDisabled={() =>
                                    !category || subCategoryValue?.length >= 5
                                  }
                                  onBlur={() => {
                                    return !category
                                      ? setErrorState({
                                          ...errorState,
                                          subCategory:
                                            "Please Select the Category to get SubCategory list",
                                        })
                                      : "";
                                  }}
                                ></Select>
                                <div className="error-text">
                                  {errorState ? (
                                    <span className="text-danger mb-1">
                                      {errorState?.subCategory}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </Col>
                            <Col lg={3} md={6}>
                              <div className="input-wrapper mb-3">
                                <Select
                                  className="custom-select-container"
                                  classNamePrefix={"custom-select"}
                                  id="transaction"
                                  isMulti={true}
                                  name="transaction"
                                  placeholder="Select Transaction"
                                  isOptionDisabled={() =>
                                    transaction?.length >= 5
                                  }
                                  options={transactionList}
                                  value={transaction}
                                  onChange={(e) => {
                                    setTransaction(e);
                                  }}
                                ></Select>
                              </div>
                            </Col>
                            <Col lg={3} md={6}>
                              <div className="input-wrapper mb-3">
                                <DatePicker
                                  placeholderText="Select Start Date"
                                  dateFormat="MMM d yyyy"
                                  className="form-control "
                                  onChange={(evt) => {
                                    setStartDate(evt);
                                  }}
                                  selected={
                                    startDate ? new Date(startDate) : ""
                                  }
                                />
                              </div>
                            </Col>
                            <Col lg={3} md={6}>
                              <div className="input-wrapper mb-3">
                                <DatePicker
                                  placeholderText="Select End Date"
                                  dateFormat="MMM d yyyy"
                                  // className="form-control "
                                  className={`form-control ${
                                    formData?.errorsFilterData?.endDate
                                      ? "endDateMarginTop"
                                      : null
                                  }`}
                                  onChange={(evt) => {
                                    setEndDate(evt);
                                  }}
                                  minDate={
                                    startDate ? new Date(startDate) : new Date()
                                  }
                                  disabled={startDate ? false : true}
                                  selected={endDate ? new Date(endDate) : ""}
                                />
                              </div>
                            </Col>
                          </Row>
                          <div className="d-flex justify-content-end mb-4">
                            <Button
                              className="me-2"
                              onClick={() => resetFilter()}
                              variant="light"
                            >
                              Reset
                            </Button>
                            <Button onClick={(evt) => ApplyRejectedFilter()}>
                              Search
                            </Button>
                          </div>
                        </>
                      )}

                      <div className="row">
                        {rejectedVideos && rejectedVideos.length === 0 ? (
                          <div className="text-center text-danger mb-1 not-found-txt">
                            No Records Found!
                          </div>
                        ) : (
                          ""
                        )}
                        {rejectedVideos &&
                          rejectedVideos.length > 0 &&
                          rejectedVideos?.map((video, id) => {
                            return (
                              <div
                                className="col-lg-4 col-xl-3 col-md-6"
                                key={video.id}
                              >
                                <div className="video-card">
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="viewApproval">
                                        Please click here to see the video
                                        details
                                      </Tooltip>
                                    }
                                  >
                                    <Link
                                      onClick={() =>
                                        history.push(
                                          `/videodetails/${video?._id}`
                                        )
                                      }
                                    >
                                      <div className="video-img position-relative">
                                        <video
                                          controls={true}
                                          width="100%;"
                                          // className="border border-secondary border-1 video-box"
                                          height="250"
                                        >
                                          <source
                                            src={video?.videoLink}
                                            type="video/mp4"
                                          />
                                        </video>
                                      </div>
                                    </Link>
                                  </OverlayTrigger>
                                  <div className="video-info">
                                    <h6 className="video-title fw-semibold">
                                      {" "}
                                      {video?.videoTitle
                                        ? video?.videoTitle
                                        : "-"}
                                    </h6>
                                    <p className="video-date">
                                      {" "}
                                      {video?.createdAt
                                        ? moment(video?.createdAt).format("L")
                                        : "-"}
                                    </p>
                                    <p className="video-title-name text-secondary mb-1">
                                      {video?.celebrityDetails[0]?.firstname
                                        ? video?.celebrityDetails[0]?.firstname
                                        : "-"}
                                      &nbsp;
                                      {video?.celebrityDetails[0]?.lastname
                                        ? video?.celebrityDetails[0]?.lastname
                                        : "-"}
                                    </p>
                                    <Button
                                      onClick={() =>
                                        history.push(
                                          `/videodetails/${video?._id}`
                                        )
                                      }
                                      className="mt-2 w-100"
                                    >
                                      Rejected Reasons
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>

                      {rejectedVideos && rejectedVideos.length < 10 ? (
                        <div>
                          <div className="table-footer mt-2 mt-sm-3 d-flex align-items-center flex-wrap justify-content-between">
                            <div className="mb-0 text-primary-light fs-14">
                              Showing
                              <b className="me-1">{rejectedVideos.length}</b>
                              Out of <b>{rejectedVideos.length}</b> Videos
                            </div>
                            <div className="pagination-list m-2">
                              <Pagination
                                className="ant-pagination"
                                pageSize={pagesize}
                                current={page}
                                total={rejectedVideos.length}
                                onChange={paginationChange}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="table-footer mt-2 mt-sm-3 d-flex align-items-center flex-wrap justify-content-between">
                          <div className="mb-0 text-primary-light fs-14">
                            Showing
                            <b className="me-1">10</b>
                            Out of <b>{rejectedVideos.length}</b> Videos
                          </div>
                          <div className="pagination-list m-2">
                            <Pagination
                              className="ant-pagination"
                              pageSize={pagesize}
                              current={page}
                              total={total}
                              onChange={paginationChange}
                            />
                          </div>
                        </div>
                      )}
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </form>
          </div>
          <Footer />
        </div>
      </div>
    </Layout>
  );
}

export default VideoApprovalQueue;
