import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "rc-pagination";
import { Layout, Footer } from "../../Components/Layout";
import { getPlan, setPlan } from "../../Store/Actions/User";
import { PROFILE_DETAILS, route, SELECT_PLAN } from "../../Utils";
import { showMessageNotification } from "../../Utils/Functions";
import { paymentData } from "../../Store/Actions/User";
/******************* 
@Purpose : Used for dashboard view
@Parameter : {}
@Author : Vista app
******************/
function SelectPlan(props) {
  const dispatch = useDispatch();
  const [pagesize, setPagesize] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [loading, setLoading] = useState(false);
  const planData = useSelector((data) => data.user.plans);
  const paymentId = useSelector((data) => data.user.planId);
  const id = paymentId;
  /******************* 
  @Purpose : Used for Bread crumb
  @Parameter : {}
  @Author : INIC
  ******************/
  const breadcrumb = [
    {
      title: PROFILE_DETAILS,
      url: route.BRAND_ACCOUNT,
    },
    {
      title: SELECT_PLAN,
      url: route.SELECT_PLAN,
    },
  ];

  /******************* 
  @Purpose : React hook Used for get all plan
  @Parameter : {}
  @Author : Vista app
  ******************/
  useEffect(() => {
    let body = {
      pageNumber: page,
      pageSize: pagesize,
    };
    dispatch(getPlan(body))
      .then((res) => setTotal(res?.totalData))
      .catch((err) => console.error(err));
  }, [page, pagesize]);

  /******************* 
  @Purpose :  Used for select plan
  @Parameter : {id}
  @Author : Vista app
  ******************/
  const selectedplan = (id) => {
    dispatch(setPlan(id))
      .then(() => {
        let data = {
          planId: id,
        };

        dispatch(paymentData(data)).then((res) => {
          if (res) {
            setLoading(false);
            props.history.push("/dashboard");
          }
        });
        showMessageNotification("plan saved successfully", "success");
        // props.history.push("/brandpayout");
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };

  /******************* 
  @Purpose : Used for pagination
  @Parameter : {page, pagesize}
  @Author : INIC
  ******************/
  const paginationChange = (page, pagesize) => {
    setPage(page);
    setPagesize(pagesize);
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Profile Detail">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block d-grid gap-3">
            <div className="row ">
              {planData?.data?.length > 0 &&
                planData?.data?.map((data, id) => {
                  return (
                    <>
                      <div className="col-md-3 mb-4" key={data.id}>
                        <div className="card h-100 mb-4">
                          <div className="card-header">
                            <h3 className="card-title text-center mb-0 text-capitalize">
                              {data?.planTitle}
                            </h3>
                          </div>
                          <div className="card-body pb-0">
                            <div className="d-flex flex-wrap mb-3 ">
                              <label className="me-3 mb-0 fw-bold">
                                Type:
                              </label>
                              <p className="mb-0">{data?.planType}</p>
                            </div>
                            <div className="d-flex flex-wrap mb-3 ">
                              <label className="me-3 mb-0 fw-bold">
                                Amount :
                              </label>
                              <p className="mb-0">{data?.planAmount}</p>
                            </div>
                            <div className="d-flex flex-wrap mb-3 ">
                              <label className="me-3 mb-0 fw-bold">
                                No. of Months :
                              </label>
                              <p className="mb-0">{data?.noOfMonths}</p>
                            </div>
                            <div className="d-flex flex-wrap mb-3 ">
                              <label className="me-3 mb-0 fw-bold">
                                No. of Video Per Month :
                              </label>
                              <p className="mb-0">{data?.videosPerMonth}</p>
                            </div>
                            <div className="d-flex flex-wrap mb-3 ">
                              <label className="me-3 mb-0 fw-bold">
                                Total Videos :
                              </label>
                              <p className="mb-0">{data?.totalVideos}</p>
                            </div>
                            <p className="text-muted">
                              {data?.description}
                            </p>
                            <Button
                              className="btn btn-primary w-100"
                              onClick={() => selectedplan(data._id)}
                            >
                              Select
                            </Button>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
            <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mb-3">
              <p className="mb-0 align-self-center text-primary-light fs-14">
                Showing <b>{planData?.data?.length ?? 0}</b> Out of
                <b> {planData?.totalData ?? 0}</b>
              </p>
              <div className="pagination-list ms-2">
                <Pagination
                  className="ant-pagination bg-white border-primary"
                  pageSize={pagesize}
                  current={page}
                  total={total}
                  onChange={paginationChange}
                />
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </Layout>
  );
}

export default SelectPlan;
