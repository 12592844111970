//Externals
import PhoneInput2 from "react-phone-input-2";
import { FormLabel, FormText } from "react-bootstrap";
import clsx from "clsx";

import "react-phone-input-2/lib/style.css";
import { useEffect } from "react";

/*******************
@purpose : used for common phone input with formik
@Parameter : {}
@Author : INIC
******************/
const PhoneInput = ({
  type,
  label,
  value,
  required = true,
  name,
  labelClassName,
  formik,
  onChange,
  feedbackClassName,
  disabled,
  size = "lg",
  touched,
  autoComplete = "on",
  errors,
  tooltip,
  ...rest
}) => {
  useEffect(() => {
    if (formik && !touched && !errors) {
      touched = formik.touched[name];
      errors = formik.errors[name];
    }
  }, [formik]);
  const getValidateClass = () => {
    if (!formik?.touched[name]) {
      return "w-100";
    }
    if (formik?.touched[name] && formik?.errors[name]) {
      return "w-100 error-block";
    }
    return " w-100 success-block";
  };

  /******************* 
  @purpose : handle data change
  @Parameter : { event }
  @Author :INIC
  ******************/
  const handleChange = (phoneNumber) => {
    let event = {
      target: {
        name: name,
        value: phoneNumber,
      },
    };
    if (formik) formik.handleChange(event);
  };

  /******************* 
  @purpose : Rander HTML/ React Components
  @Author :INIC
  ******************/
  return (
    <div
      className={clsx("position-relative w-100  mb-3", {
        "error-block":
          !disabled && formik?.touched[name] && formik?.errors[name],
      })}
    >
      {label && (
        <FormLabel className={labelClassName}>
          {label}
          {required && <sup className="sub-asterisk text-danger">*</sup>}
        </FormLabel>
      )}
      <div className={getValidateClass()}>
        <PhoneInput2
          inputclassName={clsx({
            "is-invalid":
              !disabled && formik?.touched[name] && formik?.errors[name],
          })}
          {...formik?.getFieldProps(name)}
          {...rest}
          onChange={handleChange}
          value={value}
        />
        {formik?.touched[name] && formik?.errors[name] && (
          <span className="icon-error-icon error-icon" />
        )}
        {formik?.touched[name] && !formik?.errors[name] && (
          <span className="icon-ic-check-mark error-icon" />
        )}
      </div>

      {formik?.touched[name] && formik?.errors[name] && (
        <FormText className={(feedbackClassName, "error text-danger")}>
          {formik?.errors[name]}
        </FormText>
      )}
    </div>
  );
};

export default PhoneInput;
