import { callApi } from "../../Api";
import Routes from "../../Api/Routes"; // API endpoint routes
const { CHANGE_VIDEO, UPLOAD_VIDEO } = Routes;

/*******************
@purpose :  send payment detail
@Parameter : {data}
@Author : INIC
******************/
export const getuploadid = (data) => async (dispatch) => {
  const res = await callApi(
    `${UPLOAD_VIDEO}`,
    data,
    "post",
    null,
    true,
    "",
    "",
    "multipart/form-data"
  );
  if (res) {
    return Promise.resolve(res);
  } else {
    return Promise.reject(res);
  }
};

/*******************
@purpose :  send payment detail
@Parameter : {data}
@Author : INIC
******************/
export const changeUploadVideo = (data) => async () => {
  const res = await callApi(
    `${CHANGE_VIDEO}`,
    data,
    "POST",
    null,
    true,
    "",
    ""
  );
  if (res.status) {
    return Promise.resolve(res);
  } else {
    return Promise.reject(res);
  }
};
