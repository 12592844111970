import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";

import { Layout, Footer } from "../../Components/Layout";
import { getCategories } from "../../Store/Actions/Categories";
import {
  LIST_OF_VIDEO_AD,
  PurchaseVideoAdSchema,
  route,
  PUR_OF_VIDEO_AD,
} from "../../Utils";
import { SelectField, TextField } from "../../Components/FormFields";
import { getuploadid } from "../../Store/Actions/UploadVideos";
import { setPurchaseDetails } from "../../Store/Actions/BrandVideos";

/******************* 
@Purpose : used for purchase video add
@Parameter : {}
@Author : INIC
******************/
const PurchaseVideoAd = () => {
  const history = useHistory();
  const { categoryInfo } = useSelector((data) => data?.user);
  const [loading, setLoading] = useState({ msg: "", status: false });
  const dispatch = useDispatch();
  const inputRef = useRef();
  const [fileName, setFileName] = useState();
  const [category, setCategory] = useState("");
  const [thumbImg, setThumbImg] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const purchaseVideoAdDetails = {
    title: "",
    category: "",
    subCategory: "",
    videoData: "",
  };

  /******************* 
  @Purpose : Used for Bread crumb
  @Parameter : {}
  @Author : INIC
  ******************/
  const breadcrumb = [
    {
      title: LIST_OF_VIDEO_AD,
      url: route.MANAGE_VIDEO_ADD,
    },
    {
      title: PUR_OF_VIDEO_AD,
      url: route.MANAGE_VIDEO_ADD,
    },
  ];

  /*******************
  @Purpose : React hook used for get categories
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    dispatch(getCategories());
    categoryValue();
  }, []);

  /*******************
  @Purpose : used for categories value
  @Parameter : {}
  @Author : INIC
  ******************/
  const categoryValue = () => {
    let categoryArr = [];
    categoryInfo?.length > 0 &&
      categoryInfo?.forEach((optiondata) => {
        let tempObj = {
          label: optiondata?.title,
          value: optiondata?._id,
          ...optiondata,
        };
        categoryArr.push(tempObj);
        setCategory(categoryArr);
      });
  };

  /******************* 
  @Purpose :  used for sub category value 
  @Parameter : {list}
  @Author : INIC
  ******************/
  const subCategoryValue = (catData) => {
    let subCategoryArr = [];
    catData?.subCategoryDetails?.length > 0 &&
      catData?.subCategoryDetails?.forEach((subCategory) => {
        let tempObj2 = {
          label: subCategory?.title,
          value: subCategory?._id,
        };
        subCategoryArr.push(tempObj2);
        setSubCategory(subCategoryArr);
      });
    return subCategoryArr;
  };

  /******************* 
   @purpose : set formik validation schema
   @Parameter : {}
   @Author :INIC
   ******************/

  const formik = useFormik({
    initialValues: purchaseVideoAdDetails,
    enableReinitialize: true,
    validationSchema: PurchaseVideoAdSchema(),

    onSubmit: async (values) => {
      const { title, category, subCategory, videoData } = values;
      const subCatVal = subCategory.value;
      const data = {
        videoTitle: title,
        categoryId: category._id,
        videoLink: videoData?.data?.key,
        videoThumb: videoData?.data?.thumbnailLocation,
        subCategoriedIds: [subCatVal],
        // subCategoriedIds: subCategory?.value,
      };

      delete data.category;
      delete data.subCategory;
      dispatch(setPurchaseDetails(data));
      history.push("/selectduration");
    },
  });

  /*******************
  @Purpose : used for handle upload video
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleVideoUpload = async (event) => {
    const array_of_allowed_files = ["mp4", "mov", "avi"];
    const file_extension = event.target.files[0].type.split("/")[1];
    if (!array_of_allowed_files.includes(file_extension)) {
      formik.setFieldError("videoData", "Upload valid video format");
      return 0;
    }

    setLoading({ msg: "Uploading...", status: true });
    let data = new FormData();
    const file = event.target.files[0].name;
    setFileName(event.target.files[0].name);
    data.append("uploaded_file", event.target.files[0], file);

    dispatch(getuploadid(data))
      .then((res) => {
        if (res) {
          if (!res || loading.status) {
            setLoading({ msg: "Video Not uploaded", status: false });
          } else {
            setLoading({ msg: "Uploaded successfully", status: false });
            setThumbImg(res?.data?.thumbnailLocation);
            formik.setFieldValue("videoData", res);
            setTimeout(() => {
              setLoading({ msg: "", status: false });
            }, 3000);
          }
        }
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <>
      <Layout breadcrumb={breadcrumb} breadcrumbTitle="Manage Video AD">
        <div className="dashboard-container">
          <div className="main-content-area">
            <div className="overlay" />
            <div className="main-content-block">
              <div className="row mb-5">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb p-0">
                          <li className="breadcrumb-item" aria-current="page">
                            Upload Video Ad
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            <React.Fragment>Select Duration </React.Fragment>
                          </li>
                          <li className="breadcrumb-item active">
                            <React.Fragment>
                              Select Payment Method
                            </React.Fragment>
                          </li>
                        </ol>
                      </nav>
                    </div>
                    <div className="card-body">
                      <form className="mb-5">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-12">
                                <TextField
                                  type="text"
                                  label="Title"
                                  placeholder="Enter Title"
                                  name="title"
                                  value={formik.values.title}
                                  formik={formik}
                                />
                              </div>
                              <div className="col-md-6">
                                <SelectField
                                  label="Category"
                                  options={category}
                                  className="custom-select-container"
                                  classNamePrefix={"custom-select"}
                                  name="category"
                                  formik={formik}
                                  placeholder="Select Category"
                                  value={formik.values.category}
                                  onChange={(e) =>
                                    subCategoryValue(e.target.value)
                                  }
                                />
                              </div>
                              <div className="col-md-6">
                                <SelectField
                                  options={subCategory}
                                  label="Subcategory"
                                  className="custom-select-container"
                                  classNamePrefix={"custom-select"}
                                  placeholder="Select Sub Category"
                                  name="subCategory"
                                  value={formik.values.subCategory}
                                  formik={formik}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="notification-form">
                              <div className="form-group mb-4">
                                <label className="form-label" htmlFor="Video">
                                  Video
                                  <sup className="text-danger">*</sup>
                                </label>
                                <div className="edit-image">
                                  <div className="modal-img-wrapper">
                                    {/* <div className="icon-wrapper ">
                                      <i className="icon-cloud-upload bx-lg"></i>
                                    </div> */}
                                    {thumbImg ? (
                                      <div className="icon-wrapper ">
                                        <img
                                          src={thumbImg}
                                          style={{ height: "130px" }}
                                        ></img>
                                      </div>
                                    ) : (
                                      <div className="icon-wrapper ">
                                        <i className="icon-cloud-upload bx-lg"></i>
                                      </div>
                                    )}
                                    <div className="img-content-wrapper d-flex">
                                      <input
                                        ref={inputRef}
                                        name="videoData"
                                        className="VideoInput_input"
                                        type="file"
                                        onChange={handleVideoUpload}
                                        accept=".mov,.mp4"
                                        formik={formik}
                                      />
                                      {fileName ? (
                                        <span>{fileName}</span>
                                      ) : (
                                        <span className="my-2">
                                          no file choose
                                        </span>
                                      )}
                                    </div>
                                    <div className="img-file-text">
                                      Please note that .Mp4 .MKV .MOV Video file
                                      is allowed.
                                      <sup>*</sup>
                                    </div>
                                    <span disabled>
                                      {loading.status ? (
                                        <span
                                          className="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                      ) : null}
                                      <span
                                        className={
                                          loading.status
                                            ? "text-primary"
                                            : "text-success"
                                        }
                                      >
                                        {loading.msg}
                                      </span>
                                    </span>
                                    {!loading.status && (
                                      <div className="text-danger mt-2">
                                        {formik.errors.videoData}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>

                      <div className="col-md-12">
                        <div className="text-end">
                          <Link to="/ManageVideoAd">
                            <Button
                              variant="light"
                              type="reset"
                              className="me-2"
                            >
                              Cancel
                            </Button>
                          </Link>
                          <Button
                            variant="primary"
                            onClick={() => formik.handleSubmit()}
                            disabled={loading.status}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                      <div className="mt-4 disclamer">
                        Note: If you can't locate or find the appropriate
                        sub-category, please contact Vista Admin at &nbsp;
                        <a href="mailto:support@vistareels.com">
                          support@vistareels.com
                        </a>
                        &nbsp; and provide the details (sub-category name and
                        its parent category) in the email to request the
                        addition for the same.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default PurchaseVideoAd;
