const INTIAL_STATE = {
  postedvideos: [],
  postremainvideos: "",
};

/******************* 
  @Purpose : Used for store data in redux
  @Parameter : state, action
  @Author : INIC
  ******************/
export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case "SET_POSTED_VIDEOS":
      return Object.assign({}, state, {
        postedvideos: action.payload,
      });
    case "SET_REMAIN_VIDEOS":
      return Object.assign({}, state, {
        postremainvideos: action.payload,
      });

    default:
      return state;
  }
};
