const INTIAL_STATE = {
  userProfile: "",
  plans: "",
  planId: "",
  payment: "",
  cardinfo: [],
  planHistory: [],
  categoryInfo: "",
  cmsData: "",
};
/******************* 
@Purpose : Used for store data in redux
@Parameter : state, action
@Author : INIC
******************/
export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case "SET_PLAN_DATA":
      return Object.assign({}, state, {
        plans: action.payload,
      });

    case "SET_USER_PROFILE":
      return Object.assign({}, state, {
        userProfile: action.payload,
      });

    case "SET_PLAN_ID":
      return Object.assign({}, state, {
        planId: action.payload,
      });

    case "SET_CARD_INFO":
      return Object.assign({}, state, {
        cardinfo: action.payload || [],
      });
    case "REMOVE_CARD_INFO":
      return Object.assign({}, state, {
        cardinfo: [],
      });
    case "SET_PLAN_HISTORY":
      return Object.assign({}, state, {
        planHistory: action.payload || [],
      });
    case "SET_CATEGORY_INFO":
      return Object.assign({}, state, {
        categoryInfo: action.payload,
      });
    case "SET_CMS_DATA":
      return Object.assign({}, state, {
        cmsData: action.payload,
      });

    default:
      return state;
  }
};
