/******************* 
@Purpose :  We can use following for error messages
@Author : INIC
******************/
export default {
  REQUIRED: "*Field Required",
  PROVIDE_EMAIL_OR_PHONE: "Enter email or phone Number",
  PROVIDE_EMAIL: "Enter email",
  PROVIDE_VALID_EMAIL: "Enter valid email",
  PROVIDE_PASSWORD: "Enter password",
  PROVIDE_CON_PASSWORD: "Enter confirm password",
  PROVIDE_OLD_PASSWORD: "Enter old password",
  PROVIDE_NEW_PASSWORD: "Enter new password",
  PROVIDE_CURRENT_PASSWORD: "Enter current password",
  PROVIDE_VALID_PASSWORD: `* Password should contain at least a special character (@, $, !, %), a capital letter, a number and length should be between 8-20`,
  PROVIDE_CONFIRM_PASSWORD: "Enter confirm password",
  PASSWORD_NOT_MATCHED: "New password and confirm password not matched",
  PROVIDE_NAME: "Enter your name",
  PROVIDE_CATEGORY_NAME: "Enter category name",
  // for category
  PROVIDE_VALID_CATEGORY_NAME: "*Category name should be in alphabets",
  PROVIDE_CATEGORY_IMAGE: "Enter the category Image",
  PROVIDED_CATEGORY_EMPTY: "Provide the category data to save",
  // sub category
  PROVIDE_VALID_SUB_CATEGORY_NAME: "*Category name should be in alphabets",
  PROVIDE_SUB_CATEGORY_IMAGE: "Enter the sub category Image",
  PROVIDED_SUB_CATEGORY_EMPTY: "Provide the sub category data to save",
  // settings
  PROVIDE_MONTHLY_PLAN: "Provide the amount of monthly plan",
  PROVIDE_VALID_MONTHLY_PLAN: "Enter the amount in digits only",
  PROVIDE_QUARTERLY_PLAN: "Provide the amount of  quarterly plan",
  PROVIDE_VALID_QUARTERLY_PLAN: "Enter the amount in digits only",
  PROVIDE_YEARLY_PLAN: "Provide the amount of yearly plan",
  PROVIDE_VALID_YEARLY_PLAN: "Enter the amount in digits only",
  // settings ends here
  PROVIDE_VALID_IMAGE: "Enter the images of jpeg or png",
  PROVIDE_FIRST_NAME: "Enter first name",
  PROVIDE_LAST_NAME: "Enter last name",
  PROVIDE_MOBILE_NUMBER: "Enter mobile number",
  PROVIDE_WEBSITE_URL: "Enter website url",
  PROVIDE_WHATSAPP_NUMBER: "Enter whats app bussiness number",
  PROVIDE_VALID_MOBILE_NUMBER: "Enter numbers only that contains 10 digits ",
  PROVIDE_ADDRESS: "Enter address",
  PROVIDE_ADDRESS_LINE_1: "*Enter address line 1",
  PROVIDE_ADDRESS_LINE_2: "*Enter address line 2",
  PROVIDE_ZIPCODE: "*Enter zipcode",
  PROVIDE_VALID_ZIPCODE: "*Enter valid zipcode",
  PROVIDE_WEBSITE: "Enter valid website (no whitespace)",
  PROVIDE_ROLE: "*Select role",
  PROVIDE_COUNTRY_NAME: "*Enter country name ",
  PROVIDE_COUNTRY_CODE: "*Enter country code",
  PROVIDE_PHONE_CODE: "*Enter phone code ",
  PROVIDE_TIMEZONE: "*Enter Timezone",
  PROVIDE_CURRENCY: "*Enter currency",
  PROVIDE_BACKEND: "*Secret key is required",
  PROVIDE_FROUNTEND: "Publishable key is required",
  PROVIDE_CLIENTID: "*ClientId is required",
  PROVIDE_CLIENTSECRET: "* clientSecret is required",
  PROVIDE_MODE: "*Mode is required",
  PROVIDE_FROM_EMAIL: "Enter from email",
  PROVIDE_VALID_FROM_EMAIL: "Enter valid from email",
  PROVIDE_PORT: "Enter SMTP port",
  PROVIDE_VALID_PORT: "Enter valid port number",
  PROVIDE_USER_NAME: "Enter user name",
  PROVIDE_HOST: "Enter host name ",
  PROVIDE_VALID_HOST: "Enter valid host name ",
  PROVIDE_ENCRYPTION: "Enter encryption",
  PROVIDE_VALID_ENCRYPTION: "Enter encryption (minimum length 3)",
  PROVIDE_FROMNAME: "Enter from name",
  PROVIDE_VALID_FROMNAME: "Enter from name (minimum length 5)",
  PROVIDE_APPID: "Enter appId ",
  PROVIDE_VALID_APPID: "Enter appId (minimum length 5)",
  PROVIDE_SMS_USER_NAME: "Enter user name (minimum length 5)",
  PROVIDE_VALID_SMTP_USER_NAME:
    "User name can be alphanumeric with special character _ (minimum length 5)",
  PROVIDE_SMTP_USER_NAME: "Enter user name",
  PROVIDE_SMTP_PASSWORD: "Enter password (minimum length 5)",
  PROVIDE_SMS_PASSWORD: "Enter password",
  PROVIDE_VALID_SMS_PASSWORD: "Enter password (minimum length 5)",
  PROVIDE_ACCOUNTSID: "Enter account SID",
  PROVIDE_AUTHTOKEN: "*Enter auth token",
  PROVIDE_FBURL: "Enter correct facebook link (no whitespace)",
  PROVIDE_TWITTER: "Enter correct twitter link (no whitespace)",
  PROVIDE_INSTAGRAM: "Enter correct instagram link (no whitespace)",
  PROVIDE_GITHUB: "Enter correct github link (no whitespace)",
  PROVIDE_CODEPEN: "Enter correct codepen link (no whitespace)",
  PROVIDE_SLACK: "Enter correct slack link (no whitespace)",
  PROVIDE_PINTERESTURL: "Enter correct pinterest link (no whitespace)",
  PROVIDE_LINKEDINURL: "Enter  correct linkedIn link (no whitespace)",
  PROVIDE_FACEBOOK: "Enter facebook link",
  PROVIDE_FBAPPID: "Enter facebook appId",
  PROVIDE_VALID_FBAPPID:
    "Enter valid facebook appId (length between 5-50 characters)",
  PROVIDE_TWITTERAPID: "Enter twitter appId",
  PROVIDE_VALID_TWITTERAPID:
    "Enter valid twitter appId (length between 5-50 characters)",
  PROVIDE_LINKEDINAPID: "Enter linkedIn appId",
  PROVIDE_VALID_LINKEDINAPID:
    "Enter valid linkedIn appId (length between 5-50 characters)",
  PROVIDE_FBAPPSECRET: "Enter facebook app secret",
  PROVIDE_VALID_FBAPPSECRET:
    "Enter valid facebook app secret (length between 5-50 characters)",
  PROVIDE_TWITTERAPSECRET: "Enter twitter app secret",
  PROVIDE_VALID_TWITTERAPSECRET:
    "Enter valid twitter app secret (length between 5-50 characters)",
  PROVIDE_LINKEDINAPSECRET: "Enter linkedIn app secret ",
  PROVIDE_VALID_LINKEDINAPSECRET:
    "Enter valid linkedIn app secret (length between 5-50 characters)",
  PROVIDE_ENVIRONMENT: "*Environment is required",
  PROVIDE_VALID_ENVIRONMENT:
    "Provide valid environment (length between 5-50 characters)",
  PROVIDE_PUBLIC_API_ID: "*API loginId is required",
  PROVIDE_VALID_API_ID: "Provide valid ID (length between 5-50 characters)",
  PROVIDE_TEST_TRANSACTION_KEY: "*Test transaction key is required",
  PROVIDE_VALID_TEST_TRANSACTION_KEY:
    "Provide valid test transaction key (length between 5-50 characters)",
  PROVIDE_PUBLIC_TRANSACTION_KEY: "Public transaction key is required",
  PROVIDE_VALID_PUBLIC_TRANSACTION_KEY:
    "Provide valid public transaction key (length between 5-50 characters)",

  PROVIDE_METAKEYWORDS_LIMIT: "Meta keywords should be under 150 characters",
  START_END_DATE_ERROR:
    "End date and time should be greater than start date and time",
  START_LESS_THAN_CURRENT:
    "Start date and time should be greater than current date and time",
  END_LESS_THAN_CURRENT:
    "End date and time should be greater than current date and time",
  START_END_DATE_EQUAL_ERROR: "Start and end date time can't be same",
  PROVIDE_ROLENAME: "Enter role name",
  PROVIDE_DESCRIPTION: "Enter description",
  PROVIDE_ORGANIZATION: "Enter organization",
  PROVIDE_COUNTRY: "Please select country ",
  PROVIDE_CITY: "Please select city",
  PROVIDE_PIN: "please enter pin",
  PROVIDE_STATE: "Please select state",
  PROVIDE_PHOTO: "Please upload image",
  PROVIDE_REASON: "Enter reason",
  // purchase video ad
  PROVIDE_TITLE: "Please Enter Title",
  PROVIDE_CATEGORY: "Please Select Category",
  PROVIDE_SUB_CATEGORY: "Please Select Sub Category",
  //select duration
  PROVIDE_DISPLAY_DAYS: "Please Select Display Days *",
  PROVIDE_AMOUNT: "Please Enter Amount *",
  PROVIDE_DATE: "Please Select Start Date",
  PROVIDE_PAYMENT_CARD: "Please Select Card",
  PROVIDE_NAME_ON_CARD: "Please Enter Card Holder Name",
  PROVIDE_CARD_NUMBER: "Please Enter Card Number",
  PROVIDE_CARD_EXPIRY: "Please Enter Card Expiry",
  provide_CARD_CVV: "Please Enter Card Cvv",
  PROVIDE_COMMENT: "Enter the comment",
  END_DATE: "Select end date",
};
