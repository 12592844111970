import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";
import Pagination from "rc-pagination";
import Select from "react-select";

import { Layout, Footer } from "../../Components/Layout";
import { getBrandVideos } from "../../Store/Actions/BrandVideos";
import { getCategories } from "../../Store/Actions/Categories";
import { route, VIDEO_LIST } from "../../Utils";
import VideoCard from "../../Components/BrandVideos/VideoCard";
import FilterModel from "../../Components/Model/FilterModel";

/******************* 
@Purpose :  used for filter select dropdown value for gender
@Parameter : {}
@Author : INIC
******************/
const gender = [
  { value: "MALE", label: "Male" },
  { value: "FEMALE", label: "Female" },
  { value: "OTHERS", label: "Others" },
];

/******************* 
@Purpose :  used for filter select dropdown value for key
@Parameter : {}
@Author : INIC
******************/
const filterTypeFieldValueoption = [
  { value: "gender", label: "Gender" },
  { value: "categoryId", label: "Category" },
];

/******************* 
@Purpose : Used for Brand videos View
@Parameter : {}
@Author : Vista app
******************/
const BrandVideos = () => {
  const dispatch = useDispatch();
  const defaultFilter = {
    key: "",
    type: "contains",
    value: "",
    filterType: "",
    filterOptions: [],
    valueFieldType: "",
  };
  const [filterData, setFilterData] = useState([defaultFilter]);
  const { brandvideos } = useSelector((data) => data.brandvideos);
  const tooltiipShow = true;
  const { categoryInfo } = useSelector((data) => data.user);
  const [category, setCategory] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);
  const [isUpdateFilter, setIsUpdateFilter] = useState(false);
  const field = 2;
  const [isSearchButtonDisabled, setIsSearchButtonDisabled] = useState(true);
  const [currentVideo, setCurrentVideo] = useState();
  const [oldVideo, setOldVideo] = useState();

  /******************* 
  @Purpose :  React hook used for filter search button disable and enable
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    filterData?.forEach((data) => {
      if (data.key != "" && data.value != "") {
        setIsSearchButtonDisabled(false);
      } else {
        setIsSearchButtonDisabled(true);
      }
    });
  }, [filterData]);
  useEffect(() => {
    setFilterData(filterData);
    handleFilterArray(filterData);
  }, [isUpdateFilter]);

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  useEffect(() => {
    if (oldVideo) {
      setCurrentVideo(oldVideo);
    }
    if (currentVideo) {
      currentVideo.pause();
    }
  }, [oldVideo]);

  const playEventHandler = (video) => {
    setOldVideo(video);
  };

  useEffect(() => {
    if (brandvideos) {
      setPageSize(10);
      setPage(1);
      setTotal(brandvideos?.totalVideos);
    } else {
      setPage(1);
      setPageSize(10);
      setTotal(0);
    }
  }, [brandvideos]);

  /******************* 
  @Purpose : React hook Used for get Brand videos 
  @Parameter : {}
  @Author : Vista app
  ******************/
  useEffect(() => {
    let body = {
      searchText: searchText,
      pageNumber: page,
      pageSize: pageSize,
      filter: [],
    };

    dispatch(getBrandVideos(body)).then((res) => {
      if (res.status) {
        setPageSize(res?.pageSize);
        setPage(res?.pageNumber);
      } else {
        setPageSize(10);
        setPage(1);
      }
    });
  }, [searchText, page, pageSize]);

  const handleReset = () => {
    let body = {
      searchText: "",
      pageNumber: 1,
      pageSize: 10,
      filter: [],
    };

    dispatch(getBrandVideos(body)).then((res) => {
      if (res) {
        setPage(parseInt(res?.pageNumber));
        setPageSize(parseInt(res?.pageSize));
      }
      onHide();
      // if (res) {
      //   onHide();
      // }
    });
  };
  /******************* 
  @Purpose : Used for Bread crumb 
  @Parameter : {}
  @Author : Vista app
  ******************/
  const breadcrumb = [
    {
      title: VIDEO_LIST,
      url: route.BRAND_VIDEOS,
    },
  ];

  /******************* 
  @Purpose : Used for pagination
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const paginationChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  /******************* 
  @Purpose :  React hook used for get category value
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    dispatch(getCategories());
    categoryValue();
  }, []);

  /******************* 
  @Purpose : Used for filter key selection and set value 
  @Parameter : {filterField, index}
  @Author : INIC
  ******************/
  const handleFilterTypeFields = (filterField, index) => {
    const filterRow = filterData;
    filterRow[index].key = filterField;
    setFilterData([...filterRow]);
    switch (filterField) {
      case "gender":
        filterRow[index].filterOptions = gender;
        setFilterData([...filterRow]);
        break;
      case "categoryId":
        filterRow[index].filterOptions = category;
        setFilterData([...filterRow]);
        break;
      case "subCategoryId":
        const subCat = subCategoryValue();
        filterRow[index].filterOptions = subCat;
        setFilterData([...filterRow]);
        break;
    }
  };

  /******************* 
  @Purpose :  used for category value maping
  @Parameter : {}
  @Author : INIC
  ******************/
  const categoryValue = () => {
    let categoryArr = [];
    categoryInfo?.length > 0 &&
      categoryInfo?.forEach((optiondata) => {
        let tempObj = {
          label: optiondata?.title,
          value: optiondata?._id,
          ...optiondata,
        };
        categoryArr.push(tempObj);
        setCategory(categoryArr);
      });
  };

  /******************* 
  @Purpose :  used for sub category value maping
  @Parameter : {list}
  @Author : INIC
  ******************/
  const subCategoryValue = () => {
    const catValue = filterData.filter((obj) => obj.key === "categoryId")[0]
      .value;
    const catData = categoryInfo.filter((obj) => obj._id === catValue)[0];
    let subCategoryArr = [];
    catData?.subCategoryDetails?.length > 0 &&
      catData?.subCategoryDetails?.forEach((subCategory) => {
        let tempObj2 = {
          label: subCategory?.title,
          value: subCategory?._id,
        };
        subCategoryArr.push(tempObj2);
      });
    return subCategoryArr;
  };

  /******************* 
  @Purpose :  used for set filter value
  @Parameter : {fieldValue,index}
  @Author : INIC
  ******************/
  const handleValueChange = (fieldValue, index) => {
    handleFilterArray();
    const filterRow = filterData;
    if (filterRow[index].key === "categoryId") {
      const catValue = filterData.findIndex(
        (obj) => obj.key === "subCategoryId"
      );
      if (catValue !== -1) {
        filterRow.splice(catValue, 1);
      }
    }
    filterRow[index].value = fieldValue;
    setFilterData([...filterRow]);
  };

  /******************* 
  @Purpose :  used for handle filter type field
  @Parameter : {index}
  @Author : INIC
  ******************/
  const handleFilterTypeField = (index) => {
    if (index === 0) {
      return filterTypeFieldValueoption;
    } else {
      return [
        { value: "gender", label: "Gender" },
        { value: "categoryId", label: "Category" },
        { value: "subCategoryId", label: "Sub-Category" },
      ];
    }
  };

  /******************* 
  @Purpose :  used for hide modal
  @Parameter : {}
  @Author : INIC
  ******************/
  const onHide = () => {
    setModalShow(false);
    setFilterData([defaultFilter]);
    setSelectedFilterItems([]);
  };

  /******************* 
  @Purpose : Used for handle filter data
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleFilterArray = (data = filterData) => {
    let selected = data.map((item) => item.key);
    setSelectedFilterItems(selected);
  };

  /******************* 
  @Purpose :  used for handle filter tags
  @Parameter : {fieldValue,index}
  @Author : INIC
  ******************/
  const handleFiltertags = (index) => {
    const { filterOptions } = filterData[index];
    const onChange = (e) => handleValueChange(e.value, index);
    return (
      <Select
        options={filterOptions}
        className="custom-select-container"
        classNamePrefix="custom-select"
        onChange={onChange}
      />
    );
  };

  /******************* 
  @Purpose :  used for api calling for filter
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleSubmit = () => {
    let body = {
      searchText,
      pageNumber: page,
      pageSize,
      filter: filterData,
    };
    dispatch(getBrandVideos(body)).then((res) => {
      if (res.status === 0) {
        setPage(1);
        setPageSize(10);
        setModalShow(false);
        setFilterData([defaultFilter]);
        setSelectedFilterItems([]);
      } else {
        setPage(parseInt(res?.pageNumber, 10));
        setPageSize(parseInt(res?.pageSize, 10));
        setModalShow(false);
        setFilterData([defaultFilter]);
        setSelectedFilterItems([]);
      }
    });
  };

  /******************* 
  @Purpose : Used for debounce for the search
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleDebouncedChange = React.useMemo(
    () => debounce((evt) => setSearchText(evt), 500),
    []
  );
  /******************* 
  @Purpose : Used for search on change
  @Parameter : {e}
  @Author : INIC
  ******************/
  const handleSearchChange = (e) => {
    e.persist();
    handleDebouncedChange(e.target.value);
  };
  // console.log("filterdata", filterData);
  // console.log("default", defaultFilter);
  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Tagged Videos">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <div className="row mb-5">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <div className="action-wrap d-flex">
                      <div className="search-input-wrapper position-relative me-3">
                        <i className="icon-magnifier"></i>
                        <input
                          type="search"
                          className="form-control text-capitalize "
                          placeholder="search"
                          onChange={(e) => handleSearchChange(e)}
                        />
                      </div>
                      <Button
                        variant="primary"
                        active
                        className="btn-bordered d-flex align-items-center justify-content-center "
                        onClick={() => setModalShow(true)}
                      >
                        <i className="bx bx-filter-alt align-text-top me-1"></i>
                        Filter
                      </Button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row video-list">
                      {brandvideos?.data?.length > 0 ? (
                        brandvideos?.data?.map((data, key) => (
                          <div
                            className="col-lg-4 col-xl-3 col-md-6"
                            key={data.id}
                          >
                            <VideoCard
                              link={`${route.VIDEO_INFO}/${data?._id}`}
                              caption={data?.videoTitle}
                              createdAt={data?.createdAt}
                              videoLink={data?.videoLink}
                              celebrityDetails={data?.celebrityDetails}
                              viewCount={data?.viewCount}
                              productViews={data?.affiliateClickCount}
                              playEventHandler={playEventHandler}
                              tooltiipShow={tooltiipShow}
                            />
                          </div>
                        ))
                      ) : (
                      <div className="col-12 text-center">
                        <h6 className="text-danger mt-5 mb-0">Videos Not Found</h6>
                      </div>
                      )}

                      <div className="table-footer d-flex flex-wrap align-items-center justify-content-between">
                        <p className="mb-0 align-self-center text-primary-light fs-14">
                          Showing <b>{brandvideos?.data?.length ?? 0} </b> Out
                          of
                          <b> {total ?? 0} </b>
                        </p>
                        <div className="pagination-list ms-2">
                          <Pagination
                            className="ant-pagination bg-white border-primary"
                            pageSize={pageSize}
                            current={page}
                            total={total}
                            onChange={paginationChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>

      <FilterModel
        show={modalShow}
        filterData={filterData}
        setFilterData={setFilterData}
        defaultFilter={defaultFilter}
        filterTypeField={handleFilterTypeField}
        handleFilterTypeFields={handleFilterTypeFields}
        handleValueChange={handleValueChange}
        handleSubmit={handleSubmit}
        handleFiltertags={handleFiltertags}
        handleFilterArray={handleFilterArray}
        selectedFilterItems={selectedFilterItems}
        isSearchButtonDisabled={isSearchButtonDisabled}
        onHide={onHide}
        handleReset={handleReset}
        field={field}
        isUpdateFilter={isUpdateFilter}
        setIsUpdateFilter={setIsUpdateFilter}
      />
    </Layout>
  );
};

export default BrandVideos;
