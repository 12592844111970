import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { callApi } from "../../Api";
import errorMessages from "../../Utils/ErrorMessages";
import { showMessageNotification, validateEmail } from "../../Utils/Functions";
import Theme from "../../Components/Layout/Theme";
import { Link } from "react-router-dom";
import { forgotPasswordAction } from "../../Store/Actions/User";

/******************* 
@Purpose : Used for forgot password
@Parameter : props
@Author : INIC
******************/
function ForgotPassword(props) {
  const dispatch = useDispatch();
  let [errors, setErrors] = useState({ emailId: "" });
  const [emailId, setEmailId] = useState("");
  let [loading, setLoading] = useState(false);

  /******************* 
  @Purpose : Used for form validation
  @Parameter : {}
  @Author : INIC
  ******************/
  const validateForm = () => {
    let error = { emailId: "" };
    let isFormValid = true;
    if (!emailId.trim()) {
      error.emailId = errorMessages.PROVIDE_EMAIL;
    } else if (!validateEmail(emailId)) {
      error.emailId = errorMessages.PROVIDE_VALID_EMAIL;
    } else {
      error.emailId = false;
    }
    setErrors(error);

    return isFormValid;
  };

  /******************* 
  @Purpose : Used for forgot password actions
  @Parameter : e
  @Author : INIC
  ******************/
  const forgotPassword = () => {
    let body = { emailId: emailId };
    try {
      if (validateForm()) {
        setLoading(true);
        const res = dispatch(forgotPasswordAction(body));
        setLoading(false);
        if (res) {
          setEmailId("");
          setErrors("");
          showMessageNotification(res.message, "success");
        } else {
          showMessageNotification(res.message, "error");
        }
      }
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <div className="App">
      <Theme />
      <div
        className="login-wrapper"
        style={{
          background:
            "url(../assets/images/login-bg.jpg) no-repeat center center",
        }}
      >
        <div className="login-body">
          <div className="login-container row m-0">
            <div className="col-xl-7 col-11">
              <div className="card bg-login-card">
                <div className="row g-0">
                  <div className="col-md-7 col-12 pl-0 pr-0">
                    <div className="card rounded-left mb-0 p-4 d-flex justify-content-center h-100">
                      <div className="card-header mb-4">
                        <h5 className="card-title text-center">
                          Forgot Password
                        </h5>
                        <div className="text-center">
                          <p className="common-small-text">
                            Enter the email you used and we will send you
                            temporary link
                          </p>
                        </div>
                      </div>
                      <div className="card-content">
                        <div className="card-body pb-0">
                          <form onSubmit={() => forgotPassword()}>
                            <div className="form-group mb-4">
                              <label
                                className="form-label"
                                htmlFor="EmailAddress"
                              >
                                Email
                              </label>
                              <input
                                type="email"
                                className={
                                  errors.emailId
                                    ? "form-control input-error"
                                    : "form-control"
                                }
                                id="EmailAddress"
                                aria-describedby="emailHelp"
                                placeholder="Enter your Email "
                                value={emailId}
                                onChange={(e) => {
                                  setEmailId(e.target.value);
                                  let error = Object.assign(errors, {
                                    emailId: "",
                                  });
                                  setErrors(error);
                                }}
                              />
                              <span className="error-msg">
                                {errors.emailId}
                              </span>
                            </div>
                            <Button
                              type="button"
                              className="btn w-100 btn-primary glow mb-3 btn-block"
                              onClick={() => forgotPassword()}
                            >
                              {loading ? "Loading" : "Send Link"}
                              <i className="ms-2 align-middle text-white icon-arrow-right"></i>
                            </Button>
                            <Link
                              type="button"
                              to="/login"
                              className="card-link w-100 btn btn-light glow position-relative btn-block"
                            >
                              Cancel
                            </Link>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 d-md-block d-none text-center align-self-center p-7">
                    <div className="card-content">
                      <img
                        className="img-fluid"
                        src="assets/images/forgot-password.png"
                        alt="sign up"
                        width="300"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(null, { callApi })(ForgotPassword);
