import { callApi } from "../../Api";
import Routes from "../../Api/Routes"; // API endpoint routes
import { showMessageNotification } from "../../Utils";

const { STATEANDCITIES } = Routes;

/*******************
@purpose :  send payment detail
@Parameter : {data}
@Author : INIC
******************/
export const StateCities = () => async (dispatch) => {
  const res = await callApi(STATEANDCITIES, {}, "POST", "", true);
  if (res) {
    dispatch(setStateCities(res?.StatesAndCites));
    return Promise.resolve(res);
  } else showMessageNotification(res.message, "error");
  return Promise.reject(res);
};

export const setStateCities = (data) => ({
  type: "SET_STATE_CITIES",
  payload: data,
});
