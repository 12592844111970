import { callApi } from "../../Api";
import Routes from "../../Api/Routes";
import { showMessageNotification } from "../../Utils";

const { CREATE_VIDEO_ORDER } = Routes;

/*******************
@purpose :  get Plan History
@Parameter : {data}
@Author : INIC
******************/
export const PurchaseVideoOrder = (data) => async () => {
  const res = await callApi(`${CREATE_VIDEO_ORDER}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      showMessageNotification(res.message);
    }
    return Promise.resolve(res);
  }
  return Promise.reject(res);
};
