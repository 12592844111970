import React, { useEffect } from "react";
import { FormControl, FormLabel, FormText } from "react-bootstrap";
import clsx from "clsx";

/*******************
@purpose : used for common textfield
@Parameter : {}
@Author : INIC
******************/
function TextField({
  disabled,
  label,
  required = true,
  name,
  labelClassName,
  formik,
  feedbackClassName,
  touched,
  errors,

  ...others
}) {
  /*******************
  @purpose : used for assign error and touched 
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    if (formik && !touched && !errors) {
      touched = formik?.touched[name];
      errors = formik?.errors[name];
    }
  }, [formik]);

  /*******************
  @purpose : used for dynamic classes 
  @Parameter : {}
  @Author : INIC
  ******************/
  const getValidateClass = () => {
    if (!formik?.touched[name]) {
      return "w-100";
    }
    if (formik?.touched[name] && formik?.errors[name]) {
      return "w-100 error-block";
    }
    return " w-100 success-block";
  };

  /******************* 
  @purpose : Rander HTML/ React Components
  @Author :INIC
  ******************/
  return (
    <div
      className={clsx("position-relative w-100  mb-3", {
        "error-block": !disabled && touched && errors,
      })}
    >
      {label && (
        <FormLabel className={labelClassName}>
          {label}
          {required && <sup className="sub-asterisk text-danger">*</sup>}
        </FormLabel>
      )}
      <div className={getValidateClass()}>
        <FormControl
          {...formik?.getFieldProps(name)}
          {...others}
          disabled={disabled}
        />
        {touched && errors && <span className="icon-error-icon error-icon" />}
        {touched && !errors && (
          <span className="icon-ic-check-mark error-icon" />
        )}
      </div>
      {formik?.touched[name] && formik?.errors[name] && (
        <FormText className={(feedbackClassName, "error text-danger")}>
          {formik?.errors[name]}
        </FormText>
      )}
    </div>
  );
}

export default TextField;
