import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import swal from "sweetalert";

import { deleteCard, getAllCards } from "../../Store/Actions/CardInfo";

/******************* 
@Purpose : Used for Card listing
@Parameter : {setShowEditBrand}
@Author : INIC
******************/
const CardList = ({ setShowEditBrand }) => {
  const { cardinfo } = useSelector((data) => data.user);
  const dispatch = useDispatch();

  /******************* 
  @Purpose : React hook used for fetch all cards
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    dispatch(getAllCards());
  }, []);

  /******************* 
  @Purpose : used for toggle add card components
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleAddCard = () => {
    setShowEditBrand(true);
  };

  /******************* 
  @Purpose : used for delete cards
  @Parameter : {id}
  @Author : INIC
  ******************/
  const handleDelete = async (id) => {
    if (id) {
      await swal({
        title: "Are you sure,You Want To Delete ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let data = {
            cardId: id,
          };
          dispatch(deleteCard(data))
            .then((response) => {
              if (response.status === 1) {
                dispatch(getAllCards());
              }
            })
            .catch((error) => {
              console.error(error);
              throw error;
            });
        }
      });
    }
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <>
      <div className="card">
        <div className="card-header d-flex justify-content-between pb-0">
          <h5 className="card-title">Cards</h5>
          <Button
            className=""
            variant="primary"
            onClick={() => handleAddCard()}
          >
            Add New Card
          </Button>
        </div>
        <div className="card-body">
          <div className="table-responsive mb-3">
            <table
              className="table row-border nowrap common-datatable"
              id="user-listing"
            >
              <thead>
                <tr>
                  <th>
                    Type
                    <i aria-hidden="true"></i>
                  </th>
                  <th>
                    Name
                    <i aria-hidden="true"></i>
                  </th>
                  <th>
                    Card Number
                    <i aria-hidden="true"></i>
                  </th>
                  <th>
                    Expiry
                    <i aria-hidden="true"></i>
                  </th>
                  <th>
                    CVV
                    <i aria-hidden="true"></i>
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {cardinfo && cardinfo?.length > 0 ? (
                  cardinfo?.map((card, id) => {
                    return (
                      <tr key={card.id}>
                        <td>{card?.cardType}</td>
                        <td>{card?.name}</td>
                        <td>{card?.cardNumber}</td>
                        <td>{card?.expiry}</td>
                        <td>322</td>
                        <td>
                          <div className="d-flex ml-3">
                            <a
                              className="cursor-pointer m-2"
                              onClick={() => handleDelete(card?._id)}
                            >
                              <i className="icon-trash"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="text-danger text-center" colSpan={6}>
                      Card Not Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardList;
