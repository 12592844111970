import React, { useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { State, City } from "country-state-city";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";

import { EditBrandDetails } from "../../Utils/ValidationSchema";
import { PhoneField, SelectField } from "../../Components/FormFields";
import { editBrandProfile, Uploadimages } from "../../Store/Actions/User";
import TextField from "../../Components/FormFields/TextField";
import { showMessageNotification } from "../../Utils";

/******************* 
@Purpose : Used for edit brand profile 
@Parameter : {props,setShowEditBrand}
@Author : INIC
******************/
const EditBrand = ({ setShowEditBrand }) => {
  const formData1 = new FormData();
  const dispatch = useDispatch();
  const uploadedImage = useRef(null);
  const imageUploader = useRef(null);
  const { userProfile } = useSelector((user) => user.user);
  const [brandImage, setBrandImage] = useState("");
  const [isoCode, setIsoCode] = useState();
  const [loading, setLoading] = useState(false);

  /******************* 
  @Purpose : Used for get state list
  @Parameter : {countrycode}
  @Author : INIC
  ******************/
  const getStateList = (contry = "IN") => {
    return State.getStatesOfCountry(contry).map((state) => ({
      label: state.name,
      value: state.name,
      ...state,
    }));
  };

  /******************* 
  @Purpose : Used for get states cities list
  @Parameter : {countrycode,statecode}
  @Author : INIC
  ******************/
  const getCitiesList = (contry = "IN", stateCode = isoCode) => {
    return City.getCitiesOfState(contry, stateCode).map((city) => ({
      label: city.name,
      value: city.name,
      ...city,
    }));
  };

  /******************* 
  @Purpose : Used for image upload
  @Parameter : {e}
  @Author : INIC
  ******************/
  const handleImageUpload = (e) => {
    try {
      const [file] = e.target.files;
      if (file) {
        const reader = new FileReader();
        const { current } = uploadedImage;
        current.file = file;
        setBrandImage(current.file);
        formData1.append("image", current.file);
        reader.onload = (e) => {
          current.src = e.target.result;
        };
        reader.readAsDataURL(file);
        e.target.value = null;
      }
    } catch (error) {
      console.error(error);
    }
  };

  /******************* 
  @purpose : Used for close edit model
  @Parameter : {}
  @Author :INIC
  ******************/
  const handleCancel = () => {
    setShowEditBrand(false);
  };

  /******************* 
   @purpose : set formik validation schema
   @Parameter : {}
   @Author :INIC
   ******************/
  const formik = useFormik({
    initialValues: {
      ...userProfile,
      state: {
        label: userProfile?.state,
        value: userProfile?.state,
      },
      city: {
        label: userProfile?.city,
        value: userProfile?.city,
      },
      isBusinessOnline: userProfile?.isBusinessOnline?.toString(),
      gstNumber: userProfile?.gstNumber?.toString(),
    },
    enableReinitialize: true,
    validationSchema: EditBrandDetails(),
    onSubmit: (values) => {
      setLoading(true);
      uploadFile().then((photo) => {
        const { state, city, pin, whatsAppBusinessNumber } = values;
        const data = {
          ...values,
          state: state.value,
          city: city.value,
          whatsAppBusinessNumber:
            values.isBusinessOnline === "false" ? whatsAppBusinessNumber : "",
          photo: photo?.imagePath,
          pin: pin.toString(),
          compressedImage: photo?.compressedImage,
        };
        delete data.emailId;
        dispatch(editBrandProfile(data)).then((res) => {
          if (res.status) {
            setLoading(false);
            setShowEditBrand(false);
          } else {
            showMessageNotification(res?.message, "error");
            setLoading(false);
          }
        });
      });
    },
  });
  /******************* 
  @Purpose : Used for post image 
  @Parameter : {}
  @Author : INIC
  ******************/
  const uploadFile = () => {
    let formData2 = new FormData();
    formData2.append("image", brandImage);
    return dispatch(Uploadimages(formData2)).then((response) => {
      if (response.imagePath) {
        return response;
      }
    });
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <>
      <form>
        <div className="card">
          <div className="card-body">
            <Row>
              <Col xxl={2} lg={3} md={4}>
                <div className="edit-image text-center">
                  <div>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageUpload}
                      ref={imageUploader}
                      style={{
                        display: "none",
                      }}
                    />
                    <div className="user-image overflow-hidden mb-2 mx-auto">
                      <img
                        ref={uploadedImage}
                        src={userProfile?.photo}
                        alt="Icon"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "/assets/images/brandImage.png";
                        }}
                      />
                    </div>
                    <Button
                      className="btn btn-primary mx-3"
                      onClick={() => imageUploader.current.click()}
                    >
                      Change
                    </Button>
                  </div>
                </div>
              </Col>
              <Col xxl={10} lg={9} md={8}>
                <Row>
                  <Col lg={12} xl={6}>
                    <TextField
                      type="text"
                      label="Organization Name"
                      placeholder="Enter Organization Name"
                      name="organization"
                      formik={formik}
                    />
                  </Col>
                  <Col lg={12} xl={6}>
                    <TextField
                      type="text"
                      label="GST Number"
                      placeholder="Enter GST Number"
                      name="gstNumber"
                      formik={formik}
                      required={false}
                    />
                  </Col>
                  <Col lg={12} xl={6}>
                    <PhoneField
                      label="Phone number"
                      name="mobileNumber"
                      country="in"
                      countryCodeEditable={false}
                      formik={formik}
                      masks=""
                      value={formik?.values?.mobileNumber}
                    />
                  </Col>

                  <Col lg={12} xl={6}>
                    <TextField
                      type="text"
                      label="Address"
                      placeholder="Enter Address"
                      name="address"
                      formik={formik}
                    />
                  </Col>

                  <Col lg={12} xl={6}>
                    <TextField
                      type="text"
                      label="Contact Name"
                      placeholder="Enter Contact Name"
                      name="firstName"
                      formik={formik}
                    />
                  </Col>
                  <Col lg={12} xl={6}>
                    <SelectField
                      label="State"
                      name="state"
                      className="custom-select-container"
                      classNamePrefix={"custom-select"}
                      options={getStateList()}
                      values={formik.values.state}
                      onChange={() => formik.setFieldValue("city", "")}
                      setIsoCode={setIsoCode}
                      formik={formik}
                    />
                  </Col>
                  <Col lg={12} xl={6}>
                    <TextField
                      type="text"
                      label="country"
                      name="country"
                      formik={formik}
                      required={false}
                      value="India"
                      disabled
                    />
                  </Col>

                  <Col lg={12} xl={6}>
                    <TextField
                      type="number"
                      label="Zip"
                      placeholder="Enter Zipcode"
                      name="pin"
                      formik={formik}
                    />
                  </Col>

                  <Col lg={12} xl={6}>
                    <SelectField
                      className="custom-select-container"
                      classNamePrefix={"custom-select"}
                      label="City"
                      name="city"
                      options={getCitiesList()}
                      values={formik?.values?.city}
                      formik={formik}
                    />
                  </Col>

                  <Col lg={12} xl={6}>
                    <TextField
                      type="url"
                      label="Twitter URL"
                      placeholder="Enter Twitter URL"
                      name="twitterId"
                      formik={formik}
                      required={false}
                    />
                  </Col>

                  <Col lg={12} xl={6}>
                    <TextField
                      type="url"
                      label="Youtube URL"
                      placeholder="Enter Youtube URL"
                      name="youTubeId"
                      formik={formik}
                      required={false}
                    />
                  </Col>

                  <Col lg={12} xl={6}>
                    <TextField
                      type="url"
                      label="LinkedIn URL"
                      placeholder="Enter linkedIn URL"
                      name="linkdnId"
                      formik={formik}
                      required={false}
                    />
                  </Col>

                  <Col lg={12} xl={6}>
                    <TextField
                      type="url"
                      label="Instagram URL"
                      placeholder="Enter Instagram URL"
                      name="instagramId"
                      formik={formik}
                      required={false}
                    />
                  </Col>

                  <Col lg={12} xl={6}>
                    <TextField
                      type="url"
                      label="Facebook URL"
                      placeholder="Enter fecebook URL"
                      name="fbId"
                      formik={formik}
                      required={false}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <TextField
                      as="textarea"
                      rows={3}
                      label="Short Description/Bio"
                      placeholder="Enter The description here..."
                      name="bio"
                      formik={formik}
                      required={false}
                    />
                  </Col>
                </Row>

                <div className="row">
                  <div className="col-md-6">
                    <div className="d-flex align-items-center">
                      <input
                        type="radio"
                        value={"true"}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "isBusinessOnline",
                            e.target.value
                          );
                        }}
                        checked={formik.values.isBusinessOnline === "true"}
                        name="isBusinessOnline"
                        id="isBusinessOnline"
                        className="me-2"
                      />
                      <label for="isBusinessOnline" className="m-0">
                        Is your business online
                      </label>
                    </div>
                    <br />

                    {formik.values.isBusinessOnline === "true" ? (
                      <TextField
                        type="url"
                        label="Enter website URL"
                        placeholder="Enter website URL"
                        name="websiteUrl"
                        formik={formik}
                        autoComplete="off"
                      />
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex align-items-center">
                      <input
                        type="radio"
                        name="isBusinessOnline"
                        id="isBusinessOffline"
                        value={"false"}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "isBusinessOnline",
                            e.target.value
                          );
                        }}
                        checked={formik.values.isBusinessOnline === "false"}
                        className="me-2"
                      />
                      <label for="isBusinessOffline" className="m-0">
                        Is your business Offline
                      </label>
                    </div>
                    <br />

                    {formik.values.isBusinessOnline === "false" ? (
                      <PhoneField
                        name="whatsAppBusinessNumber"
                        country={"in"}
                        label="Enter Whatspp Buisness Number"
                        placeholder="+91 9988776655"
                        masks={""}
                        countryCodeEditable={false}
                        formik={formik}
                        value={formik?.values?.whatsAppBusinessNumber}
                      />
                    ) : null}
                  </div>
                </div>
              </Col>

              <div className="col-md-12 text-end">
                <Button
                  className="me-2"
                  variant="light"
                  type="reset"
                  onClick={() => handleCancel()}
                >
                  Cancel
                </Button>

                <Button
                  variant="primary"
                  onClick={() => formik.handleSubmit()}
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Saving...
                    </>
                  ) : (
                    "Save changes"
                  )}
                </Button>
              </div>
            </Row>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditBrand;
