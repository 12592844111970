import React, { useState, useEffect } from "react";
import { Tab, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import EditBrand from "../EditBrand/EditBrand";
import { BrandCard, CardList } from "../../Components/BrandCard";
import { Footer, Layout } from "../../Components/Layout";
import BrandDetail from "../../Components/BrandDetails/BrandDetail";
import ManagePlan from "../../Components/ManagePlan/ManagePlan";
import ManagePassword from "../../Components/ManagePassword/ManagePassword";
import { setTabsValue } from "../../Store/Actions/Tabs";
import {
  MANAGE_PROFILE,
  MANAGE_CARD,
  MANAGE_PASSWORD,
  MANAGE_PLAN,
  MANAGE_SERVICE_CATEGORY,
  LINK_SELLER_ACCOUNT,
} from "../../Utils";

/******************* 
@Purpose : Used for profile view
@Parameter : {}
@Author : INIC
******************/
function Profile() {
  const [showEditBrand, setShowEditBrand] = useState(false);
  const [manageBreadcrumb, setManageBreadcrumb] = useState([
    {
      title: MANAGE_PROFILE,
      url: "/AdminProfile",
    },
  ]);
  const { tabs } = useSelector((data) => data.tabs);
  const dispatch = useDispatch();

  /*******************
    @purpose :  React hook used for handle breadcrumb
    @Parameter : {}
    @Author : INIC
  ******************/
  useEffect(() => {
    handleBreadcrumb(tabs);
  }, []);
  /*******************
    @purpose :  used for Tabs handle 
    @Parameter : {}
    @Author : INIC
  ******************/
  const handleTabs = (eventKey) => {
    handleBreadcrumb(eventKey);
    dispatch(setTabsValue(eventKey));
  };
  /*******************
    @purpose :  used for handle breadcrumb
    @Parameter : {}
    @Author : INIC
  ******************/
  const breadcrumbMap = {
    ManageProfileData: {
      title: MANAGE_PROFILE,
      url: "/profile",
    },
    ManagePassword: {
      title: MANAGE_PASSWORD,
      url: "/profile",
    },
    ManagePlan: {
      title: MANAGE_PLAN,
      url: "/profile",
    },
    Manageservicecategory: {
      title: MANAGE_SERVICE_CATEGORY,
      url: "/profile",
    },
    Linkselleraccount: {
      title: LINK_SELLER_ACCOUNT,
      url: "/profile",
    },
    MyCards: {
      title: MANAGE_CARD,
      url: "/profile",
    },
  };

  const handleBreadcrumb = (eventKey) => {
    setManageBreadcrumb([
      breadcrumbMap[eventKey] || breadcrumbMap["ManageProfileData"],
    ]);
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={manageBreadcrumb} breadcrumbTitle="My profile">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <form className="mb-5">
              <div className="profile-tabs tabs-block p-0">
                <Tab.Container
                  id="left-tabs-example"
                  activeKey={tabs ? tabs : "ManageProfileData"}
                  onSelect={(eventKey) => handleTabs(eventKey)}
                >
                  <Nav variant="pills" className="mb-3">
                    <Nav.Item>
                      <Nav.Link eventKey="ManageProfileData">
                        Manage Profile Data
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="ManagePassword">
                        Manage Password
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="ManagePlan">Manage plan</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link eventKey="Manageservicecategory">
                        Manage Service Category
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Linkselleraccount">
                        Link seller account
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="MyCards">My Cards</Nav.Link>
                    </Nav.Item> */}
                  </Nav>

                  <Tab.Content className="notification-card">
                    <Tab.Pane eventKey="ManagePassword">
                      <ManagePassword />
                    </Tab.Pane>
                    <Tab.Pane eventKey="ManageProfileData">
                      {!showEditBrand ? (
                        <BrandDetail setShowEditBrand={setShowEditBrand} />
                      ) : (
                        <EditBrand setShowEditBrand={setShowEditBrand} />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="ManagePlan">
                      <ManagePlan />
                    </Tab.Pane>
                    <Tab.Pane eventKey="Manageservicecategory">
                      Manageservicecategory
                    </Tab.Pane>
                    <Tab.Pane eventKey="Linkselleraccount">
                      Linkselleraccount
                    </Tab.Pane>
                    <Tab.Pane eventKey="MyCards">
                      {!showEditBrand ? (
                        <CardList setShowEditBrand={setShowEditBrand} />
                      ) : (
                        <BrandCard setShowEditBrand={setShowEditBrand} />
                      )}
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </form>
          </div>
          <Footer />
        </div>
      </div>
    </Layout>
  );
}
export default Profile;
