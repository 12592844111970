import { callApi } from "../../Api";
import Routes from "../../Api/Routes";
import { showMessageNotification } from "../../Utils";

const {
  PUBLISH_VIDEO,
  POSTED_VIDEO,
  DELETE_POSTED_VIDEO,
  EDIT_POSTED_VIDEO,
  REMAIN_VIDEO,
} = Routes;

/*******************
@purpose :  used for publish video
@Parameter : {data}
@Author : INIC
******************/
export const PublishVideos = (data) => async () => {
  const res = await callApi(`${PUBLISH_VIDEO}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      showMessageNotification(res.message);
    }
    return Promise.resolve(res);
  }
  return Promise.reject(res);
};

/*******************
@purpose :  get posted videos
@Parameter : {data}
@Author : INIC
******************/
export const PostedVideos = (data) => async (dispatch) => {
  const res = await callApi(POSTED_VIDEO, data, "post", "", true);
  if (res) {
    dispatch(setPostedVideos(res));
    return Promise.resolve(res);
  } else {
    showMessageNotification(res.message, "error");
    return Promise.reject(res);
  }
};

export const setPostedVideos = (data) => ({
  type: "SET_POSTED_VIDEOS",
  payload: data,
});

/*******************
@purpose :  delete posted videos
@Parameter : {data}
@Author : INIC
******************/
export const DeletePostedVideos = (data) => async () => {
  const res = await callApi(DELETE_POSTED_VIDEO, data, "post", "", true);
  if (res) {
    return Promise.resolve(res);
  } else {
    return Promise.reject(res);
  }
};

/*******************
@purpose :  delete posted videos
@Parameter : {data}
@Author : INIC
******************/
export const EditPostedVideos = (data) => async () => {
  const res = await callApi(EDIT_POSTED_VIDEO, data, "post", "", true);
  if (res) {
    return Promise.resolve(res);
  } else {
    return Promise.reject(res);
  }
};

/*******************
@purpose :  delete posted videos
@Parameter : {data}
@Author : INIC
******************/
export const RemainVideos = () => async (dispatch) => {
  const res = await callApi(REMAIN_VIDEO, "", "get", "", true);
  if (res) {
    dispatch(setRemainVideos(res?.data));
    return Promise.resolve(res);
  } else {
    return Promise.reject(res);
  }
};

export const setRemainVideos = (data) => ({
  type: "SET_REMAIN_VIDEOS",
  payload: data,
});
